import { GetterTree } from "vuex";
import { RootState } from "../../../store/RootState";
import { RoleState, UserData, UserRole } from "./types";

export const getters: GetterTree<RoleState, RootState> = {
  userRoleList(state): UserRole[] {
    return state.userRoleList;
  },
  listOfUsers(state): UserData[] {
    return state.listOfUsers;
  },
};
