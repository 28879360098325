import { getters } from "./getters";
import { Module } from "vuex";
import { QAState } from "../types";
import { actions } from "./actions";
import { mutations } from "./mutations";
import { RootState } from "@/store/RootState";

export const defaultState: QAState = {
  loading: false,
  questions: [],
  selectedBroadcastId: "",
};

export const qa: Module<QAState, RootState> = {
  namespaced: true,
  state: defaultState,
  getters,
  actions,
  mutations,
};
