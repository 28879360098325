import { Getter, GetterTree } from "vuex";
import { FusionAuthUser, Role, User, UserGetter, UserState } from "../../types";

export const getters: GetterTree<UserState, unknown> &
  Record<UserGetter, Getter<UserState, unknown>> = {
  [UserGetter.FaUser](state): FusionAuthUser | null {
    return state.faUser;
  },
  [UserGetter.User](state): User | null {
    return state.user;
  },
  [UserGetter.UserDisplayNameValid](state): boolean {
    return (
      state.user !== null &&
      state.user.editRequest.displayName !== null &&
      state.user.editRequest.displayName !== undefined
    );
  },
  [UserGetter.UserIsAdmin](state): boolean {
    return state.user !== null && state.user.roles.includes(Role.ADMIN);
  },
};
