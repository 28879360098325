import { NchanService } from "@/services/nchan-service";
import { Namespace } from "../types";

export interface NchanState {
  clients: Record<string, ActiveNchanClient>;
}

export enum NchanMutation {
  SetClient = "SetClient",
  RemoveClient = "RemoveClient",
}

export enum NchanAction {
  AddClient = "AddClient",
  RemoveClient = "RemoveClient",
}

export enum NchanGetter {}

export interface ActiveNchanClient {
  inUseBy: Namespace[];
  client: NchanService;
  path: string;
}

export interface NchanClientActionPayload {
  namespace: Namespace;
  nchanUrlPath: string;
}

export interface NchanMessage {
  type: string;
  payload: { "@type"?: string };
}
