import { Role } from "@/spect8-core-vue/src/types";
import { RootState } from "@/store/RootState";
import { GetterTree } from "vuex";
import { Pin, PinsState } from "./types";

export const getters: GetterTree<PinsState, RootState> = {
  pins(state) {
    return state.pins;
  },
  edit(state) {
    return state.edit;
  },
  loaded(state): boolean {
    return state.loaded;
  },
  userPins(state): Pin[] {
    return state.pins.filter((pin) => pin.role === Role.USER);
  },
  moderatorPins(state): Pin[] {
    return state.pins.filter((pin) => pin.role === Role.MODERATOR);
  },
};
