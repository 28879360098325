import { getters } from "./getters";
import { actions } from "./actions";
import { mutations } from "./mutations";
import { Module } from "vuex";

import { PinsState } from "./types";
import { RootState } from "@/store/RootState";

export const state: PinsState = {
  loaded: false,
  pins: [],
};

export const PINS_NAMESPACE = "pins";

export const pins: Module<PinsState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
