import { Mutation, MutationTree } from "vuex";
import Vue from "vue";
import { AuthMutation, AuthState, LoginScope, Tokens } from "../../types";

export const mutations: MutationTree<AuthState> &
  Record<AuthMutation, Mutation<AuthState>> = {
  [AuthMutation.Authenticated](state, authenticated: boolean) {
    Vue.set(state, "authenticated", authenticated);
  },
  [AuthMutation.AccessTokenExt](state, token: string) {
    Vue.set(state, "accessTokenExt", token);
  },
  [AuthMutation.Tokens](state, tokens: Tokens) {
    Vue.set(state, "tokens", tokens);
  },
  [AuthMutation.ResetAuthentication](state) {
    Vue.set(state, "authenticated", false);
    Vue.set(state, "accessTokenExt", undefined);
    Vue.set(state, "tokens", undefined);
  },
  [AuthMutation.LoginScope](state, scope: LoginScope | undefined) {
    Vue.set(state, "loginScope", scope);
  },
};
