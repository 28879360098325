import { Role } from "@/spect8-core-vue/src/types";

export interface QAState {
  loading: boolean;
  questions: Question[];
  selectedBroadcastId: string;
}

export enum QAAction {
  FetchQuestions = "FetchQuestions",
  UpdateQuestion = "UpdateQuestion",
  CreateQuestion = "CreateQuestion",
  SetSelectedBroadcastId = "SetSelectedBroadcastId",
  AddQuestion = "AddQuestion",
  DeleteQuestion = "DeleteQuestion",
  RemoveQuestion = "RemoveQuestion",
  EditQuestion = "EditQuestion",
}

export enum QAMutation {
  SetQuestions = "SetQuestions",
  AddQuestion = "AddQuestion",
  UpdateQuestion = "UpdateQuestion",
  SetLoading = "SetLoading",
  SetSelectedBroadcastId = "SetSelectedBroadcastId",
  RemoveQuestion = "RemoveQuestion",
}

export enum QAGetter {
  Questions = "Questions",
  SelectedBroadcastId = "SelectedBroadcastId",
}

export const QANchanEvents: Record<string, QAAction> = {
  QUESTION_ASKED: QAAction.AddQuestion,
  QUESTION_DELETED: QAAction.RemoveQuestion,
  QUESTION_EDITED: QAAction.EditQuestion,
};

export enum QuestionStatus {
  PENDING = "PENDING",
  APPROVED = "APPROVED",
  DISMISSED = "DISMISSED",
  ANSWERING = "ANSWERING",
  ANSWERED = "ANSWERED",
}

export enum QuestionType {
  TEXT = "TEXT",
  VIDEO = "VIDEO",
  IN_PERSON = "IN_PERSON",
}

export interface Question {
  id: string;
  broadcastId: string;
  content: string;
  type: QuestionType;
  status: QuestionStatus;
  answer: string;
  user: QuestionUser;
  createdAt: string;
  category?: string;
}

export interface QuestionDto {
  content: string;
  note: string;
  type: QuestionType;
}

export interface QuestionPatchDto {
  note?: string;
  answer?: string;
  status?: string;
}

interface QuestionUser {
  userId: string;
  tenantId: string;
  externalUserId: string;
  displayName: string;
  roles: Role[];
}

export interface UpdateQuestionPayload {
  questionId: string;
  patchDto: QuestionPatchDto;
}
export interface CreateQuestionPayload {
  broadcastId: string;
  dto: QuestionDto;
}
