import { RootState } from "@/store/RootState";
import { Getter } from "vuex";
import { RegistrationGetter, RegistrationState } from "../@types/types";

export const getters: Record<
  RegistrationGetter,
  Getter<RegistrationState, RootState>
> = {
  [RegistrationGetter.SelectedBroadcastId](state) {
    return state.selectedBroadcastId;
  },
  [RegistrationGetter.RepresentatorsRegistrations](state) {
    return state.representatorsRegistrations;
  },
  [RegistrationGetter.GetAvailableVotes](state) {
    return state.numAvailableVotes;
  },
};
