import { QAAction, QuestionStatus } from "./../../Qa/types";
import { StartVideoCallPayload, VerifyUserPayload } from "./../types";
import { ConferenceState } from "../types";
import { ActionTree } from "vuex";
import { vicoService } from "@/spect8-core-vue/src/services/vico";
import { RTCSlot, RTCInfo } from "@/spect8-core-vue/src/stores/vico/RtcSlot";
import { Conference } from "@/spect8-core-vue/src/types/vico";
import { Namespace } from "@/store/types";
import {
  CoreConfigGetters,
  User,
  CoreUserGetters,
  CoreVicoActions,
  VicoConfig,
  CoreVicoGetters,
} from "@/spect8-core-vue/src/types";

export const actions: ActionTree<ConferenceState, unknown> = {
  init({ rootGetters }): Promise<void> {
    console.info("[conference->init] start");
    // Get outgoing RTCSlot and add conference specific events the websocket
    const slot: RTCSlot = rootGetters[CoreVicoGetters.SlotOut];
    // const user: User = rootGetters[CoreUserGetters.User];

    if (slot.initialized) {
      console.info("Already initialized!", slot);
      return Promise.resolve();
    }

    slot.wsConnection.on("ConferenceClientJoined", wsHandler);

    slot.wsConnection.on("ConferenceClientLeft", wsHandler);

    slot.wsConnection.on("ConferenceClientSendingTrack", wsHandler);

    slot.wsConnection.on("ConferenceClientStoppedTrack", wsHandler);

    slot.wsConnection.on("ConferenceSlotAssignedTrack", wsHandler);

    // console.log(slot);
    // console.info("[conference->init] resolve");

    return Promise.resolve();
  },

  setTestCall({ commit }, payload: RTCSlot): void {
    commit("setTestCall", payload);
  },
  setTestConference({ commit }, payload: Conference): void {
    commit("setTestConference", payload);
  },
  setSetupDialog({ commit }, payload: boolean): void {
    commit("setSetupDialog", payload);
  },
  setTestCallDialog({ commit }, payload: boolean): void {
    commit("setTestCallDialog", payload);
  },
  async startVideoCall(
    { dispatch, rootGetters, commit },
    payload: StartVideoCallPayload
  ): Promise<RTCSlot | void> {
    const { tenantId } = rootGetters[CoreConfigGetters.TenantConfig];
    if (!tenantId) {
      console.error("Missing tenant id!");
      return;
    }

    // Keep a reference to the question
    commit("setTestQuestion", payload.question);

    const user: User = rootGetters[CoreUserGetters.User];
    const config: VicoConfig = rootGetters[CoreVicoGetters.Config];
    const socketUrl = rootGetters[CoreVicoGetters.SocketUrl];

    const res = await dispatch(
      CoreVicoActions.InitializeCall,
      {
        userId: payload.question.user.userId,
        broadcastId: payload.broadcastId,
        tenantId: tenantId,
      },
      { root: true }
    );

    if (res.conferences[0]) {
      const c = res.conferences[0];
      dispatch("setTestConference", c);
      await dispatch(CoreVicoActions.Broadcast, c, {
        root: true,
      });

      // Build rtcinfo to connect to received test conference
      const rtcInfoWatch = new RTCInfo(
        false,
        c.tenantId,
        c.broadcastId,
        c.id,
        `participant_${payload.question.user.userId}`,
        user.id,
        config.iceServers,
        socketUrl,
        user.editRequest?.displayName ?? user.id
      );

      const slotWatch: RTCSlot = await dispatch(
        CoreVicoActions.Connect,
        rtcInfoWatch,
        { root: true }
      );
      if (slotWatch) {
        dispatch("setTestCall", slotWatch);
        slotWatch.negotiateRtcConnection();
        return slotWatch;
      }
      // this.callDialog = true;
    }
  },

  async verifyUserTracks(
    { dispatch, rootGetters },
    payload: VerifyUserPayload
  ): Promise<boolean | void> {
    const { tenantId } = rootGetters[CoreConfigGetters.TenantConfig];
    if (!tenantId) {
      console.error("Missing tenant id!");
      return;
    }

    const tracks = await vicoService.getUserTracks(payload.userId, tenantId);
    if (!tracks || tracks.trackinfos.length === 0) {
      dispatch(
        `${Namespace.Qa}/${QAAction.UpdateQuestion}`,
        {
          questionId: payload.question.id,
          patchDto: {
            status:
              payload.question.status === QuestionStatus.ANSWERING
                ? QuestionStatus.ANSWERED
                : QuestionStatus.DISMISSED,
          },
        },
        {
          root: true,
        }
      );
    }
  },

  clearTestCall({ state, commit }) {
    if (state.testCall) {
      state.testCall.close();
      commit("setTestCall", null);
    }
    commit("setTestConference", null);
    commit("setTestCallDialog", false);
    commit("setTestQuestion", null);
  },
};

async function wsHandler(topic: string, content: string) {
  console.info(topic, content);
  // if (!msg.content || !msg.content.eventType) return;
  // switch (msg.content.eventType) {
  // case "ConferenceClientJoined":
  // case "ConferenceClientLeft":
  // case "ConferenceClientSendingTrack":
  // case "ConferenceClientStoppedTrack":
  // case "ConferenceSlotAssignedTrack":
  //   break;
  // default:
  //   break;
  // }
}
