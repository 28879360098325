import { RootState } from "@/store/RootState";
import { AccountState } from "../types";
import { getters } from "./getters";
import { actions } from "./actions";
import { mutations } from "./mutations";
import { Module } from "vuex";

export const state: AccountState = {
  accounts: [],
};

export const ACCOUNTS_NAMESPACE = "accounts";

export const accounts: Module<AccountState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
