import { GetterTree } from "vuex";
import { RootState } from "../../../store/RootState";
import { WordState, Word } from "./types";

export const getters: GetterTree<WordState, RootState> = {
  getAllBannedWords(state): Word[] {
    return state.allBannedWords;
  },
  getBadWordsError(state) {
    return state.badWordsError;
  },
};
