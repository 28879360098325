import {
  Poll,
  PollStatusSelection,
} from "@/services/api/secure-votes.service.types";
import { Mutation, MutationTree } from "vuex";
import { PollMutation, PollState } from "../types";

export const mutations: MutationTree<PollState> &
  Record<PollMutation, Mutation<PollState>> = {
  [PollMutation.SetPolls](state, polls: Poll[]) {
    state.securePolls = polls;
    state.secureActivePolls = polls.filter((p: Poll) => {
      return p.status === PollStatusSelection.LIVE;
    });
  },
  [PollMutation.ResetPolls](state) {
    state.securePolls = [];
    state.secureActivePolls = [];
  },
  [PollMutation.AddPoll](state, poll: Poll) {
    if (
      state.securePolls.findIndex(
        (p: Poll) => p.questionId === poll.questionId
      ) < 0
    ) {
      state.securePolls.push(poll);
      if (poll.status === PollStatusSelection.LIVE) {
        state.secureActivePolls.push(poll);
      }
    }
  },
  [PollMutation.DeletePoll](state, pollId: string) {
    state.securePolls = state.securePolls.filter(
      (p) => p.questionId !== pollId
    );
    state.secureActivePolls = state.secureActivePolls.filter(
      (p) => p.questionId !== pollId
    );
  },
  [PollMutation.UpdatePoll](state, poll: Poll) {
    state.securePolls = state.securePolls.map((p) => {
      return p.questionId === poll.questionId ? poll : p;
    });
    state.secureActivePolls = state.securePolls.map((p) => {
      return p.questionId === poll.questionId ? poll : p;
    });
  },
  [PollMutation.SetEditPoll](state, poll: Poll) {
    state.editSecurePoll = poll;
  },
  [PollMutation.ClearEditPoll](state) {
    state.editSecurePoll = null;
  },
};
export default mutations;
