import { GetterTree } from "vuex";
import { RootState } from "../RootState";
import { BroadcastId, ScheduledAds, VideoAds, VideoAdsState } from "./types";

export const getters: GetterTree<VideoAdsState, RootState> = {
  getAllVideoAds(state): VideoAds[] | undefined {
    return state.allVideoAds.content;
  },
  getVideoAdError(state) {
    return state.VideoAdsError;
  },
  getAllBroadcastIds(state): BroadcastId[] | undefined {
    return state.BroadcastIdList;
  },
  getBroadcastIdError(state) {
    return state.BroadcastIdError;
  },
  getAllScheduledAds(state): ScheduledAds[] | undefined {
    const list = state.allScheduledAds.content.filter(
      (element) =>
        !(
          !element.schedulable ||
          element.schedulable["@type"] != "VideoAd" ||
          element.broadcasts.length < 1
        )
    );
    return list;
  },
  getScheduledAdError(state) {
    return state.ScheduledAdsError;
  },
};
