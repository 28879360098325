export enum RouteName {
  //home
  Dashboard = "Dashboard",
  General = "General",
  SignIn = "SignIn",
  InviteUsers = "InviteUsers",
  //settings
  BroadcastManager = "BroadcastManager",
  GeneralSettings = "GeneralSettings",
  PinManagement = "PinManagement",
  //interactive
  Interactive = "Interactive",
  Polls = "Polls",
  SecurePolls = "SecurePolls",
  SecurePoll = "SecurePoll",
  PinnedMessages = "PinnedMessages",
  QaManagement = "QaManagement",
  ShopSettings = "ShopSettings",
  Resources = "Resources",
  VideoAds = "VideoAds",
  Merch = "Merch",
  Schedule = "Schedule",
  ScheduleManagement = "ScheduleManagement",
  PollResults = "PollResults",
  //moderation
  Moderation = "Moderation",
  BlockedTermsAndPhrases = "BlockedTermsAndPhrases",
  AdminManagement = "AdminManagement",
  Roles = "Roles",
  Bans = "Bans",
  Reports = "Reports",
  // Monitoring
  Monitoring = "Monitoring",
  Notification = "Notification",
  MonitoringOverview = "MonitoringOverview",
  // Loyalty
  Loyalty = "Loyalty",
  LoyaltySettings = "LoyaltySettings",
  LoyaltyRewards = "LoyaltyRewards",
  LoyaltyLeaderboard = "LoyaltyLeaderboard",
  Pins = "Pins",
  Accounts = "Accounts",
  // Registrations - GEMA
  Registrations = "Registrations",
  RegistrationsSingle = "RegistrationsSingle",
  RegistrationStats = "RegistrationStats",
  VotingStats = "VotingStats",
  // Accreditation (Akkreditierung) - GEMA
  Accreditation = "Accreditation",
  // Switchboard
  Switchboard = "Switchboard",
}
