import { initStoreEnum } from "../utils/StoreHelpers";
import { LoginType } from "./auth";
import { Media } from "./media";

export interface ConfigModuleConfig {
  mediaBaseUrl: string;
}

export const CORE_CONFIG_NAMESPACE = "s8-core-config";

export interface ConfigState {
  tenantConfig?: TenantConfig;
}

export enum ConfigAction {
  FetchTenantConfig = "fetchTenantConfig",
  SetPhase = "setPhase",
}

type ConfigActionStrings = keyof typeof ConfigAction;
export const CoreConfigActions: Record<ConfigActionStrings, string> =
  initStoreEnum<ConfigActionStrings>(ConfigAction, CORE_CONFIG_NAMESPACE);

export enum ConfigMutation {
  TenantConfig = "tenantConfig",
}

export enum ConfigGetter {
  TenantConfig = "tenantConfig",
}

type ConfigGetterStrings = keyof typeof ConfigGetter;
export const CoreConfigGetters: Record<ConfigGetterStrings, string> =
  initStoreEnum<ConfigGetterStrings>(ConfigGetter, CORE_CONFIG_NAMESPACE);

export interface TenantConfig {
  fusionAuthAppId: string | null;
  mediaUploadBaseUrl: string | null;
  name: string;
  phase: TenantPhase;
  shellConfig: ShellConfig | null;
  tenantId: string;
  loginSettings: {
    enabled: boolean;
    unavailableMessage: string; // html
  };
}

export interface ShellConfig {
  broadcastBackgroundImage: Media | null;
  header: string | null;
  headerImage: Media | null;
  logo: Media | null;
  loginBackgroundImage: Media | null;
  loginTypes: LoginType[] | null;
}

export enum TenantPhase {
  NONE = "NONE",
  DEMO = "DEMO",
  REGISTRATION = "REGISTRATION",
  REGISTRATION_AND_PRE_VOTE = "REGISTRATION_AND_PRE_VOTE",
  LIVE = "LIVE",
}
