import { Module } from "vuex";
import { mutations } from "./mutations";
import { getters } from "./getters";
import { actions } from "./actions";
import { ConfigState, Features } from "../types";
import { RootState } from "@/store/RootState";

export const state: ConfigState = {
  enabledFeatures: {} as Features,
};

const namespaced = true;

export const config: Module<ConfigState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
