import { state } from "./state";
import { getters } from "./getters";
import { actions } from "./actions";
import { mutations } from "./mutations";
import { VideoAdsState } from "./types";
import { RootState } from "../RootState";
import { Module } from "vuex";

export const videoAds: Module<VideoAdsState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
