export const I18N_LOCALE = process.env.VUE_APP_I18N_LOCALE || "";
export const I18N_FALLBACK_LOCALE =
  process.env.VUE_APP_I18N_FALLBACK_LOCALE || "";
export const I18N_SUPPORTED_LOCALE =
  process.env.VUE_APP_I18N_SUPPORTED_LOCALE || "";

export const SHELL_URL = process.env.VUE_APP_SHELL_URL || "";
export const LOGIN_LANDING_PAGE = process.env.VUE_APP_LOGIN_LANDING_PAGE || "";

// API's
export const API_BASE_URL = process.env.VUE_APP_API_BASE_URL || "";
export const LINKTRACKER_API_URL = process.env.VUE_APP_LINKTRACKER_URL || "";
export const NCHAN_API_URL = process.env.VUE_APP_NCHAN_URL || "";
export const UPMAN_API_URL = process.env.VUE_APP_UPMAN_URL || "";
export const UPMAN_BASE_URL = process.env.VUE_APP_UPMAN_MEDIA_BASE_URL || "";
export const ANONU_URL = process.env.VUE_APP_ANONU_URL || "";
export const FUSION_AUTH_URL = process.env.VUE_APP_FUSION_AUTH_URL || "";
export const FLUXLOG_URL = process.env.VUE_APP_FLUXLOG_URL || "";

// vico
// TODO: not finalised!!
export const MSGBUS_API_KEY = "1337vAs8-ezGame4Utoo";
export const VICO_URL = process.env.VUE_APP_VICO_URL || "";
export const VICO_WS = process.env.VUE_APP_VICO_WS || "";
