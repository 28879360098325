import NchanSubscriber from "nchan";

export class NchanService {
  sub: NchanSubscriber;

  constructor(
    nchanUrl: string,
    path: string,
    private messageHandler: (m: string) => void = (m: string) => {
      console.log(m);
    },
    private connectHandler: () => void = () => {
      console.log(`Subscribing to ${path}...`);
    },
    private disconnectHandler: () => void = () => {
      console.log(`Unsubscribing from ${path}...`);
    }
  ) {
    console.log("nchan subscribed", { nchanUrl, path });
    const url = `${nchanUrl}/sub/${path}`;
    const sub = new NchanSubscriber(url, { subscriber: "websocket" });
    sub.on("message", this.messageHandler);
    sub.on("connect", this.connectHandler);
    sub.on("disconnect", this.disconnectHandler);
    sub.start();

    this.sub = sub;
  }
}
