import { getters } from "./getters";
import { actions } from "./actions";
import { mutations } from "./mutations";
import { RootState } from "@/store/RootState";
import { Module } from "vuex";
import { NchanState } from "./types";

export const state: NchanState = {
  clients: {},
};

export const nchan: Module<NchanState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
