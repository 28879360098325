import { MutationTree } from "vuex";
import { RoleState, UserData, UserRole } from "./types";

export const mutations: MutationTree<RoleState> = {
  setRolUsersList(state: RoleState, payload: UserRole[]): void {
    state.userRoleList = payload;
  },
  setListOfUsers(state, value: UserData[]) {
    state.listOfUsers = value;
  },
};

export default mutations;
