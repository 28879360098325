import { Poll } from "@/services/api/poll.service.types";
import { Namespace } from "@/store/types";
export interface PollState {
  polls: Poll[];
  activePolls: Poll[];
  editPoll: Poll | null;
  sort: Sorting;
}

export enum PollAction {
  CreatePoll = "CreatePoll",
  FetchPolls = "FetchPolls",
  UpdatePoll = "UpdatePoll",
  DeletePoll = "DeletePoll",
  ResetPolls = "ResetPolls",
  //Edit Poll
  SetEditPoll = "SetEditPoll",
  ClearEditPoll = "ClearEditPoll",
  StartPoll = "StartPoll",
  EndPoll = "EndPoll",
  //   FetchPollsByBroadcastIds = "FetchPollsByBroadcastIds",
  //   FetchPollsByChannelIds = "FetchPollsByChannelIds"
  ExportPolls = "ExportPolls",
  ExportPage = "ExportPage",
  SetSorting = "SetSorting",
}

export const PollActions: Record<PollAction, string> = {
  CreatePoll: `${Namespace.Polls}/${PollAction.CreatePoll}`,
  FetchPolls: `${Namespace.Polls}/${PollAction.FetchPolls}`,
  UpdatePoll: `${Namespace.Polls}/${PollAction.UpdatePoll}`,
  DeletePoll: `${Namespace.Polls}/${PollAction.DeletePoll}`,
  ResetPolls: `${Namespace.Polls}/${PollAction.ResetPolls}`,
  SetEditPoll: `${Namespace.Polls}/${PollAction.SetEditPoll}`,
  ClearEditPoll: `${Namespace.Polls}/${PollAction.ClearEditPoll}`,
  StartPoll: `${Namespace.Polls}/${PollAction.StartPoll}`,
  EndPoll: `${Namespace.Polls}/${PollAction.EndPoll}`,
  ExportPolls: `${Namespace.Polls}/${PollAction.ExportPolls}`,
  ExportPage: `${Namespace.Polls}/${PollAction.ExportPage}`,
  SetSorting: `${Namespace.Polls}/${PollAction.SetSorting}`,
};

export enum PollMutation {
  SetPolls = "SetPolls",
  ResetPolls = "ResetPolls",
  AddPoll = "AddPoll",
  DeletePoll = "DeletePoll",
  UpdatePoll = "UpdatePoll",
  SetEditPoll = "SetEditPoll",
  ClearEditPoll = "ClearEditPoll",
  SetSorting = "SetSorting",
}

export enum PollGetter {
  Polls = "Polls",
  ActivePolls = "ActivePolls",
  EditPoll = "EditPoll",
  Sorting = "Sorting",
}

export const PollGetters: Record<PollGetter, string> = {
  Polls: `${Namespace.Polls}/${PollGetter.Polls}`,
  ActivePolls: `${Namespace.Polls}/${PollGetter.ActivePolls}`,
  EditPoll: `${Namespace.Polls}/${PollGetter.EditPoll}`,
  Sorting: `${Namespace.Polls}/${PollGetter.Sorting}`,
};

export interface PollDurationOption {
  label: string;
  value: number; // Minutes
}

export interface Sorting {
  // Arrays to support multi-sorting
  key: string[];
  descending: boolean[];
}
