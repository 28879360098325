import Vue from "vue";
import Vuex, { StoreOptions } from "vuex";
import { RootState } from "./RootState";
import { users } from "./users";
import { nchan } from "./nchan";

import { badWords } from "../components/TermsAndPhrases/store/index";
import { ban } from "../components/Ban/store";
import { roleUser } from "../components/Roles/roles";
import { generalSettings } from "./generalSettings/index";
import { videoAds } from "./videoAds/index";
import { report } from "../components/Reports/reports";
import { schedule } from "../components/Schedule/schedule";
import { broadcast } from "../components/Broadcast/broadcast/index";
import { config } from "../config/store";
import { polls } from "@/components/Polls/store";
import { Namespace } from "./types";
import { pins } from "@/components/Pins";
import { resources } from "@/components/Resources/store";
import { qa } from "@/components/Qa/store";
import { accounts } from "@/components/Accounts/store";
import { monitoring } from "@/components/Monitoring/monitoring-store";
import { securePolls } from "@/components/SecureVotingPolls/store";
import { conference } from "./../components/Conference/store/index";
import { switchboard } from "@/components/Switchboard/store";
import { registration } from "@/components/Registrations/store";
import { gemaMemberSearch } from "@/components/GemaMemberSearch/store";
import { pollResults } from "@/components/PollResults/store";
import { app } from "./app";

Vue.use(Vuex);

const store: StoreOptions<RootState> = {
  state: {
    version: "0.1.0",
  },
  modules: {
    // TODO: Namespace all vuex stores and enforce module namespace type check
    [Namespace.App]: app,
    [Namespace.Polls]: polls,
    [Namespace.Qa]: qa,
    [Namespace.Accounts]: accounts,
    [Namespace.Resources]: resources,
    [Namespace.Nchan]: nchan,
    [Namespace.Monitoring]: monitoring,
    [Namespace.SecurePolls]: securePolls,
    [Namespace.Registration]: registration,
    [Namespace.GemaMemberSearch]: gemaMemberSearch,
    [Namespace.PollResults]: pollResults,
    [Namespace.Users]: users,
    badWords,
    ban,
    roleUser,
    generalSettings,
    videoAds,
    [Namespace.Report]: report,
    schedule,
    [Namespace.Broadcast]: broadcast,
    config,
    pins,
    [Namespace.Conference]: conference,
    [Namespace.Switchboard]: switchboard,
  },
};

export default new Vuex.Store<RootState>(store);
