import { UpManMedia } from "./media";

export interface Broadcast {
  broadcastName: string;
  id: string;
  tenantId: string;

  agenda: string;
  description: string;
  images: BroadcastImages;
  status: BroadcastStatus;
  videoSources: string[];

  startDate: number | null;
  endDate: number | null;
  deleteDate: number | null;
  permissions?: BroadcastPermissions | null;
  data?: unknown;
}

export interface BroadcastImages {
  background: UpManMedia | null;
  logo: UpManMedia | null;
  thumbnail: UpManMedia | null;
}

export enum BroadcastStatus {
  ACTIVE = "ACTIVE",
  SCHEDULED = "SCHEDULED",
  INACTIVE = "INACTIVE",
  DELETED = "DELETED",
}

export type BroadcastPermissions = Partial<
  Record<BroadcastPermissionType, Permission[]>
>;
enum BroadcastPermissionType {
  READ = "read",
  WRITE = "write",
}

export interface Permission {
  scope: PermissionScope;
  entityId: string;
}

export enum PermissionScope {
  USER = "USER",
  GROUP = "GROUP",
  ROLE = "ROLE",
}
