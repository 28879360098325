import {
  GemaMemberResponse,
  RegistrationInfo,
} from "@/components/Registrations/@types";

export interface GemaMemberSearchState {
  searchTerm: string;
  results: GemaMemberRecordRegistration[] | null;
}

export interface GemaMemberRecordRegistration {
  member: GemaMemberResponse;
  registrations: RegistrationInfo[] | null;
}

export enum GemaMemberSearchAction {
  Submit = "Submit",
}

export enum GemaMemberSearchMutation {
  SetResults = "SetResults",
  SetSearchTerm = "SetSearchTerm",
}

export enum GemaMemberSearchGetter {
  Results = "Results",
  SearchTerm = "SearchTerm",
}

export interface SubmitSearchPayload {
  searchTerm: string;
  exactSearch?: boolean;
}
