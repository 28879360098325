import { http } from "@/services/api.service";
import { RootState } from "@/store/RootState";
import { AxiosResponse } from "axios";
import { ActionContext, Action } from "vuex";
import {
  Broadcast,
  BroadcastAction,
  BroadcastMutation,
  BroadcastState,
} from "./types";

const getAllBroadcasts = async ({
  commit,
}: ActionContext<BroadcastState, RootState>): Promise<Broadcast[]> => {
  const res: AxiosResponse<Broadcast[]> = await http.get("/broadcast/all");
  commit(BroadcastMutation.SetAllBroadcasts, res.data);
  return res.data;
};

const getBroadcasts = async ({
  commit,
}: ActionContext<BroadcastState, RootState>): Promise<Broadcast[]> => {
  commit(BroadcastMutation.SetLoading, true);
  const res: AxiosResponse<Broadcast[]> = await http.get("/broadcast/all", {
    params: {
      filterByModeratorAccess: true,
    },
  });
  commit(BroadcastMutation.SetBroadcasts, res.data);
  commit(BroadcastMutation.SetLoading, false);
  return res.data;
};

const updateBroadcast = async (
  { commit }: ActionContext<BroadcastState, RootState>,
  broadcast: Broadcast
) => {
  commit(BroadcastMutation.UpdateBroadcast, broadcast);
};

const removeBroadcast = async (
  { commit }: ActionContext<BroadcastState, RootState>,
  broadcastId: string
) => {
  commit(BroadcastMutation.RemoveBroadcast, broadcastId);
};

export const actions: Record<
  BroadcastAction,
  Action<BroadcastState, RootState>
> = {
  [BroadcastAction.GetAllBroadcasts]: getAllBroadcasts,
  [BroadcastAction.GetBroadcasts]: getBroadcasts,
  [BroadcastAction.UpdateBroadcast]: updateBroadcast,
  [BroadcastAction.RemoveBroadcast]: removeBroadcast,
};
