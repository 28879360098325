import { Action, ActionTree } from "vuex";
import { coreApiService } from "../../services/core";
import { trackingService } from "../../services/tracking";
import { Logger } from "../../utils/Logger";
import {
  FusionAuthUser,
  User,
  UserAction,
  UserMutation,
  UserState,
} from "../../types";

export const actions: ActionTree<UserState, unknown> &
  Record<UserAction, Action<UserState, unknown>> = {
  [UserAction.ResetUser]({ commit }) {
    commit(UserMutation.User, null);
    commit(UserMutation.FaUser, null);
  },
  [UserAction.SetFaUser]({ commit }, faUser: FusionAuthUser) {
    commit(UserMutation.FaUser, faUser);
  },
  [UserAction.SetUser]({ commit }, user: User) {
    commit(UserMutation.User, user);
  },
  async [UserAction.UpdateUserLanguage](
    { commit },
    language: string
  ): Promise<boolean> {
    try {
      const user = await coreApiService.updateUserLanguage(language);
      commit(UserMutation.User, user);
      return true;
    } catch (error) {
      Logger.debug("Update user language error", error);
      trackingService?.logError({
        ea: "error",
        ec: "language",
        ed: error,
      });
    }
    return false;
  },
};
