import { initStoreEnum } from "../utils/StoreHelpers";
import { Role } from "./user";

export const CORE_THEME_NAMESPACE = "s8-core-theme";

export interface ThemeState {
  theme?: ThemeConfig;
}

export enum ThemeAction {
  FetchTheme = "fetchTheme",
}

type ThemeActionStrings = keyof typeof ThemeAction;
export const CoreThemeActions: Record<ThemeActionStrings, string> =
  initStoreEnum<ThemeActionStrings>(ThemeAction, CORE_THEME_NAMESPACE);

export enum ThemeMutation {
  Theme = "theme",
}

export enum ThemeGetter {
  Theme = "theme",
}

type ThemeGetterStrings = keyof typeof ThemeGetter;
export const CoreThemeGetters: Record<ThemeGetterStrings, string> =
  initStoreEnum<ThemeGetterStrings>(ThemeGetter, CORE_THEME_NAMESPACE);

export interface ThemeConfig {
  dark: boolean;
  themes: {
    light?: ColorScheme;
    dark?: ColorScheme;
  };
  userColors: UserColors;
  roleBadges: Partial<Record<ThemeMode, RoleBadges>>;
}

type ColorCode = string;
export interface ColorScheme {
  [name: string]: ColorCode | undefined;

  primary: ColorCode;
  secondary: ColorCode;
  accent: ColorCode;
  info: ColorCode;
  warning: ColorCode;
  error: ColorCode;
  success: ColorCode;
  anchor?: ColorCode;
}

export interface UserColors {
  light?: ColorCode[];
  dark?: ColorCode[];
}

export type ThemeMode = "light" | "dark";

export type RoleBadges = {
  [Property in Role]?: RoleBadge;
};

export interface RoleBadge {
  icon: string;
  color?: string;
}
