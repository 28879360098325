import { RootState } from "@/store/RootState";
import { Getter, GetterTree } from "vuex";
import { Account, AccountGetter, AccountState } from "../types";

export const getters: GetterTree<AccountState, RootState> &
  Record<AccountGetter, Getter<AccountState, RootState>> = {
  [AccountGetter.Accounts](state): Account[] {
    return state.accounts;
  },
  [AccountGetter.EditAccount](state): Account | undefined {
    return state.editAccount;
  },
};
