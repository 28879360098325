import { GetterTree } from "vuex";
import { RootState } from "../RootState";
import { ChatSettings, GeneralState, Navigation } from "./types";

export const getters: GetterTree<GeneralState, RootState> = {
  allChannels(state) {
    return state.channels;
  },
  pinnedMessages(state) {
    return state.pinnedMessagesList;
  },
  //SHOP
  getShopCategories(state) {
    return state.shopCategories;
  },
  getShopItems(state) {
    return state.shopItems;
  },
  getDisclaimers(state) {
    return state.disclaimers;
  },
  //TABS
  navbarItems(state): Navigation {
    return state.navbarItems;
  },
  chatSettings(state): ChatSettings | null {
    return state.chatSettings;
  },
};
