import { MutationTree } from "vuex";
import { MonitoringState, MonitoringMutation, UsersStatsData } from "./types";

export const mutations: MutationTree<MonitoringState> = {
  [MonitoringMutation.SetConcurrentUsers](
    state: MonitoringState,
    payload: UsersStatsData[]
  ) {
    state.concurrentUsersData = payload;
  },
  [MonitoringMutation.SetUniqueUsers](
    state: MonitoringState,
    payload: UsersStatsData[]
  ) {
    state.uniqueUsersData = payload;
  },
  [MonitoringMutation.SetRecurringUsers](
    state: MonitoringState,
    payload: number
  ) {
    state.recurringUsersData = payload;
  },
  [MonitoringMutation.SetLinkClicks](
    state: MonitoringState,
    payload: UsersStatsData[]
  ) {
    state.linkClicks = payload;
  },
  [MonitoringMutation.SetChatActionEnd](
    state: MonitoringState,
    payload: UsersStatsData[]
  ) {
    state.chatActionEnd = payload;
  },
  [MonitoringMutation.SetChatActionReaction](
    state: MonitoringState,
    payload: UsersStatsData[]
  ) {
    state.chatActionReaction = payload;
  },
  [MonitoringMutation.SetChatActionReply](
    state: MonitoringState,
    payload: UsersStatsData[]
  ) {
    state.chatActionReply = payload;
  },
  [MonitoringMutation.SetChatActionStart](
    state: MonitoringState,
    payload: UsersStatsData[]
  ) {
    state.chatActionStart = payload;
  },
  [MonitoringMutation.SetChatActionUpub](
    state: MonitoringState,
    payload: UsersStatsData[]
  ) {
    state.chatActionUpub = payload;
  },
  [MonitoringMutation.SetChatActionVote](
    state: MonitoringState,
    payload: UsersStatsData[]
  ) {
    state.chatActionVote = payload;
  },
  [MonitoringMutation.SetUniqueUsersStat](
    state: MonitoringState,
    payload: UsersStatsData[]
  ) {
    state.uniqueUsersStat = payload;
  },
  [MonitoringMutation.SetActiveUsersData](
    state: MonitoringState,
    payload: UsersStatsData[]
  ) {
    state.activeUsersData = payload;
  },
};

export default mutations;
