import { initStoreEnum } from "../utils/StoreHelpers";
import { Role, User } from "./user";

export interface AuthModuleConfig {
  loginScopeKey: string;
  routes: Record<"home" | "login", string>;

  fusionAuthUrl: string;
  pinAuthUrl: string;
  sessionAuthUrl: string;

  approvedRoles?: Role[];
  notificationCallback?: (status: number | undefined, message: string) => void;
}

export const CORE_AUTH_NAMESPACE = "s8-core-auth";

export interface AuthState {
  authenticated: boolean;
  // access token issued from fusion auth or anonu
  accessTokenExt?: string;
  // tokens issued from the session auth service
  tokens?: Tokens;
  loginScope?: LoginScope;
}

export enum AuthAction {
  FetchLoginScope = "fetchLoginScope",
  RemoveLoginScope = "removeLoginScope",
  SetLoginScope = "SetLoginScope",
  ForgotPasswordFusionAuth = "forgotPasswordFusionAuth",
  LoginFusionAuth = "loginFusionAuth",
  LoginPinAuth = "loginPinAuth",
  Logout = "logout",
  Refresh = "refresh",
  ReAuthenticate = "reAuthenticate",
}

type CoreAuthActionStrings = keyof typeof AuthAction;
export const CoreAuthActions: Record<CoreAuthActionStrings, string> =
  initStoreEnum<CoreAuthActionStrings>(AuthAction, CORE_AUTH_NAMESPACE);

export enum AuthMutation {
  Authenticated = "authenticated",
  AccessTokenExt = "accessTokenExt",
  Tokens = "tokens",
  ResetAuthentication = "resetAuthentication",
  LoginScope = "loginScope",
}

export enum AuthGetter {
  Authenticated = "authenticated",
  AccessTokenExt = "accessTokenExt",
  Tokens = "tokens",
  LoginScope = "loginScope",
  LoginType = "loginType",
}

type CoreAuthGetterStrings = keyof typeof AuthGetter;
export const CoreAuthGetters: Record<CoreAuthGetterStrings, string> =
  initStoreEnum<CoreAuthGetterStrings>(AuthGetter, CORE_AUTH_NAMESPACE);

export interface LoginFusionAuthPayload {
  applicationId?: string;
  loginId: string;
  password: string;
  redirect?: boolean;
}

export interface LoginPinAuthPayload {
  displayname: string;
  pin: string;
  redirect?: boolean;
}

export enum LoginType {
  // TODO: rename email to FUSION_AUTH, requires backend changes
  EMAIL = "EMAIL",
  PIN = "PIN",
  PIN_WITH_NAME = "PIN_WITH_NAME",
}

export interface LoginScope {
  loginType: LoginType;
  tenantId: string;
}

export interface Tokens {
  access: string;
  refresh?: string;
}

export interface AuthenticationPayload {
  tenantId: string;
  token: string;
  isFusionAuth: boolean;
}

export enum AuthenticationStatus {
  ERROR = "ERROR",
  INSUFFICIENT_USER_ROLE_PERMISSION = "INSUFFICIENT_USER_ROLE_PERMISSION",
  NONE = "NONE",
  SUCCESS = "SUCCESS",
}

// FusionAuth

export interface FusionAuthLoginResponse {
  refreshToken: string;
  token: string;
  user: FusionAuthUser;
}

export interface FusionAuthRefreshResponse {
  refreshToken: string;
  token: string;
}

export interface FusionAuthForgotPasswordResponse {
  changePasswordId?: string;
}

// TODO: update interface, https://fusionauth.io/docs/v1/tech/apis/login/#authenticate-a-user
export interface FusionAuthUser {
  active: boolean;
  connectorId: string;
  email: string;
  id: string;
  insertInstant: number;
  lastLoginInstant: number;
  lastUpdateInstant: number;
  passwordChangeRequired: boolean;
  passwordLastUpdateInstant: number;
  tenantId: string;
  uniqueUsername: string;
  username: string;
  usernameStatus: string;
  verified: boolean;
}

// PinAuth (anonU)

export interface PinAuthTokenResponse {
  data: {
    access_token: string;
    refresh_token: string;
  };
}

// SessionAuth

export interface SessionAuthResponse {
  accessToken: string;
  refreshToken: string;
  user: User;
}
