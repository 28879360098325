import { Store } from "vuex";
import { coreApiService } from "../services/core";
import { trackingService } from "../services/tracking";
import { poll } from "../stores/poll";
import {
  CORE_CONFIG_NAMESPACE,
  CORE_POLL_NAMESPACE,
  CORE_USER_NAMESPACE,
  PollModuleConfig,
} from "../types";
import { Logger } from "../utils/Logger";

class PollModule {
  config: PollModuleConfig;

  constructor(config: PollModuleConfig) {
    this.config = config;
  }
}

let pollModule: PollModule;

function initPollModule(
  config: PollModuleConfig,
  store: Store<unknown>
): PollModule {
  // poll module dependencies
  const dependencies: string[] = [CORE_CONFIG_NAMESPACE, CORE_USER_NAMESPACE];

  // check dependencies
  dependencies.forEach((namespace: string) => {
    if (!store.hasModule(namespace)) {
      throw new Error(`PollModule - store module ${namespace} is missing`);
    }
  });

  // register core poll store
  if (!store.hasModule(CORE_POLL_NAMESPACE)) {
    store.registerModule(CORE_POLL_NAMESPACE, poll);
  }

  // check if core api service instance is available
  // TODO: find a better way
  if (!coreApiService) {
    throw new Error(`PollModule - core api service is missing`);
  }

  // check if tracking service instance is available
  if (!trackingService) {
    Logger.info("PollModule - tracking service not available");
  }

  // create module instance
  if (pollModule) {
    return pollModule;
  }
  pollModule = new PollModule(config);
  return pollModule;
}

export { pollModule, initPollModule };
