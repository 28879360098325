import i18n from "@/plugins/i18n/i18n";
import { RootState } from "@/store/RootState";
import Vue from "vue";
import { Action, ActionContext } from "vuex/types/index";
import GemaMemberService from "../services/GemaMemberService";
import {
  GemaMemberRecordRegistration,
  GemaMemberSearchAction,
  GemaMemberSearchMutation,
  GemaMemberSearchState,
  SubmitSearchPayload,
} from "./types";

const submitSearch = async (
  { commit }: ActionContext<GemaMemberSearchState, RootState>,
  payload: SubmitSearchPayload
): Promise<GemaMemberRecordRegistration[] | null> => {
  try {
    commit(GemaMemberSearchMutation.SetSearchTerm, payload.searchTerm);
    commit(GemaMemberSearchMutation.SetResults, null);
    const results = await GemaMemberService.getGemaMemberRecords(
      payload.searchTerm,
      payload.exactSearch
    );
    commit(GemaMemberSearchMutation.SetResults, results);
    return results;
  } catch (error) {
    Vue.$toast.error(i18n.tc("accreditation.searchError"), {
      timeout: 2000,
    });
  }
  commit(GemaMemberSearchMutation.SetResults, null);
  return null;
};

export const actions: Record<
  GemaMemberSearchAction,
  Action<GemaMemberSearchState, RootState>
> = {
  [GemaMemberSearchAction.Submit]: submitSearch,
};
