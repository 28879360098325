import { Module } from "vuex";
import { actions } from "./actions";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { VicoState } from "../../types";

// ReExport to be backward compatible
export { CORE_VICO_NAMESPACE } from "../../types";

export const state: VicoState = {
  initialized: false,
  conferences: [],
  conferenceSelected: null,
  slots: [],
  slotOut: null,
  tracks: [],
  config: { iceServers: [], serverUrls: [] },
};

const namespaced = true;

export const vico: Module<VicoState, unknown> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
