import { RootState } from "@/store/RootState";
import { ActionContext, Action } from "vuex";

import { PollAction, PollState, PollMutation, Sorting } from "../types";
import PollService from "@/services/api/poll.service";
import { handleHttpError, showNotification } from "@/helpers";
import {
  Poll,
  PollDto,
  PollExport,
  PollStartDto,
  UpdatePollDto,
} from "@/services/api/poll.service.types";

const createPoll = async (
  { commit }: ActionContext<PollState, RootState>,
  poll: PollDto
): Promise<Poll> => {
  const savedPoll = await PollService.createPoll(poll);
  commit(PollMutation.AddPoll, savedPoll);
  return savedPoll;
};

const updatePoll = async (
  { commit }: ActionContext<PollState, RootState>,
  poll: UpdatePollDto
): Promise<Poll> => {
  try {
    const savedPoll = await PollService.updatePoll(poll);
    commit(PollMutation.UpdatePoll, savedPoll);
    return savedPoll;
  } catch (error) {
    handleHttpError(error);
    throw error;
  }
};

const deletePoll = async (
  { commit, state }: ActionContext<PollState, RootState>,
  pollId: string
): Promise<void> => {
  try {
    await PollService.deletePoll(pollId);
    commit(PollMutation.DeletePoll, pollId);
    showNotification(200, "Poll deleted");

    if (state.editPoll && state.editPoll.id === pollId) {
      commit(PollMutation.SetEditPoll, null);
    }
  } catch (error) {
    handleHttpError(error, { 409: "Poll already exists" });

    throw error;
  }
};

const fetchPolls = async ({
  commit,
}: ActionContext<PollState, RootState>): Promise<void> => {
  const polls = await PollService.getAllPolls();
  commit(PollMutation.SetPolls, polls);
};

const resetPolls = async ({
  commit,
}: ActionContext<PollState, RootState>): Promise<void> => {
  commit(PollMutation.ResetPolls);
};

const setEditPoll = async (
  { commit }: ActionContext<PollState, RootState>,
  poll: Poll
): Promise<void> => {
  commit(PollMutation.SetEditPoll, poll);
};

const clearEditPoll = async ({
  commit,
}: ActionContext<PollState, RootState>): Promise<void> => {
  commit(PollMutation.ClearEditPoll);
};

const startPoll = async (
  { commit }: ActionContext<PollState, RootState>,
  data: PollStartDto
): Promise<void> => {
  try {
    const poll = await PollService.startPoll(data);
    commit(PollMutation.UpdatePoll, poll);
    showNotification(200, "Poll Scheduled");
  } catch (error) {
    handleHttpError(error);
    throw error;
  }
};

const endPoll = async (
  { commit }: ActionContext<PollState, RootState>,
  pollId: string
): Promise<void> => {
  try {
    const poll = await PollService.endPoll(pollId);
    commit(PollMutation.UpdatePoll, poll);
  } catch (error) {
    handleHttpError(error);
    throw error;
  }
};

const exportToCsv = async (): Promise<PollExport> => {
  const csvString = await PollService.getAllPollsCsv();
  return csvString;
};

const exportPageToCsv = async (
  context: ActionContext<PollState, RootState>,
  pollIds: Array<string>
): Promise<PollExport> => {
  const csvString = await PollService.getPollsPageCsv(pollIds);
  return csvString;
};

const setSorting = (
  context: ActionContext<PollState, RootState>,
  sort: Sorting
) => {
  context.commit("setSorting", sort);
};

export const actions: Record<PollAction, Action<PollState, RootState>> = {
  [PollAction.CreatePoll]: createPoll,
  [PollAction.UpdatePoll]: updatePoll,
  [PollAction.DeletePoll]: deletePoll,
  [PollAction.FetchPolls]: fetchPolls,
  [PollAction.ResetPolls]: resetPolls,
  [PollAction.SetEditPoll]: setEditPoll,
  [PollAction.ClearEditPoll]: clearEditPoll,
  [PollAction.StartPoll]: startPoll,
  [PollAction.EndPoll]: endPoll,
  [PollAction.ExportPolls]: exportToCsv,
  [PollAction.ExportPage]: exportPageToCsv,
  [PollAction.SetSorting]: setSorting,
};
