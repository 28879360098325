import { http } from "../api.service";
import {
  PollDto,
  Poll,
  UpdatePollDto,
  PollStartDto,
  PollExport,
} from "./poll.service.types";

const POLLS_URI = "/polls";
// const POLLS_VOTE_URI = "/pollvotes";

const createPoll = (poll: PollDto): Promise<Poll> =>
  http.post<Poll>(POLLS_URI, poll).then((res) => res.data);

const deletePoll = (pollId: string): Promise<void> =>
  http.delete<void>(`${POLLS_URI}/${pollId}`).then((res) => res.data);

const updatePoll = (poll: UpdatePollDto): Promise<Poll> =>
  http.put<Poll>(POLLS_URI, poll).then((res) => res.data);

const getAllPolls = (): Promise<Poll[]> =>
  http.get<Poll[]>(POLLS_URI + "/admin").then((res) => res.data);

const getPollById = (pollId: string): Promise<Poll> =>
  http.get<Poll>(`${POLLS_URI}/${pollId}`).then((res) => res.data);

// const getPollsByBroadcastIds = (
//   tenantId: string,
//   broadcastIds: string[]
// ): Promise<Poll[]> =>
//   http.get<Poll[]>(`${POLLS_URI}/broadcasts`).then(res => res.data);

// const getPollsByChannelIds = (
//   tenantId: string,
//   channelIds: string[]
// ): Promise<Poll[]> =>
//   http.get<Poll[]>(`${POLLS_URI}/channels`).then(res => res.data);

const startPoll = (payload: PollStartDto): Promise<Poll> =>
  http.post(`${POLLS_URI}/start`, payload).then((res) => res.data);

const endPoll = (pollId: string): Promise<Poll> =>
  http.get(`${POLLS_URI}/end/${pollId}`).then((res) => res.data);

const getAllPollsCsv = (): Promise<PollExport> =>
  http.get(`${POLLS_URI}/export/all`).then((res) => res.data);

const getPollsPageCsv = (pollIds: Array<string>): Promise<PollExport> =>
  http
    .post(`${POLLS_URI}/export/page`, { pollIds: pollIds })
    .then((res) => res.data);

const PollService = {
  createPoll,
  deletePoll,
  updatePoll,
  getAllPolls,
  getPollById,
  //   getPollsByBroadcastIds,
  //   getPollsByChannelIds,
  startPoll,
  endPoll,
  getAllPollsCsv,
  getPollsPageCsv,
};

export default PollService;
