import Vue from "vue";

export class Logger {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static debug(...args: any[]): void {
    if (Vue.$log) {
      Vue.$log.debug(args);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static info(...args: any[]): void {
    if (Vue.$log) {
      Vue.$log.info(args);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static warn(...args: any[]): void {
    if (Vue.$log) {
      Vue.$log.warn(args);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static error(...args: any[]): void {
    if (Vue.$log) {
      Vue.$log.error(args);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static fatal(...args: any[]): void {
    if (Vue.$log) {
      Vue.$log.fatal(args);
    }
  }
}
