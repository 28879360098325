import i18n from "@/plugins/i18n/i18n";
import { handleHttpError } from "@/helpers";
import { apiChat } from "@/services/api.service";
import { CoreConfigGetters } from "@/spect8-core-vue/src/types";
import { RootState } from "@/store/RootState";
import { Action, ActionContext, ActionTree } from "vuex";
import {
  Account,
  AccountAction,
  AccountActionPayload,
  AccountMutation,
  AccountState,
} from "../types";

const createAccount = (
  { commit, rootGetters }: ActionContext<AccountState, RootState>,
  user: AccountActionPayload
) => {
  const { fusionAuthAppId } = rootGetters[CoreConfigGetters.TenantConfig];
  return apiChat
    .createUser(
      {
        email: user.email,
        roles: user.roles,
        broadcastIds: user.broadcastIds,
      },
      fusionAuthAppId
    )
    .then((res) => {
      commit(AccountMutation.AddAccount, res);
      setTimeout(() => {
        commit(AccountMutation.ClearEditAccount);
      }, 1000);
    });
};

const updateAccount = (
  { commit, rootGetters }: ActionContext<AccountState, RootState>,
  user: Partial<AccountActionPayload>
) => {
  const { fusionAuthAppId } = rootGetters[CoreConfigGetters.TenantConfig];
  return apiChat
    .updateUser(user, fusionAuthAppId)
    .then((res) => {
      commit(AccountMutation.UpdateAccount, res);
      commit(AccountMutation.ClearEditAccount);
    })
    .catch((err) => {
      handleHttpError(err);
      throw err;
    });
};

const deleteAccount = async (
  context: ActionContext<AccountState, RootState>,
  userId: string
) => {
  return apiChat
    .deleteUser(userId)
    .then(() => {
      context.commit(AccountMutation.DeleteAccount, userId);
    })
    .catch((err) => {
      handleHttpError(err);
      throw err;
    });
};

const setAccounts = async ({
  commit,
}: ActionContext<AccountState, RootState>): Promise<void> => {
  const accounts = await apiChat.getUsers();
  commit(AccountMutation.SetAccounts, accounts);
};

const resetAccounts = async ({
  commit,
}: ActionContext<AccountState, RootState>): Promise<void> => {
  commit(AccountMutation.ResetAccounts);
};

const setEditAccount = async (
  { commit }: ActionContext<AccountState, RootState>,
  account: Account
): Promise<void> => {
  commit(AccountMutation.SetEditAccount, account);
};

const clearEditAccount = async ({
  commit,
}: ActionContext<AccountState, RootState>): Promise<void> => {
  commit(AccountMutation.ClearEditAccount);
};

export const actions: ActionTree<AccountState, RootState> &
  Record<AccountAction, Action<AccountState, RootState>> = {
  [AccountAction.CreateAccount]: createAccount,
  [AccountAction.UpdateAccount]: updateAccount,
  [AccountAction.DeleteAccount]: deleteAccount,
  [AccountAction.SetAccounts]: setAccounts,
  [AccountAction.ResetAccounts]: resetAccounts,
  [AccountAction.SetEditAccount]: setEditAccount,
  [AccountAction.ClearEditAccount]: clearEditAccount,
};
