import {
  PollData,
  PollStatusSelection,
  UpdateStatus,
} from "@/services/api/secure-votes.service.types";
import { RootState } from "@/store/RootState";
import { ActionContext, Action } from "vuex";

import {
  PollAction,
  PollState,
  PollMutation,
  PresenceSendObject,
} from "../types";
import PollService from "@/services/api/secure-votes.service";
import { handleHttpError, showNotification } from "@/helpers";
import {
  Poll,
  PollDto,
  UpdatePollDto,
} from "@/services/api/secure-votes.service.types";

const createPoll = async (
  { commit }: ActionContext<PollState, RootState>,
  poll: PollDto
): Promise<PollData> => {
  const savedPoll = await PollService.createPoll(poll);
  commit(PollMutation.AddPoll, {
    ...savedPoll,
    questionId: savedPoll.id,
    answerVotes: savedPoll.answers,
    totalVotes: 0,
  });
  return savedPoll;
};

const updatePoll = async (
  { commit, dispatch }: ActionContext<PollState, RootState>,
  poll: UpdatePollDto
): Promise<void> => {
  try {
    const savedPoll =
      poll.status === PollStatusSelection.PREPARING
        ? await PollService.updatePoll(poll)
        : await PollService.updatePollBroadcasts(poll);

    commit(PollMutation.UpdatePoll, {
      ...savedPoll,
      questionId: savedPoll.id,
      answerVotes: savedPoll.answers,
      totalVotes: 0,
    });
    if (poll.status !== PollStatusSelection.PREPARING)
      dispatch(PollAction.UpdatePollVotes, poll.id);
  } catch (error) {
    handleHttpError(error);
    throw error;
  }
};

const updatePollVotes = async (
  { commit }: ActionContext<PollState, RootState>,
  questionId: string
): Promise<void> => {
  const updatedPoll = await PollService.getUpdatedVotes(questionId);
  commit(PollMutation.UpdatePoll, updatedPoll[0]);
};

const updateStatus = async (
  { commit, dispatch }: ActionContext<PollState, RootState>,
  status: UpdateStatus
): Promise<void> => {
  try {
    const savedPoll = await PollService.updateStatus(status);
    commit(PollMutation.UpdatePoll, savedPoll);
    showNotification(200, "You successfully updated poll status!");
    if (savedPoll.status === PollStatusSelection.CLOSED) {
      dispatch(PollAction.UpdatePollVotes, savedPoll.id);
    }
  } catch (error) {
    handleHttpError(error);
    dispatch(PollAction.FetchPolls);
    throw error;
  }
};

const deletePoll = async (
  { commit, state }: ActionContext<PollState, RootState>,
  pollId: string
): Promise<void> => {
  try {
    await PollService.deletePoll(pollId);
    commit(PollMutation.DeletePoll, pollId);
    showNotification(200, "You successfully deleted a poll!");

    if (state.editSecurePoll && state.editSecurePoll.questionId === pollId) {
      commit(PollMutation.SetEditPoll, null);
    }
  } catch (error) {
    handleHttpError(error, { 409: "Poll already exists" });

    throw error;
  }
};

const fetchPolls = async ({
  commit,
}: ActionContext<PollState, RootState>): Promise<void> => {
  const polls = await PollService.getAllPolls();
  commit(PollMutation.SetPolls, polls);
};

const fetchPresenceStats = async (
  { dispatch }: ActionContext<PollState, RootState>,
  id: string
): Promise<void> => {
  await PollService.fetchPresenceStats(id);
  dispatch(PollAction.UpdatePollVotes, id);
};

const fetchPoll = async (
  { commit }: ActionContext<PollState, RootState>,
  id: string
): Promise<void> => {
  const poll = await PollService.getPollById(id);
  commit(PollMutation.AddPoll, poll);
};

const sendOverlayEvent = async (
  context: ActionContext<PollState, RootState>,
  questionId: string
): Promise<void> => {
  await PollService.sendOverlayEvent(questionId);
  showNotification(200, "polls.resultsMessage");
};

const resetPolls = async ({
  commit,
}: ActionContext<PollState, RootState>): Promise<void> => {
  commit(PollMutation.ResetPolls);
};

const setEditPoll = async (
  { commit }: ActionContext<PollState, RootState>,
  poll: Poll
): Promise<void> => {
  commit(PollMutation.SetEditPoll, poll);
};

const clearEditPoll = async ({
  commit,
}: ActionContext<PollState, RootState>): Promise<void> => {
  commit(PollMutation.ClearEditPoll);
};

const sendPresence = async (
  { commit }: ActionContext<PollState, RootState>,
  presenceSendObject: PresenceSendObject
): Promise<void> => {
  await PollService.sendPresence(presenceSendObject);
  showNotification(200, "polls.presenceSent");
};

export const actions: Record<PollAction, Action<PollState, RootState>> = {
  [PollAction.CreatePoll]: createPoll,
  [PollAction.UpdatePoll]: updatePoll,
  [PollAction.DeletePoll]: deletePoll,
  [PollAction.FetchPolls]: fetchPolls,
  [PollAction.FetchPoll]: fetchPoll,
  [PollAction.ResetPolls]: resetPolls,
  [PollAction.SetEditPoll]: setEditPoll,
  [PollAction.ClearEditPoll]: clearEditPoll,
  [PollAction.UpdateStatus]: updateStatus,
  [PollAction.SendOverlayEvent]: sendOverlayEvent,
  [PollAction.UpdatePollVotes]: updatePollVotes,
  [PollAction.FetchPresenceStats]: fetchPresenceStats,
  [PollAction.SendPresence]: sendPresence,
};
