import { GetterTree } from "vuex";
import { RootState } from "../../../store/RootState";
import { MonitoringState, MonitoringGetter } from "./types";
import moment from "moment";

export const getters: GetterTree<MonitoringState, RootState> = {
  [MonitoringGetter.ConcurrentUsers](state) {
    return state.concurrentUsers;
  },
  [MonitoringGetter.ConcurrentUsersData](state) {
    return state.concurrentUsersData;
  },
  [MonitoringGetter.RecurringUsersData](state) {
    return state.recurringUsersData;
  },
  [MonitoringGetter.ConcurrentUsersDataTime](state) {
    return state.concurrentUsersData.map((e) =>
      moment(e.t).format("MM/DD HH:mm")
    );
  },
  [MonitoringGetter.ConcurrentUsersDataValue](state) {
    return state.concurrentUsersData.map((e) => e.v);
  },
  [MonitoringGetter.UniqueUsersData](state) {
    return state.uniqueUsersData;
  },
  [MonitoringGetter.UniqueUsersDataTime](state) {
    return state.uniqueUsersData.map((e) => moment(e.t).format("MM/DD HH:mm"));
  },
  [MonitoringGetter.UniqueUsersDataValue](state) {
    return state.uniqueUsersData.map((e) => e.v);
  },
  [MonitoringGetter.LinkClicksData](state) {
    return state.linkClicks;
  },
  [MonitoringGetter.LinkClicksDataTime](state) {
    return state.linkClicks.map((e) => moment(e.t).format("MM/DD HH:mm"));
  },
  [MonitoringGetter.LinkClicksDataValue](state) {
    return state.linkClicks.map((e) => e.v);
  },

  [MonitoringGetter.ChatActionEndValue](state) {
    return state.chatActionEnd.map((e) => e.v);
  },
  [MonitoringGetter.ChatActionReactionValue](state) {
    return state.chatActionReaction.map((e) => e.v);
  },
  [MonitoringGetter.ChatActionReplyValue](state) {
    return state.chatActionReply.map((e) => e.v);
  },
  [MonitoringGetter.ChatActionStartValue](state) {
    return state.chatActionStart.map((e) => e.v);
  },
  [MonitoringGetter.ChatActionUpubValue](state) {
    return state.chatActionUpub.map((e) => e.v);
  },
  [MonitoringGetter.ChatActionVoteValue](state) {
    return state.chatActionVote.map((e) => e.v);
  },

  [MonitoringGetter.ChatActionEnd](state) {
    return state.chatActionEnd.map((e) => ({
      x: moment(e.t).format("MM/DD HH:mm"),
      y: e.v,
    }));
  },
  [MonitoringGetter.ChatActionReaction](state) {
    return state.chatActionReaction.map((e) => ({
      x: moment(e.t).format("MM/DD HH:mm"),
      y: e.v,
    }));
  },
  [MonitoringGetter.ChatActionStart](state) {
    return state.chatActionStart.map((e) => ({
      x: moment(e.t).format("MM/DD HH:mm"),
      y: e.v,
    }));
  },
  [MonitoringGetter.ChatActionReply](state) {
    return state.chatActionReply.map((e) => ({
      x: moment(e.t).format("MM/DD HH:mm"),
      y: e.v,
    }));
  },
  [MonitoringGetter.ChatActionUpub](state) {
    return state.chatActionUpub.map((e) => ({
      x: moment(e.t).format("MM/DD HH:mm"),
      y: e.v,
    }));
  },
  [MonitoringGetter.ChatActionVote](state) {
    return state.chatActionVote.map((e) => ({
      x: moment(e.t).format("MM/DD HH:mm"),
      y: e.v,
    }));
  },
  [MonitoringGetter.UniqueUsersStat](state) {
    return state.uniqueUsersStat[0]?.v ?? 0;
  },
  [MonitoringGetter.ActiveUsersData](state) {
    return state.activeUsersData[0]?.v ?? 0;
  },
};
