























import { Component, Vue } from "vue-property-decorator";
import MediaSetup from "@/spect8-core-vue/src/components/mediaDevices/MediaSetup.vue";
import { Namespace } from "@/store/types";
import { Action, Getter } from "vuex-class";

@Component({ components: { MediaSetup } })
export default class MediaSetupDialog extends Vue {
  @Action(`${Namespace.Conference}/setSetupDialog`)
  setSetupDialog!: (dialog: boolean) => void;
  @Getter(`${Namespace.Conference}/setupDialog`)
  setupDialog!: boolean;

  get dialog(): boolean {
    return this.setupDialog;
  }
  set dialog(dialog: boolean) {
    this.setSetupDialog(dialog);
  }
}
