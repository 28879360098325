import { Store } from "vuex";
import { coreApiService } from "../services/core";
import { trackingService } from "../services/tracking";
import { config } from "../stores/config";
import { ConfigModuleConfig, CORE_CONFIG_NAMESPACE } from "../types";
import { Logger } from "../utils/Logger";

class ConfigModule {
  config: ConfigModuleConfig;

  constructor(config: ConfigModuleConfig) {
    this.config = config;
  }
}

let configModule: ConfigModule;

function initConfigModule(
  moduleConfig: ConfigModuleConfig,
  store: Store<unknown>
): ConfigModule {
  // config module dependencies
  const dependencies: string[] = [];

  // check dependencies
  dependencies.forEach((namespace: string) => {
    if (!store.hasModule(namespace)) {
      throw new Error(`ConfigModule - store module ${namespace} is missing`);
    }
  });

  // register core config store
  if (!store.hasModule(CORE_CONFIG_NAMESPACE)) {
    store.registerModule(CORE_CONFIG_NAMESPACE, config);
  }

  // check if core api service instance is available
  // TODO: find a better way
  if (!coreApiService) {
    throw new Error(`ConfigModule - core api service is missing`);
  }

  // check if tracking service instance is available
  if (!trackingService) {
    Logger.info("ConfigModule - tracking service not available");
  }

  // create module instance
  if (configModule) {
    return configModule;
  }
  configModule = new ConfigModule(moduleConfig);
  return configModule;
}

export { configModule, initConfigModule };
