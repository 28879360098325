import { Mutation, MutationTree } from "vuex";
import Vue from "vue";
import { ConfigMutation, ConfigState, Feature, Features } from "../types";

export const mutations: MutationTree<ConfigState> &
  Record<ConfigMutation, Mutation<ConfigState>> = {
  [ConfigMutation.SetFeatures](state, payload: Feature[]) {
    const enabledFeatures = {} as Features;
    payload.forEach((f) => (enabledFeatures[f] = true));
    Vue.set(state, "enabledFeatures", enabledFeatures);
  },
};
