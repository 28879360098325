import {
  UpManFileUploadDto,
  UpManGeneralFile,
} from "@/services/api/upman.service.types";
import { Namespace } from "@/store/types";

export interface ResourceState {
  resources: Resource[];
}

export interface Resource {
  id: string;
  title: string;
  upManMedia: UpManGeneralFile & { mimeType: string };
  timeCreated: VeryPreciseTime;
  broadcastIds: string[];
}

export interface VeryPreciseTime {
  nano: number;
  epochSecond: number;
}

export interface ResourceCreateActionPayload {
  upmanId: string;
  fileSize: number;
  tags: string[];
  filename: string;
  fileExtension: string;
  mimeType: string;
  publicPath: string;

  broadcastId?: string;
}

export interface ResourceUploadCreateActionPayload {
  file: UpManFileUploadDto;
  broadcastId?: string;
}

export enum ResourceAction {
  CreateResource = "CreateResource",
  UploadAndCreateResource = "UploadAndCreateResource",
  SetResources = "SetResources",
  DeleteResource = "DeleteResource",
  ResetResources = "ResetResources",
  //   FetchResourcesByBroadcastIds = "FetchResourcesByBroadcastIds",
  //   FetchResourcesByChannelIds = "FetchResourcesByChannelIds"
}

export const ResourceActions: Record<ResourceAction, string> = {
  CreateResource: `${Namespace.Resources}/${ResourceAction.CreateResource}`,
  UploadAndCreateResource: `${Namespace.Resources}/${ResourceAction.UploadAndCreateResource}`,
  SetResources: `${Namespace.Resources}/${ResourceAction.SetResources}`,
  DeleteResource: `${Namespace.Resources}/${ResourceAction.DeleteResource}`,
  ResetResources: `${Namespace.Resources}/${ResourceAction.ResetResources}`,
};

export enum ResourceMutation {
  SetResources = "SetResources",
  ResetResources = "ResetResources",
  AddResource = "AddResource",
  DeleteResource = "DeleteResource",
}

export enum ResourceGetter {
  Resources = "Resources",
}

export const ResourceGetters: Record<ResourceGetter, string> = {
  Resources: `${Namespace.Resources}/${ResourceGetter.Resources}`,
};
