
















import { Component, Vue, Watch, Prop, Ref } from "vue-property-decorator";
import { Getter } from "vuex-class";
import { CoreMediaDevicesGetters } from "../../types";

@Component({
  components: {},
})
export default class Mirror extends Vue {
  @Prop({ default: false }) readonly controls!: boolean;
  @Prop({ default: true }) readonly autoplay!: boolean;
  @Prop({ default: true }) readonly muted!: boolean;
  @Prop({ default: true }) readonly loop!: boolean;

  @Getter(CoreMediaDevicesGetters.Stream)
  readonly streamGetter!: MediaStream | null;

  @Ref("video") videoElement!: HTMLMediaElement;

  @Watch("streamGetter", { immediate: true, deep: true })
  howdoesvueworkhonestly(stream: MediaStream | null): void {
    if (this.videoElement) {
      console.info("Updating video src", stream ? stream.id : "null");
      this.videoElement.srcObject = stream;
    }
  }

  mounted(): void {
    if (this.videoElement && !this.videoElement.srcObject && this.streamGetter)
      this.videoElement.srcObject = this.streamGetter;
  }
}
