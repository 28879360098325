import { Question } from "@/components/Qa/types";
import { RTCSlot } from "@/spect8-core-vue/src/stores/vico/RtcSlot";
import { Conference } from "@/spect8-core-vue/src/types/vico";
import { MutationTree } from "vuex";
import { ConferenceState } from "../types";

export const mutations: MutationTree<ConferenceState> = {
  setTestCall(state, rtcSlot: RTCSlot | null) {
    state.testCall = rtcSlot;
  },
  setTestConference(state, conference: Conference | null) {
    state.testConference = conference;
  },
  setSetupDialog(state, dialog: boolean) {
    state.setupDialog = dialog;
  },
  setTestCallDialog(state, dialog: boolean) {
    state.testCallDialog = dialog;
  },
  setTestQuestion(state, question: Question | null) {
    state.testQuestion = question;
  },
};
