import { Action, ActionTree } from "vuex";
import { RTCInfo, RTCSlot } from "./RtcSlot";
import { WebsocketConnection } from "./WebSocket";
import {
  CallInitActionPayload,
  ConnectActionPayload,
  EndConferencePayload,
  IceServer,
  Conferences,
  VicoConfig,
  CallInitRequest,
  Conference,
  VicoState,
  WsEventData,
  CoreConfigGetters,
  CoreMediaDevicesActions,
  CoreMediaDevicesGetters,
  TenantConfig,
  User,
  CoreUserGetters,
  VicoAction,
  VicoMutation,
} from "../../types";
import { vicoService } from "../../services/vico";

export const actions: ActionTree<VicoState, unknown> &
  Record<VicoAction, Action<VicoState, unknown>> = {
  /**
   * Fetches conferences and selects the first one to be active.
   */
  [VicoAction.Init]({ commit, state, rootGetters }): Promise<void> {
    const tenantConfig: TenantConfig =
      rootGetters[CoreConfigGetters.TenantConfig];
    return vicoService
      .getConferences(tenantConfig.tenantId)
      .then((res) => {
        console.debug(res);
        commit(VicoMutation.SetConferences, res.conferences);

        // todo: default conf id instead of first in array
        if (state.conferences.length)
          commit(
            VicoMutation.SelectConference,
            state.conferences.find(
              (conf) => !conf.tags?.includes("checkupcall")
            )
          );

        // todo: prepare broadcasts here
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        commit(VicoMutation.SetInitialized, true);
      });
  },

  /**
   * Builds an RTCSlot object, incl. a websocket connection.
   * The slot still needs negotiation.
   */
  [VicoAction.Connect](
    { commit, rootGetters, state },
    rtcInfo: RTCInfo
  ): Promise<RTCSlot | void> {
    if (!rtcInfo) throw new Error("No rtcInfo to set up connection.");

    return new Promise((resolve, reject) => {
      const ws = new WebSocket(
        [
          rtcInfo.signalSocketBaseUrl,
          rtcInfo.tenantId,
          rtcInfo.broadcastId,
          rtcInfo.conferenceId,
          rtcInfo.userId,
          rtcInfo.isBroadcaster,
          rtcInfo.slotId,
        ].join("/")
      );

      const conn = new WebsocketConnection(rtcInfo, ws);
      const rtcSlot = new RTCSlot(rtcInfo, conn, new MediaStream());

      ws.addEventListener("open", () => {
        const user: User = rootGetters[CoreUserGetters.User];

        ws.addEventListener("message", async (ev) => {
          const data: WsEventData = JSON.parse(ev.data);
          const cont = JSON.parse(data.content);
          // const slot: RTCSlot = getters["slot"]({ ...user, ...cont });
          // if (!slot) throw Error("No matching RTC slot found");
          if (!rtcSlot) throw Error("RTC slot uninitialized?");

          switch (data.topic) {
            case "ServerIceCandidate":
              rtcSlot.onRemoteIceCandidate(data.topic, cont);
              break;
            case "ServerAnswerSdp":
              rtcSlot.onServerSdp(data.topic, cont);
              break;
            case "TrackUpdate":
              rtcSlot.onTrackUpdate(data.topic, cont);
              break;
            case "AcknowledgeIntro":
              if (rtcInfo.isBroadcaster) {
                if (state.slotOut) state.slotOut.close();
                commit(VicoMutation.SetSlotOut, rtcSlot);
                // rtcSlot.negotiateRtcConnection();
              } else commit(VicoMutation.AddSlot, rtcSlot);

              rtcSlot.init();
              resolve(rtcSlot);
              break;
            case "IntroductionDenied":
              rtcSlot.close();
              reject("Introduction denied");
              break;
            case "RequestingProblemLog":
              rtcSlot
                .dump([
                  "inbound-rtp",
                  "outbound-rtp",
                  "media-source",
                  "sender",
                  "receiver",
                ])
                .then((dump) => {
                  vicoService.errorLog(JSON.stringify(dump));
                });
              break;
            case "PeerIsActiveChange":
              // data.content as PeerPacketChangeEventContent;
              // console.info(data.content);
              break;
            case "SendQAState":
            default:
              // console.log(rtcSlot);
              break;
          }
        });

        conn.sendEvent("Introduction", {
          tenantId: rtcInfo.tenantId,
          broadcastId: rtcInfo.broadcastId,
          conferenceId: rtcInfo.conferenceId,
          userId: user.id,
          userName: user.editRequest.displayName,
          isBroadcaster: rtcInfo.isBroadcaster,
          userAgent: navigator.userAgent,
          //temp
          // enforceDenial: randomDecision(),
        });
      });

      ws.addEventListener("close", function (e) {
        console.log("Closing websocket...", e);
      });
    });
  },

  [VicoAction.ConnectWithRetry](context, payload: ConnectActionPayload) {
    return new Promise((resolve, reject) => {
      const timeout = payload.timeout ?? 1000;
      const retries = payload.retries ?? 10;

      context
        .dispatch(VicoAction.Connect, payload.rtcInfo)
        .then(
          (slot) => resolve(slot),
          (reason) => {
            if (reason === "Introduction denied") {
              if (retries <= 0) reject("Too many denied connection attempts");
              else
                setTimeout(() => {
                  context.commit(VicoMutation.NextServer);
                  console.log(
                    "Retrying connection with new server",
                    context.getters["serverUrl"],
                    "for",
                    payload.rtcInfo
                  );
                  resolve(
                    context.dispatch(VicoAction.ConnectWithRetry, {
                      rtcInfo: payload.rtcInfo,
                      retries: retries - 1,
                      timeout,
                    })
                  );
                }, timeout);
            }
          }
        )
        .catch((e) => {
          console.error(e);
          reject(e);
        });
    });
  },

  /**
   * Combines the singular local mediaStream with the outgoing RTCSlot.
   * Requires slotOut and stream to be initialised already.
   * Renegotiates the RTC connection.
   */
  async [VicoAction.Broadcast](
    { state, dispatch, rootGetters, getters },
    conference: Conference
  ): Promise<void> {
    // const slot = state.slotOut;
    // if (!slot) throw Error("SlotOut is undefined?");
    // if (!slot.pc) throw Error("SlotOut has no PeerConnection");

    const stream: MediaStream = rootGetters[CoreMediaDevicesGetters.Stream];
    if (!stream) throw Error("No local stream found!");

    const user: User = rootGetters[CoreUserGetters.User];

    // If a conference was given by parameter, use that.
    // Otherwise selectedConf will do.
    const c = conference ?? state.conferenceSelected;
    if (!c) throw Error("no conf selected");

    const r = new RTCInfo(
      true,
      c.tenantId,
      c.broadcastId,
      c.id,
      `participant_${user.id}`,
      user.id,
      state.config.iceServers,
      getters["socketUrl"],
      user.editRequest.displayName ?? ""
    );
    const slot: RTCSlot = await dispatch(VicoAction.ConnectWithRetry, {
      rtcInfo: r,
      retries: 10,
      timeout: 1000,
    });
    if (slot) {
      // commit(VicoMutation.SetSlotOut, slot);
      slot.mediaStream = stream;
      slot.startBroadcasting();
    }
  },

  /**
   *  Opens websocket connections for all slots (Viewer and Broadcaster)
   *  This should only be used in a consumer client, not by admins.
   *
   * @deprecated
   */
  async [VicoAction.ConnectAll]({
    state,
    dispatch,
    getters,
    rootGetters,
  }): Promise<void> {
    if (!state.conferenceSelected) throw new Error("No conference selected.");
    const user: User = rootGetters[CoreUserGetters.User];

    // highly specific filter for test calls
    const infos = state.conferences
      .filter(
        (conf) =>
          !conf.tags?.includes("checkupcall") &&
          conf.broadcastId === state.broadcastId
      )
      .flatMap((conference) => {
        const tId = conference.tenantId;
        const bId = conference.broadcastId;
        const cId = conference.id;
        const iSrv = state.config.iceServers;
        const sUrl = getters["socketUrl"];
        const uId = user.id;
        const uName = user.editRequest.displayName ?? "No Name";
        const rtcFactory = (isBroadcaster: boolean, slotId: string) => {
          return new RTCInfo(
            isBroadcaster,
            tId,
            bId,
            cId,
            slotId,
            uId,
            iSrv,
            sUrl,
            uName
          );
        };

        const rtcInfos: RTCInfo[] = [];
        // This adds slots for each slotId
        Object.keys(conference.slotIds)
          .filter((slotId) => !!conference.slotIds[slotId])
          .forEach((id) => {
            rtcInfos.push(rtcFactory(false, id));
          });

        return rtcInfos;
      });

    console.info("Making promises...");
    console.info(infos);
    const promises: Promise<void>[] = infos.map((rtcInfo) =>
      dispatch(VicoAction.ConnectWithRetry, {
        rtcInfo: rtcInfo,
        retries: 10,
        timeout: 1000,
      }).then(
        (slot: RTCSlot) => {
          slot.negotiateRtcConnection();
        },
        (reason: string) => {
          console.error(reason);
        }
      )
    );
    console.info(promises);

    await Promise.allSettled(promises);
  },

  [VicoAction.Disconnect]({ commit, dispatch, state }): void {
    const slots = state.slots;
    //??? maybe all?
    // if (state.slotOut) slots.push(state.slotOut);

    slots.forEach((slot) => {
      slot.pc?.close();
      slot.wsConnection.websocket?.close();
      slot.mediaStream?.getTracks().forEach((track) => track.stop());
    });
    dispatch(CoreMediaDevicesActions.Clear, undefined, {
      root: true,
    });
    commit(VicoMutation.Disconnect);
  },

  async [VicoAction.StopBroadcasting]({ dispatch, state }) {
    if (state.slotOut) {
      dispatch(VicoAction.Close, state.slotOut);
    }
  },

  /**
   * Sends out a call request to a specific user.
   * This creates a new temporary conference for the sender and target.
   */
  [VicoAction.InitializeCall](
    { rootGetters },
    payload: CallInitActionPayload
  ): Promise<Conferences> {
    const user: User = rootGetters[CoreUserGetters.User];
    const req: CallInitRequest = {
      broadcastId: payload.broadcastId,
      tenantId: payload.tenantId,
      name: `TestCall-${user.id}-${Date.now()}`,
      isOpenToJoin: false,
      tags: ["gema", "checkupcall"],
      participants: [
        { userId: user.id, slotId: `participant_${user.id}` },
        { userId: payload.userId, slotId: `participant_${payload.userId}` },
      ],
    };
    return vicoService.initCall(req);
  },

  /**
   * Negotiates RTC Connections for all stored slots.
   *
   * @deprecated
   */
  [VicoAction.NegotiateAll]({ state }): void {
    const slots: RTCSlot[] = state.slots;
    slots.forEach((slot) => {
      if (!slot.rtcInfo.isBroadcaster) slot?.negotiateRtcConnection();
    });
    // state.slotOut?.negotiateRtcConnection();
  },

  [VicoAction.RefreshTracks]({ commit, rootGetters }): void {
    const tenantConfig: TenantConfig =
      rootGetters[CoreConfigGetters.TenantConfig];

    vicoService
      .getTrackInfos(tenantConfig.tenantId)
      .then((res) => {
        console.log(res);
        commit(VicoMutation.SetTracks, res.trackinfos);

        // if (state.conferences.length)
        //   commit(VicoMutation.SelectConference, state.conferences[0]);
      })
      .catch((err: unknown) => {
        console.error(err);
      });
  },

  [VicoAction.AddSlot]({ commit }, slot: RTCSlot) {
    commit(VicoMutation.AddSlot, slot);
  },

  [VicoAction.SelectConference]({ commit }, conference: Conference) {
    commit(VicoMutation.SelectConference, conference);
  },

  [VicoAction.SelectSlot]({ commit }, slotId: string) {
    // TODO: no mutation selectSlot
    commit("selectSlot", slotId);
  },

  [VicoAction.SetBroadcastId]({ commit }, id: string) {
    commit(VicoMutation.SetBroadcastId, id);
  },

  [VicoAction.MuteIncomingAudio]({ state }) {
    Object.values(state.slots).forEach((slot) => {
      slot.mediaStream?.getAudioTracks().forEach((track) => {
        track.enabled = false;
      });
    });
  },

  [VicoAction.UnmuteIncomingAudio]({ state }) {
    Object.values(state.slots).forEach((slot) => {
      slot.mediaStream?.getAudioTracks().forEach((track) => {
        track.enabled = true;
      });
    });
  },

  [VicoAction.ToggleIncomingAudio]({ state }) {
    Object.values(state.slots).forEach((slot) => {
      slot.mediaStream?.getAudioTracks().forEach((track) => {
        track.enabled = !track.enabled;
      });
    });
  },

  // TODO: remove this. please.
  // Only use for broadcasting slot
  [VicoAction.Close]({ commit }, slot: RTCSlot) {
    if (slot) {
      slot.close();
      // commit(VicoMutation.Disconnect);
      commit(VicoMutation.SetSlotOut, null);
    }
  },

  // TODO: find out if this makes sense
  [VicoAction.EndConference](
    { commit, state, dispatch },
    payload: EndConferencePayload
  ) {
    if (state.slotOut) state.slotOut.close();
    const conf = state.conferences.find((c) => c.id === payload.conferenceId);
    if (conf) commit(VicoMutation.RemoveConference, conf);
    commit(VicoMutation.Disconnect);
    commit(VicoMutation.SetSlotOut, null);

    dispatch(CoreMediaDevicesActions.Clear, null, { root: true });

    vicoService.endConference(
      payload.tenantId,
      payload.broadcastId,
      payload.conferenceId
    );
  },

  async [VicoAction.FetchServers]({ commit }): Promise<VicoConfig> {
    const {
      servers: { iceServers, serverUrls },
    } = await vicoService.getServers();

    const serverIndex = Math.floor(Math.random() * serverUrls.length);
    const randomUrl = serverUrls[serverIndex];
    const config: VicoConfig = {
      iceServers: parseIceServerList(iceServers),
      serverUrls,
      serverIndex,
    };
    vicoService.http.defaults.baseURL = `https://${randomUrl}/api/v1`;
    commit(VicoMutation.SetConfig, config);
    return config;
  },
};

function parseIceServerList(iceServers: string[]) {
  const servers: IceServer[] = [];
  iceServers.forEach((server) => {
    const parts = server.split(";");
    const anotherIce = {
      urls: parts[0],
      username: parts[1],
      credential: parts[2],
    };
    servers.push(anotherIce);
  });
  return servers;
}

// function randomDecision() {
//   const r = Math.random() < 0.5;
//   console.log("enforce denial (failed connection):", r);
//   return r;
// }
