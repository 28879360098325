import { getters } from "./getters";
import { Module } from "vuex";
import { actions } from "./actions";
import { mutations } from "./mutations";
import { RootState } from "@/store/RootState";
import { GemaMemberSearchState } from "./types";

export const defaultState: GemaMemberSearchState = {
  searchTerm: "",
  results: null,
};

export const gemaMemberSearch: Module<GemaMemberSearchState, RootState> = {
  namespaced: true,
  state: defaultState,
  getters,
  actions,
  mutations,
};
