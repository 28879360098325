import { ActionTree } from "vuex";
import { AddBannedWordRequest, Word, WordState } from "./types";
import { AxiosResponse } from "axios";
import Vue from "vue";
import i18n from "@/plugins/i18n/i18n";
import { handleHttpError } from "@/helpers";
import { RootState } from "@/store/RootState";
import { http } from "@/services/api.service";

export const actions: ActionTree<WordState, RootState> = {
  async allBannedWordsByTanant({ commit }): Promise<AxiosResponse<Word[]>> {
    try {
      const res: AxiosResponse<Word[]> = await http.get(`/tenant/badword/all`);
      if (!res.data || res.data == []) {
        commit(
          "setBadWordsError",
          "The blocked terms and phrases list is empty"
        );
        return res;
      }

      commit("setBadWordList", res.data);
      commit("setBadWordsError", "");
      return res;
    } catch (error) {
      handleHttpError(error, {
        204: i18n.tc(`The blocked terms and phrases list is empty`),
      });
      throw error;
    }
  },
  async deleteBadWord(context, word: string) {
    try {
      const res = await http.delete(`/badword/all/${word}`);
      if (res.status === 200) {
        Vue.$toast.success(i18n.tc(`Bad word deleted successfully`), {
          timeout: 2000,
        });
        context.commit("deleteBadWord", word);
      }
      return res;
    } catch (error) {
      handleHttpError(error, {
        404: i18n.tc(`The word does not exist!`),
        409: i18n.tc(`This word is already deleted!`),
      });
      throw error;
    }
  },
  async addBadWord({ dispatch }, data: AddBannedWordRequest) {
    try {
      const res = await http.post(`/badword`, data);
      if (res.status === 200) {
        Vue.$toast.success(i18n.tc(`You successfully updated Blocked terms!`), {
          timeout: 2000,
        });
        //cannot call mutation here because response for list of bad words is only last word from list
        setTimeout(function () {
          dispatch("allBannedWordsByTanant");
        }, 1000);
      }
      return res;
    } catch (error) {
      handleHttpError(error, {
        404: i18n.tc(`That term or phrase is already banned!`),
        409: i18n.tc(`You can't ban this term or phrase!`),
      });
      throw error;
    }
  },
};
