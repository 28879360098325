import { IaService } from "@/services/api/ia.service";
import PollService from "@/services/api/secure-votes.service";
import { RootState } from "@/store/RootState";
import { Action, ActionContext } from "vuex/types/index";
import { RegistrationResponse } from "../@types";
import {
  AvailableVotesRequest,
  RegistrationAction,
  RegistrationMutation,
  RegistrationState,
} from "../@types/types";

const setSelectedBroadcastId = async (
  { commit }: ActionContext<RegistrationState, RootState>,
  broadcastId: string
): Promise<void> => {
  commit(RegistrationMutation.SetSelectedBroadcastId, broadcastId);
};

const setRepresentatorsRegistrations = async (
  { commit }: ActionContext<RegistrationState, RootState>,
  registrations: RegistrationResponse[]
): Promise<void> => {
  commit(RegistrationMutation.SetRepresentatorsRegistrations, registrations);
};

const fatchAvailableVotes = async (
  { commit }: ActionContext<RegistrationState, RootState>,
  availableVotesRequest: AvailableVotesRequest
): Promise<void> => {
  const votes = await PollService.fatchAvailableVotes(
    availableVotesRequest.userId,
    availableVotesRequest.days
  );
  commit(RegistrationMutation.SetAvailableVotes, votes.numAvailableVotes);
};

const resetAvailableVotes = async ({
  commit,
}: ActionContext<RegistrationState, RootState>): Promise<void> => {
  commit(RegistrationMutation.SetAvailableVotes, 0);
};

export const actions: Record<
  RegistrationAction,
  Action<RegistrationState, RootState>
> = {
  [RegistrationAction.SetSelectedBroadcastId]: setSelectedBroadcastId,
  [RegistrationAction.SetRepresentatorsRegistrations]:
    setRepresentatorsRegistrations,
  [RegistrationAction.FatchAvailableVotes]: fatchAvailableVotes,
  [RegistrationAction.ResetAvailableVotes]: resetAvailableVotes,
};
