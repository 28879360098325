import { initStoreEnum } from "@/spect8-core-vue/src/utils/StoreHelpers";
import { Namespace } from "@/store/types";

export interface ConfigState {
  enabledFeatures: Features;
}

// TODO externalize these somehow
// TODO (central DB? dedicated flags service?)
export enum Feature {
  GENERAL = "GENERAL",
  INTERACTION = "INTERACTION",
  POLLS = "POLLS",
  PINNED_MESSAGES = "PINNED_MESSAGES",
  SHOP = "SHOP",
  VIDEO_ADS = "VIDEO_ADS",
  BLOCKED_TERMS = "BLOCKED_TERMS",
  BANNED_CHATTERS = "BANNED_CHATTERS",
  MUTED_CHATTERS = "MUTED_CHATTERS",
  REPORTS = "REPORTS",
  NOTIFICATIONS = "NOTIFICATIONS",
  ROLES = "ROLES",
  LOYALTY = "LOYALTY",
  SCHEDULE = "SCHEDULE",
  MONITORING = "MONITORING",
  ACCOUNT_MANAGEMENT = "ACCOUNT_MANAGEMENT",
  RESOURCES = "RESOURCES",
  QA = "QA",
  PINS = "PINS",
  BROADCAST_MANAGER = "BROADCAST_MANAGER",
  SETTINGS = "SETTINGS",
  VICO = "VICO",

  // gema stuff
  GEMA_ACCREDITATION = "GEMA_ACCREDITATION",
  GEMA_USER_MANAGEMENT = "GEMA_USER_MANAGEMENT",
  GEMA_QA_MANAGEMENT = "GEMA_QA_MANAGEMENT",
  SECURE_POLLS = "SECURE_POLLS",
  POLL_RESULTS = "POLL_RESULTS",
}

export type Features = Record<Feature, boolean>;

export enum ConfigAction {
  FetchFeatures = "FetchFeatures",
}

type ConfigActionStrings = keyof typeof ConfigAction;
export const ConfigActions: Record<ConfigActionStrings, string> =
  initStoreEnum<ConfigActionStrings>(ConfigAction, Namespace.Config);

export enum ConfigMutation {
  SetFeatures = "SetFeatures",
}

export enum ConfigGetter {
  EnabledFeatures = "EnabledFeatures",
}

type ConfigGetterStrings = keyof typeof ConfigGetter;
export const ConfigGetters: Record<ConfigGetterStrings, string> =
  initStoreEnum<ConfigGetterStrings>(ConfigGetter, Namespace.Config);
