import {
  configure,
  extend,
  setInteractionMode,
  ValidationProvider,
} from "vee-validate";
import Vue from "vue";
import { email, required } from "vee-validate/dist/rules";
import i18n from "./i18n/i18n";

Vue.component("ValidationProvider", ValidationProvider);

setInteractionMode("eager");

configure({
  defaultMessage: (field, values) => {
    values._field_ = i18n.t(`fields.${field}`);
    return i18n.t(`validation.${values._rule_}`, values).toString();
  },
});

extend("required", required);
extend("requiredTrue", {
  ...required,
  validate(value) {
    return {
      required: true,
      valid: value === true,
    };
  },
  computesRequired: true,
});

extend("emailUnique", {
  params: ["strings"],
  validate: (v: string, params: unknown[] | { strings?: string[] } = {}) =>
    Array.isArray(params)
      ? !params.some((s) => s === v)
      : !params.strings?.some((s) => s === v),
});

extend("email", email);
