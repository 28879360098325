import { Store } from "vuex";
import { mediaDevices } from "../stores/mediaDevices";
import { CORE_MEDIA_DEVICES_NAMESPACE } from "../types";

function initMediaDevicesModule(store: Store<unknown>): void {
  if (!store.hasModule(CORE_MEDIA_DEVICES_NAMESPACE)) {
    store.registerModule(CORE_MEDIA_DEVICES_NAMESPACE, mediaDevices);
  }
}

export { initMediaDevicesModule };
