import { RootState } from "@/store/RootState";
import { Action, ActionContext } from "vuex/types/index";
import {
  AssignSlotTrack,
  TrackSlotDraggable,
  SwitchboardAction,
  SwitchboardMutation,
  SwitchboardState,
} from "../types";
import { handleHttpError, showNotification } from "@/helpers";
import { vicoService } from "@/spect8-core-vue/src/services/vico";
import { CoreConfigGetters } from "@/spect8-core-vue/src/types";

const pushSlotTrack = (
  { commit }: ActionContext<SwitchboardState, RootState>,
  slotTrack: TrackSlotDraggable
) => {
  commit(SwitchboardMutation.PushSlotTrack, slotTrack);
};

const deleteSlotTrack = (
  { commit }: ActionContext<SwitchboardState, RootState>,
  slotTrack: TrackSlotDraggable
) => {
  commit(SwitchboardMutation.DeleteSlotTrack, slotTrack);
};

const fetchTrackInfos = async ({
  commit,
  rootGetters,
}: ActionContext<SwitchboardState, RootState>): Promise<void> => {
  try {
    const { tenantId } = rootGetters[CoreConfigGetters.TenantConfig];
    const trackinfos = await vicoService.getTrackInfos(tenantId);
    commit(SwitchboardMutation.SetTrackInfos, trackinfos.trackinfos);
  } catch (error) {
    handleHttpError(error);
    throw error;
  }
};

const fetchConferences = async ({
  commit,
  rootGetters,
}: ActionContext<SwitchboardState, RootState>): Promise<void> => {
  try {
    const { tenantId } = rootGetters[CoreConfigGetters.TenantConfig];
    const conferences = await vicoService.getConferences(tenantId);
    commit(SwitchboardMutation.SetConferences, conferences.conferences);
  } catch (error) {
    handleHttpError(error);
    throw error;
  }
};

const clearSlotTracks = ({
  commit,
}: ActionContext<SwitchboardState, RootState>) => {
  commit(SwitchboardMutation.ClearSlotTracks);
};

const assignSlotTracks = async (
  { dispatch }: ActionContext<SwitchboardState, RootState>,
  data: AssignSlotTrack
): Promise<void> => {
  try {
    data.conferenceIds.forEach((conferenceId: string) => {
      const slotAssignmentsByConference = data.slotAssignments.filter(
        (slots) => slots.conferenceId === conferenceId
      );
      Object.keys(data.slotId).forEach(async (slotId) => {
        await vicoService.assignSlotTracks({
          ...data,
          slotAssignments: slotAssignmentsByConference,
          slotId: slotId,
          conferenceId: conferenceId,
        });
      });
    });

    showNotification(200, "switchboard.tracksAssigned");
  } catch (error) {
    handleHttpError(error);
    dispatch(SwitchboardAction.ClearSlotTracks);
    dispatch(SwitchboardAction.FetchConferences);
    throw error;
  }
};

export const actions: Record<
  SwitchboardAction,
  Action<SwitchboardState, RootState>
> = {
  [SwitchboardAction.FetchTrackInfos]: fetchTrackInfos,
  [SwitchboardAction.AssignSlotTracks]: assignSlotTracks,
  [SwitchboardAction.FetchConferences]: fetchConferences,
  [SwitchboardAction.PushSlotTrack]: pushSlotTrack,
  [SwitchboardAction.DeleteSlotTrack]: deleteSlotTrack,
  [SwitchboardAction.ClearSlotTracks]: clearSlotTracks,
};
