import { DateTimeFormat, LocaleMessageObject } from "vue-i18n";
import { LocaleOptions } from "./index";

const BaseMessages: LocaleMessageObject = {
  error: {
    emailLogin: "E-Mail oder Passwort ist falsch!",
    pinLogin: "PIN ist falsch!",
    rolePermission: "Unzureichende Benutzerrollenberechtigung",
    sessionExpired: "Sitzung abgelaufen!",
  },
  poll: {
    voteError: "Abstimmung fehlgeschlagen",
    voteSuccess: "Stimme erfolgreich abgegeben!",
  },
};

const DateTimeFormats: DateTimeFormat = {
  DateShort: {
    day: "2-digit",
    month: "2-digit",
    year: "2-digit",
  },
  DateTimeShort: {
    day: "2-digit",
    month: "2-digit",
    year: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
  },
  DateTimeLong: {
    weekday: "short",
    day: "2-digit",
    month: "long",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  },
  Time: {
    hour: "2-digit",
    minute: "2-digit",
  },
  Date: {
    weekday: "long",
    month: "long",
    day: "numeric",
  },
  Time24: {
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  },
};

const DE: LocaleOptions = {
  BaseMessages,
  DateTimeFormats,
};

export default DE;
