import {
  ConnectionState,
  MediaDevicesAction,
  MediaDevicesMutation,
  MediaDevicesState,
} from "../../types/mediaDevices";
import { Action, ActionTree } from "vuex";

export const actions: ActionTree<MediaDevicesState, unknown> &
  Record<MediaDevicesAction, Action<MediaDevicesState, unknown>> = {
  async [MediaDevicesAction.Init]({ commit, dispatch }): Promise<void> {
    // try {
    //   const devices = await navigator.mediaDevices.enumerateDevices();
    //   await navigator.mediaDevices.getUserMedia({
    //     audio: true,
    //     video: true,
    //   });
    // } catch (err) {
    //   console.error(err);
    // }
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      commit(MediaDevicesMutation.SetStoredDeviceList, devices);

      // UserAgents set the chosen default device at the start of the array
      const defaultAudio = devices.find((dev) => dev.kind === "audioinput");
      if (defaultAudio) dispatch(MediaDevicesAction.SelectAudio, defaultAudio);
      const defaultVideo = devices.find((dev) => dev.kind === "videoinput");
      if (defaultVideo) dispatch(MediaDevicesAction.SelectVideo, defaultVideo);
    } catch (err) {
      console.error(err);
    }
  },

  [MediaDevicesAction.StartMedia]({
    state,
    commit,
  }): Promise<MediaStream | void> {
    // Start with clean state
    if (state.stream) state.stream.getTracks().forEach((track) => track.stop());

    if (!state.audioSelected && !state.videoSelected) {
      commit(MediaDevicesMutation.SetStream, undefined);
      return Promise.resolve();
    }

    const constraints: MediaStreamConstraints = {
      video: state.videoSelected
        ? { deviceId: state.videoSelected?.deviceId }
        : undefined,
      audio: state.audioSelected
        ? { deviceId: state.audioSelected?.deviceId }
        : undefined,
    };

    return navigator.mediaDevices.getUserMedia(constraints).then((stream) => {
      commit(MediaDevicesMutation.SetStream, stream);
      return stream;
    });
  },

  [MediaDevicesAction.SelectAudio](
    { state, commit },
    device?: MediaDeviceInfo
  ): Promise<void> {
    commit(MediaDevicesMutation.SelectAudio, device);

    // If no device is selected remove all audio tracks from existing stream in store
    if (!device) {
      if (state.stream)
        state.stream.getAudioTracks().forEach((track) => {
          state.stream?.removeTrack(track);
        });
      return Promise.resolve();
    }

    // Get user media and add the returned stream's audio track to main stream in store
    return navigator.mediaDevices
      .getUserMedia({
        audio: { deviceId: device.deviceId },
      })
      .then((stream) => {
        // If there's no stream in store we create it here
        if (!state.stream) {
          commit(MediaDevicesMutation.SetStream, stream);
          return;
        }

        // Remove current audio tracks
        state.stream
          .getAudioTracks()
          .forEach((track) => state.stream?.removeTrack(track));

        // Add new audio tracks
        stream
          .getAudioTracks()
          .forEach((track) => state.stream?.addTrack(track));
      });
  },

  [MediaDevicesAction.SelectVideo](
    { state, commit },
    device?: MediaDeviceInfo
  ): Promise<void> {
    commit(MediaDevicesMutation.SelectVideo, device);

    // If no device is selected remove all video tracks from existing stream in store
    if (!device) {
      if (state.stream)
        state.stream.getVideoTracks().forEach((track) => {
          state.stream?.removeTrack(track);
        });
      return Promise.resolve();
    }

    // Get user media and add the returned stream's video track to main stream in store
    return navigator.mediaDevices
      .getUserMedia({
        video: {
          deviceId: device.deviceId,
          height: { max: 480 },
          width: { max: 960 },
          frameRate: { max: 30 },
        },
      })
      .then((stream) => {
        // If there's no stream in store we create it here
        if (!state.stream) {
          commit(MediaDevicesMutation.SetStream, stream);
          return;
        }

        // Remove current video tracks
        state.stream
          .getVideoTracks()
          .forEach((track) => state.stream?.removeTrack(track));

        // Add new video tracks
        stream
          .getVideoTracks()
          .forEach((track) => state.stream?.addTrack(track));
      });
  },

  [MediaDevicesAction.OpenVideo]({ state }): void {
    state.stream?.getVideoTracks().forEach((track) => {
      track.enabled = true;
    });
  },

  [MediaDevicesAction.CloseVideo]({ state }): void {
    state.stream?.getVideoTracks().forEach((track) => {
      track.enabled = false;
    });
  },

  [MediaDevicesAction.ToggleVideo]({ state }): void {
    state.stream?.getVideoTracks().forEach((track) => {
      track.enabled = !track.enabled;
    });
  },

  [MediaDevicesAction.ToggleAudio]({ state }): void {
    state.stream?.getAudioTracks().forEach((track) => {
      track.enabled = !track.enabled;
    });
  },

  [MediaDevicesAction.Clear]({ commit }): void {
    commit(MediaDevicesMutation.Clear);
  },

  [MediaDevicesAction.ConnectionState](
    { commit },
    connectionState: ConnectionState
  ): void {
    commit(MediaDevicesMutation.SetConnectionState, connectionState);
  },

  [MediaDevicesAction.BroadcastActive]({ commit }, active: boolean): void {
    commit(MediaDevicesMutation.SetBroadcastActive, active);
  },
};
