import { RootState } from "@/store/RootState";
import { Getter, GetterTree } from "vuex";
import { AppGetter, AppState } from "./types";

export const getters: GetterTree<AppState, RootState> &
  Record<AppGetter, Getter<AppState, RootState>> = {
  [AppGetter.Initialized](state): boolean {
    return state.initialized;
  },
  [AppGetter.Locale](state): string {
    return state.locale;
  },
  [AppGetter.TenantError](state): boolean {
    return state.tenantError;
  },
};
