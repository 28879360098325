import i18n from "@/plugins/i18n/i18n";
import Vue from "vue";
import { Action, ActionTree } from "vuex/types/index";
import { http } from "@/services/api.service";
import { User } from "@/spect8-core-vue/src/types";
import { RootState } from "../RootState";
import { UsersAction, UsersMutation, UsersState } from "./types";

export const actions: ActionTree<UsersState, RootState> &
  Record<UsersAction, Action<UsersState, RootState>> = {
  async [UsersAction.GetUsers]({ commit }): Promise<User[]> {
    try {
      const users: User[] = await http
        .get<User[]>(`/user/tenant/all`)
        .then((res) => res.data);
      commit(UsersMutation.SetUsers, users);
      return users;
    } catch (error) {
      Vue.$toast.error(i18n.tc(`Something went wrong!`), {
        timeout: 2000,
      });
      throw error;
    }
  },
};
