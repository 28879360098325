import { Module } from "vuex";
import { mutations } from "./mutations";
import { getters } from "./getters";
import { actions } from "./actions";
import { ConfigState } from "../../types";

// ReExport to be backward compatible
export { CORE_CONFIG_NAMESPACE } from "../../types";

export const state: ConfigState = {};

const namespaced = true;

export const config: Module<ConfigState, unknown> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
