/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from "axios";
import VueRouter from "vue-router";
import { Store } from "vuex";
import { CoreConfigGetters, HitType, TrackingParameter } from "../types";
import { Logger } from "../utils/Logger";
import { generateRandomId } from "../utils/RandomId";

class TrackingService {
  overlaySessionId = "";
  router: VueRouter;
  sessionId: string;
  store: Store<unknown>;
  url: string | undefined;

  constructor(router: VueRouter, store: Store<unknown>, url?: string) {
    this.router = router;
    this.sessionId = generateRandomId();
    this.store = store;
    this.url = url;
  }

  logError(params: any): void {
    if (params.ed && params.ed.message && params.ed.stack) {
      try {
        params.ed = (params.ed as Error).toString();
        this.logEvent(params);
      } catch (error) {
        Logger.debug("Error log", error);
      }
    } else {
      this.logEvent(params);
    }
  }

  logEvent(params: any): void {
    params = { ...params };
    const tenantName = this.store.getters[CoreConfigGetters.TenantConfig]?.name;
    if (tenantName) {
      params[TrackingParameter.TENANT_ID] = tenantName;
    }

    if (params[TrackingParameter.HIT_TYPE] === undefined) {
      params[TrackingParameter.HIT_TYPE] = HitType.EVENT;
    }

    let resetOverlaySessionId = false;
    if (
      params[TrackingParameter.EVENT_ACTION] === "toggle" &&
      params[TrackingParameter.EVENT_LABEL] === "open"
    ) {
      this.overlaySessionId = generateRandomId();
    } else if (
      params[TrackingParameter.EVENT_ACTION] === "toggle" &&
      params[TrackingParameter.EVENT_LABEL] === "closed"
    ) {
      resetOverlaySessionId = true;
    }

    const pathInfo: any = this.getPathInfo();
    params = { ...pathInfo, ...params };

    this.log(params).then(() => {
      if (resetOverlaySessionId) {
        this.overlaySessionId = "";
      }
    });
  }

  private getPathInfo(): any {
    const location: any = window.location;
    const pathInfo: any = {};
    pathInfo[TrackingParameter.DOCUMENT_PATH] = this.router.currentRoute.path;
    pathInfo[TrackingParameter.DOCUMENT_TITLE] = this.router.currentRoute.name;
    if (location.hostname !== undefined) {
      pathInfo[TrackingParameter.DOCUMENT_HOST] = location.hostname;
    }
    if (location.href !== undefined) {
      pathInfo[TrackingParameter.DOCUMENT_LOCATION] = location.href;
    }
    return pathInfo;
  }

  private log(params: any): Promise<void> {
    params[TrackingParameter.APP_TYPE] = "WEB";
    params[TrackingParameter.TIMESTAMP] = Date.now();
    params[TrackingParameter.SESSION_ID] = this.sessionId;
    if (this.overlaySessionId !== "") {
      params[TrackingParameter.OVERLAY_SESSION_ID] = this.overlaySessionId;
    }

    for (const key in TrackingParameter) {
      if (
        params[TrackingParameter[key as keyof typeof TrackingParameter]] ===
        undefined
      ) {
        params[TrackingParameter[key as keyof typeof TrackingParameter]] = null;
      }
    }

    if (this.url) {
      return axios.post(this.url, JSON.stringify(params));
    }
    Logger.debug("Tracking", params);
    return new Promise((resolve) => resolve());
  }
}

let trackingService: TrackingService | undefined;

function initTrackingService(
  router: VueRouter,
  store: Store<unknown>,
  url?: string
): void {
  if (!trackingService) {
    trackingService = new TrackingService(router, store, url);
  }
}

export { trackingService, initTrackingService };
