import { MutationTree } from "vuex";
import { BroadcastId, ScheduledAds, VideoAds, VideoAdsState } from "./types";

export const mutations: MutationTree<VideoAdsState> = {
  setVideoAdsList(state: VideoAdsState, payload: VideoAds[]): void {
    state.allVideoAds.content = payload;
  },
  setVideoAdError(state: VideoAdsState, payload) {
    state.VideoAdsError = payload;
  },
  setBroadcastIdList(state: VideoAdsState, payload: BroadcastId[]): void {
    state.BroadcastIdList = payload;
  },
  setBroadcastIdError(state: VideoAdsState, payload) {
    state.BroadcastIdError = payload;
  },
  setScheduledAdsList(state: VideoAdsState, payload: ScheduledAds[]): void {
    state.allScheduledAds.content = payload;
  },
  setScheduledAdError(state: VideoAdsState, payload) {
    state.ScheduledAdsError = payload;
  },
};

export default mutations;
