import { getFeatures } from "@/helpers/features";
import { RootState } from "@/store/RootState";
import { Action, ActionTree } from "vuex";
import { ConfigAction, ConfigMutation, ConfigState } from "../types";

export const actions: ActionTree<ConfigState, RootState> &
  Record<ConfigAction, Action<ConfigState, RootState>> = {
  [ConfigAction.FetchFeatures]({ commit }, appId?: string) {
    // TODO: Get features via API
    commit(ConfigMutation.SetFeatures, getFeatures(appId || ""));
  },
};
