import { LocaleMessageObject } from "vue-i18n";
import validationMessages from "vee-validate/dist/locale/en.json";

const BaseMessages: LocaleMessageObject = {
  $vuetify: {
    noDataText: "No data available",
  },
  validation: {
    ...validationMessages.messages,
    email: "Please enter a valid email address",
    emailUnique:
      "Email address already in use. Please edit the existing account or delete and re-create.",
  },
  timeUnit: {
    hours: "{value} hour | {value} hours",
    minutes: "{value} minute | {value} minutes",
    days: "{value} day | {value} days",
  },
  tenantError: "Tenant doesn't exist!",
  "Admin Panel": "Admin Panel",
  "Chat settings": "Chat Settings",
  "Interaction Settings": "Interaction Settings",
  "User Management": "User Management",
  "Please enter an Application ID": "Please enter an Application ID",
  duration: "Duration",
  dataTable: {
    rowsPerPage: "Rows per page",
    actions: "Actions",
  },
  broadcasts: "Broadcasts",
  polls: {
    header: "Polls",
    actions: "Actions",
    addAnswer: "ADD ANOTHER ANSWER",
    answer: "Answer",
    answerNum: "Answer {num}",
    broadcasts: "Broadcasts",
    create: "Create new poll",
    createPoll: "Create Poll",
    discard: "Discard changes",
    end: "End",
    hours: "{value} hour | {value} hours",
    live: "Live",
    maxChars: "Max characters on answers",
    minutes: "{value} minute | {value} minutes",
    days: "{value} day | {value} days",
    weeks: "{value} week | {value} weeks",
    unlimited: "Unlimited",
    noVotes: "No votes yet",
    onDemand: "On demand",
    onEnd: "On poll end",
    question: "Question",
    resultVisibility: "Result Visibility",
    save: "Save changes",
    status: "Status",
    start: "Start",
    type: "Type",
    typeAnswer: "Type answer",
    typeQuestion: "Type your question here",
    addPredefinedAnswer: "Add predefined answer",
    addImageAnswer: "Add another image answer",
    maxVotesPerPersonValidationMessage:
      "Users can not have more votes than answers",
    subtitle: "Start and manage polls",
    selectBroadcasts: "Select broadcasts",
    fileSizeTooLarge: "File limit exceeded({limit})",
    singleWordAnswersLabel: "Single word answers",
    showAuthorNamesLabel: "Show author names",
    imageRestrictions:
      "Please note: Images will be cropped to {dimensions} and the file size must not exceed {fileSizeLimit}",
    location: "Post poll to chat",
    locationDefault: "No",
    locationInline: "Yes",
    pollStatus: {
      draft: "prepared",
      ended: "ended",
      active: "active",
    },
    pollType: {
      default: "default",
      open: "open",
      image: "image",
    },
    pollTypeLong: {
      default: "default poll",
      open: "open poll",
      image: "image poll",
    },
    pollTypeSelected: {
      election: "Election",
      poll: "Poll",
    },
    pollStatusSelected: {
      preparing: "PREPARED",
      preVote: "PRE-VOTE",
      pre_vote: "PRE-VOTE",
      live: "LIVE",
      hidden: "HIDDEN",
      closed: "CLOSED",
    },
    group: "Group",
    memberStatus: "Member Status",
    apllyChanges: "Apply",
    changeStatus: "Change Status",
    discardChanges: "Discard",
    choicesPerUser: "Choices per user",
    addOptionalImage: "Add image to question(optional)",
    all: "All",
    sucessPollUpdate: "You updated the poll successfully!",
    sucessPollCreate: "You successfully added a new poll!",
    showResults: "Show results",
    exportAll: "Export all",
    exportPage: "Export page",
    resultsMessage: "Results are visible in overlay",
    refreshResult: "Refresh Result",
    inAdminPanel: "In admin panel",
    inAdminPanelNum: "In admin panel (quorum: {num}%)",
    inBroadcast: "In broadcast",
    resumption: "Resumption",
    resumptionExp: "Select pre-votes to add",
    abstention: "{num} abstention | {num} abstentions",
    voter: "{num} voter | {num} voters",
    castVote: "{num} cast vote | {num} cast votes",
    totalVotes: "Total Votes",
    total: "Total",
    preVotes: "Pre-Votes",
    liveVotes: "Live-Votes",
    statistic: "Vote Statistic",
    quorum: "Quorum",
    select: "Select",
    statsRetrived: "Statistics retrieved at:",
    sendStats: "Send statistic",
    emailDialog: {
      title: "Send statistic to (Email, separated by comma):",
      cancel: "Cancel",
      send: "Send",
    },
    presenceSent: "Statistics sent",
    presenceSentError: "Statistics failed to send",
    eligibleVotersPresent:
      "Eligible voters present (now) (in person/digitally):",
    votersPresent: "Voters present (now) (in person/digitally):",
    eligibleVotersPresentAccumulated:
      "Eligible voters present (accumulated) (in person/digitally):",
    votersPresentAccumulated:
      "Voters present (accumulated) (in person/digitally):",
    requestStats: "Request statistics",
    minVotes: "Min Votes",
    maxVotes: "Max Votes",
    startDate: "Start Date",
  },
  pins: {
    pageTitle: "PIN Management",
    pageSubtitle: "Manage access and roles for your broadcasts",
    formTitle: "Create new PIN",
    formTitleEdit: "Editing PIN {pin}",
    pinLabel: "PIN",
    pinHelp:
      "PINs must have {min}-{max} characters and only contain the characters [a-zA-Z0-9-_]. \n PINs are case-sensitive.",
    pinErrorCharLimit: "PINs must have {min}-{max} characters",
    pinErrorSymbols: "PINs must only contain the characters [a-zA-Z0-9-_]",
    pinErrorUnique: "This PIN is already used",
    roleLabel: "Role",
    broadcastLabel: "Broadcasts",
    allBroadcastsCheckbox: "All existing and future broadcasts",
    formSubmit: "Create",
    formEdit: "Edit",
    listTitle: "PINs",
    colPin: "PIN",
    colRole: "Role",
    colBroadcast: "Broadcasts",
    colActions: "Actions",
    dialogDeleteTitle: "Are you sure you want to delete this pin?",
    expired: "Expired",
    expiresAt: "Expires at",
    allBroadcasts: "All broadcasts",
  },
  qa: {
    pageTitle: "Q&A Management",
    pageSubtitle: "Manage Questions & Answers",
    listTitle: "Questions",
    typeAnswer: "Type your answer",
    statusPending: "Open",
    statusApproved: "Approved",
    statusDismissed: "Dismissed",
    statusAnswered: "Completed",
    statusAnswering: "Live",
    inPersonContributionActionText: "Add in-person-contribution",
    approveActionText: "Approve",
    dismissActionText: "Dismiss",
    answeredActionText: "Complete",
    answeringActionText: "Live",
    commentActionText: "Comment",
    deleteActionText: "Delete",
    bulkUpdateError: "Failed to update, please try again",
    inPersonContribDialogTitle: "Add In Person Contribution",
    inPersonContribDialogFieldName: "Users Question:",
    submit: "Submit",
    startVideoCall: "Start Video Call",
  },
  accounts: {
    pageTitle: "Admin/Moderator Management",
    pageSubtitle: "Create accounts for your fellow admins and moderators",
    formTitle: "Create new account",
    formTitleEdit: "Editing account {account}",
    emailLabel: "Email",
    emailError: "Please enter a valid email address.",
    roleLabel: "Role",
    broadcastLabel: "Broadcasts",
    allBroadcastsCheckbox: "All existing and future broadcasts",
    formSubmit: "Create",
    formEdit: "Edit",
    listTitle: "Accounts",
    colEmail: "Email",
    colRole: "Role",
    colBroadcasts: "Broadcasts",
    colActions: "Actions",
  },
  resources: {
    pageTitle: "Resources",
    pageSubtitle: "Upload various types of files for your users to download",
    formTitle: "Upload file",
    fileLabel: "File",
    broadcastLabel: "Broadcasts",
    allBroadcastsCheckbox: "All existing and future broadcasts",
    formSubmit: "Upload",
    listTitle: "Files",
    file: "File",
    type: "Type",
    size: "Size",
    uploadError: "Image upload error, please try again",
  },
  account: "Account",
  resource: "Resource",
  created: {
    account: "Successfully created @.lower:account",
    resource: "Successfully created @.lower:resource",
  },
  updated: {
    account: "Successfully updated @.lower:account",
    resource: "",
  },
  deleted: {
    account: "Successfully deleted @.lower:account",
    resource: "Successfully deleted @.lower:resource",
  },
  dialogDeleteTitle: "Are you sure you want to delete {item}?",
  "Manage basic chat functionality like Slow Mode, Pinned messages, etc.":
    "Manage basic chat functionality like Slow Mode, Pinned messages, etc.",
  "Manage interactive parts of the Side panel like Polls and Quizzes":
    "Manage interactive parts of the Side panel like Polls and Quizzes",
  "Load Message History (users are able to see previous messages after connecting to the side panel)":
    "Load message history(users are able to see previous messages after connecting to the side panel)",
  "Enter your own text to require first-time viewers to agree to your rules before chatting. One rule per line.":
    "Enter your own text to require first-time viewers to agree to your rules before chatting. One rule per line.",
  "Pinned Messages": "Pinned messages",
  "Add a pinned message": "Add a pinned message",
  Channel: "Channel",
  "Pin message": "PIN MESSAGE",
  "Time remaining": "Time remaining",
  "Pinned by": "Pinned by",
  "No data available": "No data available",
  "Muted chatters": "Muted Chatters",
  "View and modify your muted users list":
    "View and modify your muted users list",
  "Add terms or phrases to block": "Add terms or phrases to block",
  "Add terms or phrases from CSV file": "Add terms or phrases from CSV file",
  addBadTerms: "Add one or more terms you want to block",
  "Blocked terms": "Blocked terms",
  "Add users by user-name or user-id": "Add users by user-name or user-id",
  Roles: "Roles",
  "Role Management": "Role Management",
  "Assign roles to certain Chatters e.g for enabling them to moderate a chatroom":
    "Assign roles to certain Chatters e.g for enabling them to moderate a chatroom",
  "Assign a role to a user": "Assign a role to a user",
  "Select a role": "Select a role",
  "Use '@' to get user list": "Use '@' to get user list",
  "Incorrect input. Please check id or username of user":
    "Incorrect input. Please check id or username of user",
  "You successfully updated user role!": "You successfully updated user role!",
  "You successfully updated Blocked terms!":
    "You successfully updated Blocked terms!",
  "Are you sure you want to remove this term or phrase?":
    "Are you sure you want to remove this term or phrase?",
  Cancel: "Cancel",
  Delete: "Delete",
  "Bad word deleted successfully": "Bad word deleted successfully",
  "Bad word already exists": "Bad word already exists",
  "Chat settings applied successfully": "Chat settings applied successfully",
  "You successfully pinned a message": "You successfully pinned a message",
  "Currently assigned roles": "Currently assigned roles",
  "Last modified": "Last modified",
  "Change password": "Change password",
  "New password": "New password",
  "Repeat password": "Repeat password",
  "Current password": "Current password",
  "Password incorrect!": "Password incorrect!",
  "You successfully updated your password":
    "You successfully updated your password!",
  "Password don't match": "Password don't match!",
  "Invite users": "Invite users",
  Logout: "Logout",
  "Create profile": "Create profile",
  "Join the chat": "Join the chat",
  "Tell us how you want to present yourself in the side panel":
    "Tell us how you want to present yourself in the side panel",
  "Start chatting": "Start chatting",
  "Edit side panel profile": "Edit side panel profile",
  Report: "Report",
  Block: "Block",
  "Dismiss pinned message": "Dismiss pinned message",
  "You are muted": "You are muted",
  "Active polls": "Active polls",
  "Past polls": "Past polls",
  "Manage the content of the Shop Tab.": "Manage the content of the Shop Tab.",
  "Shop categories": "Shop categories",
  "Currently listed items": "Currently listed items",
  "Add shop category": "Add shop category",
  "Add shop item": "Add shop item",
  "No shop items": "No shop items",
  Image: "Image",
  Title: "Title",
  Price: "Price",
  Currency: "Currency",
  Category: "Category",
  Description: "Description",
  "indicates required field": "indicates required field",
  "Delete selected": "Delete selected",
  "Reported user": "Reported user",
  Reason: "Reason",
  "Reported by": "Reported by",
  "Report date": "Report date",
  "Event Scheduler": "Event Scheduler",
  "Schedule events like polls and notifications ahead of time":
    "Schedule events like polls and notifications ahead of time",
  "Prepared events": "Prepared events",
  Type: "Type",
  Content: "Content",
  "Scheduled For": "Scheduled For",
  "File Has Headers": "File Has Headers",
  "Choose file": "Choose file",
  "No file chosen": "No file chosen",
  Load: "Load",
  Field: "Field",
  Column: "Column",
  Discard: "Discard",
  Preview: "Preview",
  Schedule: "Schedule",
  Reschedule: "Reschedule",
  "Choose schedule time": "Choose schedule time",
  "Select schedule time": "Select schedule time",
  Missed: "Missed",
  "Created by": "Created by",
  Password: "Password",
  "Password is required": "Password is required",
  "Email is required": "Email is required",
  "Password must have 5+ characters": "Password must have 5+ characters",
  "E-mail must be valid": "E-mail must be valid",
  minutes: "minutes",
  seconds: "seconds",
  days: "days",
  "Sign up": "Sign up",
  "Retype Password": "Retype Password",
  "Passwords must match": "Passwords must match",
  "The banned user list is empty": "The banned user list is empty",
  "You successfully banned this user!": "You successfully banned this user!",
  "The user does not exist!": "The user does not exist!",
  "This user is already banned!": "This user is already banned!",
  Notifications: "Notifications",
  "Notify user on top of the video player":
    "Notify user on top of the video player",
  "Create notification": "Create notification",
  "Chose from template": "Chose from template",
  "Notifications - Standard": " Notifications - Standard",
  "Notify you viewers by using text, images or links.":
    "Notify you viewers by using text, images or links.",
  "Notifications - Link to sidepanel tab":
    "Notifications - Link to sidepanel tab",
  "Direct your viewers to a specific tab in the sidepanel like POLLS or SHOP.":
    "Direct your viewers to a specific tab in the sidepanel like POLLS or SHOP.",
  "Animated Ad": "Animated Ad",
  "Play an ad for your viewers on top of the video player.":
    "Play an ad for your viewers on top of the video player.",
  "Pick a time": "Pick a time",
  "Pick animation": "Pick animation",
  "Animation type": "Animation type",
  "Pick width": "Pick width",
  "Size(Percentage of width)": "Size(Percentage of width)",
  "CREATE NOTIFICATION": "CREATE NOTIFICATION",
  "Server error!": "Server error!",
  "You successfully deleted a shop item!":
    "You successfully deleted a shop item!",
  "You successfully updated shop item!": "You successfully updated shop item!",
  "You successfully created new shop item!":
    "You successfully created new shop item!",
  "You successfully deleted shop category!":
    "You successfully deleted shop category!",
  "You successfully created new shop category!":
    "You successfully created new shop category!",
  "You successfully unbanned this user!":
    "You successfully unbanned this user!",
  "This user is already deleted!": "This user is already deleted!",
  Add: "Add",
  Search: "Search",
  "Are you sure you want to unban this username?":
    "Are you sure you want to unban this username?",
  "The channels list is empty": "The channels list is empty",
  Unban: "Unban",
  "There are no Pinned Messages": "There are no Pinned Messages",
  "You successfully deleted this pinned message!":
    "You successfully deleted this pinned message!",
  "The pinned message does not exist!": "The pinned message does not exist!",
  "This pinned message is already deleted!":
    "This pinned message is already deleted!",
  "This pinned message is already pinned!":
    "This pinned message is already pinned!",
  "You successfully added new poll!": "You successfully added new poll!",
  "You successfully updated poll!": "You successfully updated poll!",
  "You successfully updated poll status!":
    "You successfully updated poll status!",
  "You successfully deleted a poll!": "You successfully deleted a poll!",
  "Pinned message must be 240 characters or less":
    "Pinned message must be 240 characters or less",
  "Are you sure you want to delete this pinned message?":
    "Are you sure you want to delete this pinned message?",
  "Please select a channel!": "Please select a channel!",
  here: "here",
  "The muted user list is empty": "The muted user list is empty",
  "You successfully muted this user!": "You successfully muted this user!",
  "This user is already muted!": "This user is already muted!",
  "You successfully unmuted this user!": "You successfully unmuted this user!",
  "Add a user to your mute list": "Add a user to your mute list",
  "Set timeout duration": "Set timeout duration",
  "Currently muted": "Currently muted",
  "Are you sure you want to unmute this username?":
    "Are you sure you want to unmute this username?",
  UNMUTE: "UNMUTE",
  Moderation: "Moderation",
  "The blocked terms and phrases list is empty":
    "The blocked terms and phrases list is empty",
  "The word does not exist!": "The word does not exist!",
  "This word is already deleted!": "This word is already deleted!",
  "That term or phrase is already banned!":
    "That term or phrase is already banned!",
  "You can't ban this term or phrase!": "You can't ban this term or phrase!",
  Close: "Close",
  "Channel privileges": "Channel Privileges",
  "Manage interactive parts of the Side panel like Video Ads":
    "Manage interactive parts of the Side panel like Video Ads",
  Languages: "Languages",
  "Invite user with their email": "Invite user with their email",
  Invite: "Invite",
  "You successfully invited user": "You successfully invited user",
  "Something went wrong. Or user is already invited. Please check.":
    "Something went wrong. Or user is already invited. Please check.",
  "You successfully changed language!": "You successfully changed language!",
  "Are you sure you want to delete this category":
    "Are you sure you want to delete this category",
  "Are you sure you want to delete this item":
    "Are you sure you want to delete this item",
  Mute: "Mute",
  Ban: "Ban",
  "Video Ad - Link": "Video Ad - Link",
  "Insert the destination your Video Ad should link to (this link will not be visible to viewers)":
    "Insert the destination your Video Ad should link to (this link will not be visible to viewers)",
  "ADD VIDEO": "ADD VIDEO",
  "Video Ad - Settings": "Video Ad - Settings",
  "Create Video Ad": "Create Video Ad",
  Video: "Video",
  Link: "Link",
  "Closable after": "Closable after",
  "Last sent": "Last sent",
  "Upload video": "Upload video",
  "Link hosted video": "Link hosted video",
  "Video Ad - Link (Destination)": "Video Ad - Link (Destination)",
  "Video Ad - Name": "Video Ad - Name",
  "Add a name for the Ad": "Add a name for the Ad",
  "Video Ad - Video (Source)": "Video Ad - Video (Source)",
  "If you are hosting the video somewhere, you can also use that link":
    "If you are hosting the video somewhere, you can also use that link",
  Send: "Send",
  DELETE: "DELETE",
  "Scheduled Ads": "Scheduled Ads",
  "Schedule Selected Ad": "Schedule Selected Ad",
  "Selected Video Ad Name: ": "Selected Video Ad Name: ",
  "Schedule Ad - Description": "Schedule Ad - Description",
  "Schedule Ad - Broadcast": "Schedule Ad - Broadcast",
  "Broadcast name": "Broadcast name",
  Broadcast: "Broadcast",
  "Schedule Ad - Time": "Schedule Ad - Time",
  "Cron job preview: ": "Cron job preview: ",
  SCHEDULE: "SCHEDULE",
  "Edit Video Ad": "Edit Video Ad",
  EDIT: "EDIT",
  Instantly: "Instantly",
  "3 seconds": "3 seconds",
  "5 seconds": "5 seconds",
  "10 seconds": "10 seconds",
  "Not closeable": "Not closeable",
  "Video Ad Name": "Video Ad Name",
  "Ad name": "Ad name",
  Cron: "Cron",
  "Last Executed": "Last Executed",
  "Valid Link URL is required!": "Valid Link URL is required!",
  "Link URL must be valid!": "Link URL must be valid!",
  "Valid MP4 or WebM video URL is required!":
    "Valid MP4 or WebM video URL is required!",
  "Video URL must be valid!": "Video URL must be valid!",
  "Please insert a valid MP4 or WebM Video URL!":
    "Please insert a valid MP4 or WebM Video URL!",
  "Description must be less than 64 characters":
    "Description must be less than 64 characters",
  "Are you sure you want to delete this Video Ad?":
    "Are you sure you want to delete this Video Ad?",
  "Are you sure you want to delete this Scheduled Ad?":
    "Are you sure you want to delete this Scheduled Ad?",
  "minute(s)": "minute(s)",
  "hour(s) on minute": "hour(s) on minute",
  "day(s) at": "day(s) at",
  at: "at",
  "On the": "On the",
  "day, of every": "day, of every",
  "month(s), at": "month(s), at",
  Every: "Every",
  Mon: "Mon",
  Tue: "Tue",
  Wed: "Wed",
  Thu: "Thu",
  Fri: "Fri",
  Sat: "Sat",
  Sun: "Sun",
  "Has to be between": "Has to be between",
  and: "and",
  MINUTES: "MINUTES",
  HOURLY: "HOURLY",
  DAILY: "DAILY",
  WEEKLY: "WEEKLY",
  MONTHLY: "MONTHLY",
  ADVANCED: "ADVANCED",
  "Add a description to identify different scheduled events":
    "Add a description to identify different scheduled events",
  "cron expression:": "cron expression:",
  Loyalty: "Loyalty",
  "Manage rewards": "Manage Rewards",
  Leaderboard: "Leaderboard",
  "Browse a list of users and the amount of points they have":
    "Browse a list of users and the amount of points they have",
  "Activate the loyalty system and see how Loyalty Points are distributed":
    "Activate the loyalty system and see how Loyalty Points are distributed",
  "Enable various rewards and edit their price":
    "Enable various rewards and edit their price",
  "The video ad list is empty": "The video ad list is empty",
  "You successfully added this Video Ad!":
    "You successfully added this Video Ad!",
  "The Video Ad does not exist!": "The Video Ad does not exist!",
  "This Video Ad is already added!": "This Video Ad is already added!",
  "You successfully edited this Video Ad!":
    "You successfully edited this Video Ad!",
  "You successfully Scheduled this Ad!": "You successfully Scheduled this Ad!",
  "The Scheduled Ad does not exist!": "The Scheduled Ad does not exist!",
  "This Scheduled Ad is already added!": "This Scheduled Ad is already added!",
  "You successfully deleted this Video Ad!":
    "You successfully deleted this Video Ad!",
  "This Video Ad is already deleted!": "This Video Ad is already deleted!",
  "You successfully deleted this Scheduled Ad!":
    "You successfully deleted this Scheduled Ad!",
  "This Scheduled Ad is already deleted!":
    "This Scheduled Ad is already deleted!",
  "The broadcast id list is empty": "The broadcast id list is empty",
  "The Scheduled ad list is empty": "The Scheduled ad list is empty",
  "Manage interactive parts of the Side Panel like Polls and Pinned Messages":
    "Manage interactive parts of the Side Panel like Polls and Pinned Messages",
  "10 minutes": "10 minutes",
  "30 minutes": "30 minutes",
  "1 hour": "1 hour",
  "12 hours": "12 hours",
  "24 hours": "24 hours",
  Indefinitely: "Indefinitely",
  " hours left": " hours left",
  " minutes left": " minutes left",
  " days left": " days left",
  pickerLanguage: "eng",
  "One time ad": "One time ad",
  Now: "Now",
  Scheduled: "Scheduled",
  "Recurring job": "Recurring job",
  "Cron Job": "Cron Job",
  plusOne: "other",
  plusMore: "others",
  Date: "Date",
  UniqueUsers:
    "Total number of unique user-IDs connected <br> in the selected timeframe.",
  TotalLinkClicks:
    "Total number of link clicks in the selected timeframe.<br> Multiple clicks by the same user are counted.",
  UniqueLinkClicks:
    "Multiple clicks on the same link <br> by the same user are only counted once.",
  PeakConcurrentUsers:
    "Peak count of unique user-IDs connected <br> at the same time in the selected timeframe.",
  "E-Mail already in use. Please edit the existing account or delete and re-create.":
    "E-Mail already in use. Please edit the existing account or delete and re-create.",
  "The selected broadcast does not support the Broadcast Moderator role.":
    "The selected broadcast does not support the Broadcast Moderator role.",
  "Unique users": "Unique users",
  "Total link clicks": "Total link clicks",
  "Unique link clicks": "Unique link clicks",
  "Peak concurrent users": "Peak concurrent users",
  "This week": "This week",
  "This month": "This month",
  "This year": "This year",
  Custom: "Custom",
  CHOOSE: "CHOOSE",
  "Stats loaded successfully": "Stats loaded successfully",
  Timeframe: "Timeframe",
  Monitoring: "Monitoring",
  MONITORING: "MONITORING",
  Today: "Today",
  broadcastEditor: {
    name: "Title",
    description: "Description",
    namePlaceholder: "Enter your broadcast title here",
    agenda: "Agenda",
    thumbnail: "Thumbnail",
    logo: "Logo",
    startDate: "Start date",
    endDate: "End date",
    deleteDate: "Delete date",
    startText: "Start Broadcast",
    endText: "End Broadcast",
    deleteText: "Delete Broadcast",
    saveText: "SAVE CHANGES",
    createText: "Create Broadcast",
    backgroundImage: "Background Image",
    deleteModalTitle:
      "Are you sure you want to delete the broadcast {broadcastName}?",
    deleteModalDescription:
      "This will delete all data associated with this broadcast.\r\nThis action cannot be undone.",
    makePrimary: "make primary",
    addVideoSourceBtn: "Add source",
    cardDescription: "Create new broadcasts or manage existing ones",
    cardTitle: "Broadcast Manager",
    videoSourceInputPlaceholder: "Video source url",
    descriptionPlaceholder: "Enter your broadcast description here",
    agendaPlaceholder: "Enter your agenda here",
    videoFeedTitle: "Video Feed URLS",
    broadcastCreated: "Broadcast created",
    broadcastUpdated: "Broadcast updated",
    userPinsEmpty: "No user pins have been created for this broadcast yet.",
    moderatorPinsEmpty:
      "No moderator pins have been created for this broadcast yet.",
    userPinsTitle: "PIN - User",
  },
  form: {
    fileInput: "Upload file",
    selectDate: "Select Date",
    imageUploadFail: "Image upload failed, please try again.",
    clear: "Clear",
    selectFile: "Select a file",
    chooseFile: "Choose file",
  },
  forms: {
    imageUploadTooltip:
      "Images will be cropped to the following dimensions: {size}",
    search: "Search",
  },
  Interactive: "Interactive",
  General: "General",
  roles: {
    pageTitle: "Roles",
    pageSubtitle:
      "Assign roles to certain Chatters e.g for enabling them to moderate a chatroom",
  },
  blocked: {
    pageTitle: "Blocked Terms and Phrases",
    pageSubtitle:
      "Messages containing these terms or phrases will be blocked from chat",
  },
  Email: "Email",
  search: "Search",
  "Select a channel": "",
  "create video ad": "",
  chatSettings: {
    pageTitle: "General Settings",
    pageSubtitle:
      "Activate specific tabs in the Side Panel and set certain Chat restrictions",
    userColorModeText: "User name colors in chat",
    sectionTitle: "Chat Settings",
    broadcastHint:
      "Manage your chat settings for the selected broadcast. If you want to set tenant-wide defaults please clear the input field so it shows 'Tenant default'.",
    apply: "Apply changes",
    rules: "settings.rules",
    allowUrl: "Allow URLs in Chat",
    messageHistory: "Load message history",
    messageHistoryTooltip: "Number of messages that a new user can see.",
    charLimit: "Character limit per message",
    charLimitTooltip:
      "Messages exceeding this limit <br> will get blocked. <br> '0' means 'no limit'.",
    maxMessageCountByTimeTooltip:
      "Prohibit users from sending<br> the same message repeatedly. <br> '5' means the 6th same consecutive <br> message by the same user will <br> get blocked. <br> '0' means 'no restrictions'.",
    maxMessageCountByTime:
      "Number of times a user can send the <b>same</b> message consecutively",
    maxSameMessageCount:
      "Number of times a user can send <b>any</b> message over a set period of time",
    maxSameMessageCountLimit:
      "Prohibit users from sending too <br> many messages over a set <br> period of time (60 seconds). <br> '5' means a user can only send <br> 5 messages within 60 seconds. <br> '0' means 'no restrictions'.",
  },
  pinned: {
    pageTitle: "Pinned Messages",
    pageSubtitle: "Manage pinned messages",
  },
  shop: {
    pageTitle: "Shop Settings",
    pageSubtitle: "Manage the Shop tab in the Side Panel",
    disclaimer: "Shop Disclaimer",
    updateDisclaimer: "Update Disclaimer",
  },
  videoAds: {
    broadcasts: "Broadcasts",
    pageTitle: "Video Ads",
    pageSubtitle:
      "Show a video on top of the live stream and link to your desired destination",
  },
  bans: {
    pageTitle: "Muted Users",
    pageSubtitle: "View and modify your muted users.",
    broadcastLabel: "Broadcasts",
    broadcastPlaceholder: "Select broadcasts",
    banDurationLabel: "Set mute duration",
    editorTitle: "Mute a user",
    currentlyBanned: "Currently banned",
    tableHeaders: {
      banDate: "Ban Date",
      banDuration: "Ban Duration",
    },
    hoursLeft: "{value} hour left | {value} hours left",
    minutesLeft: "{value} minute left | {value} minutes left",
    secondsLeft: "{value} second left | {value} seconds left",
    daysLeft: "{value} day left | {value} days left",
    all: "All",
  },
  reports: {
    pageTitle: "Reports",
    pageSubtitle: "Browse user-based reports and take action",
    deleteSuccess: "You have successfully deleted the report!",
  },
  monitor: {
    pageTitle: "Overview",
    pageSubtitle: "View the various statistics",
  },
  tabManagement: {
    title: "Tab Management",
    polls: "Polls",
    chat: "Chat",
    shop: "Shop",
    qa: "Q&A",
    resources: "Resources",
    tenantDefault: "Tenant default",
    broadcastHint:
      "Manage your active tabs for the selected broadcast. If you want to set tenant-wide defaults please clear the input field so it shows 'Tenant default'.",
    notifications: {
      success: "Tabs were saved successfully",
      error: "An error occured",
    },
  },
  generalSettings: {
    spamProtection: "Spam Protection",
  },
  userColorMode: {
    none: "None",
    random: "Random",
  },
  formErrors: {
    isValidUrl: "Address must be a valid url",
    validateAgeLimit: "User does not meet required age limit",
    validateDateFormat: "Invalid Date Format(DD.MM.YYYY)",
    validateTimeFormat: "Invalid time format",
    required: "Field is required",
  },
  routeNames: {
    General: "General",
    BroadcastManager: "Broadcast Manager",
    Pins: "PIN Manager",
    GeneralSettings: "Settings",
    Interactive: "Interactive",
    Polls: "Polls",
    SecurePolls: "Polls",
    PinnedMessages: "Pinned Messages",
    VideoAds: "Ads",
    ShopSettings: "Shop",
    QaManagement: "Q&A Management",
    Resources: "Resources",
    Moderation: "Moderation",
    Roles: "Roles",
    Bans: "Mutes",
    Reports: "Reports",
    BlockedTermsAndPhrases: "Blocked terms",
    Accounts: "Accounts",
    Monitoring: "Monitoring",
    MonitoringOverview: "Monitoring Overview",
    UserManagementList: "Mitgliederdatei Gema MGV",
    Registrations: "Mitgliederdatei Gema MGV",
    RegistrationStats: "Registration Stats",
    VotingStats: "Pre-Vote Stats",
    Accreditation: "Accreditation",
    PollResults: "Results",
    Switchboard: "Switchboard",
  },
  blockedTerms: {
    addToList: "Add to list",
    termsAndPhrases: "Terms and phrases",
    invalidFileType: "Invalid file type.",
    invalidFileFormat:
      "Could not read file format, please try again with another file.",
    invalidCsvFile:
      "Please use the correct CSV file with appropriate header 'blocked term'.",
    termsTooltip:
      "You can add multiple terms at once by separating them with a comma.",
  },
  Confirm: "Confirm",
  legal: {
    menuName: "Legal",
    termsOfUse: "Terms of use",
    imprint: "Imprint",
    privacyPolicy: "Privacy Policy",
  },
  pinnedMessages: {
    broadcastChannelLabel: "Broadcast - Channel",
  },
  Username: "Username",
  "User ID": "User ID",
  monitoringOverview: {
    activeUsers: "Active Users",
    recurringUsers: "Recurring Users",
    pollVotes: "Poll Votes",
    uniqueUsers: "Unique Users",
    concurrentUsers: "Concurrent Users",
    chatActions: "Chat Actions",
    linkClicks: "Link Clicks",
    pollEvents: "Poll Events",
    noDataChart: "No data to display for selected timeframe",
    upub: "Upub",
    start: "Start",
    end: "End",
    reaction: "Reaction",
    reply: "Reply",
    vote: "Vote",
  },
  gema: {
    table: {
      header: {
        memberNumber: "Member ID",
        memberStatus: "Occupational Group",
        firstName: "First Name",
        lastName: "Last Name",
        registrationStatus: "Registration Status",
        dayOfParticipation: "Day Of Participation",
        attendanceType: "Type of participation",
        group: "Member Status",
        name: "Name",
        applicationNumber: "Application #",
        created: "Date Created",
        lastEdited: "Last Edited",
        repMemberNumber: "Representee Member Number",
        membersMemberNumber: "Member Number",
        registrationMemberName: "Name of the member",
        representativeName: "Name of the Representative",
      },
      searchBar: {
        currentView: "Current View",
        views: {
          attendance: "Attendance",
          preVoting: "Pre-Voting",
          digitalParticipation: "Digital Participation",
          digitalRightsTransfer: "Digital Rights Transfer",
          presentRightsTransfer: "Present Rights Transfer",
          digitalSubstitution: "Digital Substitution",
          presentSubstitution: "Present Substitution",
          memberFestival: "Member Festival",
          preInfoEvents: "Pre Info Events",
          allMembers: "All Members",
        },
      },
      actions: {
        newEntry: "Add entry",
      },
      exportAll: "Export All",
      exportPage: "Export Page",
      exportQuery: "Export query",
      exportSide: "Export side events",
    },
    singleUser: {
      email: "Email",
      birthDate: "Date Of Birth (DD.MM.YYYY)",
      memberStatus: "Member Status",
      delegateStatus: "Delegate Status",
      occupationalGroup: "Occupational Group",
      postcode: "Postcode",
      country: "Country",
      city: "City",
      street: "Street",
      memberNumber: "Member Number",
      lastName: "Last Name",
      firstName: "First Name",
      ePost: "E-Post",
      resetPassword: "Reset Password",
      resetPasswordConfirmation:
        "Are you sure you want to reset your password?",
      resetPasswordYes: "Yes, I am sure",
      editInfo: "Edit Info",
      cancel: "Cancel",
      save: "Save changes",
      attendanceType: "Attendance Type",
      dateOfAttendance: "Day of participation/attendance",
      voteTable: {
        title: "Vote Transfers",
        incoming: "Incoming",
        outgoing: "Outgoing",
        from: "From",
        to: "To",
        lastEditor: "Last Editor",
      },
      publisherName: "Publisher Name",
      excerciseOfVotingRights: "Exercise of voting rights",
      representation: "Representation",
      conflictOfInterestsTitle: "Conflict of Interests",
      conflictOfInterestIsUserCheckbox:
        "Deputy is user or economically intertwined with users",
      conflictOfInterestIsUserRepresentsInterestsCheckbox:
        "Deputy represents interests of users or interests of members of other professional groups",
      conflictOfInterestsComments: "Comment",
      newUserPassword: "Set User Password",
      memberType: "Member Type",
      memberTypeOption: {
        member: "Member",
        publisher: "Publisher",
      },
      delegateStatusOption: {
        empty: "(empty)",
      },
      heading1: "Member Details",
      heading2: "Representative Details",
      repIsGemaMember: "Is a GEMA member",
      findMember: "Find member",
      repCapicity: "Capacity/Role",
      resendEmail: "Resend Email",
      representeeRepsTableTitle: "Represents",
      emailSideevents: "Email side events",
      lastEditor: "",
      lastEditorDefault: "",
      printBadge: "Print badge",
    },
    attendanceType: {
      inPerson: "In Person",
      digital: "Digital",
      preVote: "Pre-Vote",
      repInPerson: "Representative - In Person",
      repDigital: "Representative - Digital",
    },
    registrationStatus: {
      confirmed: "Confirmed",
      pending: "Pending",
      denied: "Denied",
      representing: "Representing",
      notRegistered: "Not registered",
    },
    registrationDay: {
      dayOne: "Day 1",
      dayTwoAndThree: "Day 2 and Day 3",
    },
    memberLookup: {
      title: "Enter a member number",
      error: "User not found",
      buttonText: "Continue",
    },
    representationFunction: {
      proxy: "Proxy",
      owner: "Owner",
    },
    sideEvent: {
      memberFestival: "Members Festival",
      roadshow: "Roadshow",
      technicalTraining: "Technical Training",
      curiaConsultationHourLyricist: "Curia consultation hour (lyricists)",
      curiaConsultationHourPublisher: "Curia consultation hour (publishers)",
      curiaConsultationHourComposer: "Curia consultation hour (composers)",
      occupationalGroupGatheringLyricist: "Personal debate (lyricists)",
      occupationalGroupGatheringPublisher: "Personal debate (publishers)",
      occupationalGroupGatheringComposer: "Personal debate (composers)",
      title: "Side Events",
      curiaConsultationHour: "",
      occupationalGroupGathering: "",
    },
  },
  "Alle Mitglieder": "All Members",
  registrations: {
    single: {
      pageTitle: "Registration Details",
    },
    stats: {
      pageTitle: "Registration Stats",
      block: {
        totalRegistrations: "Registrations",
        votingInPerson: "Voting In Person",
        votingDigitally: "Voting Digitally",
        prevoting: "Pre-Vote",
        representedInPerson: "Represented In Person",
        representedDigitally: "Represented Digitally",
        sideEventMembersFestival: "Members Festival",
        sideEventRoadshow: "Roadshow",
        sideEventTechnicalTraining: "Technical Training",
        sideEventOccupationalGroupGathering: "Publisher group gathering",
        sideEventCuriaConsultationHour: "Curia Consultation Hour",
        registrations: "Registrations",
      },
      lyricistTitle: "Lyricist Registrations",
    },
    votingStats: {
      pageTitle: "Voting Stats",
      block: {
        usersVoted: "Members have voted",
        totalVotes: "Votes were given",
        abstainedVotes: "Empty ballots",
      },
    },
    votes: {
      abstainedOption: "Empty Ballot",
    },
    toast: {
      registrationCreated: "Registration created",
    },
    representingTable: {
      exportRepresentativeRegistrations: "Export Representative Registrations",
    },
  },
  registration: {
    stats: {
      allRegistrations: "All Registrations",
      sideEventAttendance: "Side event attendance ",
      status: "Status",
      composerTitle: "Composer Registrations",
      publisherTitle: "Publisher Registrations",
    },
    dialog: {
      title:
        "Do you want to include the artist's stage name? <br>(If not, leave it empty)",
      cancel: "Cancel",
      confirm: "Confirm",
      artistName: "Artist name",
    },
    reset: {
      authorization: "Authorization",
      button: "Reset password",
      title: "Do you really want to reset the password?",
      cancel: "Cancel",
      confirm: "Reset",
      error: "Request failed. Please try again.",
      success: "Password changed successfully.",
      generatePdf: "Generating PDF...",
    },
  },
  accreditation: {
    createRegistration: "Create Registration",
    registrations: "Registrations",
    showDetails: "Show Details",
    searchPlaceholder:
      "Membership number, first name, last name or publisher name",
    searchError: "Search failed",
    searchNoResults: "No result found. Please try again.",
    quickSearch: "Quick Search",
    fullSearch: "Full Text Search",
  },
  role: {
    admin: "Admin",
    broadcastModerator: "Broadcast Moderator",
    moderator: "Moderator",
    user: "User",
  },
  results: {
    title: "Results",
    description: "Results of finished polls.",
  },
  switchboard: {
    pageTitle: "Switchboard",
    pageSubtitle:
      "Select your broadcast and manage video tracks for all your conference rooms",
    selectBroadcast: "Select Broadcast",
    availableTracks: "Available Tracks",
    noTracks: "No tracks available",
    validationRoom: "Validation Room",
    guest: "Guest",
    noAudioTrackSelected: "Audio - No track selected",
    noVideoTrackSelected: "Video - No track selected",
    applyChanges: "Apply changes",
    peerNameNotFound: "Name not found",
    tracksAssigned: "Tracks successfully assigned",
    refresh: "Refresh",
  },
  settings: {},
  submit: "Submit",
  fields: {
    email: "Email",
  },
  "Could not find broadcast moderator user groups for all requested broadcast ids.":
    "Could not find broadcast moderator user groups for all requested broadcast ids.",
};

export default BaseMessages;
