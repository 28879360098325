import { Question, QuestionDto, QuestionPatchDto } from "@/components/Qa/types";
import { http } from "../api.service";

const QA_URI = "/qa";

const getQuestions = (broadcastId: string): Promise<Question[]> =>
  http
    .get<Question[]>(QA_URI, {
      params: {
        broadcastId: broadcastId,
        includeUserQuestions: false,
      },
    })
    .then((res) => res.data);

const updateQuestion = (
  questionId: string,
  question: QuestionPatchDto
): Promise<Question> =>
  http.patch(QA_URI + `/${questionId}`, question).then((res) => res.data);

const createQuestion = (
  broadcastId: string,
  question: QuestionDto
): Promise<Question> =>
  http
    .post(QA_URI + `?broadcastId=${broadcastId}`, question)
    .then((res) => res.data);

const deleteQuestion = (questionId: string): Promise<void> =>
  http.delete(QA_URI + `/${questionId}`).then((res) => res.data);

export const IaService = {
  getQuestions,
  updateQuestion,
  createQuestion,
  deleteQuestion,
};
