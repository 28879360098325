import axios, { AxiosInstance } from "axios";
import { PinAuthTokenResponse } from "../types";

class PinAuthService {
  private axios: AxiosInstance;

  constructor(axios: AxiosInstance) {
    this.axios = axios;
  }

  async login(
    displayname: string | null,
    pin: string,
    tenantId: string
  ): Promise<PinAuthTokenResponse> {
    return this.axios
      .post<PinAuthTokenResponse>("/auth/login/pin", {
        displayname: displayname,
        pin: pin,
        tenant_id: tenantId,
      })
      .then((res) => res.data);
  }

  async logout(): Promise<number> {
    return this.axios
      .post<number>("/auth/logout", null)
      .then((res) => res.status);
  }

  async refresh(): Promise<PinAuthTokenResponse> {
    return this.axios
      .get<PinAuthTokenResponse>("/auth/refreshtokens")
      .then((res) => res.data);
  }
}

let pinAuthService: PinAuthService;

function initPinAuthService(baseUrl: string): PinAuthService {
  if (pinAuthService) {
    return pinAuthService;
  }

  const axiosInstance = axios.create({
    baseURL: baseUrl,
    responseType: "json",
    withCredentials: true,
  });

  pinAuthService = new PinAuthService(axiosInstance);
  return pinAuthService;
}

export { pinAuthService, initPinAuthService };
