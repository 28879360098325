import { getters } from "./getters";
import { actions } from "./actions";
import { mutations } from "./mutations";
import { RootState } from "@/store/RootState";
import { Module } from "vuex";
import { PollState } from "../types";

export const state: PollState = {
  polls: [],
  activePolls: [],
  editPoll: null,
  sort: {
    key: ["startDate"],
    descending: [true],
  },
};

export const polls: Module<PollState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
