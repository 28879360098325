import { initStoreEnum } from "../utils/StoreHelpers";
import { RTCInfo, RTCSlot } from "../stores/vico/RtcSlot";

export const CORE_VICO_NAMESPACE = "s8-core-vico";

export interface VicoState {
  initialized: boolean;
  config: VicoConfig;
  conferences: Array<Conference>;
  conferenceSelected: Conference | null;
  // All slots with incoming data
  slots: RTCSlot[];
  // Single slot to stream out data
  slotOut: RTCSlot | null;
  // MediaStream tracks to be controlled with the allmighty switchboard
  tracks: TrackInfo[];

  //deprecated
  broadcastId?: string;
}

export enum VicoAction {
  Init = "init",
  Connect = "connect",
  ConnectWithRetry = "connectWithRetry",
  Broadcast = "broadcast",
  ConnectAll = "connectAll",
  Disconnect = "disconnect",
  StopBroadcasting = "stopBroadcasting",
  InitializeCall = "initializeCall",
  NegotiateAll = "negotiateAll",
  RefreshTracks = "refreshTracks",
  AddSlot = "addSlot",
  SelectConference = "selectConference",
  SelectSlot = "selectSlot",
  SetBroadcastId = "setBroadcastId",
  MuteIncomingAudio = "muteIncomingAudio",
  UnmuteIncomingAudio = "unmuteIncomingAudio",
  ToggleIncomingAudio = "toggleIncomingAudio",
  Close = "close",
  EndConference = "endConference",
  FetchServers = "fetchServers",
}

type VicoActionStrings = keyof typeof VicoAction;
export const CoreVicoActions: Record<VicoActionStrings, string> =
  initStoreEnum<VicoActionStrings>(VicoAction, CORE_VICO_NAMESPACE);

export enum VicoMutation {
  SetConferences = "setConferences",
  AddConference = "addConference",
  RemoveConference = "removeConference",
  AddSlot = "addSlot",
  RemoveSlot = "removeSlot",
  SetSlotOut = "setSlotOut",
  Disconnect = "disconnect",
  SetTracks = "setTracks",
  AddTrack = "addTrack",
  RemoveTrack = "removeTrack",
  SelectConference = "selectConference",
  SetInitialized = "setInitialized",
  SetBroadcastId = "setBroadcastId",
  SetConfig = "setConfig",
  NextServer = "nextServer",
}

export enum VicoGetter {
  Conferences = "conferences",
  ConferenceSelected = "conferenceSelected",
  Slots = "slots",
  SlotOut = "slotOut",
  Tracks = "tracks",
  Initialized = "initialized",
  Config = "config",
  SocketUrl = "socketUrl",
  ServerUrl = "serverUrl",
  Slot = "slot",
}

type VicoGetterStrings = keyof typeof VicoGetter;
export const CoreVicoGetters: Record<VicoGetterStrings, string> =
  initStoreEnum<VicoGetterStrings>(VicoGetter, CORE_VICO_NAMESPACE);

export interface VicoConfig {
  iceServers: RTCIceServer[];

  // Array of available servers and index of currently used one
  serverUrls: string[];
  serverIndex?: number;
}

// Can be any string, is unique within(!) conferences
export type SlotId = string;

// Payload of an event on the signaling websocket of a RTCSlot
export interface WsEventData {
  topic: string;
  content: string;
  from: string;
  targetId: string;
  targetType: string;
}

export interface Conference {
  broadcastId: string;
  createdAt: string;
  id: string;
  name: string;
  slotIds: Record<string, boolean>;
  slotAssignments: TrackSelection;
  tenantId: string;
  updatedAt: string;
  tags: string[] | null;
}

export interface ConferenceDTO {
  broadcastId: string;
  createdAt: string;
  id: string;
  name: string;
  slotIds: Record<string, boolean>;
  slotAssignments: TrackSelectionDTO;
  tenantId: string;
  updatedAt: string;
  tags: string[] | null;
}

export interface TrackInfo {
  // we treat this as the conferenceId
  broadcastId: string;

  // example: main stream
  // this is meant for producers, not the client sending the track
  codecCompatibility: RTPCodecCapability;
  comment: string;

  // example: 2021-07-09T15:10:58.845Z
  createdAt: string;

  // example: my shared screen
  // this is meant for the client-system to annotate what is being sent. can be used in any way, is just "passed on"
  description: string;

  // example: application
  // this is to allow for details about screen sharing: video and screen sharing: https://stackoverflow.com/questions/66728520/webrtc-ontrack-how-to-tell-if-it-is-a-screen-sharing-session
  displaySurface: string;

  // 1==audio 2==video
  id: string;
  kind: number;

  // this is the slotId defined by the peer from which the remoteTrack is coming
  peerId: string;
  peerName: string;
  slotId: string;

  // example: 2021-07-09T15:10:58.845Z
  streamId: string;
  tenantId: string;
  trackId: string;
  updatedAt: string;

  userId: string;
}

export interface RTCPFeedback {
  // The parameter value depends on the type.
  // For example, type="nack" parameter="pli" will send Picture Loss Indicator packets.
  parameter: string;

  // Type is the type of feedback.
  // see: https://draft.ortc.org/#dom-rtcrtcpfeedback
  // valid: ack, ccm, nack, goog-remb, transport-cc
  type: string;
}

export interface RTPCodecCapability {
  channels: number;
  clockRate: number;
  mimeType: string;
  rtcpfeedback: RTCPFeedback[];
  sdpfmtpLine: string;
}

export interface TrackSlot {
  slotId: string;
  trackInfoId: string;
  trackKind: number;
  peerId: string;
  peerName: string;
}

export interface SlotTrackMap {
  [slot: string]: TrackSlot;
}
export interface TrackSelection {
  slotTracks: SlotTrackMap;
}

export interface TrackSelectionDTO {
  SlotTracks: SlotTrackMap;
}

export interface ConferenceSlotTrackAssignment {
  conferenceId: string;
  slotId: string;
  tenantId: string;
  userId: string;
}

export interface AssignSlotTrackRequest {
  tenantId: string;
  conferenceId: string;
  slotId: string;
  removeAllOtherSlotAssignments: boolean;
  slotAssignments: TrackSlot[];
}

export interface SignalQaState {
  broadcastId: string;
  state: string;
  // this is any of [ "approved", "answering" "answered" ]

  tenantId: string;
  userId: string;
}

export interface ResponseVico {
  msg: string;
}

export interface ResponseConferences extends ResponseVico {
  conferences: ConferenceDTO[];
}

export interface Conferences extends ResponseVico {
  conferences: Conference[];
}

export interface ResponseTrackInfos extends ResponseVico {
  trackinfos: TrackInfo[];
}

export interface ResponseError {
  error: string;
}

export interface CallInitActionPayload {
  broadcastId: string;
  tenantId: string;
  userId: string;
}

export interface CallInitRequest {
  name: string;
  tenantId: string;
  broadcastId: string;
  tags: string[];
  participants: CallInitParticipant[];
  isOpenToJoin: boolean;
}

export interface CallInitParticipant {
  userId: string;
  slotId: string;
}

export interface EndConferencePayload {
  tenantId: string;
  broadcastId: string;
  conferenceId: string;
}

export interface ServersResponse {
  msg: string;
  servers: {
    serverUrls: string[];
    iceServers: string[];
    updatedAt: string;
  };
}

export interface IceServer {
  credential: string;
  urls: string | string[];
  username: string;
}

export interface PeerPacketChangeEventContent {
  tenantId: string;
  broadcastId: string;
  userId: string;
  isBroadcaster: boolean;
  slotId: string;
  peerId: string;
  trackId: string;
  trackKind: number;
  isActive: boolean;
}

export interface ConnectActionPayload {
  rtcInfo: RTCInfo;
  retries?: number;
  timeout?: number;
}
