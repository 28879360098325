












































import { Component, Vue, Watch } from "vue-property-decorator";
import { Getter, Action } from "vuex-class";
import MediaSetupDialog from "@/components/Conference/components/MediaSetupDialog.vue";
import Navigation from "@/components/navigation/Navigation.vue";
import NavBar from "@/components/navigation/NavBar.vue";
import { AppActions, AppGetters } from "@/store/app/types";
import { BroadcastActions } from "./components/Broadcast/broadcast/types";
import {
  CoreAuthGetters,
  User,
  CoreUserGetters,
} from "./spect8-core-vue/src/types";

@Component({
  components: { Navigation, NavBar, MediaSetupDialog },
})
export default class App extends Vue {
  @Getter(AppGetters.Initialized)
  readonly initialized!: boolean;

  @Getter(AppGetters.TenantError)
  tenantError!: boolean;

  @Getter(CoreAuthGetters.Authenticated)
  readonly authenticated!: boolean;

  @Getter(CoreUserGetters.User)
  readonly user!: User;

  @Action(AppActions.Init)
  init!: (tenantName?: string) => Promise<void>;

  @Action(AppActions.SetLocale)
  setLocale!: (locale: string) => Promise<void>;

  @Action(BroadcastActions.GetBroadcasts)
  getBroadcasts!: () => Promise<void>;

  @Watch("authenticated")
  onAuthenticated(newValue: boolean, oldValue: boolean) {
    if (newValue === true && newValue !== oldValue) {
      this.getBroadcasts();
    }
  }

  @Watch("user")
  onUserChanged(user: User) {
    if (user && user.preferredLanguage) {
      this.setLocale(user.preferredLanguage);
    }
  }

  navCollapsed = false;

  created(): void {
    if (process.env.VUE_APP_COMMIT_HASH) {
      console.info("commit:", process.env.VUE_APP_COMMIT_HASH);
    }
    // Router base is the tenant name
    this.init(this.$router.options.base);
  }

  get navVisible(): boolean {
    if (this.$route.meta?.navVisible !== undefined) {
      return this.$route.meta?.navVisible;
    }
    return true;
  }
}
