import { Store } from "vuex";
import VueRouter from "vue-router";
import { initFusionAuthService } from "../services/fusion-auth";
import { initPinAuthService } from "../services/pin-auth";
import { initSessionAuthService } from "../services/session-auth";
import { trackingService } from "../services/tracking";
import { auth } from "../stores/auth";
import {
  AuthModuleConfig,
  CORE_AUTH_NAMESPACE,
  CORE_CONFIG_NAMESPACE,
  CORE_USER_NAMESPACE,
} from "../types";
import { Logger } from "../utils/Logger";

class AuthModule {
  config: AuthModuleConfig;
  router: VueRouter;

  constructor(config: AuthModuleConfig, router: VueRouter) {
    this.config = config;
    this.router = router;
  }
}

let authModule: AuthModule;

function initAuthModule(
  config: AuthModuleConfig,
  router: VueRouter,
  store: Store<unknown>
): AuthModule {
  // auth module dependencies
  const dependencies = [CORE_CONFIG_NAMESPACE, CORE_USER_NAMESPACE];

  // check dependencies
  dependencies.forEach((namespace: string) => {
    if (!store.hasModule(namespace)) {
      throw new Error(`AuthModule - store module ${namespace} is missing`);
    }
  });

  // register core auth store
  if (!store.hasModule(CORE_AUTH_NAMESPACE)) {
    store.registerModule(CORE_AUTH_NAMESPACE, auth);
  }

  // init auth services
  initFusionAuthService(config.fusionAuthUrl);
  initPinAuthService(config.pinAuthUrl);
  initSessionAuthService(config.sessionAuthUrl);

  // check if tracking service instance is available
  if (!trackingService) {
    Logger.info("AuthModule - tracking service not available");
  }

  // create module instance
  if (authModule) {
    return authModule;
  }
  authModule = new AuthModule(config, router);
  return authModule;
}

export { authModule, initAuthModule };
