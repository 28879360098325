import {
  VicoConfig,
  VicoState,
  Conference,
  TrackInfo,
  VicoGetter,
} from "../../types";
import { Getter, GetterTree } from "vuex";
import { RTCSlot } from "./RtcSlot";

export const getters: GetterTree<VicoState, unknown> &
  Record<VicoGetter, Getter<VicoState, unknown>> = {
  [VicoGetter.Conferences](state): Conference[] {
    return state.conferences;
  },
  [VicoGetter.ConferenceSelected](state): Conference | null {
    return state.conferenceSelected;
  },
  [VicoGetter.Slots](state): RTCSlot[] {
    return state.slots;
  },
  // Only makes sense in object with Conference id keys, which seems too complex for a getter
  // slots(state): Record<SlotId, RTCSlot | null> {
  //   return state.slots;
  // },
  [VicoGetter.SlotOut](state): RTCSlot | null {
    return state.slotOut;
  },
  [VicoGetter.Tracks](state): TrackInfo[] {
    return state.tracks;
  },
  [VicoGetter.Initialized](state): boolean {
    return state.initialized;
  },
  [VicoGetter.Config](state): VicoConfig {
    return state.config;
  },
  [VicoGetter.SocketUrl](state): string {
    return `wss://${state.config.serverUrls[state.config.serverIndex ?? 0]}/ws`;
  },
  [VicoGetter.ServerUrl](state): string {
    return `https://${
      state.config.serverUrls[state.config.serverIndex ?? 0]
    }/api/v1`;
  },
  // Find single slot by various criterions
  [VicoGetter.Slot](state) {
    return (data: {
      tenantId: string;
      broadcastId: string;
      userId: string;
      slotId: string;
      isBroadcaster: boolean;
    }): RTCSlot | undefined => {
      const { tenantId, broadcastId, userId, slotId, isBroadcaster } = data;

      const compareFn = (s: RTCSlot) =>
        s.rtcInfo.tenantId === tenantId &&
        s.rtcInfo.broadcastId === broadcastId &&
        s.rtcInfo.slotId === slotId &&
        s.rtcInfo.userId === userId &&
        s.rtcInfo.isBroadcaster === isBroadcaster;

      // If we are looking for a broadcasting slot there's only one option, state.slotOut
      if (isBroadcaster && state.slotOut) {
        if (compareFn(state.slotOut)) return state.slotOut;
        return;
      }
      return Object.values(state.slots).find(compareFn);
    };
  },
};
