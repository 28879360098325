import { Module } from "vuex";
import { mutations } from "./mutations";
import { getters } from "./getters";
import { actions } from "./actions";
import { AuthState } from "../../types";

// ReExport to be backward compatible
export { CORE_AUTH_NAMESPACE } from "../../types";

export const state: AuthState = {
  authenticated: false,
};

const namespaced = true;

export const auth: Module<AuthState, unknown> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
