import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
import i18n from "./i18n/i18n";
import "@mdi/font/css/materialdesignicons.css"; // Ensure you are using css-loader

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    t: (key, ...params) => i18n.t(key, params).toString(),
  },
  locales: {},
  icons: {
    iconfont: "mdi",
  },
  theme: require("./theme.ts"),
});
