import { ActionTree } from "vuex";
import { AxiosResponse } from "axios";
import { EditRoleRequest, RoleState, UserRole } from "./types";
import Vue from "vue";
import i18n from "@/plugins/i18n/i18n";
import qs from "qs";
import { handleHttpError } from "@/helpers";
import { RootState } from "@/store/RootState";
import { http } from "@/services/api.service";
import { Role } from "@/spect8-core-vue/src/types";

export const actions: ActionTree<RoleState, RootState> = {
  async getUserRoleList({ commit }): Promise<AxiosResponse<UserRole[]>> {
    const res: AxiosResponse<UserRole[]> = await http.get(`/user/tenant/all`);

    const checkedArray:
      | UserRole[]
      | AxiosResponse<UserRole[]>
      | PromiseLike<AxiosResponse<UserRole[]>> = [];
    const listOfUsers = Array<string>();
    res.data.map((element) => {
      const hasRole = element.roles.indexOf(Role.USER) > -1;

      if (hasRole) checkedArray.push(element);

      const index = element.roles.indexOf(Role.USER);
      if (index > -1) {
        element.roles.splice(index, 1);
      }

      if (
        hasRole &&
        element.editRequest?.displayName &&
        element.editRequest?.avatar
      ) {
        listOfUsers.push(element.editRequest.displayName);
      }
    });
    commit("setListOfUsers", listOfUsers);
    commit("setRolUsersList", checkedArray);

    return res;
  },

  async editRole({ dispatch }, userData: EditRoleRequest) {
    try {
      const roles = userData.role;
      const res = await http.put(`/user/${userData.userId}/role/update`, null, {
        params: { roles },
        paramsSerializer: (params: unknown) =>
          qs.stringify(params, { arrayFormat: "repeat" }),
      });
      dispatch("getUserRoleList");

      if (res.status === 200) {
        Vue.$toast.success(i18n.tc(`You successfully updated user role!`), {
          timeout: 2000,
        });
      }

      return res;
    } catch (error) {
      handleHttpError(error, {
        404: i18n.tc(`Incorrect input. Please check id or username of user`),
      });
      throw error;
    }
  },
};
