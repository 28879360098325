import { getters } from "./getters";
import { Module } from "vuex";
import { RegistrationState } from "../@types/types";
import { actions } from "./actions";
import { mutations } from "./mutations";
import { RootState } from "@/store/RootState";

export const defaultState: RegistrationState = {
  loading: false,
  selectedBroadcastId: "",
  representatorsRegistrations: [],
  numAvailableVotes: 0,
};

export const registration: Module<RegistrationState, RootState> = {
  namespaced: true,
  state: defaultState,
  getters,
  actions,
  mutations,
};
