import { LocaleMessageObject } from "vue-i18n";
import validationMessages from "vee-validate/dist/locale/en.json";

const BaseMessages: LocaleMessageObject = {
  $vuetify: {
    noDataText: "Keine Einträge",
  },
  validation: {
    ...validationMessages.messages,
    email: "Bitte geben Sie eine gültige E-Mail-Adresse ein",
    emailUnique:
      "E-Mail-Adresse wird bereits benutzt. Bitte bearbeiten Sie den existierenden Account oder löschen und erstellen Sie ihn neu.",
    // "{_field_} ist bereits in Benutzung",
  },
  timeUnit: {
    hours: "{value} Stunde | {value} Stunden",
    minutes: "{value} Minute | {value} Minuten",
    days: "{value} Tag | {value} Tage",
  },
  tenantError: "Tenant existiert nicht!",
  "Admin Panel": "Admin Panel",
  "Chat settings": "Chat-Einstellungen",
  "Interaction Settings": "Interaktionsinstellungen",
  "User Management": "Nutzerverwaltung",
  "Please enter an Application ID": "Bitte geben Sie eine Application ID ein",
  duration: "Dauer",
  dataTable: {
    rowsPerPage: "Zeilen pro Seite",
    actions: "Aktionen",
  },
  broadcasts: "Broadcasts",
  polls: {
    header: "Umfragen",
    actions: "Aktionen",
    addAnswer: "ANTWORT HINZUFÜGEN",
    answer: "Antwort",
    answerNum: "Antwort {num}",
    broadcasts: "Broadcasts",
    create: "Neue Umfrage erstellen",
    createPoll: "Umfrage erstellen",
    discard: "Änderungen verwerfen",
    end: "Beenden",
    hours: "{value} Stunde | {value} Stunden",
    live: "Live",
    maxChars: "Maximale Zeichenanzahl bei Antworten",
    minutes: "{value} Minute | {value} Minuten",
    days: "{value} Tag | {value} Tage",
    weeks: "{value} Woche | {value} Wochen",
    unlimited: "Unbegrenzt",
    noVotes: "Noch keine Stimmen",
    onDemand: "Auf Nachfrage",
    onEnd: "Am Ende der Umfrage",
    question: "Frage",
    resultVisibility: "Ergebnissichtbarkeit",
    save: "Änderungen speichern",
    status: "Status",
    start: "Start",
    type: "Typ",
    typeAnswer: "Gib eine Antwort ein",
    typeQuestion: "Gib eine Frage ein",
    addPredefinedAnswer: "Vordefinierte Antwort hinzufügen",
    addImageAnswer: "Weitere Bildantwort hinzufügen",
    maxVotesPerPersonValidationMessage:
      "Benutzer können nicht mehr Stimmen als Antworten haben",
    subtitle: "Erstelle, bearbeite und starte Umfragen",
    selectBroadcasts: "Broadcasts auswählen",
    fileSizeTooLarge: "Dateilimit überschritten({limit})",
    singleWordAnswersLabel: "Einzelne Wortantworten",
    showAuthorNamesLabel: "Autorennamen anzeigen",
    imageRestrictions:
      "Die Bilder werden auf {dimensions} zugeschnitten und dürfen eine Größe von {fileSizeLimit} nicht überschreiten.",
    location: "Umfrage im Chat anzeigen",
    locationDefault: "Nein",
    locationInline: "Ja",
    pollStatus: {
      draft: "Vorbereitet",
      ended: "Abgelaufen",
      active: "Aktiv",
    },
    pollType: {
      default: "Standard",
      open: "Offen",
      image: "Bild",
    },
    pollTypeLong: {
      default: "Standard Umfrage",
      open: "Offene Umfrage",
      image: "Bilder Umfrage",
    },
    pollTypeSelected: {
      election: "Wahl",
      poll: "Abstimmung",
    },
    pollStatusSelected: {
      preparing: "VORBEREITET",
      preVote: "PRE-VOTE",
      pre_vote: "PRE-VOTE",
      live: "LIVE",
      hidden: "VERSTECKT",
      closed: "GESCHLOSSEN",
    },
    group: "Gruppe",
    memberStatus: "Mitgliedsstatus",
    apllyChanges: "Speichern",
    changeStatus: "Status ändern",
    discardChanges: "Verwerfen",
    choicesPerUser: "Stimmen pro Benutzer",
    addOptionalImage: "Füge ein Bild zur Frage hinzu (optional)",
    all: "Alle",
    sucessPollUpdate: "Abstimmung wurde erfolgreich aktualisiert.",
    sucessPollCreate: "Neuer Abstimmung erfolgreich erstellt.",
    showResults: "Ergebnisse anzeigen",
    exportAll: "Alle exportieren",
    exportPage: "Seite exportieren",
    resultsMessage: "Ergebnisse werden im Overlay angezeigt",
    refreshResult: "Aktualisieren",
    inAdminPanel: "Im Admin Panel",
    inAdminPanelNum: "Im Admin Panel (Quorum: {num}%)",
    inBroadcast: "Im Broadcast",
    resumption: "Wiederaufnahme",
    resumptionExp: "Pre-Votes zum hinzufügen auswählen",
    abstention: "{num} Enthaltung | {num} Enthaltungen",
    voter: "{num} Wahlbeteiligter | {num} Wahlbeteiligte",
    castVote: "{num} abgegebene Stimme | {num} abgegebene Stimmen",
    totalVotes: "Stimmen (gesamt)",
    total: "Gesamt",
    preVotes: "Pre-Votes",
    liveVotes: "Live-Votes",
    statistic: "Vote Statistik",
    quorum: "Quorum",
    select: "Auswählen",
    statsRetrived: "Statistik abgerufen um:",
    sendStats: "Statistik senden",
    emailDialog: {
      title: "Statistik senden an (E-Mail, durch Komma getrennt):",
      cancel: "Abbrechen",
      send: "Senden",
    },
    presenceSent: "Statistik gesendet",
    presenceSentError: "Senden fehlgeschlagen",
    eligibleVotersPresent:
      "Anwesende wahlberechtigte Personen (jetzt) (vor Ort/digital):",
    votersPresent: "Anwesende Stimmen (jetzt) (vor Ort/digital):",
    eligibleVotersPresentAccumulated:
      "Anwesende wahlberechtigte Personen (kumuliert) (vor Ort/digital):",
    votersPresentAccumulated:
      "Anwesende Stimmen (kumuliert) (vor Ort/digital):",
    requestStats: "Statistik anfordern",
    minVotes: "Minimale Stimmen",
    maxVotes: "Maximale Stimmen",
    startDate: "Start-Datum",
  },
  pins: {
    pageTitle: "PIN Management",
    pageSubtitle: "Erstelle PINs für deine Broadcasts",
    formTitle: "Erstelle neue PINs",
    formTitleEdit: "Bearbeite PIN {pin}",
    pinLabel: "PIN",
    pinHelp:
      "PINs müssen aus {min}-{max} Zeichen bestehen. PINs können nur die folgenden Zeichen enthalten [a-zA-Z0-9-_]. Groß- und Kleinschreibung ist entscheidend.",
    pinErrorCharLimit: "PINs müssen aus {min}-{max} Zeichen bestehen",
    pinErrorSymbols:
      "PINs können nur die folgenden Zeichen enthalten: [a-zA-Z0-9-_]",
    pinErrorUnique: "Dieser PIN ist bereits in Verwendung",
    roleLabel: "Rolle",
    broadcastLabel: "Broadcasts",
    allBroadcastsCheckbox: "Alle existierenden und zukünftigen Broadcasts",
    formSubmit: "Erstellen",
    formEdit: "Speichern",
    listTitle: "PINs",
    colPin: "PIN",
    colRole: "Rolle",
    colBroadcast: "Broadcasts",
    colActions: "Aktionen",
    dialogDeleteTitle: "Bist du sicher, dass du diesen PIN löschen willst?",
    expired: "Abgelaufen",
    expiresAt: "Läuft ab um",
    allBroadcasts: "Alle broadcasts",
  },
  qa: {
    pageTitle: "Q&A Management",
    pageSubtitle: "Verwalten von Fragen & Antworten",
    listTitle: "Fragen",
    typeAnswer: "Geben Sie Ihre Antwort ein",
    statusPending: "Offen",
    statusApproved: "Genehmigt",
    statusDismissed: "Abgelehnt",
    statusAnswered: "Abgeschlossen",
    statusAnswering: "Live",
    inPersonContributionActionText: "Persönlichen Beitrag hinzufügen",
    approveActionText: "Genehmigen",
    dismissActionText: "Ablehnen",
    answeredActionText: "Abgeschlossen",
    answeringActionText: "Live",
    commentActionText: "Kommentieren",
    deleteActionText: "Löschen",
    bulkUpdateError:
      "Aktualisierung fehlgeschlagen, bitte versuchen Sie es erneut",
    inPersonContribDialogTitle: "Persönlichen Beitrag hinzufügen",
    inPersonContribDialogFieldName: "Benutzer Frage:",
    submit: "Einreichen",
    startVideoCall: "Starte Video-Anruf",
  },
  accounts: {
    pageTitle: "Admin/Moderator Management",
    pageSubtitle: "Erstelle Admin- und Moderatorenaccounts",
    formTitle: "Erstelle neuen Account",
    formTitleEdit: "Bearbeite Account {account}",
    emailLabel: "E-Mail",
    emailError: "Bitte verwende eine gültige E-Mail-Adresse",
    roleLabel: "Rolle",
    broadcastLabel: "Broadcasts",
    allBroadcastsCheckbox: "Alle existierenden und künftigen Broadcasts",
    formSubmit: "Erstellen",
    formEdit: "Editieren",
    listTitle: "Accounts",
    colEmail: "E-Mail",
    colRole: "Rolle",
    colBroadcasts: "Broadcasts",
    colActions: "Aktionen",
  },
  resources: {
    pageTitle: "Dateien",
    pageSubtitle: "Stelle deinen Benutzern Dateien zum Download zur Verfügung",
    formTitle: "Datei hochladen",
    fileLabel: "Datei",
    broadcastLabel: "Broadcasts",
    allBroadcastsCheckbox: "Alle existierenden und künftigen Broadcasts",
    formSubmit: "Hochladen",
    listTitle: "Dateien",
    file: "Datei",
    type: "Typ",
    size: "Größe",
    uploadError: "Bild-Upload-Fehler, bitte versuchen Sie es erneut",
  },
  account: "Account",
  resource: "Datei",
  created: {
    account: "Erfolgreich @:account erstellt",
    resource: "Erfolgreich @:resource erstellt",
  },
  updated: {
    account: "Erfolgreich @:account geändert",
    resource: "Erfolgreich @:resource geändert",
  },
  deleted: {
    account: "Erfolgreich @:account gelöscht",
    resource: "Erfolgreich @:resource gelöscht",
  },
  dialogDeleteTitle: "Bist du sicher, dass du {item} löschen möchtest?",
  "Manage basic chat functionality like Slow Mode, Pinned messages, etc.":
    "Allgemeine Chat-Einstellungen wie Slow-Mode, Chat-Regeln und mehr Interaktionseinstellungen",
  "Manage interactive parts of the Side panel like Polls and Quizzes":
    "Einstellungen zu interaktiven Elementen wie Umfragen, Quizzes und mehr",
  "Load Message History (users are able to see previous messages after connecting to the side panel)":
    "Lade vorherige Nachrichten (Nutzer, die dem Chat nachträglich beitreten, können vorherige Nachrichten sehen)",
  "Enter your own text to require first-time viewers to agree to your rules before chatting. One rule per line.":
    "Gib deine eigenen Regeln ein, denen erstmalige Zuschauer vor dem Chatten zustimmen müssen. Eine Regel pro Zeile.",
  "Pinned Messages": "Angeheftete Nachrichten",
  "Add a pinned message": "Füge eine angeheftete Nachricht hinzu",
  Channel: "Kanal",
  "Pin message": "Nachricht anheften",
  Email: "E-mail",
  "Time remaining": "Restlaufzeit",
  "Pinned by": "Angeheftet von",
  "No data available": "Keine Daten verfügbar",
  "Muted chatters": "Stummgeschaltete Nutzer",
  "View and modify your muted users list":
    "Stummgeschaltete Nutzer anzeigen und bearbeiten",
  "Add terms or phrases to block": "Geblockte Begriffe oder Phrasen hinzufügen",
  addBadTerms: "Füge einen oder mehrere Begriffe zum Blocken hinzu.",
  "Blocked terms": "Geblockte Begriffe und Phrasen",
  "Add users by user-name or user-id":
    "Füge Benutzer via Benutzernamen oder ID hinzu",
  Roles: "Rollen",
  "Role Management": "Rollenverwaltung",
  "Assign roles to certain Chatters e.g for enabling them to moderate a chatroom":
    "Weise Nutzern Rollen zu, um zum Beispiel den Chat moderieren zu können",
  "Assign a role to a user": "Weise einem Benutzer eine Rolle zu",
  "Select a role": "Rolle wählen",
  "Use '@' to get user list":
    "Tippe '@' , um eine Liste aller Nutzer anzuzeigen.",
  "Incorrect input. Please check id or username of user":
    "Eingabefehler. Bitte überprüfe ID oder Nutzernamen.",
  "You successfully updated user role!": "Nutzerrolle erfolgreich geändert.",
  "You successfully updated Blocked terms!":
    "Begriffe erfolgreich aktualisiert.",
  "Are you sure you want to remove this term or phrase?":
    "Bist du sicher, dass dieser Begriff von der Liste entfernt werden soll?",
  Cancel: "Abbrechen",
  Delete: "Löschen",
  "Bad word deleted successfully": "Begriff erfolgreich gelöscht",
  "Bad word already exists": "Begriff existiert bereits",
  "Chat settings applied successfully":
    "Chat-Einstellungen erfolgreich gespeichert",
  "You successfully pinned a message": "Nachricht erfolgreich angeheftet",
  "Currently assigned roles": "Aktuell zugewiesene Rollen",
  "Last modified": "Zuletzt geändert",
  "Change password": "Passwort ändern",
  "New password": "Neues Passwort",
  "Repeat password": "Passwort wiederholen",
  "Current password": "Altes Passwort",
  "Password incorrect!": "Passwort inkorrekt.",
  "You successfully updated your password": "Passwort erfolgreich geändert",
  "Password don't match": "Passwörter stimmen nicht überein",
  "Invite users": "Nutzer einladen",
  Logout: "Abmelden",
  "Create profile": "Profil erstellen",
  "Join the chat": "Chat beitreten",
  "Tell us how you want to present yourself in the side panel":
    "Wie soll dein Profil aussehen?",
  "Start chatting": "Start",
  "Edit side panel profile": "Profil bearbeiten",
  Report: "Melden",
  Block: "Blockieren",
  "Dismiss pinned message": "Angepinnte Nachricht ausblenden",
  "You are muted": "Du bist stummgeschaltet",
  "Active polls": "Aktive Umfragen",
  "Past polls": "Abgelaufene Umfragen",
  Missed: "Verpasst",
  "Created by": "Erstellt von",
  Password: "Passwort",
  "Password is required": "Passwort benötigt",
  "Email is required": "E-Mail benötigt",
  "Password must have 5+ characters":
    "Passwort muss aus mind. 5 Zeichen bestehen",
  "E-mail must be valid": "Bitte verwende eine gültige E-Mail",
  "Sign up": "Registrieren",
  "Retype Password": "Passwort erneut eingeben",
  "Passwords must match": "Neue Passwörter müssen übereinstimmen",
  "The banned user list is empty": "Keine gesperrten Benutzer",
  "You successfully banned this user!": "Benutzer erfolgreich gesperrt",
  "The user does not exist!": "Dieser Benutzer existiert nicht!",
  "This user is already banned!": "Dieser Benutzer ist bereits gesperrt",
  "You successfully unbanned this user!": "Benutzer erfolgreich entsperrt",
  "This user is already deleted!": "Dieser Benutzer wurde bereits gelöscht",
  Search: "Suchen",
  "Are you sure you want to unban this username?":
    "Bist du sicher, dass dieser Benutzer entsperrt werden soll?",
  Unban: "Entsperren",
  "The channels list is empty": "Keine Kanäle verfügbar",
  "There are no Pinned Messages": "Keine angehefteten Nachrichten",
  "You successfully deleted this pinned message!":
    "Angeheftete Nachricht erfolgreich gelöscht!",
  "The pinned message does not exist!":
    "Diese angeheftete Nachricht existiert nicht!",
  "This pinned message is already deleted!":
    "Diese angeheftete Nachricht wurde bereits gelöscht!",
  "This pinned message is already pinned!":
    "Diese Nachricht ist bereits angeheftet!",
  "You successfully added new poll!": "Neue Umfrage erfolgreich erstellt!",
  "You successfully updated poll!": "Umfrage erfolgreich aktualisiert!",
  "You successfully updated poll status!":
    "Umfragenstatus erfolgreich aktualisiert!",
  "You successfully deleted a poll!": "Umfrage erfolgreich gelöscht!",
  "Pinned message must be 240 characters or less":
    "Angeheftete Nachricht darf nicht mehr als 240 Zeichen enthalten",
  "Are you sure you want to delete this pinned message?":
    "Bist du sicher, dass diese angeheftete Nachricht gelöscht werden soll?",
  "Please select a channel!": "Bitte wähle eine Kanal!",
  here: "hier",
  "The muted user list is empty": "Keine stummgeschalteten Benutzer",
  "You successfully muted this user!": "Benutzer erfolgreich stummgeschaltet",
  "This user is already muted!": "Dieser Benutzer ist bereits stummgeschaltet",
  "You successfully unmuted this user!": "Benutzer erfolgreich lautgeschaltet",
  "Add a user to your mute list": "Benutzer stummschalten",
  "Set timeout duration": "Dauer wählen",
  "Currently muted": "Stummgeschaltete Benutzer",
  "Are you sure you want to unmute this username?":
    "Bist du sicher, dass dieser Benutzer lautgeschalten werden soll?",
  UNMUTE: "LAUTSCHALTEN",
  Moderation: "Moderation",
  "The blocked terms and phrases list is empty":
    "Keine geblockten Begriffe und Phrasen",
  "The word does not exist!": "Begriff oder Phrase nicht gefunden",
  "This word is already deleted!":
    "Dieser Begriff oder Phrase ist bereits gelöscht!",
  "That term or phrase is already banned!":
    "Dieser Begriff oder Phrase ist bereits geblockt",
  "You can't ban this term or phrase!":
    "Du kannst diesen Begriff oder Phrase nicht blocken!",
  Close: "Schließen",
  "Channel privileges": "Kanalrechte",
  "Video Ad - Link": "Video Werbung - Link",
  "create video ad": "Video Werbung erstellen",
  "Manage interactive parts of the Side panel like Video Ads":
    "Bediene interaktive Elemente des Side-Panels wie “Video Werbungen”",
  Languages: "Sprachen",
  "Invite user with their email": "Lade einen Nutzer via E-Mail ein",
  Invite: "Einladen",
  "You successfully invited user": "Benutzer wurde erfolgreich eingeladen",
  "Something went wrong. Or user is already invited. Please check.":
    "Etwas ist schief gelaufen oder der Benutzer wurde bereits eingeladen. Bitte überprüfen.",
  "You successfully changed language!": "Sprache erfolgreich geändert.",
  Notifications: "Benachrichtigungen",
  "Notify user on top of the video player":
    "Benutzer über den Videoplayer benachrichtigen",
  "Create notification": "Benachrichtigung erstellen",
  "Chose from template": "Wähle eine Vorlage",
  "Notify you viewers by using text, images or links.":
    "Benachrichtige deine Zuschauer mit Texten, Bildern oder Links",
  "Notifications - Link to sidepanel tab":
    "Benachrichtigungen - Verknüpfung zum Seitenpanel",
  "Direct your viewers to a specific tab in the sidepanel like POLLS or SHOP.":
    "Verweisen Sie Ihre Zuschauer auf eine bestimmte Registerkarte im Seitenpanel wie UMFRAGEN oder SHOP.",
  "Animated Ad": "Animierte Werbung",
  "Play an ad for your viewers on top of the video player.":
    "Spiele eine Werbung über den Videoplayer ab",
  "Pick a time": "Zeit auswählen",
  "Pick animation": "Animation auswählen",
  "Animation type": "Art der Animation",
  "Pick width": "Breite auswählen",
  "Size(Percentage of width)": "Größe (Anteil der Breite)",
  "CREATE NOTIFICATION": "BENACHRICHTIGUNG ERSTELLEN",
  "Manage the content of the Shop Tab.":
    "Inhalte der Registerkarte “Shop” verwalten",
  "Shop categories": "Shop-Kategorien",
  "Currently listed items": "Aktuell aufgeführte Artikel",
  "Add shop category": "Shop-Kategorie hinzufügen",
  "Add shop item": "Shop-Artikel hinzufügen",
  "No shop items": "Keine Shop-Artikel vorhanden",
  Image: "Bild",
  Title: "Titel",
  Price: "Preis",
  Currency: "Währung",
  Category: "Kategorie",
  Add: "Hinzufügen",
  "indicates required field": "Pflichtfelder",
  "Delete selected": "Ausgewählte löschen",
  "Reported user": "Gemeldete Benutzer",
  Reason: "Grund",
  "Reported by": "Gemeldet von",
  "Report date": "Gemeldet am",
  "Event Scheduler": "Ereignis-Planer",
  "Schedule events like polls and notifications ahead of time":
    "Ereignisse wie Umfragen und Benachrichtigungen planen",
  "Prepared events": "Vorbereitete Ereignisse",
  Type: "Schreiben",
  Content: "Inhalt",
  "Scheduled For": "Geplant um/an/für",
  "File Has Headers": "Datei hat Überschriften",
  "Choose file": "Datei auswählen",
  "No file chosen": "Keine Datei ausgewählt",
  Load: "Laden",
  Field: "Feld",
  Column: "Spalte",
  Discard: "Verwerfen",
  Preview: "Vorschau",
  Schedule: "Planen",
  Reschedule: "Umplanen",
  "Choose schedule time": "Uhrzeit wählen",
  "Select schedule time": "Uhrzeit auswählen",
  "Add terms or phrases from CSV file":
    "Begriffe oder Ausdrücke von einer CSV-Datei hinzufügen",
  "Server error!": "Serverfehler",
  "You successfully deleted a shop item!": "Shop-Artikel erfolgreich entfernt",
  "You successfully updated shop item!":
    "Shop-Artikel erfolgreich aktualisiert",
  "You successfully created new shop item!":
    "Neuer Shop-Artikel erfolgreich erstellt",
  "You successfully deleted shop category!":
    "Shop-Kategorie erfolgreich entfernt",
  "You successfully created new shop category!":
    "Neue Shop-Kategorie erfolgreich erstellt",
  "Are you sure you want to delete this category":
    "Bist du sicher, dass diese Kategorie gelöscht werden soll?",
  "Are you sure you want to delete this item":
    "Bist du sicher, dass dieser Artikel gelöscht werden soll?",
  "Insert the destination your Video Ad should link to (this link will not be visible to viewers)":
    "Wohin soll diese Werbung verlinken (dieser Link wird den Zuschauern nicht angezeigt)",
  "ADD VIDEO": "VIDEO HINZUFÜGEN",
  "Video Ad - Settings": "Videoanzeige - Einstellungen",
  "Create Video Ad": "Videoanzeige erstellen",
  Video: "Video",
  Link: "Link",
  "Closable after": "Schließbar nach",
  "Last sent": "Zuletzt gestartet",
  "Upload video": "Video hochladen",
  "Link hosted video": "Verlinke ein Video",
  "Video Ad - Link (Destination)": "Videoanzeige - Link (Ziel)",
  "Video Ad - Name": "Videoanzeige - Name",
  "Add a name for the Ad": "Füge einen Namen für die Werbung hinzu",
  "Video Ad - Video (Source)": "Videoanzeige - Video (Quelle)",
  "If you are hosting the video somewhere, you can also use that link":
    "Verwende den Link des gehosteten Videos",
  Send: "Senden",
  DELETE: "LÖSCHEN",
  "Scheduled Ads": "Geplante Werbung",
  "Schedule Selected Ad": "Ausgewählte Werbung planen",
  "Selected Video Ad Name: ": "Ausgewählte Video Werbung Name: ",
  "Schedule Ad - Description": "Werbung planen - Beschreibung",
  "Schedule Ad - Broadcast": "Werbung planen - Broadcast",
  "Broadcast name": "Broadcast Name",
  Broadcast: "Broadcast",
  "Schedule Ad - Time": "Geplante Werbung - Zeit",
  "Cron job preview: ": "Cron job Vorschau: ",
  SCHEDULE: "PLANEN",
  "Edit Video Ad": "Videoanzeige bearbeiten",
  EDIT: "BEARBEITEN",
  Instantly: "Sofort",
  "3 seconds": "3 Sekunden",
  "5 seconds": "5 Sekunden",
  "10 seconds": "10 Sekunden",
  "Not closeable": "Nicht schließbar",
  "Video Ad Name": "Video Werbung Name",
  "Ad name": "Werbung Name",
  Description: "Beschreibung",
  Cron: "Cron",
  "Last Executed": "Zuletzt ausgeführt",
  "Valid Link URL is required!": "Gültige Link-URL ist erforderlich!",
  "Link URL must be valid!": "Link-URL muss gültig sein!",
  "Valid MP4 or WebM video URL is required!":
    "Gültige MP4- oder WebM-Video-URL ist erforderlich!",
  "Video URL must be valid!": "Video-URL muss gültig sein!",
  "Please insert a valid MP4 or WebM Video URL!":
    "Bitte geben Sie eine gültige MP4- oder WebM-Video-URL ein!",
  "Description must be less than 64 characters":
    "Beschreibung darf nicht mehr als 64 Zeichen beinhalten",
  "Are you sure you want to delete this Video Ad?":
    "Bist du sicher, dass du diese Videoanzeige löschen möchtest?",
  "Are you sure you want to delete this Scheduled Ad?":
    "Bist du sicher, dass du diese geplante Werbung löschen möchtest?",
  "minute(s)": "Minute(n)",
  "hour(s) on minute": "Stunde(n) bei Minute",
  "day(s) at": "Tag(e) um",
  at: "um",
  "On the": "Am",
  "day, of every": "Tag, jedes",
  "month(s), at": "Monat(e), um",
  Every: "Jeden",
  Mon: "Mo",
  Tue: "Di",
  Wed: "Mi",
  Thu: "Do",
  Fri: "Fr",
  Sat: "Sa",
  Sun: "So",
  "Has to be between": "Muss sein zwischen",
  and: "und",
  MINUTES: "MINÜTLICH",
  HOURLY: "STÜNDLICH",
  DAILY: "TÄGLICH",
  WEEKLY: "WÖCHENTLICH",
  MONTHLY: "MONATLICH",
  ADVANCED: "FORTGESCHRITTEN",
  "Add a description to identify different scheduled events":
    "Fügen eine Beschreibung hinzu, um verschiedene geplante Ereignisse zu identifizieren",
  "cron expression:": "cron Ausdruck:",
  Loyalty: "Treue",
  "Manage rewards": "Belohnungen verwalten",
  Leaderboard: "Bestenliste",
  "Browse a list of users and the amount of points they have":
    "Liste nach Nutzern und deren aktuellen Punktestand durchsuchen",
  "Activate the loyalty system and see how Loyalty Points are distributed":
    "Treuesystem aktivieren und anzeigen, wie Treuepunkte verteilt werden",
  "Enable various rewards and edit their price":
    "Aktiviere diverse Belohnungen und ändere deren Preise",
  "The video ad list is empty": "Keine Video Werbung verfügbar.",
  "You successfully added this Video Ad!":
    "Video Werbung erfolgreich hinzugefügt!",
  "The Video Ad does not exist!": "Video Werbung existiert nicht.",
  "This Video Ad is already added!":
    "Diese Video Werbung wurde bereits hinzugefügt.",
  "You successfully edited this Video Ad!":
    "Video Werbung erfolgreich bearbeitet.",
  "You successfully Scheduled this Ad!": "Video Werbung erfolgreich geplant.",
  "The Scheduled Ad does not exist!":
    "Diese geplante Video Werbung existiert nicht!",
  "This Scheduled Ad is already added!":
    "Diese geplante Video Werbung wurde bereits hinzugefügt!",
  "You successfully deleted this Video Ad!":
    "Diese Video Werbung existiert nicht!",
  "This Video Ad is already deleted!":
    "Diese Video Werbung wurde bereits gelöscht!",
  "You successfully deleted this Scheduled Ad!":
    "Diese geplante Video Werbung existiert nicht!",
  "This Scheduled Ad is already deleted!":
    "Diese geplante Video Werbung wurde bereits gelöscht!",
  "The broadcast id list is empty": "Keine Broadcast ID verfügbar.",
  "The Scheduled ad list is empty": "Keine geplante Video Werbung verfügbar.",
  "Manage interactive parts of the Side Panel like Polls and Pinned Messages":
    "Einstellungen zu interaktiven Elementen wie Umfragen und Angehefteten Nachrichten",
  "10 minutes": "10 Minuten",
  "30 minutes": "30 Minuten",
  "1 hour": "1 Stunde",
  "12 hours": "12 Stunden",
  "24 hours": "24 Stunden",
  Indefinitely: "Auf unbestimmte Zeit",
  " hours left": " Stunden übrig",
  " minutes left": " Minuten übrig",
  " days left": " Tage übrig",
  "Select a channel": "Wähle einen Kanal",
  pickerLanguage: "de",
  "One time ad": "Einmalige Anzeige",
  Now: "Jetzt",
  Scheduled: "Geplant",
  "Recurring job": "Wiederkehrender Job",
  "Cron Job": "Cron Job",
  plusOne: "weiterer",
  plusMore: "weitere",
  Date: "Datum",
  UniqueUsers:
    "Gesamtzahl der eindeutigen Nutzer <br> im ausgewählten Zeitraum.",
  TotalLinkClicks:
    "Gesamtzahl der Link-Klicks im ausgewählten Zeitraum. <br> Mehrere Klicks des selben Nutzers werden gezählt.",
  UniqueLinkClicks:
    "Mehrere Klicks des selben Nutzers werden pro Link <br> nur ein Mal gezählt.",
  PeakConcurrentUsers:
    "Höchstwert der gleichzeitig verbundenen, <br> eindeutigen Nutzern im ausgewählten Zeitraum.",
  "E-Mail already in use. Please edit the existing account or delete and re-create.":
    "E-Mail bereits in Benutzung. Bitte bearbeite den existierenden Account oder lösche und erstelle ihn neu.",
  "The selected broadcast does not support the Broadcast Moderator role.":
    "Der gewählte Broadcast unterstützt die Broadcast-Moderator-Rolle nicht.",
  "Unique users": "Eindeutige Nutzer",
  "Total link clicks": "Link klicks (gesamt)",
  "Unique link clicks": "Eindeutige Link klicks",
  "Peak concurrent users": "Gleichzeitige Nutzer (maximal)",
  "This week": "Diese Woche",
  "This month": "Diesen Monat",
  "This year": "Dieses Jahr",
  Custom: "Benutzerdefiniert",
  CHOOSE: "WÄHLEN",
  "Stats loaded successfully": "Statistik erfolgreich geladen",
  Timeframe: "Zeitrahmen",
  Monitoring: "Überwachung",
  MONITORING: "ÜBERWACHUNG",
  Today: "Heute",
  broadcastEditor: {
    name: "Titel",
    description: "Beschreibung",
    namePlaceholder: "Füge einen Broadcast Titel ein",
    agenda: "Tagesordnung",
    thumbnail: "Vorschaubild",
    logo: "Logo",
    startDate: "Start",
    endDate: "Ende",
    deleteDate: "Löschen",
    startText: "Broadcast starten",
    endText: "Broadcast beenden",
    deleteText: "Broadcast löschen",
    saveText: " ÄNDERUNGEN SPEICHERN",
    createText: "Sendung erstellen",
    backgroundImage: "Hintergrundbild",
    deleteModalTitle:
      "Bist du sicher, dass du den Broadcast {broadcastName} löschen willst?",
    deleteModalDescription:
      "Dadurch werden alle mit dieser Sendung verbundenen Daten gelöscht.\r\nDiese Aktion kann nicht rückgängig gemacht werden.",
    makePrimary: "primär machen",
    addVideoSourceBtn: "Quelle hinzufügen",
    cardDescription: "Erstelle neue Broadcasts oder bearbeite existierende",
    cardTitle: "Broadcast Manager",
    videoSourceInputPlaceholder: "Video-Quelle url",
    agendaPlaceholder: "Geben Sie hier Ihre Agenda ein",
    descriptionPlaceholder: "Geben Sie hier Ihre Sendungsbeschreibung ein",
    videoFeedTitle: "Video Einspeisung URLS",
    broadcastCreated: "Broadcast erstellt",
    broadcastUpdated: "Broadcast aktualisiert",
    userPinsEmpty: "Für diesen Broadcast existieren keine Benutzer Pins.",
    moderatorPinsEmpty:
      "Für diesen Broadcast existieren keine Moderatoren-Pins.",
    userPinsTitle: "PIN - Benutzer",
  },
  form: {
    fileInput: "Datei hochladen",
    selectDate: "Datum auswählen",
    imageUploadFail:
      "Bild-Upload fehlgeschlagen, bitte versuchen Sie es erneut.",
    clear: "Löschen",
    selectFile: "Datei wählen",
    chooseFile: "Datei hochladen",
  },
  forms: {
    imageUploadTooltip:
      "Die Bilder werden auf die folgenden Maße zugeschnitten: {size}",
    search: "Suche",
  },
  Ban: "Ban",
  Mute: "Mute",
  "Notifications - Standard": "",
  days: "Tage",
  minutes: "Minuten",
  seconds: "Sekunden",
  Interactive: "Interaktiv",
  General: "Allgemein",
  roles: {
    pageTitle: "Rollen",
    pageSubtitle:
      "Weise Nutzern Rollen zu, um zum Beispiel den Chat moderieren zu können",
  },
  blocked: {
    pageTitle: "Geblockte Begriffe und Phrasen",
    pageSubtitle:
      "Nachrichten, die diese Begriffe oder Phrasen enthalten, werden geblockt.",
  },
  search: "Suchen",
  chatSettings: {
    pageTitle: "Allgemeine Einstellungen",
    pageSubtitle:
      "Aktiviere Tabs im Seiten-Panel und treffe Chat-Einstellungen",
    sectionTitle: "Chat-Einstellungen",
    broadcastHint:
      "Treffe Chat Einstellungen für bestimmte Broadcasts. Lasse das Eingabefeld leer um Standardeinstellungen für alle Broadcasts zu treffen.",
    userColorModeText: "Farbige Namen im Chat",
    apply: "Änderungen speichern",
    rules: "Chat-Regeln",
    allowUrl: "Erlaube URLs im Chat",
    messageHistory: "Lade vorherige Chat Nachrichten",
    messageHistoryTooltip:
      "Anzahl der Nachrichten, die ein neuer Benutzer sehen kann.",
    charLimit: "Zeichenlimit pro Nachricht",
    charLimitTooltip:
      "Nachrichten, die dieses Limit überschreiten <br> werden blockiert. <br> '0' bedeutet 'Kein Limit'.",
    maxMessageCountByTimeTooltip:
      "Verhindere, dass Benutzer <br> die gleiche Nachricht wiederholt senden. <br> '5' bedeutet, dass die sechste, <br> gleiche, aufeinanderfolgende Nachricht <br> des selben Benutzers geblockt wird. <br> '0' bedeutet 'Kein Limit'.",
    maxMessageCountByTime:
      "Wie oft kann ein Benutzer die <b> gleiche Nachricht</b> aufeinanderfolgend abschicken?",
    maxSameMessageCount:
      "Wie viele Nachrichten kann ein Benutzer maximal innerhalb eines gewissen Zeitraums senden?",
    maxSameMessageCountLimit:
      "Verhindere, dass ein Benutzer <br> zu viele Nachrichte innerhalb eines <br> festgelegten Zeitraums (60 Sekunden) sendet. <br> '5' bedeutet, dass ein Benutzer nur 5 Nachrichten <br> innerhalb von 60 Sekunden senden kann. <br> '0' bedeutet 'Kein Limit'.",
  },
  pinned: {
    pageTitle: "Angeheftete Nachrichten",
    pageSubtitle: "Angeheftete Nachrichten verwalten",
  },
  shop: {
    pageTitle: "Shop-Einstellungen",
    pageSubtitle: "Registerkarte “Shop” im Side-Panel verwalten",
    disclaimer: "Shop Disclaimer",
    updateDisclaimer: "Disclaimer aktualisieren",
  },
  videoAds: {
    broadcasts: "Broadcasts",
    pageTitle: "Videoanzeige",
    pageSubtitle:
      "Zeige ein Video auf dem Live-Stream und hinterlege einen Link zu einer bestimmten Zielseite",
  },
  bans: {
    pageTitle: "Stummgeschaltete Benutzer",
    pageSubtitle: "Bearbeite stummgeschaltete Benutzer",
    broadcastLabel: "Broadcasts",
    broadcastPlaceholder: "Broadcasts auswählen",
    banDurationLabel: "Stummschaltedauer festlegen",
    editorTitle: "Benutzer stummschalten",
    currentlyBanned: "Aktuell stummgeschaltet",
    tableHeaders: {
      banDate: "Datum",
      banDuration: "Sperrdauer",
    },
    daysLeft: "Noch {value} Tag | Noch {value} Tage",
    hoursLeft: "Noch {value} Stunde | Noch {value} Stunden",
    minutesLeft: "Noch {value} Minute | Noch {value} Minuten",
    secondsLeft: "Noch {value} Sekunde | Noch {value} Sekunden",
    all: "Alle",
  },
  reports: {
    pageTitle: "Meldungen",
    pageSubtitle:
      "Benutzerbasierte Meldungen durchsuchen und Maßnahmen ergreifen",
    deleteSuccess: "Sie haben die Meldung erfolgreich gelöscht!",
  },
  monitor: {
    pageTitle: "Überblick",
    pageSubtitle: "Sehen Sie sich die verschiedenen Statistiken",
  },
  tabManagement: {
    title: "Registerkarte Verwaltung",
    polls: "Umfragen",
    chat: "Chat",
    shop: "Shop",
    qa: "Q&A",
    resources: "Ressourcen",
    tenantDefault: "Standardeinstellungen",
    broadcastHint:
      "Aktiviere Tabs für bestimmte Broadcasts. Lasse das Eingabefeld leer um Standardeinstellungen für alle Broadcasts zu treffen.",
    notifications: {
      error: "Es ist etwas schiefgegangen",
      success: "Tabs wurden erfolgreich gespeichert",
    },
  },
  generalSettings: {
    spamProtection: "Spam-Schutz",
  },
  userColorMode: {
    none: "Keine",
    random: "Zufällig",
  },
  formErrors: {
    isValidUrl: "Adresse muss eine gültige Url sein",
    validateAgeLimit:
      "Der Benutzer erfüllt nicht die erforderliche Altersgrenze",
    validateDateFormat: "Invalid Date Format(TT.MM.JJJJ)",
    validateTimeFormat: "Ungültiges Zeitformat",
    required: "Pflichtfeld",
  },
  routeNames: {
    General: "Allgemein",
    BroadcastManager: "Broadcast Manager",
    Pins: "PIN Management",
    GeneralSettings: "Einstellungen",
    Interactive: "Interaktiv",
    Polls: "Umfragen",
    SecurePolls: "Umfragen",
    PinnedMessages: "Angeheftete Nachrichten",
    VideoAds: "Video Werbung",
    ShopSettings: "Shop",
    QaManagement: "Frage & Antwort",
    Resources: "Dateien",
    Moderation: "Moderation",
    Roles: "Rollen",
    Bans: "Stummgeschaltet",
    Reports: "Meldungen",
    BlockedTermsAndPhrases: "Geblockte Begriffe",
    Accounts: "Accounts",
    Monitoring: "Statistik",
    MonitoringOverview: "Statistik Übersicht",
    UserManagementList: "Mitgliederdatei Gema MGV",
    RegistrationStats: "Registrierung Statistik",
    VotingStats: "Pre-Vote Statistik",
    Registrations: "Mitgliederdatei Gema MGV",
    Accreditation: "Akkreditierung",
    PollResults: "Ergebnisse",
    Switchboard: "Schaltpult",
  },
  blockedTerms: {
    addToList: "Zur Liste hinzufügen",
    termsAndPhrases: "Begriffe und Phrasen",
    invalidFileType: "Dateityp nicht unterstützt.",
    invalidFileFormat:
      "Import fehlgeschlagen. Bitte versuchen Sie es erneut oder verwenden Sie eine andere Datei.",
    invalidCsvFile:
      "Bitte verwenden Sie eine CSV Datei mit dem Header 'blocked term'.",
    termsTooltip:
      "Sie können mehrere Begriffe auf einmal hinzufügen, indem Sie sie durch ein Komma trennen.",
  },
  Confirm: "Bestätigen Sie",
  legal: {
    menuName: "Rechtliches",
    termsOfUse: "Nutzungsbedingungen",
    imprint: "Impressum",
    privacyPolicy: "Datenschutz­erklärung",
  },
  pinnedMessages: {
    broadcastChannelLabel: "Broadcast - Kanal",
  },
  Username: "Benutzername",
  "User ID": "Benutzer-ID",
  monitoringOverview: {
    activeUsers: "Aktive Benutzer",
    recurringUsers: "Wiederkehrende Benutzer",
    pollVotes: "Stimmen",
    uniqueUsers: "Eindeutige Benutzer",
    concurrentUsers: "Gleichzeitige Benutzer",
    chatActions: "Chat Aktionen",
    linkClicks: "Link Klicks",
    pollEvents: "Abstimmungsaktionen",
    noDataChart: "Keine Daten im gewählten Zeitraum",
    upub: "Upub",
    start: "Start",
    end: "Ende",
    reaction: "Reaktion",
    reply: "Antwort",
    vote: "Stimme",
  },
  gema: {
    table: {
      header: {
        memberNumber: "Mitgliedsnummer",
        memberStatus: "Berufsgruppe",
        firstName: "Vorname",
        lastName: "Nachname",
        registrationStatus: "Registrierungsstatus",
        dayOfParticipation: "Tag der Teilnahme",
        attendanceType: "Art der Teilnahme",
        group: "Mitgliedsstatus ",
        applicationNumber: "Antragsnummer",
        created: "Eingegangen",
        lastEdited: "Letzte Änderung",
        repMemberNumber: "Mitgliedsnummer Stellvetreter",
        name: "Name",
        membersMemberNumber: "Mitgliedsnummer des Mitglieds",
        registrationMemberName: "Name des Mitglieds",
        representativeName: "Name Stellvertreter",
      },
      searchBar: {
        currentView: "Aktuelle Ansicht",
        views: {
          attendance: "Präsenzteilnahme(Urheber)",
          preVoting: "Pre-Vote (o Mg)",
          digitalParticipation: "Digitale Teilnahme(Urheber)",
          digitalRightsTransfer: "Stimmrechtsübertragung digital(ao Verleger)",
          presentRightsTransfer: "Stimmrechtsübertragung Präsenz(ao Verleger)",
          digitalSubstitution: "Stellvertretung digital(o Mg)",
          presentSubstitution: "Stellvertretung Präsenz(o Mg)",
          memberFestival: "Mitgliederfest",
          preInfoEvents: "Vor- und Infoveranstaltungen",
          allMembers: "Alle Mitglieder",
        },
      },
      actions: {
        newEntry: "Eintrag hinzufügen",
      },
      exportAll: "Alle exportieren",
      exportPage: "Seite exportieren",
      exportQuery: "Anfrage exportieren",
      exportSide: "Nebenveranstaltungen exportieren",
    },
    singleUser: {
      firstName: "Vorname",
      lastName: "Nachname",
      memberNumber: "Mitgliedsnummer",
      street: "Straße, Hausnummer",
      city: "Stadt",
      country: "Land",
      postcode: "Postleitzahl",
      ePost: "E-Post",
      email: "E-Mail",
      birthDate: "Geburtsdatum (TT.MM.JJJJ)",
      memberStatus: "Mitgliedsstatus",
      delegateStatus: "Delegiertenstatus",
      occupationalGroup: "Berufsgruppe",
      resetPassword: "Passwort zurücksetzen",
      resetPasswordConfirmation:
        "Sind Sie sicher, dass Sie Ihr Passwort zurücksetzen möchten?",
      resetPasswordYes: "Ja, ich bin sicher",
      editInfo: "Informationen bearbeiten",
      cancel: "Abbrechen",
      save: "Speichern",
      attendanceType: "Art der Teilnahme",
      dateOfAttendance: "Tag der Teilnahme",
      voteTable: {
        title: "Stimmrechtsübertragung",
        incoming: "Eingehend",
        outgoing: "Ausgehend",
        from: "Übertragen von",
        to: "Übertragen an",
        lastEditor: "Zuletzt geändert von",
      },
      publisherName: "Verlagsname",
      excerciseOfVotingRights: "Stimmrechtsausübung",
      representation: "Stellvertretung",
      conflictOfInterestsTitle: "Interessenkonflikt",
      conflictOfInterestIsUserCheckbox:
        "Stellvertreter ist Nutzer oder mit Nutzern wirtschaftlich verflochten",
      conflictOfInterestIsUserRepresentsInterestsCheckbox:
        "Stellvertreter vertritt Interessen von Nutzern oder Interessen von Mitgliedern anderer Berufsgruppen",
      conflictOfInterestsComments: "Kommentar",
      newUserPassword: "Benutzerpasswort festlegen",
      memberType: "Mitgliedstyp",
      memberTypeOption: {
        member: "Urheber",
        publisher: "Verlag",
      },
      delegateStatusOption: {
        empty: "(leer)",
      },
      heading1: "Mitgliedsdetails",
      heading2: "Stellvertreterdetails",
      repIsGemaMember: "Ist Mitglied der GEMA",
      findMember: "Mitglied finden",
      repCapicity: "Funktion",
      resendEmail: "E-Mail erneut senden",
      representeeRepsTableTitle: "Stellvertretungen",
      lastEditorDefault: "System",
      lastEditor: "Bearbeiter",
      emailSideevents: "E-Mail Nebenveranstaltungen",
      printBadge: "Badge drucken",
    },
    attendanceType: {
      inPerson: "Mitglied selbst (vor Ort)",
      digital: "Mitglied selbst (digital)",
      preVote: "Pre-Vote",
      repInPerson: "Stellvertreter (vor Ort)",
      repDigital: "Stellvertreter (digital)",
    },
    registrationStatus: {
      confirmed: "Bestätigt",
      pending: "Offen",
      denied: "Abgelehnt",
      representing: "Stellvertretend",
      notRegistered: "Nicht registriert",
    },
    registrationDay: {
      dayOne: "Tag 1",
      dayTwoAndThree: "Tag 2 und Tag 3",
    },
    memberLookup: {
      title: "Geben Sie eine Mitgliedsnummer ein",
      error: "Mitglied nicht gefunden",
      buttonText: "weiter",
    },
    representationFunction: {
      proxy: "Handlungsbevollmächtigter und sonstiger Stimmberechtigter",
      owner: "Inhaber/Geschäftsführer",
    },
    sideEvent: {
      memberFestival: "Mitgliederfest",
      roadshow: "Roadshow",
      technicalTraining: "Technische Schulung",
      curiaConsultationHour: "Kuriensprechstunde",
      curiaConsultationHourLyricist: "Kuriensprechstunde (Textdichter)",
      curiaConsultationHourPublisher: "Kuriensprechstunde (Verleger)",
      curiaConsultationHourComposer: "Kuriensprechstunde (Komponisten)",
      occupationalGroupGathering: "Persönliche Aussprache",
      occupationalGroupGatheringLyricist:
        "Persönliche Aussprache (Textdichter)",
      occupationalGroupGatheringPublisher: "Persönliche Aussprache (Verleger)",
      occupationalGroupGatheringComposer:
        "Persönliche Aussprache (Komponisten)",
      title: "Nebenveranstaltungen",
    },
  },
  "Alle Mitglieder": "Alle Mitglieder",
  registrations: {
    single: {
      pageTitle: "Registrierungsdetails",
    },
    votingStats: {
      pageTitle: "Pre-Vote Statistik",
      block: {
        usersVoted: "Mitglieder haben abgestimmt",
        totalVotes: "Stimmen wurden vergeben",
        abstainedVotes: "Leere Stimmzettel",
      },
    },
    stats: {
      pageTitle: "Registrierung Statistik",
      block: {
        totalRegistrations: "Registrierungen",
        votingInPerson: "Teilnahme vor Ort",
        votingDigitally: "Teilnahme digital",
        prevoting: "Pre-Vote",
        representedDigitally: "Stellvertretung digital",
        sideEventMembersFestival: "Mitgliederfest",
        sideEventRoadshow: "Roadshow",
        sideEventTechnicalTraining: "Technische Schulung",
        sideEventOccupationalGroupGathering: "Persönliche Aussprache",
        sideEventCuriaConsultationHour: "Kuriensprechstunde",
        registrations: "Registrations",
        representedInPerson: "Stellvertretung vor Ort",
      },
      lyricistTitle: "Textdichter Registrierungen",
    },
    votes: {
      abstainedOption: "Leere Stimmzettel",
    },
    toast: {
      registrationCreated: "Registrierung erstellt",
    },
    representingTable: {
      exportRepresentativeRegistrations: "Registrierungen von Exportvertretern",
    },
  },
  registration: {
    stats: {
      allRegistrations: "Alle Registrierungen",
      sideEventAttendance: "Nebenveranstaltungen",
      status: "Stand",
      composerTitle: "Komponisten Registrierungen",
      publisherTitle: "Verlage Registrierungen",
    },
    dialog: {
      title:
        "Soll der Name des Mitglieds durch einen Künstlernamen ersetzt werden? <br> (Wenn nicht, dann lassen Sie das Feld leer und bestätigen Sie)",
      cancel: "Abbrechen",
      confirm: "Bestätigen",
      artistName: "Künstlername",
    },
    reset: {
      authorization: "Berechtigung",
      button: "Password zurücksetzen",
      title: "Wollen Sie das Passwort wirklich zurücksetzen?",
      cancel: "Abbrechen",
      confirm: "Zurücksetzen",
      error: "Anfrage fehlgeschlagen. Bitte versuchen Sie es erneut.",
      success: "Passwort erfolgreich geändert.",
      generatePdf: "PDF wird generiert...",
    },
  },
  accreditation: {
    createRegistration: "Registrierung erstellen",
    registrations: "Registrierungen",
    showDetails: "Details anzeigen",
    searchPlaceholder: "Mitgliedsnummer, Vorname, Nachname oder Verlagsname",
    searchError: "Suche fehlgeschlagen",
    searchNoResults: "Kein Ergebnis. Bitte versuchen Sie es erneut.",
    quickSearch: "Schnellsuche",
    fullSearch: "Volltextsuche",
  },
  role: {
    admin: "Admin",
    broadcastModerator: "Broadcast Moderator",
    moderator: "Moderator",
    user: "User",
  },
  results: {
    title: "Ergebnisse",
    description: "Ergebnisse geschlossener Wahlen und Abstimmungen",
  },
  switchboard: {
    pageTitle: "Schaltpult",
    pageSubtitle: "Wähle einen Broadcast und verwalte Videospuren",
    selectBroadcast: "Wähle einen Broadcast",
    availableTracks: "Verfügbare Quellen",
    noTracks: "Keine Quellen verfügbar",
    validationRoom: "Testanruf",
    guest: "Gast",
    noAudioTrackSelected: "Audio - Keine Quelle gewählt",
    noVideoTrackSelected: "Video - Keine Quelle gewählt",
    applyChanges: "Änderungen anwenden",
    peerNameNotFound: "Name nicht gefunden",
    tracksAssigned: "Quellen erfolgreich zugewiesen",
    refresh: "Aktualisieren",
  },
  settings: {},
  submit: "Speichern",
  fields: {
    email: "E-Mail",
  },
  "Could not find broadcast moderator user groups for all requested broadcast ids.":
    'Für die angefragten Broadcasts wurde die Gruppe "Broadcast Moderator" nicht gefunden.',
};

export const dateTimeFormats = {
  short: {
    year: "numeric",
    month: "short",
    day: "numeric",
  },
};

export default BaseMessages;
