import { Action, ActionTree } from "vuex/types/index";
import i18n from "@/plugins/i18n/i18n";
import { LocalStorage } from "@/spect8-core-vue/src/utils/LocalStorage";
import { RootState } from "../RootState";
import { AppAction, AppMutation, AppState } from "./types";
import {
  CoreAuthActions,
  CoreConfigActions,
  TenantConfig,
} from "@/spect8-core-vue/src/types";
import { ConfigActions } from "@/config/types";
import { Locale } from "@/spect8-core-vue/src/i18n";

const LOCALE_KEY = "s8-admin-locale";

export const actions: ActionTree<AppState, RootState> &
  Record<AppAction, Action<AppState, RootState>> = {
  async [AppAction.Init](
    { commit, dispatch },
    tenantName?: string
  ): Promise<void> {
    commit(AppMutation.SetTenantError, false);

    // fetch stored locale
    dispatch(AppAction.FetchLocale);

    const tenantConfig: TenantConfig | null = await dispatch(
      CoreConfigActions.FetchTenantConfig,
      tenantName,
      { root: true }
    );
    if (tenantConfig) {
      // fetch feature config
      // TODO: maybe the get feature request needs authentication?
      await dispatch(
        ConfigActions.FetchFeatures,
        tenantConfig.fusionAuthAppId,
        {
          root: true,
        }
      );

      // fetch login scope
      const loginScopeAvailable: boolean = await dispatch(
        CoreAuthActions.FetchLoginScope,
        tenantConfig.tenantId,
        {
          root: true,
        }
      );

      // re authenticate if login scope is available
      if (loginScopeAvailable) {
        await dispatch(CoreAuthActions.ReAuthenticate, false, {
          root: true,
        });
      }

      commit(AppMutation.SetInitialized, true);
    } else {
      commit(AppMutation.SetTenantError, true);
    }
  },
  [AppAction.FetchLocale]({ dispatch }): void {
    const storedLocale = LocalStorage.getItem(LOCALE_KEY);
    if (storedLocale) {
      dispatch(AppAction.SetLocale, storedLocale);
    }
  },
  [AppAction.SetLocale]({ commit }, locale: string): void {
    locale = locale.toLocaleLowerCase().trim();
    if (locale === Locale.DE || locale === Locale.EN) {
      i18n.locale = locale;
      LocalStorage.setItem(LOCALE_KEY, locale);
      commit(AppMutation.SetLocale, locale);
    }
  },
};
