import { RootState } from "@/store/RootState";
import { Getter, GetterTree } from "vuex";
import { ConfigGetter, ConfigState, Features } from "../types";

export const getters: GetterTree<ConfigState, RootState> &
  Record<ConfigGetter, Getter<ConfigState, RootState>> = {
  [ConfigGetter.EnabledFeatures](state): Features {
    return state.enabledFeatures;
  },
};
