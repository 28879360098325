import { RootState } from "@/store/RootState";
import { ResourceState } from "../types";
import { getters } from "./getters";
import { actions } from "./actions";
import { mutations } from "./mutations";
import { Module } from "vuex";

export const state: ResourceState = {
  resources: [],
};

export const RESOURCES_NAMESPACE = "resources";

export const resources: Module<ResourceState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
