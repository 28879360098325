import { initStoreEnum } from "../utils/StoreHelpers";
import { FusionAuthUser } from "./auth";
import { UserEvent } from "./event";
import { Media } from "./media";

export const CORE_USER_NAMESPACE = "s8-core-user";

export interface UserState {
  faUser: FusionAuthUser | null;
  user: User | null;
}

export enum UserAction {
  ResetUser = "resetUser",
  SetFaUser = "setFaUser",
  SetUser = "setUser",
  UpdateUserLanguage = "updateUserLanguage",
}

type UserActionStrings = keyof typeof UserAction;
export const CoreUserActions: Record<UserActionStrings, string> =
  initStoreEnum<UserActionStrings>(UserAction, CORE_USER_NAMESPACE);

export enum UserMutation {
  FaUser = "faUser",
  User = "user",
}

export enum UserGetter {
  FaUser = "faUser",
  User = "user",
  UserDisplayNameValid = "userDisplayNameValid",
  UserIsAdmin = "userIsAdmin",
}

type UserGetterStrings = keyof typeof UserGetter;
export const CoreUserGetters: Record<UserGetterStrings, string> =
  initStoreEnum<UserGetterStrings>(UserGetter, CORE_USER_NAMESPACE);

export class User implements UserResponse {
  private activeRoles: Partial<Record<Role, boolean>> = {};

  constructor(
    public id: string,
    public blockedUsers: BlockedUser[],
    public editRequest: EditRequest,
    public preferredLanguage: string | null,

    public roles: Role[],
    public tenant: Tenant | null,

    public events: UserEvent[] | null,
    public preVotePollIds: string[] | null,
    public groupIds: string[],

    public lastLogin: string,
    public lastModified: string,
    public timeCreated: string
  ) {}

  is(role: Role): boolean {
    return this.hasRole(role);
  }

  isOnlyBroadcastModerator(): boolean {
    return (
      this.roles.indexOf(Role.ADMIN) === -1 &&
      this.roles.indexOf(Role.BROADCAST_MODERATOR) !== -1
    );
  }

  private hasRole(role: Role): boolean {
    if (Object.prototype.hasOwnProperty.call(this.activeRoles, role)) {
      return this.activeRoles[role] || false;
    }

    this.activeRoles[role] = this.roles.includes(role);
    return this.activeRoles[role] || false;
  }
}

export interface UserResponse {
  id: string;
  blockedUsers: BlockedUser[];
  editRequest: EditRequest;
  preferredLanguage: string | null;

  roles: Role[];
  tenant: Tenant | null;

  events: UserEvent[] | null;
  preVotePollIds: string[] | null;
  groupIds: string[];

  lastLogin: string;
  lastModified: string;
  timeCreated: string;
}

export interface BlockedUser {
  id: string;
  avatar: Media;
  dateBlocked: string;
  displayName: string;
}

export interface EditRequest {
  avatar: Media | null;
  badge: Media | null;
  color: string | null;
  colorIndex: number | null;
  displayName?: string | null;
}

export enum Role {
  ADMIN = "ROLE_ADMIN",
  MODERATOR = "ROLE_MODERATOR",
  BROADCAST_MODERATOR = "ROLE_BROADCAST_MODERATOR",
  USER = "ROLE_USER",
}

export interface Tenant {
  tenantId: string;
}

export interface UserInfo {
  id: string;
  avatar: Media | null;
  badge: Media | null;
  blockedUsers: BlockedUser[];
  broadcastModerator: boolean;
  color: string | null;
  colorIndex: number | null;
  displayName?: string | null;
  externalUserId: string | null;
  preferredLanguage: string | null;
  roles: Role[];
  staff: boolean;
  superAdmin: boolean;
  tenantId: string | null;
  userId: string;

  events: UserEvent[] | null;
  preVotePollIds: string[] | null;
  groupIds: string[];

  lastLogin: string | null;
  lastModified: string | null;
  timeCreated: string | null;
}

export interface PatchEventDto {
  attribute: "EVENT";
  operation: "ADD" | "REMOVE";
  value: UserEvent;
}

export interface PatchPollDto {
  attribute: "PRE_VOTE_POLL_IDS";
  operation: "SET";
  value: string[];
}
