import { Action, ActionContext } from "vuex";
import { pollModule } from "../../modules/poll";
import { coreApiService } from "../../services/core";
import { trackingService } from "../../services/tracking";
import {
  CoreConfigGetters,
  CreatePollDto,
  Poll,
  PollAction,
  PollState,
  PollStatus,
  PollVote,
  PollVoteActionPayload,
  TenantConfig,
  User,
  CoreUserGetters,
} from "../../types";
import { Logger } from "../../utils/Logger";

const fetchPolls = async (
  context: ActionContext<PollState, unknown>
): Promise<void> => {
  // TODO: add parameter to getPollsByBroadcastIds, needed for ia-vue
  try {
    context.commit("setLoading", true);
    const polls = await coreApiService.getAllPolls();
    Logger.debug("setPolls", polls);
    context.commit("setPolls", polls);
    context.commit("setLoading", false);
  } catch (error) {
    Logger.debug("Poll fetch error", error);
    trackingService?.logError({
      ea: "error",
      ec: "fetchPolls",
      ed: error,
    });
  }
};

const getUsersVotes = async (
  context: ActionContext<PollState, unknown>,
  pollId: string
): Promise<void> => {
  try {
    const user: User | undefined = context.rootGetters[CoreUserGetters.User];

    if (!user || !user.id) {
      throw new Error("Missing user id");
    }

    const userVotes = await coreApiService.getPollVotesByPollIdAndUserId(
      pollId,
      user.id
    );

    context.commit("setUserVotes", {
      pollId,
      userVotes,
    });
  } catch (error) {
    Logger.debug("getUserVotes error", error);
  }
};

const endPoll = async (
  context: ActionContext<PollState, unknown>,
  poll: Poll
): Promise<void> => {
  context.commit("updatePoll", {
    ...poll,
    status: PollStatus.ENDED,
  });
};

const startPoll = async (
  context: ActionContext<PollState, unknown>,
  poll: Poll
): Promise<void> => {
  context.commit("startPoll", poll);
};

const createPoll = async (
  context: ActionContext<PollState, unknown>,
  pollData: CreatePollDto
): Promise<void> => {
  try {
    const tenantConfig: TenantConfig | undefined =
      context.rootGetters[CoreConfigGetters.TenantConfig];
    const user: User | undefined = context.rootGetters[CoreUserGetters.User];

    if (!tenantConfig || !user || !user.id) {
      throw new Error("Missing tenant config or user id");
    }

    const poll: CreatePollDto = {
      ...pollData,
      startDate: new Date().toISOString(),
      endDate: new Date(
        Date.now() + 60 * 1000 * pollData.duration
      ).toISOString(),
      createdById: user.id,
      tenantId: tenantConfig.tenantId,
    };

    await coreApiService.createPoll(poll);
  } catch (error) {
    Logger.debug("createPoll error", error);
    throw error;
  }
};

const vote = async (
  context: ActionContext<PollState, unknown>,
  { pollId, pollVote }: PollVoteActionPayload
): Promise<void> => {
  try {
    const user: User | undefined = context.rootGetters[CoreUserGetters.User];

    if (!user || !user.id) {
      throw new Error("Missing user Id");
    }

    const userVotes: PollVote[] = await coreApiService.saveVote({
      id: "",
      pollId,
      pollAnswer: pollVote,
      userId: user.id,
      date: new Date().toISOString(),
    });

    context.commit("setUserVotes", {
      pollId,
      userVotes,
    });
    if (pollModule.config.notificationCallback) {
      pollModule.config.notificationCallback(200, "poll.voteSuccess");
    }
  } catch (error) {
    Logger.debug("Poll vote error", error);
    trackingService?.logError({
      ea: "error",
      ec: "pollvote",
      ed: error,
    });
    if (pollModule.config.notificationCallback) {
      pollModule.config.notificationCallback(404, "poll.voteError");
    }
  }
};

const deletePoll = async (
  context: ActionContext<PollState, unknown>,
  poll: Poll
): Promise<void> => {
  context.commit("deletePoll", poll);
};

const updatePoll = async (
  context: ActionContext<PollState, unknown>,
  poll: Poll
): Promise<void> => {
  context.commit("updatePoll", poll);
};

const toggleResultDisplay = async (
  context: ActionContext<PollState, unknown>,
  pollId: string
): Promise<void> => {
  context.commit("togglePollResults", pollId);
};

const fetchPollById = async (
  { commit }: ActionContext<PollState, unknown>,
  pollId: string
): Promise<void> => {
  const poll = await coreApiService.getPollById(pollId);
  commit("updatePoll", poll);
};

export const actions: Record<
  Exclude<PollAction, PollAction.POLL_RESULTS>,
  Action<PollState, unknown>
> = {
  [PollAction.CREATE_POLL]: createPoll,
  [PollAction.FETCH_POLLS]: fetchPolls,
  [PollAction.GET_USERS_VOTES]: getUsersVotes,
  [PollAction.END_POLL]: endPoll,
  [PollAction.VOTE]: vote,
  [PollAction.POLL_VOTED]: updatePoll,
  [PollAction.POLL_PUBLISH_RESULTS]: updatePoll,
  [PollAction.POLL_STARTED]: startPoll,
  [PollAction.POLL_DELETED]: deletePoll,
  [PollAction.POLL_ENDED]: endPoll,
  [PollAction.POLL_MODERATOR_TOGGLE_RESULTS]: toggleResultDisplay,
  [PollAction.GET_POLL]: fetchPollById,
};
