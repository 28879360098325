import { Action, ActionContext, ActionTree } from "vuex";
import { LocalStorage } from "../../utils/LocalStorage";
import {
  AuthAction,
  AuthenticationPayload,
  AuthenticationStatus,
  AuthMutation,
  AuthState,
  CoreConfigGetters,
  LoginFusionAuthPayload,
  LoginPinAuthPayload,
  LoginScope,
  LoginType,
  TenantConfig,
  CoreUserActions,
} from "../../types";
import { authModule } from "../../modules/auth";
import { fusionAuthService } from "../../services/fusion-auth";
import { pinAuthService } from "../../services/pin-auth";
import { sessionAuthService } from "../../services/session-auth";
import { Logger } from "../../utils/Logger";
import { trackingService } from "../../services/tracking";

async function redirectAfterLogin(): Promise<void> {
  const redirect = authModule.router.currentRoute.query.redirect;
  if (typeof redirect == "string") {
    await authModule.router.push(redirect);
  } else if (authModule.config.routes["home"]) {
    await authModule.router.push({ name: authModule.config.routes["home"] });
  }
}

function isInsufficientUserRolePermissionError(error: unknown): boolean {
  return (
    (error as Error).message ===
    AuthenticationStatus.INSUFFICIENT_USER_ROLE_PERMISSION
  );
}

async function authenticate(
  context: ActionContext<AuthState, unknown>,
  payload: AuthenticationPayload
): Promise<AuthenticationStatus> {
  try {
    Logger.debug("Authentication Payload", payload);
    const response = await sessionAuthService.login(
      payload.token,
      payload.isFusionAuth,
      payload.tenantId
    );
    Logger.debug("Authentication Success", response);
    context.commit(AuthMutation.Tokens, {
      access: response.accessToken,
      refresh: response.refreshToken,
    });

    // check user roles
    if (
      authModule.config.approvedRoles &&
      !authModule.config.approvedRoles.some((role) =>
        response.user.roles.includes(role)
      )
    ) {
      throw Error(AuthenticationStatus.INSUFFICIENT_USER_ROLE_PERMISSION);
    }
    context.dispatch(CoreUserActions.SetUser, response.user, {
      root: true,
    });

    context.commit(AuthMutation.Authenticated, true);
    return AuthenticationStatus.SUCCESS;
  } catch (error) {
    Logger.debug("Authentication Error", error);
    trackingService?.logError({
      ea: "error",
      ec: "authenticate",
      ed: error,
    });
    if (isInsufficientUserRolePermissionError(error)) {
      return AuthenticationStatus.INSUFFICIENT_USER_ROLE_PERMISSION;
    }
    return AuthenticationStatus.ERROR;
  }
}

async function revoke(
  context: ActionContext<AuthState, unknown>
): Promise<void> {
  if (context.state.tokens) {
    try {
      Logger.debug("Revoke");
      await sessionAuthService.revoke(
        context.state.tokens.access,
        context.state.tokens.refresh
      );
      Logger.debug("Revoke Success");
    } catch (error) {
      Logger.debug("Revoke Error", error);
      trackingService?.logError({
        ea: "error",
        ec: "revoke",
        ed: error,
      });
    }
  }
}

async function refreshAuth(
  context: ActionContext<AuthState, unknown>
): Promise<string | null> {
  if (context.state.tokens && context.state.tokens.refresh) {
    try {
      Logger.debug("RefreshAuth");
      const response = await sessionAuthService.refresh(
        context.state.tokens.refresh
      );
      Logger.debug("RefreshAuth Success", response);
      context.commit(AuthMutation.Tokens, {
        access: response.accessToken,
        refresh: response.refreshToken,
      });
      context.dispatch(CoreUserActions.SetUser, response.user, {
        root: true,
      });
      return response.accessToken;
    } catch (error) {
      Logger.debug("Refresh token error", error);
      trackingService?.logError({
        ea: "error",
        ec: "refreshAuth",
        ed: error,
      });
    }
  }
  return null;
}

async function refreshFusionAuth(
  context: ActionContext<AuthState, unknown>
): Promise<string | null> {
  if (context.state.loginScope) {
    try {
      Logger.debug("RefreshFusionAuth");
      const response = await fusionAuthService.refresh(
        context.state.loginScope.tenantId
      );
      Logger.debug("RefreshFusionAuth Success");
      context.commit(AuthMutation.AccessTokenExt, response.token);
      return response.token;
    } catch (error) {
      Logger.debug("RefreshFusionAuth Error", error);
      trackingService?.logError({
        ea: "error",
        ec: "refreshFusionAuth",
        ed: error,
      });
    }
  }
  return null;
}

async function refreshPinAuth(
  context: ActionContext<AuthState, unknown>
): Promise<string | null> {
  try {
    Logger.debug("RefreshPinAuth");
    const response = await pinAuthService.refresh();
    Logger.debug("RefreshPinAuth Success");
    context.commit(AuthMutation.AccessTokenExt, response.data.access_token);
    return response.data.access_token;
  } catch (error) {
    Logger.debug("RefreshPinAuth Error", error);
    trackingService?.logError({
      ea: "error",
      ec: "refreshPinAuth",
      ed: error,
    });
  }
  return null;
}

async function refreshExternal(
  context: ActionContext<AuthState, unknown>
): Promise<string | null> {
  let accessTokenExt: string | null = null;
  if (context.state.loginScope?.loginType === LoginType.EMAIL) {
    accessTokenExt = await refreshFusionAuth(context);
  } else if (context.state.loginScope?.loginType === LoginType.PIN) {
    accessTokenExt = await refreshPinAuth(context);
  }
  return accessTokenExt;
}

async function resetAuth(
  context: ActionContext<AuthState, unknown>,
  redirect: boolean
): Promise<void> {
  context.commit(AuthMutation.ResetAuthentication);
  await context.dispatch(AuthAction.RemoveLoginScope);
  await context.dispatch(CoreUserActions.ResetUser, null, {
    root: true,
  });
  if (redirect && authModule.config.routes["login"]) {
    await authModule.router.push({ name: authModule.config.routes["login"] });
  }
}

async function logoutFusionAuth(
  context: ActionContext<AuthState, unknown>
): Promise<void> {
  if (context.state.loginScope) {
    try {
      Logger.debug("LogoutFusionAuth");
      await fusionAuthService.logout(context.state.loginScope.tenantId);
      Logger.debug("LogoutFusionAuth Success");
    } catch (error) {
      Logger.debug("LogoutFusionAuth Error", error);
      trackingService?.logError({
        ea: "error",
        ec: "logoutFusionAuth",
        ed: error,
      });
    }
  }
}

async function logoutPinAuth(): Promise<void> {
  try {
    Logger.debug("LogoutPinAuth");
    await pinAuthService.logout();
    Logger.debug("LogoutPinAuth Success");
  } catch (error) {
    Logger.debug("LogoutPinAuth Error", error);
    trackingService?.logError({
      ea: "error",
      ec: "logoutPinAuth",
      ed: error,
    });
  }
}

async function logoutExternal(
  context: ActionContext<AuthState, unknown>
): Promise<void> {
  if (context.state.loginScope?.loginType === LoginType.EMAIL) {
    await logoutFusionAuth(context);
  } else if (context.state.loginScope?.loginType === LoginType.PIN) {
    await logoutPinAuth();
  }
}

export const actions: ActionTree<AuthState, unknown> &
  Record<AuthAction, Action<AuthState, unknown>> = {
  [AuthAction.FetchLoginScope](context, tenantId: string | null): boolean {
    const storedLoginScope = LocalStorage.getItem(
      authModule.config.loginScopeKey
    );
    if (storedLoginScope) {
      const loginScopeArray = storedLoginScope.split("|");
      const scopedTenantId = loginScopeArray[1];
      // don't set login type if tenant id doesn't match
      if (tenantId && scopedTenantId !== tenantId) {
        return false;
      }
      const scopedLoginType = loginScopeArray[0] as LoginType;
      context.commit(AuthMutation.LoginScope, {
        loginType: scopedLoginType,
        tenantId: scopedTenantId,
      });
      return true;
    }
    return false;
  },
  [AuthAction.RemoveLoginScope](context): void {
    LocalStorage.removeItem(authModule.config.loginScopeKey);
    context.commit(AuthMutation.LoginScope, undefined);
  },
  [AuthAction.SetLoginScope](context, loginScope: LoginScope): void {
    LocalStorage.setItem(
      authModule.config.loginScopeKey,
      `${loginScope.loginType}|${loginScope.tenantId}`
    );
    context.commit(AuthMutation.LoginScope, loginScope);
  },
  async [AuthAction.ForgotPasswordFusionAuth](
    context,
    loginId: string
  ): Promise<boolean> {
    try {
      Logger.debug("ForgotPasswordFusionAuth login id", loginId);

      const tenantConfig: TenantConfig | undefined =
        context.rootGetters[CoreConfigGetters.TenantConfig];
      let applicationId: string | null = null;
      let tenantId: string | undefined;
      if (tenantConfig) {
        applicationId = tenantConfig.fusionAuthAppId;
        tenantId = tenantConfig.tenantId;
      }

      if (!applicationId) {
        throw new Error("Application ID not available");
      }

      await fusionAuthService.forgotPassword(applicationId, loginId, tenantId);
      return true;
    } catch (error) {
      Logger.debug("ForgotPasswordFusionAuth Error", error);
      trackingService?.logError({
        ea: "error",
        ec: "forgotPasswordFusionAuth",
        ed: error,
      });
    }
    return false;
  },
  async [AuthAction.LoginFusionAuth](
    context,
    payload: LoginFusionAuthPayload
  ): Promise<boolean> {
    try {
      Logger.debug("LoginFusionAuth Payload", payload);

      let applicationId: string | null = null;
      let tenantId: string | undefined;

      // try to set application and tenant id from tenant config or payload
      // tenant id is optional
      const tenantConfig: TenantConfig | undefined =
        context.rootGetters[CoreConfigGetters.TenantConfig];
      if (tenantConfig) {
        applicationId = tenantConfig.fusionAuthAppId;
        tenantId = tenantConfig.tenantId;
      } else if (payload.applicationId) {
        applicationId = payload.applicationId;
      }
      if (!applicationId) {
        throw new Error("Application ID not available");
      }

      const response = await fusionAuthService.login(
        applicationId,
        payload.loginId,
        payload.password,
        tenantId
      );
      Logger.debug("LoginFusionAuth Success", response);

      context.dispatch(CoreUserActions.SetFaUser, response.user, {
        root: true,
      });

      // if tenant config is not available, use tenant id from fusion auth response
      if (!tenantId) {
        tenantId = response.user.tenantId;
      }

      context.commit(AuthMutation.AccessTokenExt, response.token);
      context.dispatch(AuthAction.SetLoginScope, {
        loginType: LoginType.EMAIL,
        tenantId: tenantId,
      });

      const authenticationStatus = await authenticate(context, {
        tenantId: tenantId,
        token: response.token,
        isFusionAuth: true,
      });
      if (authenticationStatus !== AuthenticationStatus.SUCCESS) {
        throw new Error(authenticationStatus);
      }

      if (payload.redirect !== false) {
        await redirectAfterLogin();
      }

      return true;
    } catch (error) {
      Logger.debug("LoginFusionAuth Error", error);
      trackingService?.logError({
        ea: "error",
        ec: "loginFusionAuth",
        ed: error,
      });

      await context.dispatch(AuthAction.Logout, false);
      if (authModule.config.notificationCallback) {
        if (isInsufficientUserRolePermissionError(error)) {
          authModule.config.notificationCallback(403, "error.rolePermission");
        } else {
          authModule.config.notificationCallback(404, "error.emailLogin");
        }
      }
      return false;
    }
  },
  async [AuthAction.LoginPinAuth](
    context,
    payload: LoginPinAuthPayload
  ): Promise<boolean> {
    try {
      Logger.debug("LoginPinAuth Payload", payload);
      const tenantConfig: TenantConfig | undefined =
        context.rootGetters[CoreConfigGetters.TenantConfig];
      if (!tenantConfig) {
        throw new Error("Tenant ID not available");
      }
      const response = await pinAuthService.login(
        payload.displayname !== "" ? payload.displayname : null,
        payload.pin,
        tenantConfig.tenantId
      );
      Logger.debug("LoginPinAuth Success", response);
      context.commit(AuthMutation.AccessTokenExt, response.data.access_token);
      context.dispatch(AuthAction.SetLoginScope, {
        loginType: LoginType.PIN,
        tenantId: tenantConfig.tenantId,
      });

      const authenticationStatus = await authenticate(context, {
        tenantId: tenantConfig.tenantId,
        token: response.data.access_token,
        isFusionAuth: false,
      });
      if (authenticationStatus !== AuthenticationStatus.SUCCESS) {
        throw new Error(authenticationStatus);
      }

      if (payload.redirect !== false) {
        await redirectAfterLogin();
      }

      return true;
    } catch (error) {
      Logger.debug("LoginPinAuth Error", error);
      trackingService?.logError({
        ea: "error",
        ec: "loginPinAuth",
        ed: error,
      });
      await context.dispatch(AuthAction.Logout, false);
      if (authModule.config.notificationCallback) {
        if (isInsufficientUserRolePermissionError(error)) {
          authModule.config.notificationCallback(403, "error.rolePermission");
        } else {
          authModule.config.notificationCallback(404, "error.pinLogin");
        }
      }
      return false;
    }
  },
  async [AuthAction.Logout](context, redirect = true): Promise<void> {
    // revoke session auth tokens
    await revoke(context);

    // logout from fusion auth or pin auth
    await logoutExternal(context);

    // reset authentication, user and redirect
    await resetAuth(context, redirect);
  },
  async [AuthAction.Refresh](context): Promise<string | null> {
    // refresh session auth token
    const accessToken = await refreshAuth(context);

    // refresh token for fusion auth or pin auth
    let accessTokenExt: string | null = null;
    if (accessToken) {
      accessTokenExt = await refreshExternal(context);
    }

    if (accessToken === null || accessTokenExt === null) {
      // reset authentication, user and redirect
      await resetAuth(context, true);
      if (authModule.config.notificationCallback) {
        authModule.config.notificationCallback(400, "error.sessionExpired");
      }
      return null;
    }

    return accessToken;
  },
  async [AuthAction.ReAuthenticate](
    context,
    session: boolean
  ): Promise<string | null> {
    try {
      Logger.debug("ReAuthenticate");

      // 1. refresh token for fusion auth or pin auth
      const accessTokenExt: string | null = await refreshExternal(context);
      if (accessTokenExt === null) {
        throw Error("External token refresh failed");
      }

      if (session) {
        // 2.1 revoke session auth tokens
        await revoke(context);
      }

      // 2.2 authenticate with new external access token
      let authenticationStatus = AuthenticationStatus.NONE;
      if (context.state.loginScope) {
        authenticationStatus = await authenticate(context, {
          tenantId: context.state.loginScope.tenantId,
          token: accessTokenExt,
          isFusionAuth: context.state.loginScope.loginType === LoginType.EMAIL,
        });
      }
      if (authenticationStatus !== AuthenticationStatus.SUCCESS) {
        // logout pin or email on authentication failed
        await logoutExternal(context);
        throw new Error(authenticationStatus);
      }

      Logger.debug("ReAuthenticate Success");
      return accessTokenExt;
    } catch (error) {
      Logger.debug("ReAuthenticate Error", error);
      // reset authentication, user and redirect
      await resetAuth(context, session);
      if (session && authModule.config.notificationCallback) {
        if (isInsufficientUserRolePermissionError(error)) {
          authModule.config.notificationCallback(403, "error.rolePermission");
        } else {
          authModule.config.notificationCallback(400, "error.sessionExpired");
        }
      }
    }
    return null;
  },
};
