import { Namespace } from "@/store/types";

export interface ResultState {
  results: Result[];
}

export interface Result {
  answerVotes: AnswerVotes[];
  closedAt: string | null;
  question: string;
  responseId: string;
  title: string;

  allLiveVotesPresenceInPerson: number | null;
  allLiveVotesPresenceDigital: number | null;
  verlegerLiveVotePresenceInPerson: number | null;
  verlegerLiveVotePresenceDigital: number | null;
  komponistenLiveVotePresenceInPerson: number | null;
  komponistenLiveVotePresenceDigital: number | null;
  textdichterLiveVotePresenceInPerson: number | null;
  textdichterLiveVotePresenceDigital: number | null;

  // deprecated
  allLiveVotesPresence: number | null;
  allPreVotesPresence: number | null;
  allTotalVotesPresence: number | null;
  komponistenLiveVotePresence: number | null;
  komponistenPreVotePresence: number | null;
  komponistenTotalPresence: number | null;
  textdichterLiveVotePresence: number | null;
  textdichterPreVotePresence: number | null;
  textdichterTotalPresence: number | null;
  verlegerLiveVotePresence: number | null;
  verlegerPreVotePresence: number | null;
  verlegerTotalPresence: number | null;
}

export interface AnswerVotes {
  value: string;
  komponistenPreVote: number | null;
  komponistenLiveVote: number | null;
  komponistenTotal: number | null;
  textdichterPreVote: number | null;
  textdichterLiveVote: number | null;
  textdichterTotal: number | null;
  verlegerPreVote: number | null;
  verlegerLiveVote: number | null;
  verlegerTotal: number | null;
  allPreVotes: number | null;
  allLiveVotes: number | null;
  allTotalVotes: number | null;
}

export enum ResultAction {
  fetchPollResults = "fetchPollResults",
}

export const ResultActions: Record<ResultAction, string> = {
  fetchPollResults: `${Namespace.PollResults}/${ResultAction.fetchPollResults}`,
};

export enum ResultMutation {
  SetResults = "SetResults",
}

export enum ResultGetter {
  Results = "Results",
}

export const ResultGetters: Record<ResultGetter, string> = {
  Results: `${Namespace.PollResults}/${ResultGetter.Results}`,
};
