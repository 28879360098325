import { WebsocketConnection } from "./WebSocket";

class RTCInfo {
  peerId: string;
  iceCandidates: RTCIceCandidate[];

  constructor(
    public isBroadcaster: boolean,
    public tenantId: string,
    public broadcastId: string,
    public conferenceId: string,
    public slotId: string,
    public userId: string,
    public iceServers: RTCIceServer[],
    public signalSocketBaseUrl: string,
    public peerName: string
  ) {
    this.iceCandidates = [];
    this.peerId = "";
  }

  public fullPath(): string {
    return [
      this.isBroadcaster ? "bc" : "view",
      this.tenantId,
      this.broadcastId,
      this.userId,
      this.slotId,
    ].join(":");
  }
}

/**  RTC layer abstraction class per slot */
class RTCSlot {
  pc: RTCPeerConnection | null = null;
  mediaStream: MediaStream | null;

  initialized = false;

  // only incoming
  streaming = false;

  constructor(
    public rtcInfo: RTCInfo,
    public wsConnection: WebsocketConnection,
    mediaStream?: MediaStream
  ) {
    this.mediaStream = mediaStream ?? new MediaStream();
  }

  /** Initializes this RTCSlot */
  public init(): void {
    this.initRtc();
    if (!this.rtcInfo.isBroadcaster && !!this.pc) {
      this.pc.addTransceiver("video");
      this.pc.addTransceiver("audio");
    }

    this.initialized = true;
  }

  /** Initializes a new RTC peer connection */
  public initRtc(): void {
    this.pc = new RTCPeerConnection({
      iceServers: this.rtcInfo.iceServers,
    });
    this.pc.onicecandidate = this.onlocalicecandidate.bind(this);
    this.pc.oniceconnectionstatechange =
      this.onConnectionStateChange.bind(this);
    this.pc.ontrack = this.onTrack.bind(this);
  }

  // Entry point for simple viewer
  public startWatching(): Promise<void> {
    return this.negotiateRtcConnection();
  }

  onTrack(ev: RTCTrackEvent): void {
    console.debug(`# track for slot (${this.rtcInfo.slotId})`, ev);
    const stream = ev.streams[0];
    const track = ev.track;

    try {
      if (!this.rtcInfo.isBroadcaster) {
        stream.getTracks().forEach((track) => {
          this.mediaStream?.addTrack(track);
          this.streaming = true;
        });

        stream.onremovetrack = ({ track }) => {
          console.log("remove track", track);
          if (!stream.getTracks().length) this.streaming = false;
        };
      }
    } catch (err) {
      console.error("[onTrack] ERROR", err);
    }
  }

  // BROADCASTER
  // Entry point for broadcasting users
  public startBroadcasting(): Promise<void> {
    if (!this.pc) {
      throw Error("Can't broadcast without broadcasting RTC ...");
    }

    this.mediaStream?.getTracks().forEach((track: MediaStreamTrack) => {
      if (this.pc && this.mediaStream) {
        console.info("Adding media track", track);
        this.pc.addTrack(track, this.mediaStream);
      }
    });
    return this.negotiateRtcConnection();
  }

  onConnectionStateChange(ev: Event): void {
    console.debug(
      "New state for slot ",
      this.rtcInfo.slotId,
      this.pc?.iceConnectionState,
      ev
    );
    if (this.pc?.iceConnectionState === "connected") {
      console.debug(
        "WebRTC connection to server established for slot ",
        this.rtcInfo.slotId
      );
    } else if (this.pc?.iceConnectionState === "disconnected") {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      this.pc.restartIce();

      // this.StartWatching();
      console.debug(
        "WebRTC connection to server lost for slot ",
        this.rtcInfo.slotId
      );
      if (!this.rtcInfo.isBroadcaster) {
        this.mediaStream = null;
        this.streaming = false;
        // this.AssignVideoSource(this.rtcInfo.slotId, null);
      }
    }
  }

  async negotiateRtcConnection(): Promise<void> {
    // reset candidates for new negotiation
    this.rtcInfo.iceCandidates = [];
    console.info("Negotiating for ", this.rtcInfo.fullPath());

    // ensure config for receiver
    const config: RTCOfferOptions = !this.rtcInfo.isBroadcaster
      ? { offerToReceiveAudio: true, offerToReceiveVideo: true }
      : {};

    if (!this.pc) throw Error("No PeerConnection");

    const offerSDP = await this.pc.createOffer(config);
    console.debug("[NegotiateRTCConnection] OFFER-SDP = ", offerSDP);

    await this.pc.setLocalDescription(offerSDP);
    this.sendOfferSdp(offerSDP);
    // .catch((err: any) => {
    //   console.debug("[NegotiateRTCConnection] createOffer failed!", err);
    // });
  }

  sendOfferSdp(offerSDP: RTCSessionDescriptionInit): void {
    const eventName = "OfferSdp";
    const data = {
      slotId: this.rtcInfo.slotId,
      clientSdp: offerSDP,
    };
    this.wsConnection.sendEvent(eventName, JSON.stringify(data));
    console.debug("[NegotiateRTCConnection] sendOfferSdp: ", data.slotId);
  }

  onServerSdp(topic: string, data: { serverSdp: any }): Promise<void> {
    // console.debug("[_OnServerSdp] =SERVER SDP ==>", data.serverSdp);
    const serverSdp = JSON.parse(data.serverSdp);
    console.debug("[onServerSdp] = SERVER SDP ==>", this.rtcInfo.slotId);
    if (!this.pc) throw Error("No PeerConnection");
    // if (!this.rtcInfo.isBroadcaster) this.mediaStream = null;

    return this.pc
      .setRemoteDescription(serverSdp)
      .then(() => {
        console.debug(
          "[onServerSdp] setRemoteDescription success: ",
          this.rtcInfo.slotId
        );
      })
      .catch((err: any) => {
        console.error(
          "[onServerSdp] setRemoteDescription fail: ",
          this.rtcInfo.slotId,
          err
        );
      });
  }

  onQaStateUpdate(topic: any, data: { qaState: any }): void {
    console.debug(
      "[onQAStateUpdate] == new state ==>",
      JSON.parse(data.qaState)
    );
  }

  // ~~~~~ this happens when a track changed after we connected
  onTrackUpdate(topic: any, data: any): Promise<void> {
    console.debug("[onTrackUpdate] +++ :");
    if (!this.pc) throw Error("No PeerConnection");
    // this.peerConnectionServer.restartIce();
    return (
      this.negotiateRtcConnection()
        // .then(function () { console.debug("[onTrackUpdate] NegotiateRTCConnection completed +++"); })
        .catch(function (err: any) {
          console.error(
            "[onTrackUpdate] NegotiateRTCConnection failed :( :",
            err
          );
        })
    );
  }

  sendIceCandidate(candidate: RTCIceCandidate): void {
    const EventName = "ClientIceCandidate";
    const data = {
      slotId: this.rtcInfo.slotId,
      candidate: candidate,
    };
    console.debug("[sendIceCandidate] +++ ", EventName, data);
    this.wsConnection.sendEvent(EventName, JSON.stringify(data));
  }

  onRemoteIceCandidate(topic: any, data: { candidate: string }): Promise<void> {
    const candidate: RTCIceCandidate = JSON.parse(data.candidate);
    // todo: add proper logger and put this kinda stuff on super super verbose
    // console.debug( "[onRemoteIceCandidate] success for slot ", this.rtcInfo.slotId, candidate);
    if (!this.pc) throw Error("No PeerConnection");

    return (
      this.pc
        .addIceCandidate(candidate)
        // .then(function () { console.debug("[onRemoteIceCandidate] success = ", candidate); })
        .catch(function (err: any) {
          console.error("[onRemoteIceCandidate] ERROR adding candidate:", err);
          return;
        })
    );
  }

  onlocalicecandidate = (event: RTCPeerConnectionIceEvent): void => {
    if (event.candidate) {
      this.rtcInfo.iceCandidates.push(event.candidate);
      this.sendIceCandidate(event.candidate);
    }
  };

  replaceTrack(track: MediaStreamTrack): void {
    if (!this.pc) throw Error("No PeerConnection");

    const sender = this.pc
      .getSenders()
      .find((s) => s?.track?.kind == track.kind);
    console.debug("found sender:", sender);
    if (sender) sender.replaceTrack(track);
  }

  /**
   * Clean the house
   */
  close(): void {
    if (this.mediaStream) {
      this.mediaStream.getTracks().forEach((t) => t.stop());
      this.mediaStream = null;
    }
    if (this.wsConnection) {
      this.wsConnection.websocket?.close();
      this.wsConnection.websocket = undefined;
    }
    if (this.pc) {
      this.pc?.close();
      this.pc = null;
    }
    this.streaming = false;
  }

  async dump(filter: string[]) {
    const stats = await this.pc?.getStats(null);
    const obj: Record<string, any> = {};
    if (stats)
      stats.forEach((report) => {
        if (filter.includes(report.type)) {
          obj[report.type] = report;
        }
      });
    return obj;
  }
}

export { RTCInfo, RTCSlot };
