import Vue from "vue";
import { Mutation, MutationTree } from "vuex";
import { ConfigMutation, ConfigState, TenantConfig } from "../../types";

export const mutations: MutationTree<ConfigState> &
  Record<ConfigMutation, Mutation<ConfigState>> = {
  [ConfigMutation.TenantConfig](state, tenantConfig: TenantConfig) {
    Vue.set(state, "tenantConfig", tenantConfig);
  },
};
