import { Getter, GetterTree } from "vuex";
import { RootState } from "../../../store/RootState";
import { Broadcast, BroadcastGetter, BroadcastState } from "./types";

export const getters: GetterTree<BroadcastState, RootState> &
  Record<BroadcastGetter, Getter<BroadcastState, RootState>> = {
  [BroadcastGetter.AllBroadcasts](state): Broadcast[] | null {
    return state.allBroadcasts;
  },
  [BroadcastGetter.Broadcasts](state): Broadcast[] {
    return state.broadcasts;
  },
  [BroadcastGetter.Loading](state): boolean {
    return state.loading;
  },
};
