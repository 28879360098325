export interface MonitoringState {
  uniqueUsers: number;
  uniqueLinks: number;
  concurrentUsers: number;
  total: number;
  recurringUsersData: number;
  uniqueUsersStat: UsersStatsData[];
  concurrentUsersData: UsersStatsData[];
  uniqueUsersData: UsersStatsData[];
  linkClicks: UsersStatsData[];
  chatActionUpub: UsersStatsData[];
  chatActionEnd: UsersStatsData[];
  chatActionStart: UsersStatsData[];
  chatActionReaction: UsersStatsData[];
  chatActionReply: UsersStatsData[];
  chatActionVote: UsersStatsData[];
  activeUsersData: UsersStatsData[];
}

export enum MonitoringAction {
  FetchRecurringUsers = "FetchRecurringUsers",
  FetchUniqueUsers = "FetchUniqueUsers",
  FetchConcurrentUsers = "FetchConcurrentUsers",
  FetchLinkClicks = "FetchLinkClicks",
  FetchChatActions = "FetchChatActions",
  FetchActiveUsersStat = "FetchActiveUsersStat",
}

export enum MonitoringMutation {
  SetConcurrentUsers = "SetConcurrentUsers",
  SetUniqueUsers = "SetUniqueUsers",
  SetRecurringUsers = "SetRecurringUsers",
  SetLinkClicks = "SetLinkClicks",
  SetChatActionUpub = "SetChatActionUpub",
  SetChatActionStart = "SetChatActionStart",
  SetChatActionEnd = "SetChatActionEnd",
  SetChatActionReaction = "SetChatActionReaction",
  SetChatActionReply = "SetChatActionReply",
  SetChatActionVote = "SetChatActionVote",
  SetUniqueUsersStat = "SetUniqueUsersStat",
  SetActiveUsersData = "SetActiveUsersData",
}

export enum MonitoringGetter {
  ConcurrentUsers = "ConcurrentUsers",
  ConcurrentUsersData = "ConcurrentUsersData",
  RecurringUsersData = "RecurringUsersData",
  UniqueUsersData = "UniqueUsersData",
  ConcurrentUsersDataTime = "ConcurrentUsersDataTime",
  ConcurrentUsersDataValue = "ConcurrentUsersDataValue",
  UniqueUsersStat = "UniqueUsersStat",
  UniqueUsersDataTime = "UniqueUsersDataTime",
  UniqueUsersDataValue = "UniqueUsersDataValue",
  LinkClicksData = "LinkClicksData",
  LinkClicksDataTime = "LinkClicksDataTime",
  LinkClicksDataValue = "LinkClicksDataValue",

  ChatActionUpubValue = "ChatActionUpubValue",
  ChatActionStartValue = "ChatActionStartValue",
  ChatActionEndValue = "ChatActionEndValue",
  ChatActionReactionValue = "ChatActionReactionValue",
  ChatActionReplyValue = "ChatActionReplyValue",
  ChatActionVoteValue = "ChatActionVoteValue",

  ChatActionUpub = "ChatActionUpub",
  ChatActionStart = "ChatActionStart",
  ChatActionEnd = "ChatActionEnd",
  ChatActionReaction = "ChatActionReaction",
  ChatActionReply = "ChatActionReply",
  ChatActionVote = "ChatActionVote",

  ActiveUsersData = "ActiveUsersData",
}

export enum ChatActions {
  upub = "upub",
  start = "start",
  end = "end",
  reaction = "reaction",
  reply = "reply",
  vote = "vote",
}

export interface UsersStatsData {
  t: string;
  v: number;
}

export interface UsersStatsRequest {
  start: string;
  end: string;
  tenantId?: string;
  aggwindow?: string;
  action?: string;
}

export interface StatsRequestContent {
  stats: [
    {
      timeseries: {
        data: [
          {
            t: string;
            v: number;
          }
        ];
      };
    }
  ];
}
