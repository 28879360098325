import Vue from "vue";
import { Mutation, MutationTree } from "vuex";
import { Resource, ResourceMutation, ResourceState } from "../types";

export const mutations: MutationTree<ResourceState> &
  Record<ResourceMutation, Mutation<ResourceState>> = {
  [ResourceMutation.AddResource](state, resource: Resource) {
    state.resources.push(resource);
  },
  [ResourceMutation.SetResources](state, resources: Resource[]): void {
    Vue.set(state, "resources", resources);
  },
  [ResourceMutation.ResetResources](state) {
    Vue.set(state, "resources", []);
  },
  [ResourceMutation.DeleteResource](state, resourceId: string) {
    state.resources = state.resources.filter((a) => a.id !== resourceId);
  },
};
