import { ActionTree } from "vuex";
import { apiChat } from "./../../services/api.service";
import { RootState } from "../RootState";
import {
  GeneralState,
  Channel,
  AddPinnedMessageRequest,
  ShopCategory,
  ShopItem,
  ShopDisclaimer,
  CategoryResponse,
  ShopItemResponse,
  NavigationRequest,
  Navigation,
  NavigationDeleteRequest,
  AddChatSettings,
  PinnedMessage,
  ChatSettings,
} from "./types";
import { AxiosResponse } from "axios";
import Vue from "vue";
import i18n from "@/plugins/i18n/i18n";
import {
  Broadcast,
  BroadcastGetters,
} from "@/components/Broadcast/broadcast/types";
import qs from "qs";
import { handleHttpError } from "@/helpers";
import { http } from "@/services/api.service";

// function calculateDuration(dateEnd: Date, currentDate: Date) {
//   const ms = moment(dateEnd, "DD/MM/YYYY HH:mm:ss").diff(
//     moment(currentDate, "DD/MM/YYYY HH:mm:ss")
//   );
//   const d = moment.duration(ms);
//   const s = Math.floor(d.asHours()) + moment.utc(ms).format("mm");
//   const dur = parseInt(s);
//   if (dur > 0) return `${dur.toString()} ${i18n.tc("minutes")}`;
//   else return "";
// }

// upload shop item image
async function uploadImage(img: File): Promise<string | null> {
  if (img) {
    const formData = new FormData();
    formData.append("file", img);
    const res = await http.post("/upload", formData, {
      responseType: "text",
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return res.data;
  }
  return null;
}

function showNotification(status: number | undefined, message: string) {
  if (status === 200)
    Vue.$toast.success(i18n.tc(`${message}`), {
      timeout: 2000,
    });
  else if (status == 500 || status == 404 || status == 400 || status == 409)
    Vue.$toast.error(i18n.tc(`${message}`), {
      timeout: 2000,
    });
  else
    Vue.$toast.info(i18n.tc(`${message}`), {
      timeout: 2000,
    });
}

export const actions: ActionTree<GeneralState, RootState> = {
  async applyChatSettings({ commit }, chatData: AddChatSettings) {
    // eslint-disable-next-line
    const { broadcastId, ...dtoData } = chatData;
    const data: ChatSettings = {
      ...dtoData,
    };

    try {
      const res = await apiChat.setChatSettings(data, broadcastId);
      if (res) {
        commit("setChatSettings", res);
        showNotification(200, "Chat settings applied successfully");
        return res;
      }
    } catch (error) {
      handleHttpError(error);
    }
  },
  async getChatSettings(
    { commit },
    broadcastId?: string
  ): Promise<ChatSettings> {
    try {
      const res = await apiChat.getChatSettings(broadcastId);

      commit("setChatSettings", res);
      return res;
    } catch (error) {
      handleHttpError(error);
      throw error;
    }
  },
  async getChannelsList({ commit, rootGetters }) {
    const broadcasts = rootGetters[BroadcastGetters.Broadcasts];
    const broadcastIds: string[] = broadcasts.map((b: Broadcast) => b.id);

    commit("resetChannels");

    if (broadcastIds.length > 0)
      try {
        const res: AxiosResponse<Channel[]> = await http.get(
          `/channel/broadcasters`,
          {
            params: {
              broadcastIds: broadcastIds.join(","),
            },
          }
        );
        if (!res.data || res.data == [])
          commit("setChannelsError", "The channels list is empty");

        commit("setChannelsList", res.data);
        commit("setChannelsError", "");
      } catch (error) {
        commit("setChannelsError", i18n.tc(`The channels list is empty`));
        handleHttpError(error);
      }
    else console.log("No broadcasts");
  },
  async getPinnedMessagesList({
    commit,
  }): Promise<AxiosResponse<PinnedMessage[]>> {
    try {
      const res: AxiosResponse<PinnedMessage[]> = await http.get(
        `/tenant/pinnedmessage/all`
      );
      commit("setPinnedMessagesList", res.data);
      return res;
    } catch (error) {
      handleHttpError(error);
      throw error;
    }
  },
  async deletePinnedMessage({ commit }, id: string) {
    try {
      await http.delete(`/pinnedmessages/${id}`);
      showNotification(200, "You successfully deleted this pinned message!");
      commit("deletePinnedMessage", id);
    } catch (error) {
      handleHttpError(error, {
        404: "The pinned message does not exist!",
        409: "This pinned message is already deleted!",
      });
      throw error;
    }
  },
  async addPinnedMessage(
    { commit },
    pinnedData: AddPinnedMessageRequest
  ): Promise<void> {
    try {
      const pinnedMessage = await http
        .post<PinnedMessage>(`/pinnedmessages`, pinnedData)
        .then((res) => res.data);
      showNotification(200, "You successfully pinned a message");
      commit("addPinnedMessage", pinnedMessage);
    } catch (error) {
      handleHttpError(error, {
        404: "The pinned message does not exist!",
        409: "This pinned message is already pinned!",
      });
      throw error;
    }
  },
  //SHOP
  // create new category
  async createCategory(context, data: ShopCategory) {
    try {
      await http.post(`/shop/category`, data).then((response) => {
        showNotification(
          response.status,
          "You successfully created new shop category!"
        );
        if (response.status == 200)
          context.commit("addCategory", response.data);
      });
    } catch (error) {
      handleHttpError(error);
      throw error;
    }
  },

  // get categories
  async getCategories(context): Promise<CategoryResponse[]> {
    try {
      const response = await http.get(`/shop/category`);
      context.commit("setCategories", response.data);
      return response.data;
    } catch (error) {
      handleHttpError(error);
      throw error;
    }
  },

  // delete category
  async deleteCategory(context, id: string) {
    try {
      await http.delete(`/shop/category/${id}`).then((response) => {
        showNotification(
          response.status,
          "You successfully deleted shop category!"
        );
        if (response.status == 200) context.commit("deleteCategory", id);
      });
    } catch (error) {
      handleHttpError(error);
      throw error;
    }
  },

  // create shop item
  async createShopItem(context, data: ShopItem) {
    let img = null;
    if (data.image) img = await uploadImage(data.image);
    delete data.image;
    if (img) data.url = img;

    try {
      await http.post(`/shop/item`, data).then(async (response) => {
        if (response.status === 200) {
          showNotification(
            response.status,
            "You successfully created new shop item!"
          );
          context.commit("addItem", response.data);
        }
      });
    } catch (error) {
      handleHttpError(error);
      throw error;
    }
  },

  // get shop items
  async getShopItems(context, categoryId: string): Promise<ShopItemResponse[]> {
    try {
      const response = await http
        //change url
        .get(`/shop/item/${categoryId}`);
      context.commit("setShopItems", response.data);
      return response.data;
    } catch (error) {
      handleHttpError(error);
      throw error;
    }
  },

  // update shop item
  async updateShopItem(context, data: ShopItem) {
    const id = data.id;
    delete data.id;
    let img = null;
    if (data.image) img = await uploadImage(data.image);
    delete data.image;
    if (img) data.url = img;

    try {
      await http.put(`/shop/item/${id}`, data).then(async (response) => {
        showNotification(
          response.status,
          "You successfully updated shop item!"
        );

        if (response.status === 200)
          context.commit("updateItem", response.data);
      });
    } catch (error) {
      handleHttpError(error);
      throw error;
    }
  },

  // delete shop item
  async deleteShopItem(context, id: string) {
    try {
      await http.delete(`/shop/item/${id}`).then(async (response) => {
        showNotification(
          response.status,
          "You successfully deleted a shop item!"
        );
        if (response.status == 200) context.commit("deleteItem", id);
      });
    } catch (error) {
      handleHttpError(error);
      throw error;
    }
  },
  // create shop disclaimer
  async createShopDisclaimer(context, data: ShopDisclaimer) {
    try {
      await http.post(`/shop/disclaimer`, data).then((response) => {
        showNotification(
          response.status,
          "You successfully created a shop disclaimer!"
        );
        if (response.status === 200)
          context.commit("addDisclaimer", response.data);
      });
    } catch (error) {
      handleHttpError(error);
      throw error;
    }
  },
  // get shop disclaimer
  async getDisclaimers(context): Promise<ShopDisclaimer[]> {
    try {
      const response = await http.get(`/shop/disclaimer`);
      context.commit("setDisclaimers", response.data);
      return response.data;
    } catch (error) {
      handleHttpError(error);
      throw error;
    }
  },
  // delete disclaimer
  async deleteDisclaimer(context, id: string) {
    try {
      await http.delete(`/shop/disclaimer/${id}`).then(async (response) => {
        showNotification(
          response.status,
          "You successfully deleted a disclaimer!"
        );
        if (response.status == 200) context.commit("deleteDisclaimer", id);
      });
    } catch (error) {
      handleHttpError(error);
      throw error;
    }
  },
  // update disclaimer
  async updateDisclaimer(context, data: ShopDisclaimer) {
    const disId = data.id;
    delete data.id;
    try {
      await http
        .put(`/shop/disclaimer/${disId}`, data)
        .then(async (response) => {
          showNotification(
            response.status,
            "You successfully updated a disclaimer!"
          );
          if (response.status == 200)
            context.commit("updateDisclaimer", response.data);
        });
    } catch (error) {
      handleHttpError(error);
      throw error;
    }
  },

  // Tab settings
  // Get active tabs
  async getNavbarItems({ commit }, broadcastId: string | null) {
    try {
      const response = await http.get<Navigation[]>(`/settings/tab`, {
        params: {
          broadcastId,
        },
      });
      if (response.data) commit("setNavbarItems", response.data);
      else
        commit("setNavbarItems", {
          broadcastId: null,
          id: "",
          tabs: [],
          tenantPayload: { name: "", tenantId: "" },
        } as Navigation);
    } catch (error) {
      handleHttpError(error);
      throw error;
    }
  },
  // Add new tab
  async addNavbarItem({ dispatch }, data: NavigationRequest) {
    try {
      const response = await http.put(`/settings/tab`, null, {
        params: data.broadcastId
          ? {
              name: data.name,
              broadcastId: data.broadcastId,
            }
          : {
              name: data.name,
            },
        paramsSerializer: (params: unknown) =>
          qs.stringify(params, { arrayFormat: "repeat" }),
      });
      if (response.status === 200) {
        dispatch("getNavbarItems");
        showNotification(
          response.status,
          "You successfully added a navigation item!"
        );
      }
    } catch (error) {
      handleHttpError(error);
      throw error;
    }
  },
  async createNavbarItem({ commit }, data: NavigationRequest) {
    try {
      const navbarItems = await http
        .post<Navigation[]>(`/settings/tab`, null, {
          params: data.broadcastId
            ? {
                name: data.name,
                broadcastId: data.broadcastId,
              }
            : {
                name: data.name,
              },
          paramsSerializer: (params: unknown) =>
            qs.stringify(params, { arrayFormat: "repeat" }),
        })
        .then((res) => res.data);

      commit("setNavbarItems", navbarItems);
      showNotification(200, "You successfully added a navigation item!");
    } catch (error) {
      handleHttpError(error);
      throw error;
    }
  },
  // Delete tab
  async deleteNavbarItem({ commit }, data: NavigationDeleteRequest) {
    try {
      const navbarItems = await http
        .delete<Navigation[]>(`/settings/tab`, {
          params: data.broadcastId
            ? {
                name: [data.name],
                broadcastId: data.broadcastId,
              }
            : {
                name: [data.name],
              },
          paramsSerializer: (params: unknown) =>
            qs.stringify(params, { arrayFormat: "repeat" }),
        })
        .then((res) => res.data);

      commit("setNavbarItems", navbarItems);
      showNotification(200, "You successfully deleted a navigation item!");
    } catch (error) {
      handleHttpError(error);
      throw error;
    }
  },
};
