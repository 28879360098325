import { Mutation } from "vuex";
import { QAMutation, QAState, Question } from "../types";

export const mutations: Record<QAMutation, Mutation<QAState>> = {
  [QAMutation.SetQuestions](state, questions: Question[]) {
    state.questions = questions;
  },
  [QAMutation.UpdateQuestion](state, question: Question) {
    state.questions = state.questions.map((q) =>
      q.id === question.id ? question : q
    );
  },
  [QAMutation.AddQuestion](state, question: Question) {
    state.questions.push(question);
  },
  [QAMutation.SetLoading](state, loading: boolean) {
    state.loading = loading;
  },
  [QAMutation.SetSelectedBroadcastId](state, broadcastId: string) {
    state.selectedBroadcastId = broadcastId;
  },
  [QAMutation.RemoveQuestion](state, questionId: string) {
    state.questions = state.questions.filter(
      (question) => question.id !== questionId
    );
  },
};
