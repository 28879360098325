import PollService from "@/services/api/secure-votes.service";
import { RootState } from "@/store/RootState";
import { ActionContext, Action } from "vuex";

import { ResultAction, ResultState, ResultMutation } from "../types";

const fetchPollResults = async ({
  commit,
}: ActionContext<ResultState, RootState>): Promise<void> => {
  const results = await PollService.getAllResults();
  commit(ResultMutation.SetResults, results);
};

export const actions: Record<ResultAction, Action<ResultState, RootState>> = {
  [ResultAction.fetchPollResults]: fetchPollResults,
};
