import { RTCSlot } from "@/spect8-core-vue/src/stores/vico/RtcSlot";
import { RootState } from "@/store/RootState";
import { ConferenceState } from "./../types";
import { GetterTree } from "vuex";
import { Conference } from "@/spect8-core-vue/src/types/vico";
import { Question } from "@/components/Qa/types";

export const getters: GetterTree<ConferenceState, RootState> = {
  testConference(state): Conference | null {
    return state.testConference;
  },
  testCall(state): RTCSlot | null {
    return state.testCall;
  },
  setupDialog(state): boolean {
    return state.setupDialog;
  },
  testCallDialog(state): boolean {
    return state.testCallDialog;
  },
  testQuestion(state): Question | null {
    return state.testQuestion;
  },
};
