import { RootState } from "@/store/RootState";
import { Getter } from "vuex";
import { SwitchboardGetter, SwitchboardState } from "../types";

export const getters: Record<
  SwitchboardGetter,
  Getter<SwitchboardState, RootState>
> = {
  [SwitchboardGetter.GetAllTrackInfos](state) {
    return state.trackinfos;
  },
  [SwitchboardGetter.GetAllConferences](state) {
    return state.conferences;
  },
  [SwitchboardGetter.GetActiveConferences]:
    (state) => (selectedBroadcastId: string | undefined) => {
      return state.conferences.filter(
        (conference) => selectedBroadcastId === conference.broadcastId
      );
    },
  [SwitchboardGetter.GetSlotTracks](state) {
    return state.slotTracks;
  },
};
