import { DateTimeFormat, LocaleMessageObject } from "vue-i18n";
import { LocaleOptions } from "./index";

const BaseMessages: LocaleMessageObject = {
  error: {
    emailLogin: "E-mail or password is incorrect!",
    pinLogin: "PIN is incorrect!",
    rolePermission: "Insufficient user role permission",
    sessionExpired: "Session expired!",
  },
  poll: {
    voteError: "Vote failed!",
    voteSuccess: "Vote successfully submitted!",
  },
};

const DateTimeFormats: DateTimeFormat = {
  DateShort: {
    day: "2-digit",
    month: "2-digit",
    year: "2-digit",
  },
  DateTimeShort: {
    day: "2-digit",
    month: "2-digit",
    year: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  },
  DateTimeLong: {
    weekday: "short",
    day: "2-digit",
    month: "long",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  },
  Time: {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  },
  Date: {
    weekday: "long",
    month: "long",
    day: "numeric",
  },
  Time24: {
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  },
};

const EN: LocaleOptions = {
  BaseMessages,
  DateTimeFormats,
};

export default EN;
