import Vue from "vue";
import { Mutation, MutationTree } from "vuex";
import {
  FusionAuthUser,
  User,
  UserMutation,
  UserResponse,
  UserState,
} from "../../types";

export const mutations: MutationTree<UserState> &
  Record<UserMutation, Mutation<UserState>> = {
  [UserMutation.FaUser](state, faUser: FusionAuthUser | null) {
    Vue.set(state, "faUser", faUser);
  },
  [UserMutation.User](state, user: UserResponse | null) {
    if (user === null) {
      Vue.set(state, "user", user);
    } else {
      Vue.set(
        state,
        "user",
        new User(
          user.id,
          user.blockedUsers,
          user.editRequest,
          user.preferredLanguage,
          user.roles,
          user.tenant,
          user.events,
          user.preVotePollIds,
          user.groupIds,
          user.lastLogin,
          user.lastModified,
          user.timeCreated
        )
      );
    }
  },
};
