import { RootState } from "@/store/RootState";
import { Getter, GetterTree } from "vuex";
import { Resource, ResourceGetter, ResourceState } from "../types";

export const getters: GetterTree<ResourceState, RootState> &
  Record<ResourceGetter, Getter<ResourceState, RootState>> = {
  [ResourceGetter.Resources](state): Resource[] {
    return state.resources;
  },
};
