import { MutationTree } from "vuex";
import { Word, WordState } from "./types";

export const mutations: MutationTree<WordState> = {
  setBadWordList(state: WordState, payload: Word[]): void {
    state.allBannedWords = payload;
  },
  deleteBadWord(state, word: string) {
    const i = state.allBannedWords[0].map.all.findIndex((bw) => bw === word);
    if (i >= 0) state.allBannedWords[0].map.all.splice(i, 1);
  },
  setBadWordsError(state: WordState, payload) {
    state.badWordsError = payload;
  },
};

export default mutations;
