import { RegistrationResponse } from "./Registration";

export interface RegistrationState {
  loading: boolean;
  selectedBroadcastId: string;
  representatorsRegistrations: RegistrationResponse[];
  numAvailableVotes: number;
}

export enum RegistrationAction {
  SetSelectedBroadcastId = "SetSelectedBroadcastId",
  SetRepresentatorsRegistrations = "SetRepresentatorsRegistrations",
  FatchAvailableVotes = "FatchAvailableVotes",
  ResetAvailableVotes = "ResetAvailableVotes",
}

export enum RegistrationMutation {
  SetLoading = "SetLoading",
  SetSelectedBroadcastId = "SetSelectedBroadcastId",
  SetRepresentatorsRegistrations = "SetRepresentatorsRegistrations",
  SetAvailableVotes = "SetAvailableVotes",
}

export enum RegistrationGetter {
  SelectedBroadcastId = "SelectedBroadcastId",
  RepresentatorsRegistrations = "RepresentatorsRegistrations",
  GetAvailableVotes = "GetAvailableVotes",
}

export interface AvailableVotesRequest {
  userId: string;
  days: string;
}
export interface AvailableVotes {
  numAvailableVotes: number;
}
