import { RouteConfig } from "vue-router";

import { Feature } from "@/config/types";
import { RouteName } from "./types";

import MainView from "../views/MainView.vue";
import i18n from "@/plugins/i18n/i18n";
import { LOGIN_LANDING_PAGE } from "@/helpers/envConstants";
import { Role } from "@/spect8-core-vue/src/types";

const OverviewList = () => import("../components/OverviewList.vue");

export const routes: Array<RouteConfig> = [
  {
    path: "/",
    component: MainView,
    meta: {
      navIcon: "mdi-view-dashboard",
    },
    children: [
      {
        path: "/",
        name: RouteName.Dashboard,
        // TODO: REMOVE HACKY LANDING PAGE IMPLEMENTATION
        redirect: {
          name: LOGIN_LANDING_PAGE ? LOGIN_LANDING_PAGE : RouteName.General,
        },
      },
      {
        path: "sign-in",
        name: RouteName.SignIn,
        component: () => import("../views/SignIn.vue"),
        meta: {
          authRequired: false,
        },
      },
      // TODO: Do we need invite users view, maybe it was replaced by the accounts view
      // {
      //   path: "invite-users",
      //   name: RouteName.InviteUsers,
      //   component: () => import("../views/InviteUsers.vue"),
      //   meta: {
      //     requiredRoles: [Role.ADMIN, Role.MODERATOR],
      //   },
      // },
    ],
  },
  {
    path: "/general",
    component: MainView,
    meta: {
      navItem: true,
      navIcon: "mdi-domain",
      feature: Feature.GENERAL,
      requiredRoles: [Role.ADMIN, Role.MODERATOR, Role.BROADCAST_MODERATOR],
    },
    children: [
      {
        path: "/general",
        name: RouteName.General,
        component: OverviewList,
        props: {
          routeName: RouteName.General,
        },
      },
      {
        path: "broadcast-manager",
        name: RouteName.BroadcastManager,
        component: () => import("../views/General/BroadcastManager.vue"),
        meta: {
          feature: Feature.BROADCAST_MANAGER,
          overviewPanel: {
            group: () => i18n.t("General"),
            title: () => i18n.t("broadcastEditor.cardTitle"),
            description: () => i18n.t("broadcastEditor.cardDescription"),
          },
        },
      },
      {
        path: "pins",
        name: RouteName.Pins,
        component: () => import("@/views/General/Pins.vue"),
        meta: {
          feature: Feature.PINS,
          overviewPanel: {
            group: () => i18n.t("General"),
            title: () => i18n.t("pins.pageTitle"),
            description: () => i18n.t("pins.pageSubtitle"),
          },
        },
      },
      {
        path: "settings",
        name: RouteName.GeneralSettings,
        component: () => import("../views/General/GeneralSettings.vue"),
        meta: {
          feature: Feature.SETTINGS,
          overviewPanel: {
            group: () => i18n.t("General"),
            title: () => i18n.t("chatSettings.pageTitle"),
            description: () => i18n.t("chatSettings.pageSubtitle"),
          },
        },
      },
    ],
  },
  {
    path: "/interactive",
    component: MainView,
    meta: {
      navIcon: "mdi-layers",
      navItem: true,
      feature: Feature.INTERACTION,
    },
    children: [
      {
        path: "/interactive",
        name: RouteName.Interactive,
        component: OverviewList,
        props: {
          routeName: RouteName.Interactive,
        },
      },
      {
        path: "polls",
        name: RouteName.Polls,
        component: () => import("../views/Interactive/Polls.vue"),
        meta: {
          feature: Feature.POLLS,
          overviewPanel: {
            group: () => i18n.t("Interactive"),
            title: () => i18n.t("polls.header"),
            description: () => i18n.t("polls.subtitle"),
          },
        },
      },
      {
        path: "secure-polls",
        name: RouteName.SecurePolls,
        component: () => import("../views/Interactive/SecurePolls.vue"),
        meta: {
          feature: Feature.SECURE_POLLS,
          overviewPanel: {
            group: () => i18n.t("Interactive"),
            title: () => i18n.t("polls.header"),
            description: () => i18n.t("polls.subtitle"),
          },
        },
      },
      {
        path: "pinned-messages",
        name: RouteName.PinnedMessages,
        component: () => import("../views/Interactive/PinnedMessages.vue"),
        meta: {
          feature: Feature.PINNED_MESSAGES,
          overviewPanel: {
            group: () => i18n.t("Interactive"),
            title: () => i18n.t("pinned.pageTitle"),
            description: () => i18n.t("pinned.pageSubtitle"),
          },
        },
      },
      {
        path: "video-ads",
        name: RouteName.VideoAds,
        component: () => import("../views/Interactive/VideoAds.vue"),
        meta: {
          feature: Feature.VIDEO_ADS,
          requiredRoles: [Role.ADMIN, Role.MODERATOR],
          overviewPanel: {
            group: () => i18n.t("Interactive"),
            title: () => i18n.t("videoAds.pageTitle"),
            description: () => i18n.t("videoAds.pageSubtitle"),
          },
        },
      },
      //   {
      //     path: "notification",
      //     name: RouteName.Notification,
      //     component: () => import("../components/Notification/Notification.vue"),
      //   },
      {
        path: "shop-settings",
        name: RouteName.ShopSettings,
        component: () => import("../views/Interactive/Shop.vue"),
        meta: {
          feature: Feature.SHOP,
          requiredRoles: [Role.ADMIN, Role.MODERATOR],
          overviewPanel: {
            group: () => i18n.t("Interactive"),
            title: () => i18n.t("shop.pageTitle"),
            description: () => i18n.t("shop.pageSubtitle"),
          },
        },
      },
      {
        path: "qa",
        name: RouteName.QaManagement,
        component: () => import("@/views/Interactive/QaManagement.vue"),
        meta: {
          feature: Feature.QA,
          overviewPanel: {
            group: () => i18n.t("Interactive"),
            title: () => i18n.t("qa.pageTitle"),
            description: () => i18n.t("qa.pageSubtitle"),
          },
        },
      },
      {
        path: "resources",
        name: RouteName.Resources,
        component: () => import("@/views/Interactive/Resources.vue"),
        meta: {
          feature: Feature.RESOURCES,
          overviewPanel: {
            group: () => i18n.t("Interactive"),
            title: () => i18n.t("resources.pageTitle"),
            description: () => i18n.t("resources.pageSubtitle"),
          },
        },
      },
      {
        path: "results",
        name: RouteName.PollResults,
        component: () => import("../views/Interactive/PollResults.vue"),
        meta: {
          feature: Feature.POLL_RESULTS,
          overviewPanel: {
            group: () => i18n.t("Interactive"),
            title: () => i18n.t("results.title"),
            description: () => i18n.t("results.description"),
          },
        },
      },
    ],
  },
  {
    path: "/moderation",
    component: MainView,
    meta: {
      navItem: true,
      navIcon: "mdi-shield",
    },
    children: [
      {
        name: RouteName.Moderation,
        path: "/moderation",
        component: OverviewList,
        props: {
          routeName: RouteName.Moderation,
        },
      },
      {
        path: "blocked-terms-and-phrases",
        name: RouteName.BlockedTermsAndPhrases,
        component: () =>
          import("../views/Moderation/BlockedTermsAndPhrases.vue"),
        meta: {
          feature: Feature.BLOCKED_TERMS,
          requiredRoles: [Role.ADMIN, Role.MODERATOR],
          overviewPanel: {
            group: () => i18n.t("Chat settings"),
            title: () => i18n.t("blocked.pageTitle"),
            description: () => i18n.t("blocked.pageSubtitle"),
          },
        },
      },
      {
        path: "accounts",
        name: RouteName.Accounts,
        component: () => import("@/views/Moderation/Accounts.vue"),
        meta: {
          requiredRoles: [Role.ADMIN],
          feature: Feature.ACCOUNT_MANAGEMENT,
          overviewPanel: {
            group: () => i18n.t("User Management"),
            title: () => i18n.t("accounts.pageTitle"),
            description: () => i18n.t("accounts.pageSubtitle"),
          },
        },
      },
      {
        path: "roles",
        name: RouteName.Roles,
        component: () => import("../views/Moderation/Roles.vue"),
        meta: {
          feature: Feature.ROLES,
          requiredRoles: [Role.ADMIN, Role.MODERATOR],
          overviewPanel: {
            group: () => i18n.t("User Management"),
            title: () => i18n.t("roles.pageTitle"),
            description: () => i18n.t("roles.pageSubtitle"),
          },
        },
      },
      {
        path: "banned",
        name: RouteName.Bans,
        component: () => import("../views/Moderation/Bans.vue"),
        meta: {
          feature: Feature.BANNED_CHATTERS,
          overviewPanel: {
            group: () => i18n.t("User Management"),
            title: () => i18n.t("bans.pageTitle"),
            description: () => i18n.t("bans.pageSubtitle"),
          },
        },
      },
      {
        path: "reports",
        name: RouteName.Reports,
        component: () => import("../views/Moderation/Reports.vue"),
        meta: {
          feature: Feature.REPORTS,
          // Temporary Role Restrictions until view supports filtering by broadcast and applying bans/mutes on a broadcast level
          requiredRoles: [Role.ADMIN, Role.MODERATOR],
          overviewPanel: {
            group: () => i18n.t("User Management"),
            title: () => i18n.t("reports.pageTitle"),
            description: () => i18n.t("reports.pageSubtitle"),
          },
        },
      },
    ],
  },
  {
    path: "/schedule",
    component: MainView,
    meta: {
      feature: Feature.SCHEDULE,
    },
    children: [
      {
        path: "/schedule",
        name: RouteName.Schedule,
        component: () => import("../views/Interactive/Schedule.vue"),
      },
      {
        path: "events",
        name: RouteName.ScheduleManagement,
        component: () =>
          import("../components/Schedule/ScheduleManagement.vue"),
      },
    ],
  },
  {
    path: "/monitoring",
    component: MainView,
    meta: {
      navItem: true,
      navIcon: "mdi-chart-timeline-variant-shimmer",
      feature: Feature.MONITORING,
    },
    children: [
      {
        path: "/monitoring",
        name: RouteName.Monitoring,
        component: () => import("../views/Monitoring/Monitoring.vue"),
      },
      {
        path: "overview",
        name: RouteName.MonitoringOverview,
        component: () => import("../views/Monitoring/MonitoringOverview.vue"),
      },
    ],
  },
  // GEMA
  {
    path: "/registrations",
    name: RouteName.Registrations,
    component: () => import("../views/Registrations/Registrations.vue"),
    meta: {
      navIcon: "mdi-account-group",
      navItem: true,
      feature: Feature.GEMA_USER_MANAGEMENT,
      overviewPanel: {
        group: () => i18n.t("User Management"),
        title: () => "Mitgliederdatei Gema MGV",
        description: () => "",
      },
    },
    children: [
      {
        path: "stats",
        name: RouteName.RegistrationStats,
        component: () =>
          import("../views/Registrations/RegistrationStatsView.vue"),
      },
      {
        path: "voting-stats",
        name: RouteName.VotingStats,
        component: () => import("../views/Registrations/VotingStatsView.vue"),
      },
      {
        name: RouteName.RegistrationsSingle,
        path: ":registrationId",
        alias: "new",
        component: () =>
          import("../views/Registrations/RegistrationsSingular.vue"),
        meta: {
          requiredRoles: [Role.ADMIN, Role.MODERATOR],
          excluded: true,
        },
      },
    ],
  },

  {
    path: "/accreditation",
    name: RouteName.Accreditation,
    component: () => import("../views/Accreditation/Accreditation.vue"),
    meta: {
      navIcon: "mdi-account-check",
      navItem: true,
      feature: Feature.GEMA_ACCREDITATION,
    },
  },

  {
    path: "/interactive/secure-polls/:pollId",
    name: RouteName.SecurePoll,
    component: () => import("../views/Interactive/SecurePoll.vue"),
    meta: {
      navVisible: false,
      navItem: false,
      feature: Feature.SECURE_POLLS,
    },
  },

  {
    path: "/switchboard",
    name: RouteName.Switchboard,
    component: () => import("../views/Switchboard/Switchboard.vue"),
    meta: {
      navIcon: "mdi-television-play",
      navItem: true,
      feature: Feature.VICO,
    },
  },

  {
    path: "*",
    redirect: { name: RouteName.Dashboard },
    // TODO: Maybe create a 404 component?
  },
];
