import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import { CoreAuthActions, CoreAuthGetters } from "../types";
import { Store } from "vuex";

export function getAxiosInstance(
  axiosConfig: AxiosRequestConfig,
  store: Store<unknown>
): AxiosInstance {
  const axiosInstance = axios.create(axiosConfig);

  axiosInstance.interceptors.request.use(
    (config) => {
      const tokens = store.getters[CoreAuthGetters.Tokens];
      if (tokens) {
        if (config.headers) {
          config.headers.Authorization = `Bearer ${tokens.access}`;
        } else {
          config.headers = {
            Authorization: `Bearer ${tokens.access}`,
          };
        }
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  axiosInstance.interceptors.response.use(
    (res) => res,
    async (error) => {
      const originalRequest = error.config;
      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        const accessToken: string | null = await store.dispatch(
          CoreAuthActions.Refresh
        );
        if (accessToken !== null) {
          originalRequest.headers["Authorization"] = `Bearer ${accessToken}`;
          return axiosInstance(originalRequest);
        }
      }
      return Promise.reject(error);
    }
  );

  return axiosInstance;
}
