const options = {
  customProperties: true,
};

const dark = false;

const themes = {
  dark: {
    primary: "#fafafa",
    background: "#F8F8F8",
    brand: "#00B5E5",
    secondary: "#cfcfcf",
    tertiary: "#808080",
  },
  light: {
    primary: "#3f51b5",
    secondary: "#ffffff",
    background: "#F8F8F8",
    accent: "#8c9eff",
    error: "#FF4E45",
    info: "#2196F3",
    success: "#4CAF50",
    warning: "#FFC107",
    brand: "#00B5E5",
    disabled: "#ffffff",
    tertiary: "#808080",
  },
};

export { options, dark, themes };
