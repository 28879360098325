import {
  PollGroupSelection,
  PollMemberStatusSelection,
  PollStatusSelection,
  SVPollType,
} from "./../services/api/secure-votes.service.types";
// import { CsvMap } from "@/types";
import { FormRule } from "./form.validators";
import { mimeTypes } from "./consts";
import axios from "axios";
import Vue from "vue";
import i18n from "@/plugins/i18n/i18n";
import {
  PollResultVisibility,
  PollStatus,
} from "@/services/api/poll.service.types";
import { Locale } from "@/spect8-core-vue/src/i18n";
import { Role } from "@/spect8-core-vue/src/types";

export function guessFileType(fileName: string): string {
  const extension = fileName.split(".").pop();

  if (!extension) return "";

  const mimeType = mimeTypes.find((mime) => {
    return mime.extensions.includes(extension);
  });

  return mimeType ? mimeType.fileType : "";
}

export const validate = (value: string, rules: FormRule[]): string | true => {
  for (const rule of rules) {
    const result = rule(value);
    if (typeof result !== "boolean") return result;
  }
  return true;
};

export function csvToArray(str: string): string[][] | null {
  try {
    // Most comprehensive csv regex in the wild but its not perfect... -  https://stackoverflow.com/a/8497474
    const csvStringRegex =
      /^\s*(?:'[^'\\]*(?:\\[\S\s][^'\\]*)*'|"{0,3}[^"\\]*(?:\\[\S\s][^"\\]*)*"{0,3}|[^,'"\s\\]*(?:\s+[^,'"\s\\]+)*)\s*(?:,\s*(?:'[^'\\]*(?:\\[\S\s][^'\\]*)*'|"{0,3}[^"\\]*(?:\\[\S\s][^"\\]*)*"{0,3}|[^,'"\s\\]*(?:\s+[^,'"\s\\]+)*)\s*)*$/;

    // Replace double quotes for validity check. csvToArray handles stacked escaped double quotes but the regex cannot.
    if (!csvStringRegex.test(str.replace(/"/g, ""))) {
      // Return NULL if input string is not well formed CSV string.
      return null;
    }

    // Build a 2d structure of csv row values. [value1, value2, value3...]
    // CSV headers will be row[0];
    let line = [""];
    const ret = [line];
    let quote = false;

    for (let i = 0; i < str.length; i++) {
      const cur = str[i];
      const next = str[i + 1];

      if (!quote) {
        const cellIsEmpty = line[line.length - 1].length === 0;
        if (cur === '"' && cellIsEmpty) quote = true;
        else if (cur === ",") line.push("");
        else if (cur === "\r" && next === "\n") {
          line = [""];
          ret.push(line);
          i++;
        } else if (cur === "\n" || cur === "\r") {
          line = [""];
          ret.push(line);
        } else line[line.length - 1] += cur;
      } else {
        if (cur === '"' && next === '"') {
          line[line.length - 1] += cur;
          i++;
        } else if (cur === '"') quote = false;
        else line[line.length - 1] += cur;
      }
    }

    return ret.length ? ret : null;
  } catch (e) {
    return null;
  }
}

export function showNotification(status: number | undefined, message: string) {
  if (typeof status === "number" && status >= 200 && status < 300)
    Vue.$toast.success(i18n.tc(`${message}`), {
      timeout: 2000,
    });
  else if (typeof status === "number" && status >= 400 && status < 600)
    Vue.$toast.error(i18n.tc(`${message}`), {
      timeout: 2000,
    });
  else
    Vue.$toast.info(i18n.tc(`${message}`), {
      timeout: 2000,
    });
}

type Messages = { [status: number]: string };
export function handleHttpError(error: unknown, messages?: Messages) {
  if (axios.isAxiosError(error) && error.response) {
    if (
      error.config.url?.startsWith("/registrations") &&
      error.response.status === 404
    )
      return;

    showNotification(
      error.response.status,
      messages ? messages[error.response.status] : error.message
    );
  }
}

export function getPollStatusLabel(status: PollStatus): string {
  return i18n.t(`polls.pollStatus.${status.toLowerCase()}`).toString();
}

export function getStatusLabel(status: PollStatusSelection): string {
  return i18n.t(`polls.pollStatusSelected.${status.toLowerCase()}`).toString();
}

export function getPollVisibilityLabel(
  visibility: PollResultVisibility
): string {
  switch (visibility) {
    case PollResultVisibility.LIVE:
      return i18n.t("polls.live").toString();
    case PollResultVisibility.ON_DEMAND:
      return i18n.t("polls.onDemand").toString();
    case PollResultVisibility.ON_POLL_END:
      return i18n.t("polls.onEnd").toString();

    default:
      return visibility;
  }
}

export const formatIsoDateString = (date: Date): string => {
  const day = ("0" + date.getDate()).slice(-2);
  const month = ("0" + (date.getMonth() + 1)).slice(-2);
  const year = date.getFullYear();

  return `${year}-${month}-${day}`;
};

export function getPollTypeSelectionLabel(type: SVPollType): string {
  switch (type) {
    case SVPollType.ELECTION:
      return i18n.t("polls.pollTypeSelected.election").toString();
    case SVPollType.POLL:
      return i18n.t("polls.pollTypeSelected.poll").toString();

    default:
      return type;
  }
}

export function getPollStatusSelectionLabel(
  status: PollStatusSelection
): string {
  switch (status) {
    case PollStatusSelection.LIVE:
      return i18n.t("polls.pollStatusSelected.live").toString();
    case PollStatusSelection.CLOSED:
      return i18n.t("polls.pollStatusSelected.closed").toString();
    case PollStatusSelection.HIDDEN:
      return i18n.t("polls.pollStatusSelected.hidden").toString();
    case PollStatusSelection.PREPARING:
      return i18n.t("polls.pollStatusSelected.preparing").toString();
    case PollStatusSelection.PRE_VOTE:
      return i18n.t("polls.pollStatusSelected.preVote").toString();

    default:
      return status;
  }
}

export function getPollGroupSelectionLabel(status: PollGroupSelection): string {
  switch (status) {
    case PollGroupSelection.K:
      return "K";
    case PollGroupSelection.T:
      return "T";
    case PollGroupSelection.V:
      return "V";
    case PollGroupSelection.ALL:
      return i18n.t("polls.all").toString();

    default:
      return status;
  }
}

export function getMemberStatusSelectionLabel(
  status: PollMemberStatusSelection
): string {
  switch (status) {
    case PollMemberStatusSelection.A:
      return "A";
    case PollMemberStatusSelection.O:
      return "O";
    case PollMemberStatusSelection.ALL:
      return i18n.t("polls.all").toString();

    default:
      return status;
  }
}

export function getRoleLabel(role: Role): string {
  switch (role) {
    case Role.ADMIN:
      return i18n.t("role.admin").toString();
    case Role.BROADCAST_MODERATOR:
      return i18n.t("role.broadcastModerator").toString();
    case Role.MODERATOR:
      return i18n.t("role.moderator").toString();
    case Role.USER:
      return i18n.t("role.user").toString();
    default:
      return role;
  }
}

export function getRoleSelectionItems(roles: Role[]): {
  label: string;
  value: Role;
}[] {
  const items = roles.map((role) => {
    return {
      label: getRoleLabel(role),
      value: role,
    };
  });
  return items;
}

export function getHighestRole(roles: Role[] | null): Role | null {
  if (roles) {
    const sortedRoles = [
      Role.ADMIN,
      Role.MODERATOR,
      Role.BROADCAST_MODERATOR,
      Role.USER,
    ];
    for (let i = 0; i < sortedRoles.length; i++) {
      if (roles.indexOf(sortedRoles[i]) > -1) {
        return sortedRoles[i];
      }
    }
  }
  return null;
}

export function getHighestRoleLabel(roles: Role[] | null): string {
  const highestRole = getHighestRole(roles);
  return highestRole ? getRoleLabel(highestRole) : "";
}

export const formatIsoTimeString = (date: Date): string => {
  let hours = ("0" + date.getHours()).slice(-2);
  const minutes = ("0" + date.getMinutes()).slice(-2);

  if (i18n.locale === Locale.EN) {
    const ampm = date.getHours() <= 12 ? "am" : "pm";
    hours = date.getHours() > 12 ? (date.getHours() - 12).toString() : hours;
    hours = hours.length < 2 ? `0${hours}` : hours;
    return `${hours}:${minutes}${ampm}`;
  }
  return `${hours}:${minutes}`;
};

export function debounce<T extends unknown[], R>(
  func: (...args: T) => PromiseLike<R> | R,
  wait: number
) {
  let timeout: number;
  return (...args: T): Promise<R> => {
    clearTimeout(timeout);
    return new Promise((resolve) => {
      timeout = setTimeout(() => resolve(func(...args)), wait);
    });
  };
}

/**
 * Format bytes as human-readable text.
 *
 * @param bytes Number of bytes.
 * @param si True to use metric (SI) units, aka powers of 1000. False to use
 *           binary (IEC), aka powers of 1024.
 * @param dp Number of decimal places to display.
 *
 * @return Formatted string.
 */
export function humanFileSize(bytes: number, si = false, dp = 1) {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + " B";
  }

  const units = si
    ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (
    Math.round(Math.abs(bytes) * r) / r >= thresh &&
    u < units.length - 1
  );

  return bytes.toFixed(dp) + " " + units[u];
}

// interface createMessageRouterPayloadArgs {
//   broadcastIds?: string[];
//   channelIds?: string[];
//   broadcasts: Broadcast[];
//   channels: Channel[];
// }

// export function createMessageRouterPayload(
//   args: createMessageRouterPayloadArgs
// ): MessageRouterPayload {
//   const broadcasts: Broadcast[] =
//     store.getters[`${BROADCAST_NAMESPACE}/getBroadcasts`];
//   const channels: Channel[] = store.getters[`generalSettings/allChannels`];

//   if (!broadcasts.length || !channels.length) {
//     console.error("No Broadcasts or Channels found.");
//     throw new Error("No Broadcasts or Channels found.");
//   }

//   return {
// 	  tenantWide: false,
// 	  bro
//   }

//   //   const

//   //   console.log(broadcasts, channels);
// }
