import { Result } from "@/components/PollResults/types";
import { AvailableVotes } from "@/components/Registrations/@types/types";
import {
  PresenceLogObject,
  PresenceSendObject,
} from "@/components/SecureVotingPolls/types";
import { http } from "../api.service";
import {
  PollDto,
  Poll,
  UpdatePollDto,
  UpdateStatus,
  PollData,
  VotePresenceResponse,
} from "./secure-votes.service.types";

const SECURE_VOTING_URI = "/secure-voting";
// const POLLS_VOTE_URI = "/pollvotes";

const createPoll = (poll: PollDto): Promise<PollData> =>
  http
    .post<PollData>(SECURE_VOTING_URI + "/questions", poll)
    .then((res) => res.data);

const sendOverlayEvent = (questionId: string): Promise<void> =>
  http
    .post<void>(`${SECURE_VOTING_URI + "/questions"}/${questionId}/results`)
    .then((res) => res.data);

const deletePoll = (pollId: string): Promise<void> =>
  http
    .delete<void>(`${SECURE_VOTING_URI + "/questions"}/${pollId}`)
    .then((res) => res.data);

const updatePoll = (poll: UpdatePollDto): Promise<PollData> =>
  http
    .patch<PollData>(`${SECURE_VOTING_URI + "/questions"}/${poll.id}`, {
      answers: poll.answers,
      broadcastIds: poll.broadcastIds,
      question: poll.question,
      title: poll.title,
      targetMemberStatus: poll.targetMemberStatus,
      targetGroup: poll.targetGroup,
      minVotes: poll.minVotes,
      maxVotes: poll.maxVotes,
    })
    .then((res) => res.data);

const updatePollBroadcasts = (poll: UpdatePollDto): Promise<PollData> =>
  http
    .patch<PollData>(`${SECURE_VOTING_URI + "/questions"}/${poll.id}`, {
      broadcastIds: poll.broadcastIds,
    })
    .then((res) => res.data);

const updateStatus = (status: UpdateStatus): Promise<PollData> =>
  http
    .patch<Poll>(`${SECURE_VOTING_URI + "/questions"}/${status.id}`, {
      status: status.status,
    })
    .then((res) => res.data);

const getAllPolls = (): Promise<Poll[]> =>
  http
    .get<Poll[]>(SECURE_VOTING_URI + "/questions/votes/count")
    .then((res) => res.data);

const getAllResults = (): Promise<Result[]> =>
  http
    .get<Result[]>(SECURE_VOTING_URI + "/votes/grouped/count")
    .then((res) => res.data);

const getUpdatedVotes = (questionId: string): Promise<Poll[]> =>
  http
    .get<Poll[]>(SECURE_VOTING_URI + "/votes/count", {
      params: {
        questionId: questionId,
      },
    })
    .then((res) => res.data);

const getPollById = (pollId: string): Promise<Poll> =>
  http
    .get<Poll>(`${SECURE_VOTING_URI}/questions/${pollId}/votes/count`)
    .then((res) => res.data);

const getQuestions = (userId: string): Promise<boolean> =>
  http
    .get<boolean>(`${SECURE_VOTING_URI}/questions/votes`, {
      params: { userId },
    })
    .then((res) => res.data);

const getVotePresence = (broadcastId: string): Promise<VotePresenceResponse> =>
  http
    .get<VotePresenceResponse>(`${SECURE_VOTING_URI}/presence`, {
      params: { broadcastId },
    })
    .then((res) => res.data);

const fatchAvailableVotes = (
  userId: string,
  days: string
): Promise<AvailableVotes> =>
  http
    .get<AvailableVotes>(
      `${SECURE_VOTING_URI}/available-votes?userId=${userId}&days=${days}`
    )
    .then((res) => res.data);

const fetchPresenceStats = (id: string): Promise<PresenceLogObject> =>
  http
    .post<PresenceLogObject>(`${SECURE_VOTING_URI}/presence/${id}`)
    .then((res) => res.data);

const sendPresence = (presenceSendObject: PresenceSendObject): Promise<void> =>
  http
    .post<void>(SECURE_VOTING_URI + "/presence/send", presenceSendObject)
    .then((res) => res.data);

// const getPollsByBroadcastIds = (
//   tenantId: string,
//   broadcastIds: string[]
// ): Promise<Poll[]> =>
//   http.get<Poll[]>(`${POLLS_URI}/broadcasts`).then(res => res.data);

// const getPollsByChannelIds = (
//   tenantId: string,
//   channelIds: string[]
// ): Promise<Poll[]> =>
//   http.get<Poll[]>(`${POLLS_URI}/channels`).then(res => res.data);

const PollService = {
  createPoll,
  deletePoll,
  updatePoll,
  getAllPolls,
  getPollById,
  updateStatus,
  sendOverlayEvent,
  getUpdatedVotes,
  updatePollBroadcasts,
  getQuestions,
  getVotePresence,
  getAllResults,
  fetchPresenceStats,
  fatchAvailableVotes,
  sendPresence,
  //   getPollsByBroadcastIds,
  //   getPollsByChannelIds,
};

export default PollService;
