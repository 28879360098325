import { Role } from "@/spect8-core-vue/src/types";
import { Namespace } from "@/store/types";
import { BroadcastPayload } from "@/types";

export interface AccountState {
  accounts: Account[];
  editAccount?: Account;
}

export interface Account {
  id: string;
  email: string;
  roles: Role[];
  moderatedBroadcastIds: BroadcastPayload[] | null;
}

export interface AccountActionPayload {
  id?: string;
  email: string;
  roles: Role[];
  broadcastIds?: string[];
}

export enum AccountAction {
  CreateAccount = "CreateAccount",
  SetAccounts = "SetAccounts",
  UpdateAccount = "UpdateAccount",
  DeleteAccount = "DeleteAccount",
  ResetAccounts = "ResetAccounts",
  //Edit Account
  SetEditAccount = "SetEditAccount",
  ClearEditAccount = "ClearEditAccount",
  //   FetchAccountsByBroadcastIds = "FetchAccountsByBroadcastIds",
  //   FetchAccountsByChannelIds = "FetchAccountsByChannelIds"
}

export const AccountActions: Record<AccountAction, string> = {
  CreateAccount: `${Namespace.Accounts}/${AccountAction.CreateAccount}`,
  SetAccounts: `${Namespace.Accounts}/${AccountAction.SetAccounts}`,
  UpdateAccount: `${Namespace.Accounts}/${AccountAction.UpdateAccount}`,
  DeleteAccount: `${Namespace.Accounts}/${AccountAction.DeleteAccount}`,
  ResetAccounts: `${Namespace.Accounts}/${AccountAction.ResetAccounts}`,
  SetEditAccount: `${Namespace.Accounts}/${AccountAction.SetEditAccount}`,
  ClearEditAccount: `${Namespace.Accounts}/${AccountAction.ClearEditAccount}`,
};

export enum AccountMutation {
  SetAccounts = "SetAccounts",
  ResetAccounts = "ResetAccounts",
  AddAccount = "AddAccount",
  DeleteAccount = "DeleteAccount",
  UpdateAccount = "UpdateAccount",
  SetEditAccount = "SetEditAccount",
  ClearEditAccount = "ClearEditAccount",
}

export enum AccountGetter {
  Accounts = "Accounts",
  EditAccount = "EditAccount",
}

export const AccountGetters: Record<AccountGetter, string> = {
  Accounts: `${Namespace.Accounts}/${AccountGetter.Accounts}`,
  EditAccount: `${Namespace.Accounts}/${AccountGetter.EditAccount}`,
};
