import { Getter, GetterTree } from "vuex";
import { RootState } from "@/store/RootState";
import { PollGetter, PollState, Sorting } from "../types";
import { Poll } from "@/services/api/poll.service.types";

export const getters: GetterTree<PollState, RootState> &
  Record<PollGetter, Getter<PollState, RootState>> = {
  [PollGetter.Polls](state): Poll[] {
    return state.polls;
  },
  [PollGetter.ActivePolls](state): Poll[] {
    return state.activePolls;
  },
  [PollGetter.EditPoll](state): Poll | null {
    return state.editPoll;
  },
  [PollGetter.Sorting](state): Sorting {
    return state.sort;
  },
};
