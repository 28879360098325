import { Mutation, MutationTree } from "vuex";
import { Result, ResultMutation, ResultState } from "../types";

export const mutations: MutationTree<ResultState> &
  Record<ResultMutation, Mutation<ResultState>> = {
  [ResultMutation.SetResults](state, polls: Result[]) {
    state.results = polls;
  },
};
export default mutations;
