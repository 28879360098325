import axios, { AxiosInstance } from "axios";
import { SessionAuthResponse } from "../types";

class SessionAuthService {
  private axios: AxiosInstance;

  constructor(axios: AxiosInstance) {
    this.axios = axios;
  }

  async login(
    externalJwt: string,
    isFusionAuthToken: boolean,
    tenantId: string
  ): Promise<SessionAuthResponse> {
    return this.axios
      .post<SessionAuthResponse>("/auth/log-in", null, {
        params: {
          externalJwt: externalJwt,
          isFusionAuthToken: isFusionAuthToken,
          tenantId: tenantId,
        },
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => res.data);
  }

  async revoke(accessToken: string, refreshToken?: string): Promise<number> {
    return this.axios
      .post<number>("/auth/revoke", {
        accessToken: accessToken,
        refreshToken: refreshToken,
      })
      .then((res) => res.status);
  }

  async refresh(refreshToken?: string): Promise<SessionAuthResponse> {
    return this.axios
      .post<SessionAuthResponse>("/auth/refresh", null, {
        params: {
          refreshToken: refreshToken,
        },
      })
      .then((res) => res.data);
  }
}

let sessionAuthService: SessionAuthService;

function initSessionAuthService(baseUrl: string): SessionAuthService {
  if (sessionAuthService) {
    return sessionAuthService;
  }

  const axiosInstance = axios.create({
    baseURL: baseUrl,
    responseType: "json",
  });

  sessionAuthService = new SessionAuthService(axiosInstance);
  return sessionAuthService;
}

export { sessionAuthService, initSessionAuthService };
