import Vue from "vue";
import { Mutation } from "vuex";
import { ActiveNchanClient, NchanMutation, NchanState } from "./types";

export const mutations: Record<NchanMutation, Mutation<NchanState>> = {
  [NchanMutation.SetClient](state, payload: ActiveNchanClient) {
    Vue.set(state.clients, payload.path, payload);
  },
  [NchanMutation.RemoveClient](state, payload: ActiveNchanClient) {
    Vue.delete(state.clients, payload.path);
  },
};
export default mutations;
