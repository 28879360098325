import { RootState } from "@/store/RootState";
import { ActionContext, Action } from "vuex";

import {
  NchanAction,
  ActiveNchanClient,
  NchanClientActionPayload,
  NchanMutation,
  NchanState,
} from "./types";
import { NchanService } from "@/services/nchan-service";
import { NCHAN_API_URL } from "@/helpers/envConstants";
import nchanMessageHandler from "./nchanMessageHandler";

const addClient = async (
  { state, commit }: ActionContext<NchanState, RootState>,
  payload: NchanClientActionPayload
): Promise<void> => {
  let activeClient: ActiveNchanClient = state.clients[payload.nchanUrlPath];

  if (activeClient) {
    if (!activeClient.inUseBy.includes(payload.namespace)) {
      activeClient.inUseBy.push(payload.namespace);
      commit(NchanMutation.SetClient, activeClient);
    }
    return;
  }

  activeClient = {
    inUseBy: [payload.namespace],
    client: new NchanService(
      NCHAN_API_URL,
      payload.nchanUrlPath,
      nchanMessageHandler,
      () => {
        //
      }
    ),
    path: payload.nchanUrlPath,
  };

  commit(NchanMutation.SetClient, activeClient);
};

const removeClient = async (
  { state, commit }: ActionContext<NchanState, RootState>,
  payload: NchanClientActionPayload
): Promise<void> => {
  const activeClient: ActiveNchanClient = state.clients[payload.nchanUrlPath];
  if (!activeClient) return;

  const activeUserIndex = activeClient.inUseBy.indexOf(payload.namespace);

  if (activeUserIndex > -1) {
    activeClient.inUseBy.splice(activeUserIndex, 1);
  }

  if (activeClient.inUseBy.length > 0) {
    commit(NchanMutation.SetClient, activeClient);
  } else {
    activeClient.client.sub.stop();
    commit(NchanMutation.RemoveClient, activeClient);
  }
};

export const actions: Record<NchanAction, Action<NchanState, RootState>> = {
  [NchanAction.AddClient]: addClient,
  [NchanAction.RemoveClient]: removeClient,
};
