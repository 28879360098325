import { UserInfo } from "@/spect8-core-vue/src/types";
import { Permission } from "@/types";

export interface MessageRouterPayload {
  tenantWide: boolean;
  broadcastIds: string[];
  channelIds: string[];
}

export enum PollStatus {
  DRAFT = "DRAFT",
  ACTIVE = "ACTIVE",
  SCHEDULED = "SCHEDULED",
  ENDED = "ENDED",
}

export enum PollResultVisibility {
  LIVE = "LIVE",
  ON_DEMAND = "ON_DEMAND",
  ON_POLL_END = "ON_POLL_END",
}

export enum PollType {
  DEFAULT = "DEFAULT",
  OPEN = "OPEN",
  IMAGE = "IMAGE",
  //   GEMA = "GEMA"
}

export enum PollLocation {
  DEFAULT = "DEFAULT", // Tab Panel
  INLINE = "INLINE", // Tab Panel & Chat Panel - Chat
  //   PINNED = "PINNED" // Tab Panel & Chat Panel - Pinned Messages
}

export interface PollAnswer {
  answerId: string;
  voteCount: number;
  value: string;
  userSubmission: boolean;
  image: PollImage;
  authorDisplayName: string;
}

export interface NewPollAnswerDto {
  text: string;
  image: PollImage;
}

export interface PollDto {
  question: string;
  answers: NewPollAnswerDto[];
  duration: number; // Minutes
  startDate: string;
  endDate: string;
  tenantId: string;
  createdById: string;
  messageRouterPayload: MessageRouterPayload;
  immediate: boolean;
  options: Partial<PollOptions>;
  title: string;
  location: PollLocation;
}

export interface UpdatePollDto {
  id: string;
  question: string;
  answers: PollAnswer[];
  duration: number; // Minutes
  startDate: string;
  endDate: string;
  tenantId: string;
  createdByUserInfo: UserInfo;
  messageRouterPayload: MessageRouterPayload;
  immediate: boolean;
  options: PollOptions;
  title: string;
  location: PollLocation;
}

export interface PollOptions {
  type: PollType;
  maxCharacterCount: number;
  resultVisibility: PollResultVisibility;
  showAuthors: boolean;
  singleWordAnswers: boolean;
  votesPerPerson?: number;
}

export interface Poll {
  id: string;
  question: string;
  answers: PollAnswer[];
  duration: number; // Minutes
  startDate: string;
  endDate: string;
  tenantId: string;
  messageRouterPayload: MessageRouterPayload;
  createdByUserInfo: UserInfo;
  status: PollStatus;
  options: PollOptions;
  image: PollImage;
  title: string;
  permissions?: PollPermissions | null;
  location: PollLocation;
}

export interface PollStartDto {
  id: string;
  startDate: string;
  endDate: string;
  immediate?: boolean;
}

export interface PollExport {
  csvString: string;
}

export type PollImage = {
  upmanId: string;
  publicPath: string;
  fileExtension: string;
  filename: string;
} | null;

export type PollPermissions = Partial<Record<PollPermissionType, Permission[]>>;
enum PollPermissionType {
  READ = "read",
  WRITE = "write",
  VOTE = "vote",
}
