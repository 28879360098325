import { ActionTree } from "vuex";
import { ReportState } from "./types";
import i18n from "@/plugins/i18n/i18n";
import Vue from "vue";
import { handleHttpError } from "@/helpers";
import { RootState } from "@/store/RootState";
import { http } from "@/services/api.service";

export const actions: ActionTree<ReportState, RootState> = {
  async getReportedUsers({ commit }) {
    try {
      const response = await http.get(`/report/all`);
      commit("setReports", response.data);
      return response;
    } catch (error) {
      handleHttpError(error);
      throw error;
    }
  },
  async deleteReportedUser({ commit }, id: string) {
    try {
      const response = await http.delete(`/report/${id}`);
      if (response.status === 200) {
        commit("deleteReport", id);
        Vue.$toast.success(i18n.tc("reports.deleteSuccess"), {
          timeout: 2000,
        });
      }
      return response;
    } catch (error) {
      handleHttpError(error);
      throw error;
    }
  },
};
