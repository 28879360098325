import { state } from "./state";
import { getters } from "./getters";
import { actions } from "./actions";
import { mutations } from "./mutations";
import { GeneralState } from "./types";
import { RootState } from "../RootState";
import { Module } from "vuex";

export const generalSettings: Module<GeneralState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
