import { Module } from "vuex";
import { mutations } from "./mutations";
import { getters } from "./getters";
import { actions } from "./actions";
import { AppState } from "./types";
import { RootState } from "@/store/RootState";
import { DEFAULT_LOCALE } from "@/plugins/i18n/i18n";

export const state: AppState = {
  initialized: false,
  locale: DEFAULT_LOCALE,
  tenantError: false,
};

const namespaced = true;

export const app: Module<AppState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
