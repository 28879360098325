import { Getter, GetterTree } from "vuex";
import { RootState } from "@/store/RootState";
import { Result, ResultGetter, ResultState } from "../types";

export const getters: GetterTree<ResultState, RootState> &
  Record<ResultGetter, Getter<ResultState, RootState>> = {
  [ResultGetter.Results](state): Result[] {
    return state.results;
  },
};
