import axios from "axios";
import qs from "qs";
import {
  ChatSettings,
  ChatSettingsResponse,
  NavigationRequest,
} from "./../store/generalSettings/types";
import { Broadcast } from "@/components/Broadcast/broadcast/types";
import {
  FA_SimpleUserResponse,
  FA_UserPayload,
  FeatureResponse,
} from "./types";
import { API_BASE_URL } from "@/helpers/envConstants";
import { NavigationDto } from "@/store/generalSettings/types";
import { rejectInterceptor, requestInterceptor } from "./interceptors";

// Axios instance to handle all requests to spect8-chat
export const http = axios.create({
  baseURL: API_BASE_URL,
  responseType: "json",
});

// Request interceptors for API calls
http.interceptors.request.use(requestInterceptor);

// Response interceptors for API calls
http.interceptors.response.use(undefined, rejectInterceptor(http));

// Creates or updates a broadcast.
const saveBroadcast = (broadcast: Broadcast): Promise<Broadcast> =>
  http.put<Broadcast>("/broadcast", broadcast).then((res) => res.data);

const deleteBroadcast = (broadcastId: string): Promise<void> =>
  http.delete(`/broadcast/${broadcastId}`);

const startBroadcast = (broadcastId: string): Promise<Broadcast> =>
  http
    .get<Broadcast>(`/broadcast/start/${broadcastId}`)
    .then((res) => res.data);

const endBroadcast = (broadcastId: string): Promise<Broadcast> =>
  http.get<Broadcast>(`/broadcast/end/${broadcastId}`).then((res) => res.data);

const getGroups = (tenantId: string): Promise<Broadcast> =>
  http.get(`/group?tenantId=${tenantId}`).then((res) => res.data);

export const apiService = {
  axios: http,
  // Broadcasts
  saveBroadcast,
  deleteBroadcast,
  startBroadcast,
  endBroadcast,
  getGroups,
};

const updateUser = (
  user: Partial<FA_UserPayload>,
  appId: string
): Promise<FA_SimpleUserResponse> => {
  return http
    .patch<FA_SimpleUserResponse>(`/fa/user/${user.id}`, {
      fusionAuthApplicationId: appId,
      email: user.email,
      roles: user.roles,
      broadcastIds: user.broadcastIds || [],
    })
    .then((res) => res.data);
};

const deleteUser = (userId: string): Promise<void> => {
  return http.delete<void>(`/fa/user/${userId}`).then((res) => res.data);
};

const getUsers = (): Promise<FA_SimpleUserResponse> => {
  return http.get<FA_SimpleUserResponse>(`/fa/user`).then((res) => res.data);
};

const createUser = (
  user: FA_UserPayload,
  appId: string
): Promise<FA_SimpleUserResponse> => {
  return http
    .post<FA_SimpleUserResponse>(`/fa/user`, {
      fusionAuthApplicationId: appId,
      email: user.email,
      roles: user.roles,
      broadcastIds: user.broadcastIds || [],
    })
    .then((res) => res.data);
};
export interface ArchiveFile {
  upmanId: string;
  fileSize: number;
  tags: string[];
  filename: string;
  fileExtension: string;
  mimeType: string;
  publicPath: string;
}

// resource == archiveFile because reasons
const createArchiveFile = (
  file: ArchiveFile,
  broadcastId?: string
): Promise<ArchiveFile> =>
  http
    .post<ArchiveFile>("/file-archive", {
      upManMedia: file,
      broadcastIds: broadcastId ? [broadcastId] : undefined,
    })
    .then((res) => res.data);

const getArchiveFileById = (fileId: string): Promise<ArchiveFile> =>
  http.get<ArchiveFile>(`/file-archive/${fileId}`).then((res) => res.data);

const getArchiveFiles = (broadcastId?: string): Promise<ArchiveFile[]> =>
  http
    .get<ArchiveFile[]>(`/file-archive`, {
      params: {
        broadcastId: broadcastId,
      },
    })
    .then((res) => res.data);

const deleteArchiveFile = (fileId: string): Promise<void> =>
  http.delete<void>(`/file-archive/${fileId}`).then((res) => res.data);

const getEnabledFeatures = (): Promise<FeatureResponse> =>
  http.get(`/settings/feature`).then((res) => res.data);

const logAkkred = (log: Record<string, unknown>): Promise<void> =>
  http.post<void>("/registrations/akkred", log).then((res) => res.data);

const getTabs = (broadcastId?: string): Promise<NavigationDto> =>
  http
    .get<NavigationDto>("/settings/tab", { params: { broadcastId } })
    .then((res) => res.data);

const setTabs = (tabs: NavigationRequest): Promise<NavigationDto> =>
  http
    .post<NavigationDto>("/settings/tab", null, {
      params: tabs.broadcastId
        ? { name: tabs.name, broadcastId: tabs.broadcastId }
        : { name: tabs.name },
      paramsSerializer: (params) =>
        qs.stringify(params, { arrayFormat: "repeat" }),
    })
    .then((res) => res.data);

const getChatSettings = (broadcastId?: string): Promise<ChatSettings> =>
  http
    .get<ChatSettingsResponse>("/settings/chat", { params: { broadcastId } })
    .then((res) => res.data as ChatSettings);

const setChatSettings = (settings: ChatSettings, broadcastId?: string) =>
  http
    .post<ChatSettingsResponse>("/settings/chat", settings, {
      params: { broadcastId },
    })
    .then((res) => res.data as ChatSettings);

export const apiChat = {
  // Broadcasts
  saveBroadcast,
  deleteBroadcast,
  startBroadcast,
  endBroadcast,
  // getUserByEmail,
  // getUserById,
  getUsers,
  deleteUser,
  updateUser,
  createUser,
  createArchiveFile,
  getArchiveFileById,
  getArchiveFiles,
  getEnabledFeatures,
  deleteArchiveFile,
  logAkkred,
  getTabs,
  setTabs,
  getChatSettings,
  setChatSettings,
};
