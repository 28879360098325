import { render, staticRenderFns } from "./Mirror.vue?vue&type=template&id=96c2f35a&scoped=true&"
import script from "./Mirror.vue?vue&type=script&lang=ts&"
export * from "./Mirror.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "96c2f35a",
  null
  
)

export default component.exports