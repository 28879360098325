import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import {
  FusionAuthForgotPasswordResponse,
  FusionAuthLoginResponse,
  FusionAuthRefreshResponse,
} from "../types";

class FusionAuthService {
  private axios: AxiosInstance;

  constructor(axios: AxiosInstance) {
    this.axios = axios;
  }

  async login(
    applicationId: string,
    loginId: string,
    password: string,
    tenantId?: string
  ): Promise<FusionAuthLoginResponse> {
    const requestConfig: AxiosRequestConfig = {};
    if (tenantId) {
      requestConfig.headers = {
        "X-FusionAuth-TenantId": tenantId,
      };
    }
    return this.axios
      .post<FusionAuthLoginResponse>(
        "/api/login",
        {
          applicationId: applicationId,
          loginId: loginId,
          password: password,
        },
        requestConfig
      )
      .then((res) => res.data);
  }

  async logout(tenantId: string): Promise<number> {
    return this.axios
      .post<number>("/api/logout", null, {
        headers: {
          "X-FusionAuth-TenantId": tenantId,
        },
      })
      .then((res) => res.status);
  }

  async refresh(tenantId: string): Promise<FusionAuthRefreshResponse> {
    return this.axios
      .post<FusionAuthRefreshResponse>("/api/jwt/refresh", null, {
        headers: {
          "X-FusionAuth-TenantId": tenantId,
        },
      })
      .then((res) => res.data);
  }

  // A JSON response body will be provided when authenticated using an API key
  async forgotPassword(
    applicationId: string,
    loginId: string,
    tenantId?: string
  ): Promise<FusionAuthForgotPasswordResponse> {
    const requestConfig: AxiosRequestConfig = {};
    if (tenantId) {
      requestConfig.headers = {
        "X-FusionAuth-TenantId": tenantId,
      };
    }
    return this.axios
      .post<FusionAuthForgotPasswordResponse>(
        "/api/user/forgot-password",
        {
          applicationId: applicationId,
          loginId: loginId,
        },
        requestConfig
      )
      .then((res) => res.data);
  }
}

let fusionAuthService: FusionAuthService;

function initFusionAuthService(baseUrl: string): FusionAuthService {
  if (fusionAuthService) {
    return fusionAuthService;
  }

  const axiosInstance = axios.create({
    baseURL: baseUrl,
    responseType: "json",
    withCredentials: true,
  });

  fusionAuthService = new FusionAuthService(axiosInstance);
  return fusionAuthService;
}

export { fusionAuthService, initFusionAuthService };
