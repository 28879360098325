import DE from "./locales/de";
import EN from "./locales/en";
import VueI18n, { LocaleMessages } from "vue-i18n";
import { VuetifyI18n } from "./locales/$vuetify";
import Vue from "vue";
import {
  coreBaseMessages,
  coreDateTimeFormats,
  Locale,
  mergeI18nMessages,
} from "@/spect8-core-vue/src/i18n";

Vue.use(VueI18n);
EN.$vuetify = Object.assign(VuetifyI18n.en, EN.$vuetify);
DE.$vuetify = Object.assign(VuetifyI18n.de, DE.$vuetify);

const baseMessages: LocaleMessages = {
  [Locale.DE]: DE,
  [Locale.EN]: EN,
};

export const DEFAULT_LOCALE =
  process.env.VUE_APP_I18N_LOCALE ||
  navigator.language.split("-")[0] ||
  Locale.EN;
const FALLBACK_LOCALE = process.env.VUE_APP_I18N_FALLBACK_LOCALE || Locale.EN;

const i18n = new VueI18n({
  locale: DEFAULT_LOCALE,
  fallbackLocale: FALLBACK_LOCALE,
  messages: coreBaseMessages,
  dateTimeFormats: coreDateTimeFormats,
});

mergeI18nMessages(i18n, baseMessages);

export default i18n;
