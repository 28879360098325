import { Store } from "vuex";
import { trackingService } from "../services/tracking";
import { user } from "../stores/user";
import { CORE_USER_NAMESPACE } from "../types";
import { Logger } from "../utils/Logger";

function initUserModule(store: Store<unknown>): void {
  // user module dependencies
  const dependencies: string[] = [];

  // check dependencies
  dependencies.forEach((namespace: string) => {
    if (!store.hasModule(namespace)) {
      throw new Error(`UserModule - store module ${namespace} is missing`);
    }
  });

  // register core user store
  if (!store.hasModule(CORE_USER_NAMESPACE)) {
    store.registerModule(CORE_USER_NAMESPACE, user);
  }

  // check if tracking service instance is available
  if (!trackingService) {
    Logger.info("UserModule - tracking service not available");
  }
}

export { initUserModule };
