import AnonU from "@/services/api/anonu.service";
import { PinRequest } from "@/services/api/anonu.types";
import { CoreConfigGetters } from "@/spect8-core-vue/src/types";
import { RootState } from "@/store/RootState";
import { ActionTree } from "vuex";
import { Pin, PinActionPayload, PinsState } from "./types";

export const actions: ActionTree<PinsState, RootState> = {
  async fetchPins({ commit, rootGetters }): Promise<void> {
    const { tenantId } = rootGetters[CoreConfigGetters.TenantConfig];
    const pins = await AnonU.fetchPins(tenantId);
    commit("setPins", pins);
    commit("setLoaded", true);
  },

  async deletePin({ commit, rootGetters }, pin: Pin): Promise<void> {
    const { tenantId } = rootGetters[CoreConfigGetters.TenantConfig];
    await AnonU.deletePin(pin.id, tenantId);
    commit("deletePin", pin.id);
  },

  async addPin({ commit, rootGetters }, pin: PinActionPayload): Promise<void> {
    const { tenantId } = rootGetters[CoreConfigGetters.TenantConfig];
    if (!tenantId) return Promise.resolve();
    const pr: PinRequest = {
      id: pin.id,
      key: pin.key,
      pin_type: pin.pinType,
      tenant_id: tenantId,
      broadcast_ids: pin.broadcasts,
      expires_at: new Date(pin.expiresAt).toISOString(),
      role: pin.role,
    };
    const pins = await AnonU.createPin(pr);
    commit("addPin", pins[0]);
    commit("clearEdit");
  },

  async updatePin(
    { commit, rootGetters },
    pin: PinActionPayload
  ): Promise<void> {
    const { tenantId } = rootGetters[CoreConfigGetters.TenantConfig];
    if (!tenantId || !pin.id) return Promise.resolve();
    const pr: PinRequest = {
      ...pin,
      pin_type: pin.pinType,
      tenant_id: tenantId,
      broadcast_ids: pin.broadcasts,
      expires_at: new Date(pin.expiresAt).toISOString(),
    };
    const pins = await AnonU.updatePin(pr, tenantId);
    commit("updatePin", pins[0]);
    commit("clearEdit");
  },

  isUnique({ rootGetters }, key: string): Promise<boolean> {
    const { tenantId } = rootGetters[CoreConfigGetters.TenantConfig];
    return AnonU.isUnique(key, tenantId).then((res) => res.isUnique);
  },

  setEdit(context, pin: Pin): void {
    context.commit("setEdit", pin);
    window.scrollTo(0, 0);
  },

  clearEdit(context): void {
    context.commit("clearEdit");
  },
};
