import { Poll, PollStatus } from "@/services/api/poll.service.types";
import { Mutation, MutationTree } from "vuex";
import { PollMutation, PollState, Sorting } from "../types";

export const mutations: MutationTree<PollState> &
  Record<PollMutation, Mutation<PollState>> = {
  [PollMutation.SetPolls](state, polls: Poll[]) {
    state.polls = polls;
    state.activePolls = polls.filter((p: Poll) => {
      return p.status === PollStatus.ACTIVE;
    });
  },
  [PollMutation.ResetPolls](state) {
    state.polls = [];
    state.activePolls = [];
  },
  [PollMutation.AddPoll](state, poll: Poll) {
    if (state.polls.findIndex((p: Poll) => p.id === poll.id) < 0) {
      state.polls.push(poll);
      if (poll.status === PollStatus.ACTIVE) {
        state.activePolls.push(poll);
      }
    }
  },
  [PollMutation.DeletePoll](state, pollId: string) {
    state.polls = state.polls.filter((p) => p.id !== pollId);
    state.activePolls = state.activePolls.filter((p) => p.id !== pollId);
  },
  [PollMutation.UpdatePoll](state, poll: Poll) {
    state.polls = state.polls.map((p) => {
      return p.id === poll.id ? poll : p;
    });
    state.activePolls = state.polls.map((p) => {
      return p.id === poll.id ? poll : p;
    });
  },
  [PollMutation.SetEditPoll](state, poll: Poll) {
    state.editPoll = poll;
  },
  [PollMutation.ClearEditPoll](state) {
    state.editPoll = null;
  },
  [PollMutation.SetSorting](state, sort: Sorting) {
    state.sort = sort;
  },
};
export default mutations;
