import {
  ConnectionState,
  MediaDevicesGetter,
  MediaDevicesState,
} from "./../../types/mediaDevices";
import { Getter, GetterTree } from "vuex";

export const getters: GetterTree<MediaDevicesState, unknown> &
  Record<MediaDevicesGetter, Getter<MediaDevicesState, unknown>> = {
  [MediaDevicesGetter.AudioDevices](state): MediaDeviceInfo[] {
    return (
      state?.storedDeviceList?.filter(
        (device) => device.kind === "audioinput"
      ) || []
    );
  },
  [MediaDevicesGetter.VideoDevices](state): MediaDeviceInfo[] {
    return (
      state?.storedDeviceList?.filter(
        (device) => device.kind === "videoinput"
      ) || []
    );
  },
  [MediaDevicesGetter.AudioSelected](state): MediaDeviceInfo | undefined {
    return state.audioSelected;
  },
  [MediaDevicesGetter.VideoSelected](state): MediaDeviceInfo | undefined {
    return state.videoSelected;
  },
  [MediaDevicesGetter.Stream](state): MediaStream | undefined {
    return state.stream;
  },
  [MediaDevicesGetter.BroadcastActive](state): boolean {
    return state.broadcastActive;
  },
  [MediaDevicesGetter.ConnectionState](state): ConnectionState {
    return state.connectionState;
  },
};
