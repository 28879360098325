import { Feature, Features } from "./../config/types";

type AppId = string;
export type FeatureMap = Record<AppId, Features>;

// placeholder until we build a better feature/config system
export function getFeatures(appId: string): Feature[] {
  const features: Feature[] = [];
  switch (appId) {
    // GEMA
    case "7d2f6089-76ea-490f-a7bd-2f3b3ddbf6a5": // GEMA
    case "c10b894b-4c18-4eea-b5e8-5cde3bdb874c":
      features.push(
        Feature.INTERACTION,
        Feature.GEMA_USER_MANAGEMENT,
        Feature.SECURE_POLLS,
        Feature.QA,
        Feature.GEMA_QA_MANAGEMENT,
        Feature.GEMA_ACCREDITATION,
        Feature.POLL_RESULTS,
        Feature.GENERAL,
        Feature.VICO
      );
      break;
    case "f34c4c5b-e568-41ec-93ca-3387d2470d85": // GIZ
    case "e6cdf2ae-3c99-4e26-ab89-555a69f0b71d":
    case "b76ec110-5e8c-427b-b144-c3190248f818":
      features.push(
        Feature.GENERAL,
        Feature.INTERACTION,
        Feature.POLLS,
        Feature.PINNED_MESSAGES,
        Feature.VIDEO_ADS,
        Feature.QA,
        Feature.SETTINGS,
        Feature.PINS,
        Feature.BROADCAST_MANAGER,
        Feature.BANNED_CHATTERS,
        Feature.BLOCKED_TERMS,
        Feature.MUTED_CHATTERS,
        Feature.REPORTS,
        Feature.ROLES,
        Feature.SCHEDULE,
        Feature.MONITORING,
        Feature.ACCOUNT_MANAGEMENT,
        Feature.RESOURCES
      );
      break;
    case "d37293cf-384e-4294-8837-9d7d7daf0829": // Demo
    case "4c3a0ce6-93cf-46e1-a71d-b6f4ed1d8a40":
    case "6e90330b-0e96-4bcc-bf0f-f028971c3001":
    case "8dfce598-0572-46bf-bbd8-ee7f74702ebd": // SportDeutschland
    case "43739023-3ba9-483d-bf15-5269d578eec3":
      features.push(
        Feature.GENERAL,
        Feature.INTERACTION,
        Feature.POLLS,
        Feature.PINNED_MESSAGES,
        Feature.VIDEO_ADS,
        Feature.QA,
        Feature.SETTINGS,
        Feature.PINS,
        Feature.BROADCAST_MANAGER,
        Feature.BANNED_CHATTERS,
        Feature.BLOCKED_TERMS,
        Feature.MUTED_CHATTERS,
        Feature.REPORTS,
        Feature.ROLES,
        Feature.SCHEDULE,
        Feature.MONITORING,
        Feature.ACCOUNT_MANAGEMENT,
        Feature.RESOURCES,
        Feature.SHOP,
        Feature.LOYALTY
      );
      break;
    default:
      break;
  }

  return features;
}
