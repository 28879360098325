import Vue from "vue";
import { Mutation, MutationTree } from "vuex";
import { Account, AccountMutation, AccountState } from "../types";

export const mutations: MutationTree<AccountState> &
  Record<AccountMutation, Mutation<AccountState>> = {
  [AccountMutation.AddAccount](state, account: Account) {
    state.accounts.push(account);
  },
  [AccountMutation.SetAccounts](state, accounts: Account[]): void {
    Vue.set(state, "accounts", accounts);
  },
  [AccountMutation.ResetAccounts](state) {
    Vue.set(state, "accounts", undefined);
  },
  [AccountMutation.DeleteAccount](state, accountId: string) {
    state.accounts = state.accounts.filter((a) => a.id !== accountId);
  },
  [AccountMutation.UpdateAccount](state, account: Account) {
    state.accounts = state.accounts.map((a) =>
      a.id === account.id ? account : a
    );
    // const idx = state.accounts.indexOf(account);
    // Vue.set(state.accounts, idx, account);
  },
  [AccountMutation.SetEditAccount](state, account: Account) {
    Vue.set(state, "editAccount", account);
  },
  [AccountMutation.ClearEditAccount](state) {
    Vue.set(state, "editAccount", undefined);
  },
};
