import { getters } from "./getters";
import { actions } from "./actions";
import { mutations } from "./mutations";
import { RootState } from "@/store/RootState";
import { Module } from "vuex";
import { ResultState } from "../types";

export const state: ResultState = {
  results: [],
};

export const pollResults: Module<ResultState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
