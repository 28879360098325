import { QANchanEvents } from "@/components/Qa/types";
import store from "@/store";
import { Namespace } from "../types";
import { NchanMessage } from "./types";

const getDispatchMethodName = (
  namespace: Namespace | null,
  methodName: string
): string => {
  return namespace ? `${namespace}/${methodName}` : methodName;
};

export default (message: string) => {
  const { dispatch } = store;
  const nchanMessage: NchanMessage = JSON.parse(message);
  const messageType = nchanMessage.type || nchanMessage.payload["@type"];
  const messagePayload: unknown | null = nchanMessage.payload || null;

  //   console.log(messageType, { messagePayload });

  if (!messageType) {
    console.warn(
      "Unhandled message, could not identify message type",
      nchanMessage
    );
    return;
  }

  if (messagePayload) {
    switch (true) {
      case Object.keys(QANchanEvents).includes(messageType):
        return dispatch(
          getDispatchMethodName(Namespace.Qa, QANchanEvents[messageType]),
          messagePayload,
          { root: true }
        );
    }
  }
};
