import { UpManGeneralFile } from "@/services/api/upman.service.types";
import { initStoreEnum } from "@/spect8-core-vue/src/utils/StoreHelpers";
import { Namespace } from "@/store/types";
import { Permission } from "@/types";

export interface BroadcastState {
  allBroadcasts: Broadcast[] | null;
  broadcasts: Broadcast[];
  loading: boolean;
}

export interface Broadcast {
  id: string;
  tenantId: string;
  broadcastName: string;
  description: string;
  agenda: string;
  startDate: number | null;
  endDate: number | null;
  deleteDate: number | null;
  status: BroadcastStatus;
  videoSources: string[];
  images: BroadcastImages;
  permissions?: BroadcastPermissions | null;
  data?: unknown;
}

export enum BroadcastStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  SCHEDULED = "SCHEDULED",
  DELETED = "DELETED",
}

export interface BroadcastImages {
  thumbnail: UpManGeneralFile | null;
  background: UpManGeneralFile | null;
  logo: UpManGeneralFile | null;
}

export type BroadcastPermissions = Partial<
  Record<BroadcastPermissionType, Permission[]>
>;
enum BroadcastPermissionType {
  READ = "read",
  WRITE = "write",
}

export enum BroadcastAction {
  GetAllBroadcasts = "GetAllBroadcasts",
  GetBroadcasts = "GetBroadcasts",
  RemoveBroadcast = "RemoveBroadcast",
  UpdateBroadcast = "UpdateBroadcast",
}

type BroadcastActionStrings = keyof typeof BroadcastAction;
export const BroadcastActions: Record<BroadcastActionStrings, string> =
  initStoreEnum<BroadcastActionStrings>(BroadcastAction, Namespace.Broadcast);

export enum BroadcastMutation {
  RemoveBroadcast = "RemoveBroadcast",
  SetAllBroadcasts = "SetAllBroadcasts",
  SetBroadcasts = "SetBroadcasts",
  SetLoading = "SetLoading",
  UpdateBroadcast = "UpdateBroadcast",
}

export enum BroadcastGetter {
  AllBroadcasts = "AllBroadcasts",
  Broadcasts = "Broadcasts",
  Loading = "Loading",
}

type BroadcastGetterStrings = keyof typeof BroadcastGetter;
export const BroadcastGetters: Record<BroadcastGetterStrings, string> =
  initStoreEnum<BroadcastGetterStrings>(BroadcastGetter, Namespace.Broadcast);
