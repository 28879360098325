import { getters } from "./getters";
import { Module } from "vuex";
import { SwitchboardState } from "../types";
import { actions } from "./actions";
import { mutations } from "./mutations";
import { RootState } from "@/store/RootState";

export const defaultState: SwitchboardState = {
  trackinfos: [],
  conferences: [],
  slotTracks: [],
};

export const switchboard: Module<SwitchboardState, RootState> = {
  namespaced: true,
  state: defaultState,
  getters,
  actions,
  mutations,
};
