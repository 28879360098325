import { Action, ActionTree } from "vuex";
import { coreApiService } from "../../services/core";
import { trackingService } from "../../services/tracking";
import {
  ThemeAction,
  ThemeConfig,
  ThemeMutation,
  ThemeState,
} from "../../types";
import { Logger } from "../../utils/Logger";

export const actions: ActionTree<ThemeState, unknown> &
  Record<ThemeAction, Action<ThemeState, unknown>> = {
  async [ThemeAction.FetchTheme](
    context,
    tenantId: string
  ): Promise<ThemeConfig | null> {
    try {
      const theme = await coreApiService.getThemeConfig(tenantId);
      context.commit(ThemeMutation.Theme, theme);
      Logger.debug("Set Theme success", theme);
      return theme;
    } catch (error) {
      Logger.debug("Set Theme error", error);
      trackingService?.logError({
        ea: "error",
        ec: "themeConfig",
        ed: error,
      });
    }
    return null;
  },
};
