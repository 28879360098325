import { RootState } from "@/store/RootState";
import { Getter } from "vuex";
import { GemaMemberSearchGetter, GemaMemberSearchState } from "./types";

export const getters: Record<
  GemaMemberSearchGetter,
  Getter<GemaMemberSearchState, RootState>
> = {
  [GemaMemberSearchGetter.Results](state) {
    return state.results;
  },
  [GemaMemberSearchGetter.SearchTerm](state) {
    return state.searchTerm;
  },
};
