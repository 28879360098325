import { MutationTree } from "vuex";
import { Report, ReportState } from "./types";

export const mutations: MutationTree<ReportState> = {
  setReports(state, value: Report[]) {
    state.reports = value;
  },
  deleteReport(state, id: string) {
    const i = state.reports.findIndex((r: Report) => r.id === id);
    if (i >= 0) state.reports.splice(i, 1);
  },
};

export default mutations;
