























import { Vue, Component, Prop } from "vue-property-decorator";

@Component({})
export default class NavBarSubMenu extends Vue {
  @Prop({ required: true }) label!: string;
  @Prop({ required: true }) active!: boolean;
  @Prop() selectedItem: unknown | undefined;
}
