import Vue from "vue";
import VueRouter, { RouteConfig, RouteRecord } from "vue-router";
import { routes } from "@/router/routes";
import { ConfigGetters, Feature, Features } from "@/config/types";
import store from "@/store";
import { RouteName } from "./types";
import { LOGIN_LANDING_PAGE } from "@/helpers/envConstants";
import { AppGetters } from "@/store/app/types";
import {
  CoreAuthGetters,
  FusionAuthUser,
  User,
  CoreUserGetters,
} from "@/spect8-core-vue/src/types";

Vue.use(VueRouter);

export function routeDisabled(
  route: RouteConfig | RouteRecord,
  parentRoute?: RouteConfig | RouteRecord
): boolean {
  return !routeEnabled(route, parentRoute);
}

export function routeEnabled(
  route: RouteConfig | RouteRecord,
  parentRoute?: RouteConfig | RouteRecord
): boolean {
  const enabledFeatures: Features =
    store.getters[ConfigGetters.EnabledFeatures];
  const user: User | null = store.getters[CoreUserGetters.User];

  if (user) {
    // TODO: remove after GEMA (specific check)
    const faUser: FusionAuthUser | null = store.getters[CoreUserGetters.FaUser];
    const faUsername = faUser?.username;
    if (
      faUsername &&
      faUsername.startsWith("akkred-") &&
      route.name !== RouteName.Accreditation
    )
      return false;

    if (
      faUsername &&
      faUsername.startsWith("va-") &&
      (route.name === RouteName.BroadcastManager ||
        route.name === RouteName.General)
    )
      return true;

    if (
      parentRoute &&
      parentRoute.meta?.requiredRoles &&
      !parentRoute.meta.requiredRoles.some((role) => user.roles.includes(role))
    )
      return false;

    if (
      route.meta?.requiredRoles &&
      !route.meta.requiredRoles.some((role) => user.roles.includes(role))
    )
      return false;
  }

  if (enabledFeatures) {
    if (route.meta?.feature && !enabledFeatures[route.meta.feature as Feature])
      return false;
  }

  return true;
}

const pathnameArray = window.location.pathname.split("/");
const router = new VueRouter({
  mode: "history",
  base: pathnameArray.length > 1 ? pathnameArray[1] : "",
  routes,
});

async function waitUntilInitialized(): Promise<void> {
  return new Promise((resolve) => {
    const unwatch = store.watch(
      (_, getters) => getters[AppGetters.Initialized],
      () => {
        unwatch();
        resolve();
      }
    );
  });
}

function findFirstAlternateRoute() {
  return routes.find(
    (route) => route.meta && route.meta.navItem && routeEnabled(route)
  );
}

router.beforeEach(async (to, from, next) => {
  const initialized: boolean = store.getters[AppGetters.Initialized];
  if (!initialized) {
    await waitUntilInitialized();
  }

  const currentRouteRecord = to.matched[to.matched.length - 1];
  const authRequired = to.matched.every(
    (match) => match.meta?.authRequired !== false
  );
  const isAuthenticated = store.getters[CoreAuthGetters.Authenticated];

  // Redirect to sign-in for restricted routes where the user is not authorised.
  if (
    !isAuthenticated &&
    authRequired &&
    currentRouteRecord.name !== RouteName.SignIn
  ) {
    return next({ name: RouteName.SignIn });
  }

  // Redirect to dashboard if the user is authenticated
  if (isAuthenticated && currentRouteRecord.name === RouteName.SignIn) {
    return next({
      name: LOGIN_LANDING_PAGE ? LOGIN_LANDING_PAGE : RouteName.Dashboard,
    });
  }

  // find alternative route if the current is disabled
  if (routeDisabled(currentRouteRecord, currentRouteRecord.parent)) {
    return next(findFirstAlternateRoute());
  }

  return next();
});

export default router;
