













































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { RouteName } from "@/router/types";
import { NavItem } from "./types";
import { routes } from "@/router/routes";
import { Getter } from "vuex-class";
import { ConfigGetters, Features } from "@/config/types";
import { routeDisabled } from "@/router";

@Component
export default class Navigation extends Vue {
  @Getter(ConfigGetters.EnabledFeatures)
  readonly enabledFeatures!: Features;

  @Prop({ default: false }) expanded!: boolean;

  RouteName = RouteName;
  loading = true;
  navItems: NavItem[] = [];

  created() {
    this.navItems = this.getNavItems();

    if (this.$route?.name) {
      this.setActiveNavItems();
    }
  }

  @Watch("$route")
  setActiveNavItems() {
    for (const navItem of this.navItems) {
      for (const child of navItem.children) {
        child.active = this.$route.name === child.routeName;
      }

      navItem.active = navItem.active || this.isActiveNavElement(navItem);
    }

    this.loading = false;
  }

  isActiveNavElement(navItem: NavItem): boolean {
    return (
      this.$route.name === navItem.routeName ||
      navItem.children.some((child) => child.active)
    );
  }

  getNavItems(): NavItem[] {
    return routes
      .map((route) => {
        if (!route.meta?.navItem) return;

        // Remove top-level routes which are not enabledFeatures
        if (routeDisabled(route)) return;

        let routeName = route.name || "";
        const children = [];

        if (route.children) {
          for (const childRoute of route.children) {
            if (childRoute?.meta?.excluded) continue;
            // Remove child routes which are not enabledFeatures
            if (routeDisabled(childRoute, route)) continue;

            if (childRoute.path === route.path) {
              routeName = routeName || childRoute.name || "";
              continue;
            }

            children.push({
              routeName: childRoute.name ?? "",
              active: false,
            });
          }

          // Remove top-level routes which have children but none are active. The top-level route is an overview for nothing.
          if (!children.length) {
            const excludedChildren = route.children.filter(
              (child) => child.meta?.excluded
            ).length;
            // Only exclude Top level routes where no children are active and all were excluded by default
            if (route.children.length !== excludedChildren) return;
          }
        }

        return {
          routeName,
          icon: route.meta?.navIcon || "",
          active: false,
          children,
        };
      })
      .filter((item) => item && item.routeName) as NavItem[];
  }
}
