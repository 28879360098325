import { handleHttpError } from "@/helpers";
import { apiChat } from "@/services/api.service";
import UpMan from "@/services/api/upman.service";
import { RootState } from "@/store/RootState";
import { Action, ActionContext, ActionTree } from "vuex";
import {
  ResourceAction,
  ResourceCreateActionPayload,
  ResourceMutation,
  ResourceState,
  ResourceUploadCreateActionPayload,
} from "../types";

const createResource = (
  context: ActionContext<ResourceState, RootState>,
  resource: ResourceCreateActionPayload
) =>
  apiChat
    .createArchiveFile(
      {
        fileExtension: resource.fileExtension,
        fileSize: resource.fileSize,
        filename: resource.filename,
        mimeType: resource.mimeType,
        tags: resource.tags,
        upmanId: resource.upmanId,
        publicPath: resource.publicPath,
      },
      resource.broadcastId
    )
    .then((res) => context.commit(ResourceMutation.AddResource, res))
    .catch((err) => {
      handleHttpError(err);
      throw err;
    });

const uploadAndCreateResource = (
  context: ActionContext<ResourceState, RootState>,
  payload: ResourceUploadCreateActionPayload
) =>
  UpMan.uploadFile(payload.file)
    .then((res) =>
      context.dispatch(ResourceAction.CreateResource, {
        ...res,
        mimeType: payload.file.nfile.type,
        broadcastId: payload.broadcastId,
      })
    )
    .catch((err) => {
      handleHttpError(err);
      throw err;
    });

const deleteResource = async (
  context: ActionContext<ResourceState, RootState>,
  resourceId: string
) => {
  return apiChat
    .deleteArchiveFile(resourceId)
    .then(() => context.commit(ResourceMutation.DeleteResource, resourceId))
    .catch((err) => {
      handleHttpError(err);
      throw err;
    });
};

const setResources = async (
  { commit }: ActionContext<ResourceState, RootState>,
  broadcastId?: string
): Promise<void> => {
  const resources = await apiChat.getArchiveFiles(broadcastId);
  commit(ResourceMutation.SetResources, resources);
};

const resetResources = async ({
  commit,
}: ActionContext<ResourceState, RootState>): Promise<void> => {
  commit(ResourceMutation.ResetResources);
};

export const actions: ActionTree<ResourceState, RootState> &
  Record<ResourceAction, Action<ResourceState, RootState>> = {
  [ResourceAction.CreateResource]: createResource,
  [ResourceAction.UploadAndCreateResource]: uploadAndCreateResource,
  [ResourceAction.DeleteResource]: deleteResource,
  [ResourceAction.SetResources]: setResources,
  [ResourceAction.ResetResources]: resetResources,
};
