import { Module } from "vuex";
import { actions } from "./actions";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { MediaDevicesState } from "../../types";

// ReExport to be backward compatible
export { CORE_MEDIA_DEVICES_NAMESPACE } from "../../types";

export const state: MediaDevicesState = {
  devicesLiveMap: {},
  devicesAccessibleMap: {},
  kindsAccessibleMap: {},
  storedDeviceList: [],
  canExposeCameraInfo: false,
  canExposeMicrophoneInfo: false,
  broadcastActive: false,
  // TODO: move this to conf
  connectionState: 0,
};

const namespaced = true;

export const mediaDevices: Module<MediaDevicesState, unknown> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
