import { MimeType } from "@/types";

export const mimeTypes: MimeType[] = [
  {
    fileType: "text/csv",
    extensions: ["csv"],
  },
  {
    fileType: "application/vnd.ms-excel",
    extensions: ["xls", "xlm", "xla", "xlc", "xlt", "xlw", "xlsx"],
  },
  {
    fileType: "text/plain",
    extensions: ["txt", "text", "conf", "def", "list", "log", "in", "ini"],
  },
];

export const MINUTE_IN_MS = 1000 * 60;
export const HOUR_IN_MS = MINUTE_IN_MS * 60;
export const DAY_IN_MS = HOUR_IN_MS * 24;
export const YEAR_IN_MS = DAY_IN_MS * 365;

export const HOUR_IN_SECONDS = 3600;
export const MINUTE_IN_SECONDS = 60;

export const HOUR_IN_MINUTES = 60;
export const DAY_IN_MINUTES = HOUR_IN_MINUTES * 24;
export const WEEK_IN_MINUTES = DAY_IN_MINUTES * 7;
