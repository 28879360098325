import { initStoreEnum } from "../utils/StoreHelpers";

export const CORE_MEDIA_DEVICES_NAMESPACE = "s8-core-devices";

export interface MediaDevicesState {
  devicesLiveMap: Record<string, boolean>;
  devicesAccessibleMap: Record<string, boolean>;
  kindsAccessibleMap: Record<string, "granted" | false>;

  storedDeviceList: MediaDeviceInfo[];

  canExposeCameraInfo: boolean;
  canExposeMicrophoneInfo: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  mediaStreamTrackSources?: any;

  stream?: MediaStream;
  videoSelected?: MediaDeviceInfo;
  audioSelected?: MediaDeviceInfo;

  broadcastActive: boolean;
  connectionState: ConnectionState;
}

export enum ConnectionState {
  SETUP,
  TEST_QUEUE,
  TEST_CALL,
  LIVE_QUEUE,
  LIVE,
}

export enum MediaDevicesAction {
  Init = "init",
  StartMedia = "startMedia",
  SelectAudio = "selectAudio",
  SelectVideo = "selectVideo",
  OpenVideo = "openVideo",
  CloseVideo = "closeVideo",
  ToggleVideo = "toggleVideo",
  ToggleAudio = "toggleAudio",
  Clear = "clear",
  ConnectionState = "connectionState",
  BroadcastActive = "broadcastActive",
}

type MediaDevicesActionStrings = keyof typeof MediaDevicesAction;
export const CoreMediaDevicesActions: Record<
  MediaDevicesActionStrings,
  string
> = initStoreEnum<MediaDevicesActionStrings>(
  MediaDevicesAction,
  CORE_MEDIA_DEVICES_NAMESPACE
);

export enum MediaDevicesMutation {
  SetDevicesLiveMap = "setDevicesLiveMap",
  SetDevicesAccessibleMap = "setDevicesAccessibleMap",
  SetKindsAccessibleMap = "setKindsAccessibleMap",
  SetStoredDeviceList = "setStoredDeviceList",
  SetCanExposeCameraInfo = "setCanExposeCameraInfo",
  SetCanExposeMicrophoneInfo = "setCanExposeMicrophoneInfo",
  SetMediaStreamTrackSources = "setMediaStreamTrackSources",
  SetStream = "setStream",
  SelectAudio = "selectAudio",
  SelectVideo = "selectVideo",
  Clear = "clear",
  SetBroadcastActive = "setBroadcastActive",
  SetConnectionState = "setConnectionState",
}

export enum MediaDevicesGetter {
  AudioDevices = "audioDevices",
  VideoDevices = "videoDevices",
  AudioSelected = "audioSelected",
  VideoSelected = "videoSelected",
  Stream = "stream",
  BroadcastActive = "broadcastActive",
  ConnectionState = "connectionState",
}

type MediaDevicesGetterStrings = keyof typeof MediaDevicesGetter;
export const CoreMediaDevicesGetters: Record<
  MediaDevicesGetterStrings,
  string
> = initStoreEnum<MediaDevicesGetterStrings>(
  MediaDevicesGetter,
  CORE_MEDIA_DEVICES_NAMESPACE
);
