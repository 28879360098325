import Vue from "vue";
import { MutationTree } from "vuex";
import { Pin, PinsState } from "./types";

export const mutations: MutationTree<PinsState> = {
  setPins(state, pins: Pin[]): void {
    Vue.set(state, "pins", pins);
  },
  addPin(state, pin: Pin) {
    if (!state.pins.some((p) => pin.id === p.id)) state.pins.push(pin);
  },
  updatePin(state, pin: Pin) {
    state.pins = state.pins.map((p) => (p.id === pin.id ? pin : p));
  },
  deletePin(state, pinId: string) {
    state.pins = state.pins.filter((p) => p.id !== pinId);
  },
  setEdit(state, pin: Pin) {
    Vue.set(state, "edit", pin);
  },
  clearEdit(state) {
    Vue.set(state, "edit", undefined);
  },
  setLoaded(state, loaded: boolean) {
    state.loaded = loaded;
  },
};
