import { RootState } from "@/store/RootState";
import { Module } from "vuex";
import { ConferenceState } from "../types";
import { actions } from "./actions";
import { getters } from "./getters";
import { mutations } from "./mutations";

export const state: ConferenceState = {
  testCall: null,
  testConference: null,
  testQuestion: null,
  setupDialog: false,
  testCallDialog: false,
};

const namespaced = true;

export const conference: Module<ConferenceState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
