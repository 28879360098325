export function initStoreEnum<T extends string | number | symbol>(
  enumX: { [index: string]: string },
  namespace: string
): Record<T, string> {
  const obj: Record<T, string> = {} as Record<T, string>;
  for (const key in enumX) {
    obj[key as T] = `${namespace}/${enumX[key]}`;
  }
  return obj;
}
