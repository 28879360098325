import { handleHttpError } from "@/helpers";
import { CoreAuthActions, CoreAuthGetters } from "@/spect8-core-vue/src/types";
import store from "@/store";
import axios, { AxiosInstance, AxiosRequestConfig } from "axios";

export const requestInterceptor = async (
  config: AxiosRequestConfig
): Promise<AxiosRequestConfig> => {
  const tokens = store.getters[CoreAuthGetters.Tokens];
  if (tokens) {
    if (config.headers) {
      config.headers.Authorization = `Bearer ${tokens.access}`;
    } else {
      config.headers = {
        Authorization: `Bearer ${tokens.access}`,
      };
    }
  }
  return config;
};

export const rejectInterceptor =
  (axiosInstance: AxiosInstance) => async (error: unknown) => {
    if (axios.isAxiosError(error)) {
      const originalRequest: AxiosRequestConfig & { _retry?: boolean } =
        error.config;
      if (error.response?.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        const accessToken: string | null = await store.dispatch(
          CoreAuthActions.Refresh
        );
        if (accessToken !== null) {
          originalRequest.headers["Authorization"] = `Bearer ${accessToken}`;
          return axiosInstance(originalRequest);
        }
      } else handleHttpError(error);
    }
    return Promise.reject(error);
  };

export const faRequestInterceptor = async (
  config: AxiosRequestConfig
): Promise<AxiosRequestConfig> => {
  const token = store.getters[CoreAuthGetters.AccessTokenExt];
  if (token) {
    if (config.headers) {
      config.headers.Authorization = `Bearer ${token}`;
    } else {
      config.headers = {
        Authorization: `Bearer ${token}`,
      };
    }
  }
  return config;
};
