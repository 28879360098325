import { ActionTree } from "vuex";
import { RootState } from "../RootState";
import {
  AddScheduleAdRequest,
  AddVideoAdRequest,
  EditVideoAdRequest,
  ScheduledAdsContent,
  VideoAdsContent,
  VideoAdsState,
} from "./types";
import { AxiosResponse } from "axios";
import Vue from "vue";
import i18n from "@/plugins/i18n/i18n";
import { handleHttpError } from "@/helpers";
import { http } from "@/services/api.service";

export const actions: ActionTree<VideoAdsState, RootState> = {
  async fetchAllVideoAds({ commit }): Promise<AxiosResponse<VideoAdsContent>> {
    try {
      const res: AxiosResponse<VideoAdsContent> = await http.get(`/ads`);
      const list = res.data.content.filter(
        (element) => !(!element || element["@type"] != "VideoAd")
      );
      if (
        !res.data ||
        res.data.content == [] ||
        list.length == 0 ||
        res.data.content.length == 0
      ) {
        commit("setVideoAdError", i18n.tc("The video ad list is empty"));
        return res;
      }
      commit("setVideoAdsList", list);
      commit("setVideoAdError", "");
      return res;
    } catch (error) {
      commit("setVideoAdError", i18n.tc("The video ad list is empty"));
      handleHttpError(error);
      throw error;
    }
  },
  async addVideoAd(_, VideoAdData: AddVideoAdRequest) {
    const data = {
      "@type": "VideoAd",
      name: VideoAdData.name,
      altText: VideoAdData.altText,
      clickLink: VideoAdData.clickLink,
      video: {
        src: VideoAdData.video.src,
        mimeType: VideoAdData.video.mimeType,
      },
      closableAfterSeconds: VideoAdData.closableAfterSeconds,
    };
    try {
      const res = await http.post(`/ads`, data);
      if (res.status === 200) {
        Vue.$toast.success(i18n.tc("You successfully added this Video Ad!"), {
          timeout: 2000,
        });
      }
      //dispatch("getAllBans");
      //commit("setBanToList", data);
      return res;
    } catch (error) {
      handleHttpError(error, {
        404: i18n.tc("The Video Ad does not exist!"),
        409: i18n.tc("This Video Ad is already added!"),
      });
      throw error;
    }
  },
  async editVideoAd(_, VideoAdData: EditVideoAdRequest) {
    const data = {
      "@type": "VideoAd",
      name: VideoAdData.name,
      altText: VideoAdData.altText,
      clickLink: VideoAdData.clickLink,
      video: {
        src: VideoAdData.video.src,
        mimeType: VideoAdData.video.mimeType,
      },
      closableAfterSeconds: VideoAdData.closableAfterSeconds,
    };
    try {
      const res = await http.put(`/ads/${VideoAdData.id}`, data);
      if (res.status === 200) {
        Vue.$toast.success(i18n.tc("You successfully edited this Video Ad!"), {
          timeout: 2000,
        });
      }
      return res;
    } catch (error) {
      handleHttpError(error);
      throw error;
    }
  },
  async addScheduleAd(_, ScheduleAdData: AddScheduleAdRequest) {
    let data = {};
    if (ScheduleAdData.cron) {
      data = {
        name: ScheduleAdData.name,
        schedulableId: ScheduleAdData.schedulableId,
        schedulableType: ScheduleAdData.schedulableType,
        broadcastIds: ScheduleAdData.broadcastIds,
        cron: ScheduleAdData.cron,
      };
    } else if (!ScheduleAdData.cron && !ScheduleAdData.scheduledAt) {
      data = {
        name: ScheduleAdData.name,
        schedulableId: ScheduleAdData.schedulableId,
        schedulableType: ScheduleAdData.schedulableType,
        broadcastIds: ScheduleAdData.broadcastIds,
      };
    } else {
      data = {
        name: ScheduleAdData.name,
        schedulableId: ScheduleAdData.schedulableId,
        schedulableType: ScheduleAdData.schedulableType,
        broadcastIds: ScheduleAdData.broadcastIds,
        scheduledAt: ScheduleAdData.scheduledAt,
      };
    }
    try {
      const res = await http.post(`/schedules/byRef`, data);
      if (res.status === 200) {
        Vue.$toast.success(i18n.tc("You successfully Scheduled this Ad!"), {
          timeout: 2000,
        });
      }
      //dispatch("getAllBans");
      //commit("setBanToList", data);
      return res;
    } catch (error) {
      handleHttpError(error, {
        404: i18n.tc("The Scheduled Ad does not exist!"),
        409: i18n.tc("This Scheduled Ad is already added!"),
      });
      throw error;
    }
  },
  async deleteVideoAd(_, id: string) {
    try {
      const res = await http.delete(`/ads/${id}`);
      if (res.status === 200) {
        Vue.$toast.success(i18n.tc("You successfully deleted this Video Ad!"), {
          timeout: 2000,
        });
      }
      return res;
    } catch (error) {
      handleHttpError(error, {
        404: i18n.tc("The Video Ad does not exist!"),
        409: i18n.tc("This Video Ad is already deleted!"),
      });
      throw error;
    }
  },
  async deleteScheduledAd(_, id: string) {
    try {
      const res = await http.delete(`/schedules/${id}`);
      if (res.status === 200) {
        Vue.$toast.success(
          i18n.tc("You successfully deleted this Scheduled Ad!"),
          {
            timeout: 2000,
          }
        );
      }
      return res;
    } catch (error) {
      handleHttpError(error, {
        404: i18n.tc("The Scheduled Ad does not exist!"),
        409: i18n.tc("This Scheduled Ad is already deleted!"),
      });
      throw error;
    }
  },
  async fetchBroadcastIds({ commit }) {
    try {
      const res = await http.get(`/broadcast/all`, {
        params: {
          filterByModeratorAccess: true,
        },
      });
      if (!res.data || res.data == []) {
        commit(
          "setBroadcastIdError",
          i18n.tc("The broadcast id list is empty")
        );
        return res;
      }
      commit("setBroadcastIdList", res.data);
      commit("setBroadcastIdError", "");
      return res;
    } catch (error) {
      handleHttpError(error);
      throw error;
    }
  },
  async fetchAllScheduledAds({
    commit,
  }): Promise<AxiosResponse<ScheduledAdsContent>> {
    try {
      const res: AxiosResponse<ScheduledAdsContent> = await http.get(
        `/schedules`,
        {}
      );
      const list = res.data.content.filter(
        (element) =>
          !(
            !element.schedulable ||
            element.schedulable["@type"] != "VideoAd" ||
            element.broadcasts.length < 1
          )
      );
      if (
        !res.data ||
        res.data.content == [] ||
        list.length == 0 ||
        res.data.content.length == 0
      ) {
        commit(
          "setScheduledAdError",
          i18n.tc("The Scheduled ad list is empty")
        );
        return res;
      }
      commit("setScheduledAdsList", list);
      commit("setScheduledAdError", "");
      return res;
    } catch (error) {
      commit("setScheduledAdError", i18n.tc("The Scheduled ad list is empty"));
      handleHttpError(error);
      throw error;
    }
  },
};
