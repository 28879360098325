import { initStoreEnum } from "@/spect8-core-vue/src/utils/StoreHelpers";
import { Namespace } from "../types";

export interface AppState {
  initialized: boolean;
  locale: string;
  tenantError: boolean;
}

export enum AppAction {
  Init = "Init",
  FetchLocale = "FetchLocale",
  SetLocale = "SetLocale",
}

type AppActionStrings = keyof typeof AppAction;
export const AppActions: Record<AppActionStrings, string> =
  initStoreEnum<AppActionStrings>(AppAction, Namespace.App);

export enum AppMutation {
  SetInitialized = "SetInitialized",
  SetLocale = "SetLocale",
  SetTenantError = "SetTenantError",
}

export enum AppGetter {
  Initialized = "Initialized",
  Locale = "Locale",
  TenantError = "TenantError",
}

type AppGetterStrings = keyof typeof AppGetter;
export const AppGetters: Record<AppGetterStrings, string> =
  initStoreEnum<AppGetterStrings>(AppGetter, Namespace.App);
