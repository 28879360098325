import Vue from "vue";
import { Mutation, MutationTree } from "vuex";
import { AppMutation, AppState } from "./types";

export const mutations: MutationTree<AppState> &
  Record<AppMutation, Mutation<AppState>> = {
  [AppMutation.SetInitialized](state, initialized: boolean) {
    Vue.set(state, "initialized", initialized);
  },
  [AppMutation.SetLocale](state, locale: string) {
    Vue.set(state, "locale", locale);
  },
  [AppMutation.SetTenantError](state, tenantError: boolean) {
    Vue.set(state, "tenantError", tenantError);
  },
};
