import { http } from "@/services/api.service";
import { AxiosInstance } from "axios";
import { GemaMemberRecordRegistration } from "../store/types";

class GemaMemberService {
  private axios: AxiosInstance;

  constructor(axiosInstance: AxiosInstance) {
    this.axios = axiosInstance;
  }

  async getGemaMemberRecords(
    searchTerm: string,
    exactSearch?: boolean
  ): Promise<GemaMemberRecordRegistration[]> {
    const params: { searchTerm: string; exactSearch?: boolean } = {
      searchTerm: searchTerm,
    };
    if (exactSearch !== undefined) {
      params.exactSearch = exactSearch;
    }
    return this.axios
      .post<GemaMemberRecordRegistration[]>(
        "/gema-members/record/search",
        null,
        { params: params }
      )
      .then((res) => res.data);
  }
}

export default new GemaMemberService(http);
