import { state } from "./state";
import { getters } from "./getters";
import { actions } from "./actions";
import { mutations } from "./mutations";
import { RoleState } from "./types";
import { RootState } from "../../../store/RootState";
import { Module } from "vuex";

export const roleUser: Module<RoleState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
