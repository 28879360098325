import { Mutation, MutationTree } from "vuex";
import Vue from "vue";
import { RTCSlot } from "./RtcSlot";
import {
  VicoState,
  Conference,
  TrackInfo,
  VicoConfig,
  VicoMutation,
} from "../../types";

export const mutations: MutationTree<VicoState> &
  Record<VicoMutation, Mutation<VicoState>> = {
  [VicoMutation.SetConferences](state, conferences: Conference[]): void {
    state.conferences = conferences;
  },
  [VicoMutation.AddConference](state, conference: Conference) {
    if (!state.conferences.some((c) => c.id === conference.id))
      state.conferences.push(conference);
  },
  [VicoMutation.RemoveConference](state, conference: Conference) {
    state.conferences = state.conferences.filter(
      (c) => !(c.id === conference.id)
    );
  },
  [VicoMutation.AddSlot](state, slot: RTCSlot): void {
    if (
      !state.slots.some((s) => s.rtcInfo.fullPath() === slot.rtcInfo.fullPath())
    )
      state.slots.push(slot);
  },
  [VicoMutation.RemoveSlot](state, slot: RTCSlot): void {
    state.slots = state.slots.filter(
      (s) => !(s.rtcInfo.fullPath() === slot.rtcInfo.fullPath())
    );
  },
  [VicoMutation.SetSlotOut](state, slot: RTCSlot | null): void {
    if (!slot) Vue.delete(state, "slotOut");
    else Vue.set(state, "slotOut", slot);
  },

  [VicoMutation.Disconnect](state) {
    // todo: maybe close them or smth?
    // Are they cleaned up by GC?
    state.slots = [];
  },

  [VicoMutation.SetTracks](state, tracks: TrackInfo[]): void {
    state.tracks = tracks;
  },
  [VicoMutation.AddTrack](state, track: TrackInfo): void {
    if (!state.tracks.includes(track)) state.tracks.push(track);
  },
  [VicoMutation.RemoveTrack](state, track: TrackInfo): void {
    state.tracks = state.tracks.filter((t) => t.id !== track.id);
  },
  [VicoMutation.SelectConference](state, conference: Conference): void {
    state.conferenceSelected = conference;
  },
  [VicoMutation.SetInitialized](state, initialized: boolean) {
    state.initialized = initialized;
  },
  [VicoMutation.SetBroadcastId](state, id: string) {
    Vue.set(state, "broadcastId", id);
  },
  [VicoMutation.SetConfig](state, config: VicoConfig): void {
    Vue.set(state, "config", config);
  },
  [VicoMutation.NextServer](state) {
    const newIdx =
      (state.config.serverIndex ? state.config.serverIndex + 1 : 0) %
      state.config.serverUrls.length;
    Vue.set(state.config, "serverIndex", newIdx);
  },
};
