import { Getter, GetterTree } from "vuex";
import { Poll, PollGetter, PollState, PollVote } from "../../types";

export const getters: GetterTree<PollState, unknown> &
  Record<PollGetter, Getter<PollState, unknown>> = {
  [PollGetter.IsLoading](state): boolean {
    return state.isLoading;
  },
  [PollGetter.Polls](state): Poll[] {
    return state.polls.sort((a, b) => {
      if (a.title === b.title) return 0;
      return a.title < b.title ? -1 : 1;
    });
  },
  [PollGetter.UserVotes](state): Record<string, PollVote[]> {
    return state.pollVotes;
  },
};
