import {
  ConnectionState,
  MediaDevicesMutation,
  MediaDevicesState,
} from "../../types/mediaDevices";
import Vue from "vue";
import { Mutation, MutationTree } from "vuex";

export const mutations: MutationTree<MediaDevicesState> &
  Record<MediaDevicesMutation, Mutation<MediaDevicesState>> = {
  [MediaDevicesMutation.SetDevicesLiveMap](
    state,
    devicesLiveMap?: Record<string, boolean>
  ) {
    Vue.set(state, "devicesLiveMap", devicesLiveMap);
  },
  [MediaDevicesMutation.SetDevicesAccessibleMap](
    state,
    devicesAccessibleMap?: Record<string, boolean>
  ) {
    Vue.set(state, "devicesAccessibleMap", devicesAccessibleMap);
  },
  [MediaDevicesMutation.SetKindsAccessibleMap](
    state,
    kindsAccessibleMap?: Record<string, "granted" | false>
  ) {
    Vue.set(state, "kindsAccessibleMap", kindsAccessibleMap);
  },

  [MediaDevicesMutation.SetStoredDeviceList](
    state,
    storedDeviceList?: MediaDeviceInfo[]
  ) {
    Vue.set(state, "storedDeviceList", storedDeviceList);
  },

  [MediaDevicesMutation.SetCanExposeCameraInfo](
    state,
    canExposeCameraInfo?: boolean
  ) {
    Vue.set(state, "canExposeCameraInfo", canExposeCameraInfo);
  },
  [MediaDevicesMutation.SetCanExposeMicrophoneInfo](
    state,
    canExposeMicrophoneInfo?: boolean
  ) {
    Vue.set(state, "canExposeMicrophoneInfo", canExposeMicrophoneInfo);
  },
  [MediaDevicesMutation.SetMediaStreamTrackSources](
    state,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    mediaStreamTrackSources?: any
  ) {
    Vue.set(state, "mediaStreamTrackSources", mediaStreamTrackSources);
  },
  [MediaDevicesMutation.SetStream](state, stream?: MediaStream) {
    Vue.set(state, "stream", stream);
  },
  [MediaDevicesMutation.SelectAudio](state, device?: MediaDeviceInfo) {
    Vue.set(state, "audioSelected", device);
  },
  [MediaDevicesMutation.SelectVideo](state, device?: MediaDeviceInfo) {
    Vue.set(state, "videoSelected", device);
  },
  [MediaDevicesMutation.Clear](state) {
    Vue.delete(state, "videoSelected");
    Vue.delete(state, "audioSelected");
    Vue.delete(state, "stream");
  },
  [MediaDevicesMutation.SetBroadcastActive](state, active: boolean) {
    Vue.set(state, "broadcastActive", active);
  },
  [MediaDevicesMutation.SetConnectionState](
    state,
    connectionState: ConnectionState
  ): void {
    Vue.set(state, "connectionState", connectionState);
  },
};
