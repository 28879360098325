import Vue from "vue";
import { Mutation, MutationTree } from "vuex";
import { Broadcast, BroadcastMutation, BroadcastState } from "./types";

export const mutations: MutationTree<BroadcastState> &
  Record<BroadcastMutation, Mutation<BroadcastState>> = {
  [BroadcastMutation.SetAllBroadcasts](
    state: BroadcastState,
    payload: Broadcast[]
  ): void {
    state.allBroadcasts = payload;
  },
  [BroadcastMutation.SetBroadcasts](
    state: BroadcastState,
    payload: Broadcast[]
  ): void {
    state.broadcasts = payload;
  },
  [BroadcastMutation.SetLoading](
    state: BroadcastState,
    loading: boolean
  ): void {
    state.loading = loading;
  },
  [BroadcastMutation.UpdateBroadcast](
    state: BroadcastState,
    broadcast: Broadcast
  ): void {
    const index = state.broadcasts.findIndex((bc) => bc.id === broadcast.id);
    if (index > -1) {
      state.broadcasts[index] = broadcast;
      Vue.set(state, "broadcasts", state.broadcasts);
    } else {
      state.broadcasts.push(broadcast);
    }
    // update all broadcasts
    if (state.allBroadcasts) {
      const index = state.allBroadcasts.findIndex(
        (bc) => bc.id === broadcast.id
      );
      if (index > -1) {
        state.allBroadcasts[index] = broadcast;
        Vue.set(state, "allBroadcasts", state.broadcasts);
      } else {
        state.allBroadcasts.push(broadcast);
      }
    }
  },
  [BroadcastMutation.RemoveBroadcast](
    state: BroadcastState,
    broadcastId: string
  ): void {
    Vue.set(
      state,
      "broadcasts",
      state.broadcasts.filter((broadcast) => broadcast.id !== broadcastId)
    );
    Vue.set(
      state,
      "allBroadcasts",
      state.allBroadcasts?.filter((broadcast) => broadcast.id !== broadcastId)
    );
  },
};
