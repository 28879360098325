import { GeneralState } from "./types";

export const state: GeneralState = {
  channels: [],
  pinnedMessagesList: [],
  channelsError: "",
  chatSettings: null,
  generalError: "",
  shopCategories: [],
  shopItems: [],
  disclaimers: [],
  navbarItems: {
    broadcastId: null,
    id: "",
    tabs: [],
  },
};
