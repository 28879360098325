import { User } from "@/spect8-core-vue/src/types";
import { initStoreEnum } from "@/spect8-core-vue/src/utils/StoreHelpers";
import { Namespace } from "../types";

export interface UsersState {
  users: User[];
}

export enum UsersAction {
  GetUsers = "GetUsers",
}

type UsersActionStrings = keyof typeof UsersAction;
export const UsersActions: Record<UsersActionStrings, string> =
  initStoreEnum<UsersActionStrings>(UsersAction, Namespace.Users);

export enum UsersMutation {
  SetUsers = "SetUsers",
}

export enum UsersGetter {
  Users = "Users",
}

type UsersGetterStrings = keyof typeof UsersGetter;
export const UsersGetters: Record<UsersGetterStrings, string> =
  initStoreEnum<UsersGetterStrings>(UsersGetter, Namespace.Users);
