import { SVKeys, SVVote } from "../types/secure-voting";
import { AxiosInstance } from "axios";
import { Store } from "vuex";
import CryptoJS from "crypto-js";
import {
  Broadcast,
  DelegateResponse,
  DelegateRequestDto,
  ThemeConfig,
  TenantConfig,
  PatchEventDto,
  PatchPollDto,
  Poll,
  PollVote,
  CreatePollDto,
  EventDay,
  UserResponse,
} from "../types";
import { getAxiosInstance } from "./axios-base";

export class CoreApiService {
  private broadcastEndpoint = "/broadcast";
  private delegateEndpoint = "/delegate";
  private eventDayEndpoint = "/eventday";
  private pollsEndpoint = "/polls";
  private pollVotesEndpoint = "/pollvotes";
  private settingsEndpoint = "/settings";
  private tenantEndpoint = "/tenant";
  private userEndpoint = "/user";
  private secureVotingEndpoint = "/secure-voting";

  // private shopEndpoint = "/shop";
  // private userinfoEndpoint = "/userinfo";
  // private resourcesEndpoint = "/file-archive";

  // Note: needs to be public for module augmentation
  public axios: AxiosInstance;

  constructor(axios: AxiosInstance) {
    this.axios = axios;
  }

  // Broadcast

  async getBroadcasts(): Promise<Broadcast[]> {
    return this.axios.get(this.broadcastEndpoint).then((res) => res.data);
  }

  async getBroadcastByName(broadcastName: string): Promise<Broadcast> {
    return this.axios
      .get(`${this.broadcastEndpoint}/${broadcastName}`)
      .then((res) => res.data);
  }

  async getOrCreateBroadcast(broadcastName: string): Promise<Broadcast> {
    return this.axios
      .post(this.broadcastEndpoint, null, {
        params: { broadcastName },
      })
      .then((res) => res.data);
  }

  // Delegate

  async getDelegateByUser(): Promise<DelegateResponse> {
    return this.axios
      .get(`${this.delegateEndpoint}/byUser`)
      .then((res) => res.data);
  }

  async createDelegate(
    delegate: DelegateRequestDto
  ): Promise<DelegateResponse> {
    return this.axios
      .post(this.delegateEndpoint, delegate)
      .then((res) => res.data);
  }

  async validateDelegate(delegateUser: DelegateRequestDto): Promise<boolean> {
    return this.axios
      .post(`${this.delegateEndpoint}/validate`, delegateUser)
      .then((res) => res.data);
  }

  // Eventday

  public async getEventDay(): Promise<EventDay[]> {
    return this.axios
      .get<EventDay[]>(this.eventDayEndpoint, {})
      .then((res) => res.data);
  }

  // Polls

  async getAllPolls(): Promise<Poll[]> {
    return this.axios.get<Poll[]>(this.pollsEndpoint).then((res) => res.data);
  }

  public async getPollById(pollId: string): Promise<Poll> {
    return this.axios
      .get<Poll>(`${this.pollsEndpoint}/${pollId}`)
      .then((res) => res.data);
  }

  public async getPollsByBroadcastIds(
    tenantId: string,
    broadcastIds: string[]
  ): Promise<Poll[]> {
    const ids = broadcastIds.join(",");
    return this.axios
      .get<Poll[]>(
        `${this.pollsEndpoint}/broadcasts?tenantId=${tenantId}&broadcastIds=${ids}`
      )
      .then((res) => res.data);
  }

  public async getPollsByChannelIds(
    tenantId: string,
    channelIds: string[]
  ): Promise<Poll[]> {
    const ids = channelIds.join(",");

    return this.axios
      .get<Poll[]>(
        `${this.pollsEndpoint}/broadcasts?tenantId=${tenantId}&channelIds=${ids}`
      )
      .then((res) => res.data);
  }

  public async getPollVotesByPollIdAndUserId(
    pollId: string,
    userId: string
  ): Promise<PollVote[]> {
    return this.axios
      .get<PollVote[]>(`${this.pollVotesEndpoint}/${pollId}/${userId}`)
      .then((res) => res.data);
  }

  public async saveVote({
    userId,
    pollId,
    pollAnswer,
  }: PollVote): Promise<PollVote[]> {
    return this.axios
      .post(`${this.pollVotesEndpoint}/${pollId}`, {
        userId,
        pollAnswer,
      })
      .then((res) => res.data);
  }

  public async createPoll(poll: CreatePollDto): Promise<void> {
    return this.axios.post(this.pollsEndpoint, poll).then((res) => res.data);
  }

  public async publishPollResults(pollId: string): Promise<void> {
    return this.axios.post(`${this.pollsEndpoint}/${pollId}/results/publish`);
  }

  public async triggerPollResults(pollId: string): Promise<void> {
    return this.axios.post(`${this.pollsEndpoint}/${pollId}/results`);
  }

  async deleteQuestion(id: string): Promise<void> {
    return this.axios.delete(`/qa/${id}`);
  }

  // Settings

  // async getNavigationTabs(
  //   broadcastId: string | null
  // ): Promise<NavigationItem[]> {
  //   return this.axios
  //     .get<NavigationResponse>(this.settingsEndpoint + "/tab/", {
  //       params: { broadcastId: broadcastId }
  //     })
  //     .then(res => res.data.tabs);
  // }

  // async getFeatures(broadcastId?: string): Promise<FeatureResponse> {
  //   let promise!: Promise<AxiosResponse<FeatureResponse>>;
  //   const endpoint = this.settingsEndpoint + "/feature";

  //   if (broadcastId) {
  //     promise = this.axios.get(endpoint, {
  //       params: { broadcastId: broadcastId }
  //     });
  //   } else {
  //     promise = this.axios.get(endpoint);
  //   }

  //   return await promise.then(res => res.data);
  // }

  async getThemeConfig(tenantId: string): Promise<ThemeConfig> {
    return this.axios
      .get<ThemeConfig>(`${this.settingsEndpoint}/theme/${tenantId}`)
      .then((res) => res.data);
  }

  // Tenant

  async getTenantByName(name: string): Promise<TenantConfig> {
    return this.axios
      .get<TenantConfig>(this.tenantEndpoint, {
        params: { name: name },
      })
      .then((res) => res.data);
  }

  async getTenantById(id: string): Promise<TenantConfig> {
    return this.axios
      .get<TenantConfig>(this.tenantEndpoint, {
        params: { id: id },
      })
      .then((res) => res.data);
  }

  async updateTenant(
    tenantId: string,
    tenantConfig: Partial<TenantConfig>
  ): Promise<TenantConfig> {
    return this.axios.patch<Partial<TenantConfig>, TenantConfig>(
      `${this.tenantEndpoint}/${tenantId}`,
      tenantConfig
    );
  }

  // User

  async updateUser(
    userId: string,
    patchDtos: PatchEventDto[] | PatchPollDto[]
  ): Promise<UserResponse> {
    return this.axios.patch(`${this.userEndpoint}/${userId}`, patchDtos, {
      params: {
        requestingUserId: userId,
      },
    });
  }

  // GEMA Event Secure Voting

  async getUserKeys(): Promise<SVKeys> {
    return this.axios
      .get(this.secureVotingEndpoint + "/keys")
      .then((res) => res.data);
  }

  public async submitUserPreVotesNew(
    votes: Array<string>,
    userPublicKeyId: string
  ): Promise<void> {
    return this.axios
      .post(this.secureVotingEndpoint + "/questions/votes", votes, {
        params: { userPublicKeyId },
      })
      .then((res) => res.data);
  }

  async decodeUserKeys(
    userKeys: SVKeys,
    password: string
  ): Promise<{
    decryptedPrivateKey: string;
    decryptedPublicKey: string;
  }> {
    const decodedPkIv = CryptoJS.enc.Base64.parse(userKeys.encodedPrivateKeyIv);
    const decodedIdIv = CryptoJS.enc.Base64.parse(
      userKeys.encodedPublicKeyIdIv
    );
    const secretKey = password + userKeys.salt;
    const secretKeySHA = CryptoJS.SHA256(secretKey);

    const decryptedPrivateKey = CryptoJS.AES.decrypt(
      userKeys.encodedEncryptedPrivateKey,
      secretKeySHA,
      {
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
        iv: decodedPkIv,
      }
    );
    const decryptedPublicKey = CryptoJS.AES.decrypt(
      userKeys.encodedEncryptedPublicKeyId,
      secretKeySHA,
      {
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
        iv: decodedIdIv,
      }
    );
    return {
      decryptedPrivateKey: decryptedPrivateKey.toString(CryptoJS.enc.Utf8),
      decryptedPublicKey: decryptedPublicKey.toString(CryptoJS.enc.Utf8),
    };
  }

  async encryptPreVotePayload(
    votes: { questionId: string; answerIds: string[] }[],
    privateKey: string
  ): Promise<string[]> {
    const encryptedVotes: string[] = await Promise.all(
      votes.map(async (vote: SVVote) => {
        //TODO Use client-side encrypt method instead of endpoint
        const encryptedData = await this.getEncryptedVote(privateKey, vote);
        return encryptedData;
      })
    );
    return encryptedVotes;
  }

  async getEncryptedVote(
    privateKey: string,
    vote: { questionId: string; answerIds: string[] }
  ): Promise<string> {
    const payload = JSON.stringify({
      contentToEncrypt: vote,
      encodedPrivateKey: privateKey,
    });
    return this.axios
      .post(this.secureVotingEndpoint + "/keys/encrypt", payload, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => res.data);
  }

  // Resources

  // async getResources(
  //   tenantWide: boolean,
  //   broadcastId?: string
  // ): Promise<Resource[]> {
  //   const params: { broadcastId?: string; tenantWide: boolean } = {
  //     tenantWide
  //   };

  //   if (broadcastId) {
  //     params["broadcastId"] = broadcastId;
  //   }
  //   return this.axios
  //     .get(this.resourcesEndpoint, { params })
  //     .then(res => res.data);
  // }

  // Shop

  // async getShopCategories(): Promise<ShopCategory[]> {
  //   return this.axios
  //     .get(this.shopEndpoint + "/category")
  //     .then(res => res.data);
  // }

  // async getShopItemsByCategoryId(categoryId: string): Promise<ShopItem[]> {
  //   return this.axios
  //     .get(this.shopEndpoint + `/item/${categoryId}`)
  //     .then(res => res.data);
  // }

  // async getShopDisclaimers(): Promise<ShopDisclaimer[]> {
  //   return this.axios
  //     .get(this.shopEndpoint + "/disclaimer")
  //     .then(res => res.data);
  // }

  // UserInfo

  async updateUserLanguage(language: string): Promise<UserResponse> {
    return this.axios
      .put(`/userinfo/language/${language}`)
      .then((res) => res.data);
  }

  // async getUserInfo(): Promise<UserResponse> {
  //   return this.axios.get(this.userinfoEndpoint).then(res => res.data);
  // }

  // async updateUserInfo(editRequest: EditRequest): Promise<UserResponse> {
  //   return this.axios
  //     .put(this.userinfoEndpoint, editRequest)
  //     .then(res => res.data);
  // }
}

let coreApiService: CoreApiService;

function initCoreApiService(
  baseUrl: string,
  store: Store<unknown>
): CoreApiService {
  if (coreApiService) {
    return coreApiService;
  }

  const axiosInstance = getAxiosInstance(
    {
      baseURL: baseUrl,
      responseType: "json",
    },
    store
  );

  coreApiService = new CoreApiService(axiosInstance);
  return coreApiService;
}

export { coreApiService, initCoreApiService };
