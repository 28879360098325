import { Mutation } from "vuex";
import {
  TrackSlotDraggable,
  SwitchboardMutation,
  SwitchboardState,
} from "../types";
import { Conference, TrackInfo } from "@/spect8-core-vue/src/types/vico";

export const mutations: Record<
  SwitchboardMutation,
  Mutation<SwitchboardState>
> = {
  [SwitchboardMutation.SetTrackInfos](state, trackInfos: TrackInfo[]) {
    state.trackinfos = trackInfos;
  },
  [SwitchboardMutation.SetConferences](state, conferences: Conference[]) {
    state.conferences = conferences;
  },
  [SwitchboardMutation.PushSlotTrack](state, slotTrack: TrackSlotDraggable) {
    if (
      !state.slotTracks.some(
        (track) =>
          (track.trackInfoId === slotTrack.trackInfoId ||
            track.trackInfoId === slotTrack.id) &&
          track.slotId === slotTrack.slotId &&
          track.conferenceId === slotTrack.conferenceId
      )
    ) {
      state.slotTracks.push(slotTrack);
    }
  },
  [SwitchboardMutation.ClearSlotTracks](state) {
    state.slotTracks = [];
  },
  [SwitchboardMutation.DeleteSlotTrack](state, slotTrack: TrackSlotDraggable) {
    if (slotTrack) {
      const index = state.slotTracks.findIndex(
        (track) =>
          (track.trackInfoId === slotTrack.trackInfoId ||
            track.trackInfoId === slotTrack.id) &&
          track.slotId === slotTrack.slotId &&
          track.conferenceId === slotTrack.conferenceId
      );

      if (index !== -1) state.slotTracks.splice(index, 1);
    }
  },
};
