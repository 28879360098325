import Vue from "vue";
import { MutationTree } from "vuex";
import {
  CategoryResponse,
  Channel,
  ChatSettings,
  GeneralState,
  Navigation,
  PinnedMessage,
  ShopDisclaimer,
  ShopItem,
} from "./types";

export const mutations: MutationTree<GeneralState> = {
  setChatSettings(state: GeneralState, payload: ChatSettings): void {
    Vue.set(state, "chatSettings", payload);
  },
  setChannelsList(state: GeneralState, payload: Channel[]): void {
    Vue.set(state, "channels", payload);
  },
  resetChannels(state: GeneralState) {
    state.channels = [];
  },
  setChannelsError(state: GeneralState, payload) {
    state.channelsError = payload;
  },
  deletePinnedMessage(state, pinnedMessageId: string) {
    state.pinnedMessagesList = state.pinnedMessagesList.filter(
      (message) => message.id !== pinnedMessageId
    );
  },
  addPinnedMessage(state, pinnedMessage: PinnedMessage) {
    state.pinnedMessagesList.push(pinnedMessage);
  },
  setPinnedMessagesList(state: GeneralState, payload: PinnedMessage[]): void {
    state.pinnedMessagesList = payload;
  },
  setCategories(state, value: CategoryResponse[]) {
    state.shopCategories = value;
  },
  addCategory(state, value: CategoryResponse) {
    state.shopCategories.push(value);
  },
  deleteCategory(state, id: string) {
    const i = state.shopCategories.findIndex((c) => c.id === id);
    if (i >= 0) state.shopCategories.splice(i, 1);
  },
  setShopItems(state, value: ShopItem[]) {
    state.shopItems = value;
  },
  addItem(state, value) {
    state.shopItems.unshift(value);
  },
  deleteItem(state, id: string) {
    const i = state.shopItems.findIndex((s) => s.id === id);
    if (i >= 0) state.shopItems.splice(i, 1);
  },
  updateItem(state, value: ShopItem) {
    const i = state.shopItems.findIndex((s: ShopItem) => s.id === value.id);
    Vue.set(state.shopItems, i, value);
  },
  setDisclaimers(state, value: ShopDisclaimer[]) {
    state.disclaimers = value;
  },
  addDisclaimer(state, value: ShopDisclaimer) {
    state.disclaimers.push(value);
  },
  deleteDisclaimer(state, id: string) {
    const i = state.disclaimers.findIndex((s) => s.id === id);
    if (i >= 0) state.disclaimers.splice(i, 1);
  },
  updateDisclaimer(state, value: ShopDisclaimer) {
    const i = state.disclaimers.findIndex(
      (s: ShopDisclaimer) => s.id === value.id
    );
    Vue.set(state.disclaimers, i, value);
  },
  setNavbarItems(state, value: Navigation) {
    state.navbarItems = value;
  },
};
export default mutations;
