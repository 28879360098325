import { PinDTO, PinRequest, PinsResponse } from "./anonu.types";
import { ANONU_URL } from "@/helpers/envConstants";
import axios from "axios";
import { IsUniqueResponse } from "./anonu.types";
import { Pin, PinType } from "@/components/Pins/types";
import store from "@/store";
import {
  Broadcast,
  BroadcastActions,
  BroadcastGetters,
} from "@/components/Broadcast/broadcast/types";
import { faRequestInterceptor, rejectInterceptor } from "../interceptors";
import { Role } from "@/spect8-core-vue/src/types";

const http = axios.create({
  baseURL: ANONU_URL,
  responseType: "json",
  headers: {
    "Content-Type": "application/json",
  },
});

http.interceptors.request.use(faRequestInterceptor);
http.interceptors.response.use(undefined, rejectInterceptor(http));

const getPinById = (id: string, tenantId: string) =>
  http.get(`${ANONU_URL}/pins/${tenantId}/${id}`).then((res) => res.data);

const createPin = (pin: PinRequest) =>
  getAllBroadcasts().then((broadcasts) => {
    return http
      .post<PinsResponse>(`${ANONU_URL}/pins`, pin)
      .then((res) => res.data.pins.map((pin) => pinDtoToPin(pin, broadcasts)));
  });

const updatePin = (pin: PinRequest, tenantId: string) =>
  getAllBroadcasts().then((broadcasts) => {
    return http
      .put<PinsResponse>(`${ANONU_URL}/pins/${tenantId}/${pin.id}`, pin)
      .then((res) => res.data.pins.map((pin) => pinDtoToPin(pin, broadcasts)));
  });

const deletePin = (pin: PinDTO | string, tenantId: string) =>
  http
    .delete<void>(
      `${ANONU_URL}/pins/${tenantId}/${typeof pin === "string" ? pin : pin.id}`
    )
    .then((res) => res.data);

const fetchPins = (tenantId: string) =>
  getAllBroadcasts().then((broadcasts) => {
    return http
      .get<PinsResponse>(`${ANONU_URL}/pins/${tenantId}`)
      .then((res) => res.data.pins.map((pin) => pinDtoToPin(pin, broadcasts)));
  });

// const getUniquePin = () => http.get

const isUnique = (pin: string, tenantId: string) =>
  http
    .get<IsUniqueResponse>(`${ANONU_URL}/pins/isunique/${tenantId}/${pin}`)
    .then((res) => res.data);

const getAllBroadcasts = async (): Promise<Broadcast[]> => {
  let broadcasts: Broadcast[] | null =
    store.getters[BroadcastGetters.AllBroadcasts];
  if (!broadcasts) {
    await store.dispatch(BroadcastActions.GetAllBroadcasts, null, {
      root: true,
    });
    broadcasts = store.getters[BroadcastGetters.AllBroadcasts];
  }
  return broadcasts ?? [];
};

const pinDtoToPin = (pDto: PinDTO, broadcasts: Broadcast[]): Pin => {
  return {
    id: pDto.id,
    key: pDto.key,
    role: pDto.role as Role,
    pin_type: pDto.pin_type as PinType,
    broadcasts:
      pDto.broadcast_ids && broadcasts
        ? pDto.broadcast_ids.flatMap(
            (bid: string) => broadcasts.find((b) => b.id === bid) || []
          )
        : [],
    expiresAt: new Date(pDto.expires_at).getTime(),
  };
};

const AnonU = {
  getPinById,
  createPin,
  updatePin,
  deletePin,
  fetchPins,
  isUnique,
};

export default AnonU;
