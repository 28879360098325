import { initStoreEnum } from "../utils/StoreHelpers";
import { UserInfo } from "./user";

export interface PollModuleConfig {
  notificationCallback?: (status: number | undefined, message: string) => void;
}

export const CORE_POLL_NAMESPACE = "s8-core-poll";

export interface PollState {
  isLoading: boolean;
  polls: Poll[];
  pollVotes: Record<string, PollVote[]>;
}

export enum PollAction {
  GET_POLL = "GET_POLL",
  CREATE_POLL = "CREATE_POLL",
  FETCH_POLLS = "FETCH_POLLS",
  GET_USERS_VOTES = "GET_USERS_VOTES",
  VOTE = "VOTE",
  END_POLL = "END_POLL",
  POLL_VOTED = "POLL_VOTED",
  POLL_STARTED = "POLL_STARTED",
  POLL_DELETED = "POLL_DELETED",
  POLL_ENDED = "POLL_ENDED",
  POLL_MODERATOR_TOGGLE_RESULTS = "POLL_MODERATOR_TOGGLE_RESULTS",
  POLL_PUBLISH_RESULTS = "POLL_PUBLISH_RESULTS",
  POLL_RESULTS = "POLL_RESULTS",
}

type PollActionStrings = keyof typeof PollAction;
export const CorePollActions: Record<PollActionStrings, string> =
  initStoreEnum<PollActionStrings>(PollAction, CORE_POLL_NAMESPACE);

export enum PollMutation {
  SetLoading = "setLoading",
  SetPolls = "setPolls",
  StartPoll = "startPoll",
  DeletePoll = "deletePoll",
  UpdatePoll = "updatePoll",
  SetUserVotes = "setUserVotes",
  TogglePollResults = "togglePollResults",
}

export enum PollGetter {
  IsLoading = "isLoading",
  Polls = "polls",
  UserVotes = "userVotes",
}

type PollGetterStrings = keyof typeof PollGetter;
export const CorePollGetters: Record<PollGetterStrings, string> =
  initStoreEnum<PollGetterStrings>(PollGetter, CORE_POLL_NAMESPACE);

export interface PollVoteActionPayload {
  pollId: string;
  pollVote: PollAnswer;
}

export interface MessageRouterPayload {
  tenantWide: boolean;
  broadcastIds: string[];
  channelIds: string[];
}

export enum PollStatus {
  DRAFT = "DRAFT",
  ACTIVE = "ACTIVE",
  SCHEDULED = "SCHEDULED",
  ENDED = "ENDED",
}

export enum PollResultVisibility {
  LIVE = "LIVE",
  ON_DEMAND = "ON_DEMAND",
  ON_POLL_END = "ON_POLL_END",
}

export enum PollType {
  DEFAULT = "DEFAULT",
  OPEN = "OPEN",
  IMAGE = "IMAGE",
}

export enum PollLocation {
  DEFAULT = "DEFAULT",
  INLINE = "INLINE",
}

export interface NewPollAnswerDto {
  text: string;
  image: PollImage;
}

export interface PollAnswer {
  answerId: string;
  voteCount: number;
  value: string;
  userSubmission: boolean;
  image: PollImage;
  authorDisplayName: string;
}

export interface CustomPollVoteDto {
  answerId: string;
  value: string;
}

export interface Poll {
  id: string;
  question: string;
  answers: PollAnswer[];
  // Minutes
  duration: number;
  startDate: string;
  endDate: string;
  tenantId: string;
  messageRouterPayload: MessageRouterPayload;
  createdByUserInfo: UserInfo;
  status: PollStatus;
  showResults: boolean;
  options: PollOptions;
  moderatorShowResults: boolean;
  image: PollImage;
  title: string;
  location: PollLocation;
}

export interface CreatePollDto {
  question: string;
  answers: NewPollAnswerDto[];
  // Minutes
  duration: number;
  startDate: string;
  endDate: string;
  tenantId: string;
  createdById: string;
  messageRouterPayload: MessageRouterPayload;
  immediate: boolean;
  options?: Partial<PollOptions>;
  location: PollLocation;
}

export interface PollVote {
  id: string;
  userId: string;
  pollId: string;
  pollAnswer: PollAnswer;
  date: string;
}

export interface PollOptions {
  type: PollType;
  maxCharacterCount: number;
  resultVisibility: PollResultVisibility;
  showAuthors: boolean;
  singleWordAnswers: boolean;
  votesPerPerson: number;
}

export type PollImage = {
  upmanId: string;
  publicPath: string;
  fileExtension: string;
  filename: string;
} | null;
