import DE from "./de";
import EN from "./en";
import VueI18n, {
  DateTimeFormat,
  DateTimeFormats,
  LocaleMessageObject,
  LocaleMessages,
} from "vue-i18n";

export enum DateTimeFormatOption {
  DateShort = "DateShort",
  DateTimeShort = "DateTimeShort",
  DateTimeLong = "DateTimeLong",
  Time = "Time",
  Date = "Date",
  Time24 = "Time24",
}

export enum Locale {
  DE = "de",
  EN = "en",
}

export interface LocaleOptions {
  BaseMessages: LocaleMessageObject;
  DateTimeFormats: DateTimeFormat;
}

export const coreBaseMessages: LocaleMessages = {
  [Locale.DE]: DE.BaseMessages,
  [Locale.EN]: EN.BaseMessages,
};

// https://402.ecma-international.org/2.0/#sec-intl-datetimeformat-constructor
export const coreDateTimeFormats: DateTimeFormats = {
  [Locale.DE]: DE.DateTimeFormats,
  [Locale.EN]: EN.DateTimeFormats,
};

export const mergeI18nMessages = (
  i18n: VueI18n,
  messages: LocaleMessages
): VueI18n => {
  Object.keys(messages).forEach((locale) =>
    i18n.mergeLocaleMessage(locale, messages[locale])
  );
  return i18n;
};

export const mergeI18nDateTimeFormats = (
  i18n: VueI18n,
  dateTimeFormats: DateTimeFormats
): VueI18n => {
  Object.keys(dateTimeFormats).forEach((locale) =>
    i18n.mergeDateTimeFormat(locale, dateTimeFormats[locale])
  );
  return i18n;
};
