export interface EventDay {
  id: string;
  tenantId: string;
  name: string;
  description: string;
  location: string;
  date: number;
  accessGroupIds: string[];
}

export interface UserEvent {
  eventDayId: string;
  status: EventDayStatus;
}

export enum EventDayStatus {
  ONLINE = "ONLINE",
  OFFLINE = "OFFLINE",
  TBD = "TBD",
}
