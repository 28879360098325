import { Getter, GetterTree } from "vuex";
import { RootState } from "@/store/RootState";
import { PollGetter, PollState } from "../types";
import { Poll } from "@/services/api/secure-votes.service.types";

export const getters: GetterTree<PollState, RootState> &
  Record<PollGetter, Getter<PollState, RootState>> = {
  [PollGetter.Polls](state): Poll[] {
    return state.securePolls;
  },
  [PollGetter.ActivePolls](state): Poll[] {
    return state.secureActivePolls;
  },
  [PollGetter.EditPoll](state): Poll | null {
    return state.editSecurePoll;
  },
};
