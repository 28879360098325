import {
  AssignSlotTrackRequest,
  CallInitRequest,
  Conferences,
  ServersResponse,
} from "./../types/vico";
import {
  ConferenceSlotTrackAssignment,
  ResponseConferences,
  Conference,
  SignalQaState,
  ResponseTrackInfos,
} from "../types/vico";
import axios, { AxiosInstance } from "axios";

class VicoService {
  // public because URL can change during runtime/is randomized
  http: AxiosInstance;

  constructor(axios: AxiosInstance) {
    this.http = axios;
  }

  updateSlotTracks = (
    data: ConferenceSlotTrackAssignment
  ): Promise<Conferences> =>
    this.http
      .put<ResponseConferences>(`/signal/conferenceslottouser`, data)
      .then((res) => this._transformResponseConferences(res.data));

  updateConference = (conference: Conference): Promise<Conferences> =>
    this.http
      .put<ResponseConferences>(`/signal/conferences`, conference)
      .then((res) => this._transformResponseConferences(res.data));

  createConference = (conference: Conference): Promise<Conferences> =>
    this.http
      .post<ResponseConferences>(`/signal/conferences`, conference)
      .then((res) => this._transformResponseConferences(res.data));

  assignSlotTracks = (data: AssignSlotTrackRequest): Promise<Conferences> =>
    this.http
      .put<ResponseConferences>(`/signal/assignslottracks`, data)
      .then((res) => this._transformResponseConferences(res.data));

  getConferenceById = (
    conferenceId: string,
    tenantId: string
  ): Promise<Conferences> =>
    this.http
      .get<ResponseConferences>(
        `/signal/conferences/${tenantId}/${conferenceId}`
      )
      .then((res) => this._transformResponseConferences(res.data));

  getConferences = (tenantId: string): Promise<Conferences> =>
    this.http
      .get<ResponseConferences>(`/signal/conferences/${tenantId}`)
      .then((res) => this._transformResponseConferences(res.data));

  deleteConference = (
    conferenceId: string,
    tenantId: string
  ): Promise<Conferences> =>
    this.http
      .delete<ResponseConferences>(`/signal/${tenantId}/${conferenceId}`)
      .then((res) => this._transformResponseConferences(res.data));

  updateQaState = (qaState: SignalQaState): Promise<Conferences> =>
    this.http
      .put<ResponseConferences>(`/signal/vqastate`, qaState)
      .then((res) => this._transformResponseConferences(res.data));

  getTrackInfos = (tenantId: string): Promise<ResponseTrackInfos> =>
    this.http
      .get<ResponseTrackInfos>(`/signal/trackinfos/${tenantId}`)
      .then((res) => res.data);

  getTrackInfoById = (
    id: string,
    tenantId: string
  ): Promise<ResponseTrackInfos> =>
    this.http
      .get<ResponseTrackInfos>(`/signal/trackinfos/${tenantId}/${id}`)
      .then((res) => res.data);

  getUserTracks = (
    userId: string,
    tenantId: string
  ): Promise<ResponseTrackInfos> =>
    this.http
      .get<ResponseTrackInfos>(`/signal/usertracks`, {
        params: { tenantId, userId },
      })
      .then((res) => res.data);

  initCall = (val: CallInitRequest): Promise<Conferences> =>
    this.http
      .post<ResponseConferences>(`/signal/conferences/callinit`, val)
      .then((res) => this._transformResponseConferences(res.data));

  endConference = (
    tenantId: string,
    broadcastId: string,
    conferenceId: string
  ): Promise<void> =>
    this.http.post(`/signal/conferences/end`, {
      tenantId,
      broadcastId,
      conferenceId,
    });

  getServers = (): Promise<ServersResponse> =>
    this.http.get<ServersResponse>(`/signal/servers`).then((res) => res.data);

  errorLog = (error: string) =>
    this.http.post(`/signal/errorlog`, { errorJson: error });

  /** Transforms the conference responses from vico to internal Conferences */
  _transformResponseConferences = (dto: ResponseConferences) => ({
    ...dto,
    conferences: dto.conferences.map((conf) => ({
      ...conf,
      slotAssignments: { slotTracks: conf.slotAssignments.SlotTracks },
    })),
  });
}

let vicoService: VicoService;

function initVicoService(baseUrl: string, apiKey: string): VicoService {
  if (vicoService) {
    return vicoService;
  }

  const axiosInstance = axios.create({
    baseURL: baseUrl,
    responseType: "json",
    headers: {
      apikey: apiKey,
    },
  });

  vicoService = new VicoService(axiosInstance);
  return vicoService;
}

export { vicoService, initVicoService };
