import { Mutation } from "vuex";
import { RegistrationResponse } from "../@types";
import { RegistrationMutation, RegistrationState } from "../@types/types";

export const mutations: Record<
  RegistrationMutation,
  Mutation<RegistrationState>
> = {
  [RegistrationMutation.SetLoading](state, loading: boolean) {
    state.loading = loading;
  },
  [RegistrationMutation.SetSelectedBroadcastId](state, broadcastId: string) {
    state.selectedBroadcastId = broadcastId;
  },
  [RegistrationMutation.SetRepresentatorsRegistrations](
    state,
    registrations: RegistrationResponse[]
  ) {
    state.representatorsRegistrations = registrations;
  },
  [RegistrationMutation.SetAvailableVotes](state, availableVotes: number) {
    state.numAvailableVotes = availableVotes;
  },
};
