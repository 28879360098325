import { RootState } from "@/store/RootState";
import { ActionTree } from "vuex";
import { BanOutDTO, Ban, BanState, BanActionPayload } from "./types";
import { AxiosResponse } from "axios";
import Vue from "vue";
import i18n from "@/plugins/i18n/i18n";
import { handleHttpError } from "@/helpers";
import { http } from "@/services/api.service";

export const actions: ActionTree<BanState, RootState> = {
  async getBans({ commit }): Promise<Ban[]> {
    try {
      const res: AxiosResponse<Ban[]> = await http.get("/tenant/ban/all");
      if (!res.data) {
        commit("setBans", []);
        return [];
      }
      commit("setBans", res.data);
      return res.data;
    } catch (error) {
      handleHttpError(error, { 204: i18n.tc("Email is required") });
      throw error;
    }
  },
  async addBan(
    { commit },
    {
      soft,
      banDurationSeconds,
      userId,
      messageRouter,
      message,
    }: BanActionPayload
  ): Promise<Ban> {
    const data: BanOutDTO = {
      bannedMessage: message ?? "Banned in backoffice",
      banDurationSeconds: banDurationSeconds,
      userId,
      messageRouterPayload: messageRouter,
      softBan: soft,
    };
    try {
      const res = await http.post(`/ban`, data);
      if (res.status === 200) {
        Vue.$toast.success(i18n.tc(`You successfully banned this user!`), {
          timeout: 2000,
        });
        commit("updateBan", res.data);
      }
      return res.data;
    } catch (error) {
      handleHttpError(error, {
        404: i18n.tc(`The user does not exist!`),
        409: i18n.tc(`This user is already banned!`),
      });
      throw error;
    }
  },
  async deleteBan({ commit }, id: string): Promise<void> {
    try {
      const res = await http.delete(`/ban/${id}`);
      if (res.status === 200) {
        Vue.$toast.success(i18n.tc(`You successfully unbanned this user!`), {
          timeout: 2000,
        });
        commit("removeBan", id);
      }
      return;
    } catch (error) {
      handleHttpError(error, {
        404: i18n.tc(`The user does not exist!`),
        409: i18n.tc(`This user is already deleted!`),
      });
      throw error;
    }
  },
};
