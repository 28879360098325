import { MonitoringState } from "./types";

export const state: MonitoringState = {
  total: 0,
  concurrentUsers: 0,
  uniqueLinks: 0,
  uniqueUsers: 0,
  recurringUsersData: 0,
  uniqueUsersStat: [],
  concurrentUsersData: [],
  uniqueUsersData: [],
  linkClicks: [],
  chatActionEnd: [],
  chatActionReaction: [],
  chatActionReply: [],
  chatActionStart: [],
  chatActionUpub: [],
  chatActionVote: [],
  activeUsersData: [],
};
