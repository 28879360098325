import axios from "axios";
import { FLUXLOG_URL } from "@/helpers/envConstants";
import {
  StatsRequestContent,
  UsersStatsRequest,
} from "@/components/Monitoring/monitoring-store/types";
import { rejectInterceptor, requestInterceptor } from "../interceptors";

// Axios instance to handle all requests to spect8 fluxlog
export const http = axios.create({
  baseURL: FLUXLOG_URL,
  responseType: "json",
  headers: {
    apiKey: process.env.VUE_APP_FLUXLOG_APIKEY,
  },
});

// Request interceptors for API calls
http.interceptors.request.use(requestInterceptor);
http.interceptors.response.use(undefined, rejectInterceptor(http));

const fetchRecurringUsers = (
  data: UsersStatsRequest
): Promise<StatsRequestContent> =>
  http
    .get(FLUXLOG_URL + `/stats/recurringusers`, {
      params: {
        tenantid: data.tenantId,
        start: data.start,
        end: data.end,
      },
    })
    .then((res) => res?.data?.stats[0]?.timeseries?.data[0]?.v ?? 0);

const fetchConcurrentUsers = (
  data: UsersStatsRequest
): Promise<StatsRequestContent> =>
  http
    .get(FLUXLOG_URL + `/stats/concurrentusers`, {
      params: {
        tenantid: data.tenantId,
        start: data.start,
        end: data.end,
        aggwindow: data.aggwindow,
      },
    })
    .then((res) => res?.data?.stats[0]?.timeseries?.data);

const fetchUniqueUsers = (
  data: UsersStatsRequest
): Promise<StatsRequestContent> =>
  http
    .get(FLUXLOG_URL + `/stats/uniqueusers`, {
      params: {
        tenantid: data.tenantId,
        start: data.start,
        end: data.end,
        aggwindow: data.aggwindow,
      },
    })
    .then((res) => res?.data?.stats[0]?.timeseries?.data);

const fetchLinkClicks = (
  data: UsersStatsRequest
): Promise<StatsRequestContent> =>
  http
    .get(FLUXLOG_URL + `/stats/relaylinkclicks`, {
      params: {
        tenantid: data.tenantId,
        start: data.start,
        end: data.end,
        aggwindow: data.aggwindow,
      },
    })
    .then((res) => {
      return res?.data?.stats[0]?.timeseries?.data;
    });

const fetchChatActions = (
  data: UsersStatsRequest
): Promise<StatsRequestContent> =>
  http
    .get(FLUXLOG_URL + `/stats/chatactions`, {
      params: {
        tenantid: data.tenantId,
        start: data.start,
        end: data.end,
        aggwindow: data.aggwindow,
        action: data.action,
      },
    })
    .then((res) => {
      return res?.data?.stats[0]?.timeseries?.data;
    });

const fetchActiveUsersStat = (
  data: UsersStatsRequest
): Promise<StatsRequestContent> =>
  http
    .get(FLUXLOG_URL + `/stats/activeusers`, {
      params: {
        tenantid: data.tenantId,
        start: data.start,
        end: data.end,
      },
    })
    .then((res) => {
      return res?.data?.stats[0]?.timeseries?.data;
    });

export const fluxlogService = {
  http,
  fetchRecurringUsers,
  fetchConcurrentUsers,
  fetchUniqueUsers,
  fetchLinkClicks,
  fetchChatActions,
  fetchActiveUsersStat,
};
