import { Store } from "vuex";
import { vico } from "../stores/vico";
import { CORE_VICO_NAMESPACE, CoreVicoActions } from "../types";

class VicoModule {}

let vicoModule: VicoModule;

async function initVicoModule(store: Store<unknown>): Promise<VicoModule> {
  // register core vico store
  if (!store.hasModule(CORE_VICO_NAMESPACE)) {
    store.registerModule(CORE_VICO_NAMESPACE, vico);
    store.dispatch(CoreVicoActions.FetchServers);
  }

  // create module instance
  if (vicoModule) {
    return vicoModule;
  }
  vicoModule = new VicoModule();
  return vicoModule;
}

export { vicoModule, initVicoModule };
