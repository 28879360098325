import { IaService } from "@/services/api/ia.service";
import { RootState } from "@/store/RootState";
import { Action, ActionContext } from "vuex/types/index";
import {
  CreateQuestionPayload,
  QAAction,
  QAMutation,
  QAState,
  Question,
  UpdateQuestionPayload,
} from "../types";

const fetchQuestions = async (
  { state, commit }: ActionContext<QAState, RootState>,
  broadcastId: string
): Promise<void> => {
  if (state.loading) return;

  try {
    commit(QAMutation.SetLoading, true);
    const questions = await IaService.getQuestions(broadcastId);
    commit(QAMutation.SetQuestions, questions);
  } finally {
    commit(QAMutation.SetLoading, false);
  }
};

const updateQuestion = async (
  { commit }: ActionContext<QAState, RootState>,
  payload: UpdateQuestionPayload
): Promise<void> => {
  const updatedQuestion = await IaService.updateQuestion(
    payload.questionId,
    payload.patchDto
  );
  commit(QAMutation.UpdateQuestion, updatedQuestion);
};

const createQuestion = async (
  { state, commit }: ActionContext<QAState, RootState>,
  payload: CreateQuestionPayload
): Promise<void> => {
  const savedQuestion = await IaService.createQuestion(
    payload.broadcastId,
    payload.dto
  );

  if (state.questions.findIndex((q) => q.id === savedQuestion.id) === -1) {
    commit(QAMutation.AddQuestion, savedQuestion);
  }
};

const setSelectedBroadcastId = async (
  { commit }: ActionContext<QAState, RootState>,
  broadcastId: string
): Promise<void> => {
  commit(QAMutation.SetSelectedBroadcastId, broadcastId);
};

const addQuestion = async (
  { state, commit }: ActionContext<QAState, RootState>,
  question: Question
): Promise<void> => {
  const questionFound =
    state.questions.findIndex((q) => q.id === question.id) > -1;
  if (!questionFound) {
    commit(QAMutation.AddQuestion, question);
  }
};

const deleteQuestion = async (
  { commit }: ActionContext<QAState, RootState>,
  questionId: string
): Promise<void> => {
  await IaService.deleteQuestion(questionId);
  commit(QAMutation.RemoveQuestion, questionId);
};

const removeQuestion = async (
  { commit }: ActionContext<QAState, RootState>,
  questionId: string
): Promise<void> => {
  commit(QAMutation.RemoveQuestion, questionId);
};

const editQuestion = async (
  { commit }: ActionContext<QAState, RootState>,
  question: Question
): Promise<void> => {
  commit(QAMutation.UpdateQuestion, question);
};

export const actions: Record<QAAction, Action<QAState, RootState>> = {
  [QAAction.FetchQuestions]: fetchQuestions,
  [QAAction.UpdateQuestion]: updateQuestion,
  [QAAction.CreateQuestion]: createQuestion,
  [QAAction.SetSelectedBroadcastId]: setSelectedBroadcastId,
  [QAAction.AddQuestion]: addQuestion,
  [QAAction.DeleteQuestion]: deleteQuestion,
  [QAAction.RemoveQuestion]: removeQuestion,
  [QAAction.EditQuestion]: editQuestion,
};
