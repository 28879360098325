import { Action, ActionTree } from "vuex";
import { configModule } from "../../modules/config";
import { coreApiService } from "../../services/core";
import { trackingService } from "../../services/tracking";
import {
  ConfigAction,
  ConfigMutation,
  ConfigState,
  TenantConfig,
  TenantPhase,
} from "../../types";
import { Logger } from "../../utils/Logger";

export const actions: ActionTree<ConfigState, unknown> &
  Record<ConfigAction, Action<ConfigState, unknown>> = {
  async [ConfigAction.FetchTenantConfig](
    context,
    tenantName?: string
  ): Promise<TenantConfig | null> {
    if (tenantName) {
      try {
        const tenantConfig = await coreApiService.getTenantByName(tenantName);
        tenantConfig.mediaUploadBaseUrl =
          tenantConfig.mediaUploadBaseUrl || configModule.config.mediaBaseUrl;
        context.commit(ConfigMutation.TenantConfig, tenantConfig);
        Logger.debug("Set TenantConfig success", tenantConfig);
        return tenantConfig;
      } catch (error) {
        Logger.debug("Set TenantConfig error", error);
        trackingService?.logError({
          ea: "error",
          ec: "tenantConfig",
          ed: error,
        });
      }
    }
    return null;
  },
  async [ConfigAction.SetPhase]({ commit, state }, phase: TenantPhase) {
    try {
      const tenantId = state.tenantConfig?.tenantId;
      if (tenantId) {
        const response = await coreApiService.updateTenant(tenantId, {
          phase: phase,
        });
        const tenantConfig: TenantConfig = response;
        tenantConfig.mediaUploadBaseUrl =
          tenantConfig.mediaUploadBaseUrl || configModule.config.mediaBaseUrl;
        commit(ConfigMutation.TenantConfig, tenantConfig);
        Logger.debug("Set Phase success", tenantConfig);
      }
    } catch (error) {
      Logger.debug("Set Phase error", error);
    }
  },
};
