import Vue from "vue";
import { Mutation, MutationTree } from "vuex";
import { ThemeConfig, ThemeMutation, ThemeState } from "../../types";

export const mutations: MutationTree<ThemeState> &
  Record<ThemeMutation, Mutation<ThemeState>> = {
  [ThemeMutation.Theme](state, theme: ThemeConfig) {
    Vue.set(state, "theme", theme);
  },
};
