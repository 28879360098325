



























































































import { Vue, Component, Watch } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import NavBarSubMenu from "./NavBarSubMenu.vue";
import { LegalLinks } from "./types";
import { Namespace } from "@/store/types";
import { ConfigGetters, Features } from "@/config/types";
import { Locale } from "@/spect8-core-vue/src/i18n";
import { AppGetters } from "@/store/app/types";
import { CoreAuthActions, CoreUserActions } from "@/spect8-core-vue/src/types";

@Component({
  components: {
    NavBarSubMenu,
  },
})
export default class NavBar extends Vue {
  @Action(CoreAuthActions.Logout)
  logout!: () => Promise<void>;

  @Action(CoreUserActions.UpdateUserLanguage)
  updateUserLanguage!: (language: string) => Promise<boolean>;

  @Action(`${Namespace.Conference}/setSetupDialog`)
  setSetupDialog!: (dialog: boolean) => void;

  @Action(`${Namespace.Conference}/setTestCallDialog`)
  setTestCallDialog!: (dialog: boolean) => void;

  @Getter(`${Namespace.Conference}/testCall`)
  testCall!: boolean;

  @Getter(ConfigGetters.EnabledFeatures)
  readonly enabledFeatures!: Features;

  @Getter(AppGetters.Locale)
  readonly locale!: string;
  @Watch("locale", { immediate: true })
  onLocaleChanged(locale: string): void {
    this.selectedLanguage = locale;
  }

  selectedLanguage = "";

  languageMenuActive = false;
  legalMenuActive = false;

  languages = Object.values(Locale);

  async changeLanguage(lang: string) {
    this.languageMenuActive = false;
    if (lang !== this.selectedLanguage) {
      const success = await this.updateUserLanguage(lang);
      if (success) {
        this.$toast.success(this.$tc(`You successfully changed language!`), {
          timeout: 2000,
        });
      }
    }
  }

  get legalLinks(): LegalLinks {
    const navItems: LegalLinks = {
      termsOfUse: {
        label: "legal.termsOfUse",
        link: "https://www.vaudience.net/terms-of-use/",
      },
      imprint: {
        label: "legal.imprint",
        link: "https://www.vaudience.net/imprint/",
      },

      privacyPolicy: {
        label: "legal.privacyPolicy",
        link: "https://www.vaudience.net/privacy/",
      },
    };

    switch (this.$i18n.locale) {
      case Locale.DE:
        navItems.termsOfUse.link =
          "https://www.vaudience.net/de/nutzungsbedingungen/";
        navItems.imprint.link = "https://www.vaudience.net/de/impressum/";
        navItems.privacyPolicy.link =
          "https://www.vaudience.net/de/datenschutzerklaerung/";
        break;
    }

    return navItems;
  }
}
