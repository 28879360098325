import { Module } from "vuex";
import { UserState } from "../../types";
import { actions } from "./actions";
import { getters } from "./getters";
import { mutations } from "./mutations";

// ReExport to be backward compatible
export { CORE_USER_NAMESPACE } from "../../types";

export const state: UserState = {
  faUser: null,
  user: null,
};

const namespaced = true;

export const user: Module<UserState, unknown> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
