import { Mutation } from "vuex";
import {
  GemaMemberRecordRegistration,
  GemaMemberSearchMutation,
  GemaMemberSearchState,
} from "./types";

export const mutations: Record<
  GemaMemberSearchMutation,
  Mutation<GemaMemberSearchState>
> = {
  [GemaMemberSearchMutation.SetResults](
    state,
    results: GemaMemberRecordRegistration[] | null
  ) {
    state.results = results;
  },
  [GemaMemberSearchMutation.SetSearchTerm](state, searchTerm: string) {
    state.searchTerm = searchTerm;
  },
};
