import { fluxlogService } from "@/services/api/fluxlog.service";
import { CoreConfigGetters } from "@/spect8-core-vue/src/types";
import { RootState } from "@/store/RootState";
import { Action, ActionContext } from "vuex";
import {
  MonitoringState,
  UsersStatsRequest,
  MonitoringAction,
  MonitoringMutation,
  ChatActions,
} from "./types";

const fetchRecurringUsers = async (
  { commit, rootGetters }: ActionContext<MonitoringState, RootState>,
  data: UsersStatsRequest
): Promise<void> => {
  const { tenantId } = rootGetters[CoreConfigGetters.TenantConfig];
  data.tenantId = tenantId;
  const recurringUsersStats = await fluxlogService.fetchRecurringUsers(data);
  commit(MonitoringMutation.SetRecurringUsers, recurringUsersStats);
};

const fetchConcurrentUsers = async (
  { commit, rootGetters }: ActionContext<MonitoringState, RootState>,
  data: UsersStatsRequest
): Promise<void> => {
  const { tenantId } = rootGetters[CoreConfigGetters.TenantConfig];
  data.tenantId = tenantId;
  const concrrentUsersStats = await fluxlogService.fetchConcurrentUsers(data);
  commit(MonitoringMutation.SetConcurrentUsers, concrrentUsersStats);
};

const fetchUniqueUsers = async (
  { commit, rootGetters }: ActionContext<MonitoringState, RootState>,
  data: UsersStatsRequest
): Promise<void> => {
  const { tenantId } = rootGetters[CoreConfigGetters.TenantConfig];
  data.tenantId = tenantId;
  const uniqueUsersStats = await fluxlogService.fetchUniqueUsers(data);
  if (data?.aggwindow) {
    commit(MonitoringMutation.SetUniqueUsers, uniqueUsersStats);
  } else {
    commit(MonitoringMutation.SetUniqueUsersStat, uniqueUsersStats);
  }
};

const fetchLinkClicks = async (
  { commit, rootGetters }: ActionContext<MonitoringState, RootState>,
  data: UsersStatsRequest
): Promise<void> => {
  const { tenantId } = rootGetters[CoreConfigGetters.TenantConfig];
  data.tenantId = tenantId;
  const linkClicksStats = await fluxlogService.fetchLinkClicks(data);
  commit(MonitoringMutation.SetLinkClicks, linkClicksStats);
};

const fetchChatActions = async (
  { commit, rootGetters }: ActionContext<MonitoringState, RootState>,
  data: UsersStatsRequest
): Promise<void> => {
  const { tenantId } = rootGetters[CoreConfigGetters.TenantConfig];
  data.tenantId = tenantId;
  const chatActionStats = await fluxlogService.fetchChatActions(data);
  if (data.action === ChatActions.end) {
    commit(MonitoringMutation.SetChatActionEnd, chatActionStats);
  } else if (data.action === ChatActions.reaction) {
    commit(MonitoringMutation.SetChatActionReaction, chatActionStats);
  } else if (data.action === ChatActions.reply) {
    commit(MonitoringMutation.SetChatActionReply, chatActionStats);
  } else if (data.action === ChatActions.start) {
    commit(MonitoringMutation.SetChatActionStart, chatActionStats);
  } else if (data.action === ChatActions.upub) {
    commit(MonitoringMutation.SetChatActionUpub, chatActionStats);
  } else {
    commit(MonitoringMutation.SetChatActionVote, chatActionStats);
  }
};

const fetchActiveUsersStat = async (
  { commit, rootGetters }: ActionContext<MonitoringState, RootState>,
  data: UsersStatsRequest
): Promise<void> => {
  const { tenantId } = rootGetters[CoreConfigGetters.TenantConfig];
  data.tenantId = tenantId;
  const activeUsersStat = await fluxlogService.fetchActiveUsersStat(data);
  commit(MonitoringMutation.SetActiveUsersData, activeUsersStat);
};

export const actions: Record<
  MonitoringAction,
  Action<MonitoringState, RootState>
> = {
  [MonitoringAction.FetchRecurringUsers]: fetchRecurringUsers,
  [MonitoringAction.FetchConcurrentUsers]: fetchConcurrentUsers,
  [MonitoringAction.FetchUniqueUsers]: fetchUniqueUsers,
  [MonitoringAction.FetchLinkClicks]: fetchLinkClicks,
  [MonitoringAction.FetchChatActions]: fetchChatActions,
  [MonitoringAction.FetchActiveUsersStat]: fetchActiveUsersStat,
};
