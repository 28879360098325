import { PresenceLog } from "@/components/SecureVotingPolls/types";

export interface MessageRouterPayload {
  broadcastIds: string[];
}

export enum PollStatusSelection {
  PREPARING = "PREPARING",
  PRE_VOTE = "PRE_VOTE",
  HIDDEN = "HIDDEN",
  LIVE = "LIVE",
  CLOSED = "CLOSED",
}

export enum PollGroupSelection {
  T = "T",
  K = "K",
  V = "V",
  ALL = "ALL",
}

export enum PollMemberStatusSelection {
  O = "O",
  A = "A",
  ALL = "ALL",
}

export interface UpdateStatus {
  id: string;
  status: PollStatusSelection;
}

export interface PollAnswer {
  answerId: string;
  value: string;
  voteCount?: number;
  liveVoteCount?: number;
  preVoteCount?: number;
}

export interface NewPollAnswerDto {
  value: string;
}

export interface PollDto {
  tenantId: string;
  broadcastIds: string[];
  targetMemberStatus: PollMemberStatusSelection | null;
  targetGroup: PollGroupSelection | null;
  type: SVPollType;
  title: string;
  link?: string;
  question: string;
  answers: NewPollAnswerDto[];
  minVotes?: number;
  maxVotes?: number;
  status: PollStatusSelection;
}

export interface UpdatePollDto {
  id: string;
  question: string;
  answers: PollAnswer[];
  title: string;
  broadcastIds: string[];
  targetMemberStatus: PollMemberStatusSelection | null;
  targetGroup: PollGroupSelection | null;
  status: PollStatusSelection;
  minVotes?: number;
  maxVotes?: number;
}

export interface Poll {
  presenceLogs?: PresenceLog[];
  logTimestamp?: string;
  questionId: string;
  tenantId: string;
  broadcastIds: string[];
  targetMemberStatus: PollMemberStatusSelection | null;
  targetGroup: PollGroupSelection | null;
  type: SVPollType;
  title: string;
  link?: string;
  question: string;
  answerVotes: PollAnswer[];
  minVotes?: number;
  maxVotes?: number;
  minimumVotes?: number;
  maximumVotes?: number;
  status: PollStatusSelection;
  uniqueAbstainedVotes: number;
  uniqueValidVotes: number;
  uniqueVotes: number;
}

export interface PollData {
  id?: string;
  tenantId?: string;
  broadcastIds?: string[];
  targetMemberStatus: PollMemberStatusSelection | null;
  targetGroup: PollGroupSelection | null;
  type?: SVPollType;
  title?: string;
  link?: string;
  question?: string;
  answers?: PollAnswer[];
  minVotes?: number;
  maxVotes?: number;
  status?: PollStatusSelection;
}

export enum SVPollType {
  ELECTION = "ELECTION", // => "Wahl"
  POLL = "POLL", // => "Abstimmung"
}

export interface VotePresenceResponse {
  broadcastId: string;

  accumulatedNumberOfUsersWithVotingRights: number;
  accumulatedNumberOfUsersWithVotingRightsDigital: number;
  accumulatedNumberOfUsersWithVotingRightsInPerson: number;
  accumulatedNumberOfVotes: number;
  accumulatedNumberOfVotesDigital: number;
  accumulatedNumberOfVotesInPerson: number;
  currentNumberOfUsersWithVotingRights: number;
  currentNumberOfUsersWithVotingRightsDigital: number;
  currentNumberOfUsersWithVotingRightsInPerson: number;
  currentNumberOfVotes: number;
  currentNumberOfVotesDigital: number;
  currentNumberOfVotesInPerson: number;
}
