export class LocalStorage {
  static getItem(key: string): string | null {
    if (window.localStorage) {
      return window.localStorage.getItem(key);
    }
    return null;
  }

  static removeItem(key: string): void {
    if (window.localStorage) {
      window.localStorage.removeItem(key);
    }
  }

  static setItem(key: string, value: string): void {
    if (window.localStorage) {
      window.localStorage.setItem(key, value);
    }
  }
}
