import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import vuetify from "./plugins/vuetify";
import Toast, { POSITION } from "vue-toastification";
import "vue-toastification/dist/index.css";
import { LogLevels } from "vuejs-logger/dist/enum/log-levels";
import VueLoggerPlugin from "vuejs-logger/dist/vue-logger";
import i18n from "./plugins/i18n/i18n";
import "@/plugins/vee-validate";
import DatetimePicker from "vuetify-datetime-picker";
import {
  VICO_URL,
  MSGBUS_API_KEY,
  LOGIN_LANDING_PAGE,
} from "./helpers/envConstants";
import {
  initAuthModule,
  initConfigModule,
  initMediaDevicesModule,
  initUserModule,
  initVicoModule,
} from "./spect8-core-vue/src/modules";
import { initVicoService } from "./spect8-core-vue/src/services/vico";
import { RouteName } from "./router/types";
import { showNotification } from "./helpers";
import { initCoreApiService } from "./spect8-core-vue/src/services/core";
import { Role } from "./spect8-core-vue/src/types";
import { initTrackingService } from "./spect8-core-vue/src/services/tracking";

Vue.config.productionTip = false;

const isProduction = process.env.NODE_ENV === "production";

const logOptions = {
  isEnabled: true,
  logLevel: isProduction ? LogLevels.ERROR : LogLevels.DEBUG,
  stringifyArguments: false,
  showLogLevel: true,
  showMethodName: true,
  separator: "|",
  showConsoleColors: true,
};
Vue.use(VueLoggerPlugin, logOptions);

Vue.use(Toast, {
  transition: "Vue-Toastification__bounce",
  maxToasts: 20,
  newestOnTop: true,
  position: POSITION.BOTTOM_RIGHT,
});
Vue.use(DatetimePicker);

// init core api service, used by different core modules
initCoreApiService(process.env.VUE_APP_API_BASE_URL, store);

// init tracking service
initTrackingService(router, store);

// init vico service
initVicoService(VICO_URL, MSGBUS_API_KEY);

// Initialise spect8-core modules
initConfigModule(
  {
    mediaBaseUrl: process.env.VUE_APP_UPMAN_MEDIA_BASE_URL,
  },
  store
);
initUserModule(store);
initAuthModule(
  {
    approvedRoles: [Role.ADMIN, Role.BROADCAST_MODERATOR],
    loginScopeKey: "s8-admin-login-scope",
    notificationCallback: showNotification,
    routes: {
      // TODO: REMOVE HACKY LANDING PAGE IMPLEMENTATION
      home: LOGIN_LANDING_PAGE ? LOGIN_LANDING_PAGE : RouteName.Dashboard,
      login: RouteName.SignIn,
    },
    fusionAuthUrl: process.env.VUE_APP_FUSION_AUTH_URL,
    pinAuthUrl: process.env.VUE_APP_ANONU_URL,
    sessionAuthUrl: process.env.VUE_APP_SESSION_AUTH_URL,
  },
  router,
  store
);
initMediaDevicesModule(store);
initVicoModule(store);

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
