import { UPMAN_API_URL } from "@/helpers/envConstants";
import axios from "axios";
import { faRequestInterceptor, rejectInterceptor } from "../interceptors";
import {
  UpManFileUploadDto,
  UpManFileUploadResponse,
  UpManGeneralFile,
} from "./upman.service.types";

const http = axios.create({
  baseURL: UPMAN_API_URL,
  responseType: "json",
  headers: {
    "Content-Type": "multipart/form-data",
  },
  withCredentials: true,
});

http.interceptors.request.use(faRequestInterceptor);
http.interceptors.response.use(undefined, rejectInterceptor);

const uploadFile = async (
  fileUploadDto: UpManFileUploadDto
): Promise<UpManGeneralFile> => {
  const formData = new FormData();

  for (const [key, value] of Object.entries(fileUploadDto)) {
    formData.append(key, value);
  }

  return http.post<UpManFileUploadResponse>("/files", formData).then((res) => {
    const file = res.data.files[0];

    return {
      upmanId: file.fileId,
      uploaderId: file.uploaderId,
      fileSize: file.fileSize,
      tags: file.tags,
      filename: file.filename,
      publicPath: file.publicPath,
      fileExtension: file.fileExtension,
    };
  });
};

const deleteFile = async (fileId: string): Promise<void> => {
  return http.delete(`/files/${fileId}`);
};

const UpMan = {
  uploadFile,
  deleteFile,
};

export default UpMan;
