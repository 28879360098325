import {
  Conference,
  TrackInfo,
  TrackSlot,
} from "@/spect8-core-vue/src/types/vico";

export interface SwitchboardState {
  trackinfos: TrackInfo[];
  conferences: Conference[];
  slotTracks: TrackSlotDraggable[];
}

export interface TrackSlotDraggable {
  id?: string;
  tenantId?: string;
  broadcastId?: string;
  conferenceId?: string;
  userId?: string;
  kind?: number;
  peerId?: string;
  peerName?: string;
  slotId?: string;
  streamId?: string;
  trackId?: string;
  sourceType?: string;
  codecCompatibility?: {
    MimeType: string;
    ClockRate: number;
    Channels: number;
    SDPFmtpLine: string;
    RTCPFeedback: null;
  };
  trackInfoId?: string;
  trackKind?: number;
}

export interface AssignSlotTrack {
  tenantId: string;
  conferenceIds: string[];
  slotId: Record<string, boolean>;
  removeAllOtherSlotAssignments: boolean;
  slotAssignments: TrackSlotAssignment[];
}

export interface TrackSlotAssignment extends TrackSlot {
  conferenceId?: string;
}

export enum SwitchboardAction {
  FetchTrackInfos = "FetchTrackInfos",
  FetchConferences = "FetchConferences",
  AssignSlotTracks = "AssignSlotTracks",
  PushSlotTrack = "PushSlotTrack",
  DeleteSlotTrack = "DeleteSlotTrack",
  ClearSlotTracks = "ClearSlotTracks",
}

export enum SwitchboardMutation {
  SetTrackInfos = "SetTrackInfos",
  SetConferences = "SetConferences",
  PushSlotTrack = "PushSlotTrack",
  DeleteSlotTrack = "DeleteSlotTrack",
  ClearSlotTracks = "ClearSlotTracks",
}

export enum SwitchboardGetter {
  GetAllTrackInfos = "GetAllTrackInfos",
  GetAllConferences = "GetConferences",
  GetActiveConferences = "GetActiveConferences",
  GetSlotTracks = "GetSlotTracks",
}
