import Vue from "vue";
import { MutationTree } from "vuex";
import { Ban, BanState } from "./types";

export const mutations: MutationTree<BanState> = {
  setBans(state: BanState, payload: Ban[]): void {
    Vue.set(state, "bans", payload);
  },
  updateBan(state: BanState, updatedBan: Ban): void {
    const index = state.bans.findIndex((ban) => ban.id === updatedBan.id);
    if (index !== -1) {
      state.bans[index] = updatedBan;
    } else {
      state.bans.push(updatedBan);
    }
  },
  removeBan(state: BanState, banId: string): void {
    Vue.set(
      state,
      "bans",
      state.bans.filter((ban) => ban.id !== banId)
    );
  },
};

export default mutations;
