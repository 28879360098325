import { User } from "@/spect8-core-vue/src/types";
import { Getter, GetterTree } from "vuex";
import { RootState } from "../RootState";
import { UsersGetter, UsersState } from "./types";

export const getters: GetterTree<UsersState, RootState> &
  Record<UsersGetter, Getter<UsersState, RootState>> = {
  [UsersGetter.Users](state): User[] {
    return state.users.filter(
      (user) => user.editRequest?.displayName !== undefined
    );
  },
};
