import { User } from "@/spect8-core-vue/src/types";
import Vue from "vue";
import { Mutation, MutationTree } from "vuex";
import { UsersMutation, UsersState } from "./types";

export const mutations: MutationTree<UsersState> &
  Record<UsersMutation, Mutation<UsersState>> = {
  [UsersMutation.SetUsers](state, users: User[]) {
    Vue.set(state, "users", users);
  },
};

export default mutations;
