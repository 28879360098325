import { Getter, GetterTree } from "vuex";
import {
  AuthGetter,
  AuthState,
  LoginScope,
  LoginType,
  Tokens,
} from "../../types";

export const getters: GetterTree<AuthState, unknown> &
  Record<AuthGetter, Getter<AuthState, unknown>> = {
  [AuthGetter.Authenticated](state): boolean {
    return state.authenticated;
  },
  [AuthGetter.AccessTokenExt](state): string | undefined {
    return state.accessTokenExt;
  },
  [AuthGetter.Tokens](state): Tokens | undefined {
    return state.tokens;
  },
  [AuthGetter.LoginScope](state): LoginScope | undefined {
    return state.loginScope;
  },
  [AuthGetter.LoginType](state): LoginType | undefined {
    return state.loginScope?.loginType;
  },
};
