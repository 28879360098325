import { Poll } from "@/services/api/secure-votes.service.types";
import { Namespace } from "@/store/types";
export interface PollState {
  securePolls: Poll[];
  secureActivePolls: Poll[];
  editSecurePoll: Poll | null;
}

export enum PollAction {
  CreatePoll = "CreatePoll",
  FetchPolls = "FetchPolls",
  FetchPoll = "FetchPoll",
  UpdatePoll = "UpdatePoll",
  DeletePoll = "DeletePoll",
  ResetPolls = "ResetPolls",
  //Edit Poll
  SetEditPoll = "SetEditPoll",
  ClearEditPoll = "ClearEditPoll",
  UpdateStatus = "UpdateStatus",
  SendOverlayEvent = "SendOverlayEvent",
  UpdatePollVotes = "UpdatePollVotes",
  FetchPresenceStats = "FetchPresenceStats",
  SendPresence = "SendPresence",
  //   FetchPollsByBroadcastIds = "FetchPollsByBroadcastIds",
  //   FetchPollsByChannelIds = "FetchPollsByChannelIds"
}

export const PollActions: Record<PollAction, string> = {
  CreatePoll: `${Namespace.SecurePolls}/${PollAction.CreatePoll}`,
  FetchPolls: `${Namespace.SecurePolls}/${PollAction.FetchPolls}`,
  FetchPoll: `${Namespace.SecurePolls}/${PollAction.FetchPoll}`,
  UpdatePoll: `${Namespace.SecurePolls}/${PollAction.UpdatePoll}`,
  DeletePoll: `${Namespace.SecurePolls}/${PollAction.DeletePoll}`,
  ResetPolls: `${Namespace.SecurePolls}/${PollAction.ResetPolls}`,
  SetEditPoll: `${Namespace.SecurePolls}/${PollAction.SetEditPoll}`,
  ClearEditPoll: `${Namespace.SecurePolls}/${PollAction.ClearEditPoll}`,
  UpdateStatus: `${Namespace.SecurePolls}/${PollAction.UpdateStatus}`,
  SendOverlayEvent: `${Namespace.SecurePolls}/${PollAction.SendOverlayEvent}`,
  UpdatePollVotes: `${Namespace.SecurePolls}/${PollAction.UpdatePollVotes}`,
  FetchPresenceStats: `${Namespace.SecurePolls}/${PollAction.FetchPresenceStats}`,
  SendPresence: `${Namespace.SecurePolls}/${PollAction.SendPresence}`,
};

export enum PollMutation {
  SetPolls = "SetPolls",
  ResetPolls = "ResetPolls",
  AddPoll = "AddPoll",
  DeletePoll = "DeletePoll",
  UpdatePoll = "UpdatePoll",
  SetEditPoll = "SetEditPoll",
  ClearEditPoll = "ClearEditPoll",
}

export enum PollGetter {
  Polls = "Polls",
  ActivePolls = "ActivePolls",
  EditPoll = "EditPoll",
}

export const PollGetters: Record<PollGetter, string> = {
  Polls: `${Namespace.SecurePolls}/${PollGetter.Polls}`,
  ActivePolls: `${Namespace.SecurePolls}/${PollGetter.ActivePolls}`,
  EditPoll: `${Namespace.SecurePolls}/${PollGetter.EditPoll}`,
};

export interface PollDurationOption {
  label: string;
  value: number; // Minutes
}

export interface PresenceLog {
  broadcastId: string | null;
  currentNumberOfUsersWithVotingRights: number | null;
  currentNumberOfUsersWithVotingRightsDigital: number | null;
  currentNumberOfUsersWithVotingRightsInPerson: number | null;
  currentNumberOfVotes: number | null;
  currentNumberOfVotesDigital: number | null;
  currentNumberOfVotesInPerson: number | null;
}

export interface PresenceLogObject {
  presenceLogs: PresenceLog[];
  logTimestamp: string;
}
export interface PresenceSendObject {
  questionId: string;
  emailRecipients: string[];
  ccAddresses: string[];
}
