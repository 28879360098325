import { VideoAdsState } from "./types";

export const state: VideoAdsState = {
  allVideoAds: { content: [] },
  allScheduledAds: { content: [] },
  ScheduledAds: {
    cron: "",
    scheduledAt: "",
    id: "",
    lastExecutedAt: "",
    name: "",
    broadcasts: [
      {
        broadcastName: "",
        id: "",
        tenantId: "",
      },
    ],
    schedulable: {
      "@type": "",
      id: "",
      altText: "",
      clickLink: "",
      video: {
        src: "",
        mimeType: "",
      },
      name: "",
      closableAfterSeconds: 0,
    },
  },
  VideoAdsError: "",
  VideoAds: {
    "@type": "",
    id: "",
    altText: "",
    clickLink: "",
    video: {
      src: "",
      mimeType: "",
    },
    closableAfterSeconds: 0,
  },
  BroadcastIdError: "",
  BroadcastIdList: [],
  ScheduledAdsError: "",
};
