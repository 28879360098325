export enum Namespace {
  Accounts = "Accounts",
  App = "app",
  Broadcast = "broadcast",
  Config = "config",
  Conference = "Conference",
  Monitoring = "Monitoring",
  Nchan = "Nchan",
  Polls = "Polls",
  PollResults = "PollResults",
  Report = "Report",
  Resources = "Resources",
  SecurePolls = "SecurePolls",
  Switchboard = "Switchboard",
  Users = "users",
  Qa = "Qa",

  Registration = "Registration",
  GemaMemberSearch = "GemaMemberSearch",
}
