import { RootState } from "@/store/RootState";
import { Getter } from "vuex";
import { QAGetter, QAState } from "../types";

export const getters: Record<QAGetter, Getter<QAState, RootState>> = {
  [QAGetter.Questions](state) {
    return state.questions;
  },
  [QAGetter.SelectedBroadcastId](state) {
    return state.selectedBroadcastId;
  },
};
