import { WsEventData } from "../../types";
import PubSub from "pubsub-js";
import { RTCInfo } from "./RtcSlot";

export class WebsocketMessageOutgoing {
  targetType: string;
  targetId: string;
  from: string;
  content: string;

  constructor(public topic: string, content: Record<string, unknown> | string) {
    this.targetType = "Server";
    this.targetId = "";
    this.from = "";
    this.topic = topic;
    this.content =
      typeof content === "object" ? JSON.stringify(content) : content;
  }
}

export interface Config {
  reconnect?: boolean;
  reconnectInterval?: number;
}

export interface WebsocketError {
  reason: string;
  code: number;
  message: string;
}

/** Websocket wrapper and event manager */
export class WebsocketConnection {
  websocket?: WebSocket;
  private config: Config;
  private reconnectInterval: ReturnType<typeof setTimeout> | null = null;

  constructor(public rtcInfo: RTCInfo, websocket?: WebSocket) {
    this.config = {
      reconnect: true,
      reconnectInterval: 5000,
    };
    if (websocket) this.websocket = websocket;
  }

  setReconnect(): ReturnType<typeof setTimeout> | null {
    if (
      this.config.reconnect === true &&
      this.config.reconnectInterval &&
      this.config.reconnectInterval > 0
    ) {
      return setInterval(() => {
        if (
          typeof this.websocket !== "undefined" &&
          this.websocket.readyState === 3 &&
          this.config.reconnect
        ) {
          this.connect();
        }
      }, this.config.reconnectInterval);
    }
    return null;
  }

  connect(): void {
    const path = [
      this.rtcInfo.signalSocketBaseUrl,
      this.rtcInfo.tenantId,
      this.rtcInfo.broadcastId,
      this.rtcInfo.conferenceId,
      this.rtcInfo.userId,
      this.rtcInfo.isBroadcaster,
      this.rtcInfo.slotId,
    ].join("/");
    this.websocket = new WebSocket(path);

    this.websocket.onopen = (event) => {
      const subsTriggered = PubSub.publish("open", { details: event });
      console.log(`Connection established -> triggered subs=${subsTriggered}`);
    };

    this.websocket.onmessage = (event) => {
      // console.log( "[socket.onmessage] onmessage Data received from server: ", event.data);
      let msg: WsEventData;
      try {
        msg = JSON.parse(event.data);
      } catch (err) {
        console.error("Error during JSON parsing", err);
        return;
      }
      if (!msg.topic) return;
      PubSub.publish(msg.topic, msg.content);
      // console.log(`Event: ${msg.topic} triggered subscribers`, subsTriggered);
    };

    this.websocket.onclose = (event) => {
      const subsTriggered = PubSub.publish("close", {
        code: event.code,
        reason: event.reason,
      });

      if (event.wasClean) {
        console.log(
          `Connection closed cleanly, code=${event.code} reason=${event.reason} -> triggered subs=${subsTriggered}`
        );
      } else {
        // e.g. server process killed or network down
        // event.code is usually 1006 in this case
        console.log(
          `Connection died unexpectedly -> triggered subs=${subsTriggered}`
        );
      }
    };

    // TODO: error type? where is it from? is it defined?
    this.websocket.onerror = (error: any) => {
      if (error.code && error.message) {
        const subsTriggered = PubSub.publish("error", {
          code: error.code,
          message: error.message,
        });
        console.warn(`${error.message} -> triggered subs=${subsTriggered}`);
      }
    };

    this.reconnectInterval = this.setReconnect();
  }

  sendEvent(topic: string, eventData: any): void {
    const eventObject = new WebsocketMessageOutgoing(topic, eventData);
    const payload = JSON.stringify(eventObject);
    if (this.websocket && this.websocket.readyState === WebSocket.OPEN)
      this.websocket.send(payload);
  }

  on(topic: string, cb: (topic: string, content: any) => void): void {
    this.websocket?.addEventListener("message", (ev) => {
      const data: WsEventData = JSON.parse(ev.data);
      const contentParsed = JSON.parse(data.content);
      if (data.topic && data.topic === topic) cb(topic, contentParsed);
    });
    // return PubSub.subscribe(`${topic}${id ? "." + id : ""}`, cb);
  }

  // off(topic: string, id: string): boolean {
  off(cb: (topic: string, content: string) => void): string | boolean {
    return PubSub.unsubscribe(cb);
  }
}
