















































































































import { Component, Vue, Ref, Prop, Watch } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import { CoreMediaDevicesActions, CoreMediaDevicesGetters } from "../../types";
import Mirror from "./Mirror.vue";

type Option = { text: string; value: string };

@Component({
  components: { Mirror },
})
export default class MediaSetup extends Vue {
  @Prop({ default: 500 }) maxWidth!: string;

  @Action(CoreMediaDevicesActions.Init)
  init!: () => Promise<void>;
  @Action(CoreMediaDevicesActions.StartMedia)
  startMedia!: () => Promise<MediaStream | void>;
  @Action(CoreMediaDevicesActions.SelectAudio)
  selectAudio!: (device?: MediaDeviceInfo) => void;
  @Action(CoreMediaDevicesActions.SelectVideo)
  selectVideo!: (device?: MediaDeviceInfo) => void;
  @Action(CoreMediaDevicesActions.ToggleVideo)
  toggleVideoAction!: () => void;
  @Action(CoreMediaDevicesActions.ToggleVideo)
  toggleAudioAction!: () => void;

  @Getter(CoreMediaDevicesGetters.AudioDevices)
  readonly audioDevices!: MediaDeviceInfo[];
  @Getter(CoreMediaDevicesGetters.VideoDevices)
  readonly videoDevices!: MediaDeviceInfo[];
  @Getter(CoreMediaDevicesGetters.AudioSelected)
  readonly audioSelected?: MediaDeviceInfo;
  @Getter(CoreMediaDevicesGetters.VideoSelected)
  readonly videoSelected?: MediaDeviceInfo;
  @Getter(CoreMediaDevicesGetters.Stream)
  readonly stream!: MediaStream;

  @Ref("video") videoElement!: HTMLMediaElement;

  @Watch("videoSelected", { immediate: true })
  onVideoSelected(videoSelected?: MediaDeviceInfo): void {
    this.cameraStarted = !!videoSelected;
    this.videoActive = !!videoSelected;
  }

  @Watch("audioSelected", { immediate: true })
  onAudioSelected(audioSelected?: MediaDeviceInfo): void {
    this.audioActive = !!audioSelected;
  }

  audioActive = true;
  videoActive = true;
  settingsDialog = false;
  cameraStarted = false;

  setAudio(deviceId: string): void {
    const device =
      this.audioDevices?.find((dev) => dev.deviceId === deviceId) || undefined;
    this.selectAudio(device);
  }

  get audio(): Option {
    if (!this.audioSelected) return { text: "Kein Audio", value: "" };
    return {
      text: this.audioSelected.label,
      value: this.audioSelected.deviceId,
    };
  }

  setVideo(deviceId: string): void {
    const device =
      this.videoDevices?.find((dev) => dev.deviceId === deviceId) || undefined;
    this.selectVideo(device);
  }

  get video(): Option {
    if (!this.videoSelected) return { text: "Kein Video", value: "" };
    return {
      text: this.videoSelected.label,
      value: this.videoSelected.deviceId,
    };
  }

  get videoDeviceOptions(): Option[] {
    return [{ text: "Kein Video", value: "" }].concat(
      this.videoDevices.map((dev) => ({
        text: dev.label,
        value: dev.deviceId,
      }))
    );
  }

  get audioDeviceOptions(): Option[] {
    return [{ text: "Kein Audio", value: "" }].concat(
      this.audioDevices.map((dev) => ({
        text: dev.label,
        value: dev.deviceId,
      }))
    );
  }

  mounted(): void {
    this.init();
  }

  toggleVideo(): void {
    this.toggleVideoAction();
    this.videoActive = !this.videoActive;
  }

  toggleAudio(): void {
    this.toggleAudioAction();
    this.audioActive = !this.audioActive;
  }
}
